import React from "react";
import { BonusBehavior } from "types/globalTypes";
import { Table } from "antd";
import moment from "moment-timezone";

enum ColumnKey {
  Title = "title",
  Status = "status",
  StartDate = "startDate",
  EndDate = "endDate",
  SubmittedDate = "submittedDate",
  CompletedDate = "completedDate",
  Amount = "amount",
}

interface BonusBehaviorsProps {
  bonusBehaviors: BonusBehavior[];
  loading: boolean;
}
export const BonusBehaviors: React.FC<BonusBehaviorsProps> = ({
  bonusBehaviors,
  loading,
}) => (
  <div>
    <Table
      size="small"
      loading={loading}
      pagination={false}
      dataSource={bonusBehaviors}
      rowKey={({ title }) => title}
      columns={[
        { title: "Title", key: ColumnKey.Title, dataIndex: ColumnKey.Title },
        {
          title: "Start Date",
          key: ColumnKey.StartDate,
          dataIndex: ColumnKey.StartDate,
          render: (_, { startDate }) => moment(startDate).format("ll"),
        },
        {
          title: "End Date",
          key: ColumnKey.EndDate,
          dataIndex: ColumnKey.EndDate,
          render: (_, { endDate }) => moment(endDate).format("ll"),
        },
        {
          title: "Amount",
          key: ColumnKey.Amount,
          dataIndex: ColumnKey.Amount,
          render: (_, { rewards: { amount } }) => <>${amount}</>,
        },
        {
          title: "Submitted Date",
          key: ColumnKey.SubmittedDate,
          dataIndex: ColumnKey.SubmittedDate,
          render: (_, { submittedDate }) =>
            submittedDate
              ? moment(submittedDate)
                  .startOf("day")
                  .format("ll")
              : "--",
        },
        {
          title: "Completed Date",
          key: ColumnKey.CompletedDate,
          dataIndex: ColumnKey.CompletedDate,
          render: (_, { completedDate }) =>
            completedDate
              ? moment(completedDate)
                  .startOf("day")
                  .format("ll")
              : "--",
        },
      ]}
    />
  </div>
);

export default BonusBehaviors;
