import React from "react";
import { Tabs, Card } from "@wellth/web-ui";
import CARD_STYLES from "../styles";
import styles from "./styles";

const { TabPane } = Tabs;

interface Props {
  total: number;
  last30: number;
  last7: number;
}

interface AdherenceCardProps extends Props {
  header?: string;
}

const prettyDisplayPercent = (number: number): string =>
  `${Number.isNaN(number) ? "--" : Math.round(number)}%`;

const Adherence: React.FC<AdherenceCardProps> = ({
  header,
  last7,
  last30,
  total,
}) => (
  <>
    <div style={styles.allTasksHeader}>{header}</div>
    <div style={styles.container}>
      <div>
        <p style={styles.title}>Last 7</p>
        <p style={styles.content}>{prettyDisplayPercent(last7)}</p>
      </div>
      <div>
        <p style={styles.title}>Last 30</p>
        <p style={styles.content}>{prettyDisplayPercent(last30)}</p>
      </div>
      <div>
        <p style={styles.title}>Total</p>
        <p style={styles.content}>{prettyDisplayPercent(total)}</p>
      </div>
    </div>
  </>
);

const AdherenceTabs: React.FC<{
  requiredRemindersAdherence: Props;
  optionalRemindersAdherence: Props;
}> = ({ requiredRemindersAdherence, optionalRemindersAdherence }) => (
  <Tabs style={styles.tabHeader} defaultActiveKey="required">
    <TabPane tab="Required" key="required">
      <Adherence
        last30={requiredRemindersAdherence.last30}
        last7={requiredRemindersAdherence.last7}
        total={requiredRemindersAdherence.total}
      />
    </TabPane>
    <TabPane tab="Optional" key="optional">
      <Adherence
        last30={optionalRemindersAdherence.last30}
        last7={optionalRemindersAdherence.last7}
        total={optionalRemindersAdherence.total}
      />
    </TabPane>
  </Tabs>
);

interface AdherenceProps {
  optionalRemindersAdherence: Props;
  requiredRemindersAdherence: Props;
  allTasksRemindersAdherence: Props;
}

export const AdherenceCard: React.FC<AdherenceProps> = ({
  optionalRemindersAdherence,
  requiredRemindersAdherence,
  allTasksRemindersAdherence,
}) => (
  <Card
    headStyle={CARD_STYLES.head}
    bodyStyle={CARD_STYLES.body}
    style={CARD_STYLES.card}
    title="Adherence"
  >
    <Adherence
      last30={allTasksRemindersAdherence.last30}
      last7={allTasksRemindersAdherence.last7}
      total={allTasksRemindersAdherence.total}
      header="All Tasks"
    />
    <AdherenceTabs
      requiredRemindersAdherence={requiredRemindersAdherence}
      optionalRemindersAdherence={optionalRemindersAdherence}
    />
  </Card>
);

export default AdherenceCard;
