import React, { useRef } from "react";
import { Modal } from "components/Modal";
import { PauseStatus } from "types/globalTypes";
import ChangePauseStatusForm from "components/ChangePauseStatusForm";

export interface ChangePauseStatusSubmissionPayload {
  reason?: string;
  note?: string;
}

export interface ChangePauseStatusModalProps {
  goBack: () => void;
  submit: (payload: ChangePauseStatusSubmissionPayload) => Promise<void>;
  pauseStatus: PauseStatus;
  loading: boolean;
  submissionLoading: boolean;
}

const setTitle = (pauseStatus: PauseStatus): string => {
  switch (pauseStatus) {
    case PauseStatus.Active:
      return "Pause Request";
    case PauseStatus.Paused:
      return "Unpause Request";
    case PauseStatus.Pausing:
      return "Cancel Pause Request";
    case PauseStatus.Unpausing:
      return "Cancel Unpause Request";
    default:
      return "Other Actions";
  }
};

export const ChangePauseStatusModal: React.FC<ChangePauseStatusModalProps> = ({
  goBack,
  submit,
  pauseStatus,
  loading = false,
  submissionLoading = false,
}: ChangePauseStatusModalProps) => {
  const formRef = useRef(null);
  return (
    <Modal
      title={setTitle(pauseStatus)}
      loading={loading}
      submissionLoading={submissionLoading}
      visible
      onCancel={goBack}
      onOk={async (): Promise<void> => {
        const payload = await formRef.current.getPauseStatus();
        await submit(payload);
      }}
      okText="Submit"
    >
      <ChangePauseStatusForm pauseStatus={pauseStatus} ref={formRef} />
    </Modal>
  );
};

export default ChangePauseStatusModal;
