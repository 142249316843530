"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _default = _reactNative.StyleSheet.create({
  rightPortion: {
    flexDirection: "row",
    alignItems: "center"
  },
  titleLabel: {
    fontSize: 17,
    lineHeight: 20,
    color: _colors["default"].GREY,
    marginHorizontal: 16,
    marginLeft: 0
  },
  valueLabel: {
    fontSize: 17,
    lineHeight: 20,
    color: _colors["default"].BLACK
  },
  disclosureIndicator: {
    marginHorizontal: 16
  }
});
exports["default"] = _default;