import React from "react";
import moment from "moment-timezone";
import first from "lodash/first";
import { message } from "@wellth/web-ui";
import { LoadingScreen } from "@wellth/ui";
import { PROSPECT_PROFILE_REFETCH } from "utils";
import {
  useGetProspectByIdQuery,
  useUpdateProspectByIdMutation,
} from "hooks/graphql";
import formatName from "utils/formatName";
import {
  ProspectPatch,
  ProspectStatus as ProspectStatusType,
} from "types/globalTypes";
import { ProspectPage, ProspectStatus } from "components/ProspectPage";
import { AdminPrivilege } from "constants/adminPrivilege";

export interface ProspectInformationProps {
  id: string;
  adminPrivilege: AdminPrivilege;
  routeProspectStatus: (
    id: string,
    shortId: string,
    programCode: string,
    status: ProspectStatus,
    adminRole: AdminPrivilege,
  ) => void;
  routeProspectInformation: (id: string) => void;
  routeEnrollment: (id: string) => void;
  routeReOpenProspect: (programCode: string, shortId: string) => void;
}

export default ({
  id,
  adminPrivilege,
  routeProspectInformation,
  routeProspectStatus,
  routeEnrollment,
  routeReOpenProspect,
}: ProspectInformationProps) => {
  const {
    loading,
    error,
    data: {
      getProspectById: {
        status = ProspectStatus.open,
        createdAt = null,
        eligibilityEndDate = null,
        description = null,
        isTestProspect = false,
        pcpName = "",
        pcpNumber = "",
        person: {
          shortId = "",
          names = [],
          gender = "",
          birthDate = "",
          addresses = [],
          emailAddresses = [],
          phoneNumbers = [],
          primaryLanguage = "",
          primaryTimezone = "",
        } = {},
        program: { programCode = "", expiry = null } = {},
        prospectNotes = [],
        prospectStatuses = [],
        overallScore,
        externalId = null,
      } = {},
    } = {},
  } = useGetProspectByIdQuery({
    fetchPolicy: "network-only",
    onError: () => message.error("Unable to fetch prospect"),
    variables: {
      id,
    },
  });
  const [updateProspect] = useUpdateProspectByIdMutation();

  if (loading) {
    return <LoadingScreen showLoadingIndicator />;
  }

  const prospectName = first(names);
  const programExpired = moment(expiry).isBefore(new Date(), "day");

  const prospectIsEligible = eligibilityEndDate
    ? moment(eligibilityEndDate).isSameOrAfter(new Date(), "day")
    : true;
  const enrollmentEnabled = !programExpired && prospectIsEligible;
  const isEnrollmentEnabledForProgram = !programExpired;

  return (
    <ProspectPage
      id={id}
      externalId={externalId}
      enrollmentEnabled={enrollmentEnabled}
      isEnrollmentEnabledForProgram={isEnrollmentEnabledForProgram}
      name={prospectName ? formatName(prospectName) : ""}
      gender={gender}
      birthdate={birthDate}
      phoneNumbers={phoneNumbers}
      emailAddresses={emailAddresses}
      addresses={addresses}
      adminPrivilege={adminPrivilege}
      language={primaryLanguage}
      timezone={primaryTimezone}
      shortId={shortId}
      prospectSince={moment(createdAt)}
      status={status as ProspectStatus}
      prospectStatuses={prospectStatuses as ProspectStatusType[]}
      programCode={programCode}
      prospectNotes={prospectNotes}
      isTestUser={isTestProspect}
      routeProspectInformation={() => routeProspectInformation(id)}
      routeProspectStatus={() =>
        routeProspectStatus(
          id,
          shortId,
          programCode,
          status as ProspectStatus,
          adminPrivilege,
        )
      }
      routeEnrollment={() => routeEnrollment(id)}
      disablePage={!!error}
      updateProspect={async (prospect: ProspectPatch) => {
        await updateProspect({
          variables: {
            id,
            prospectPatch: {
              ...prospect,
            },
          },
          refetchQueries: PROSPECT_PROFILE_REFETCH(id),
          awaitRefetchQueries: true,
        });
      }}
      description={description}
      overallScore={overallScore}
      routeReOpenProspect={routeReOpenProspect}
      pcpName={pcpName}
      pcpNumber={pcpNumber}
    />
  );
};
