import React from "react";
import moment from "moment-timezone";
import COLORS from "utils/colors";
import { TIME_FORMAT, dayIsInTheFuture } from "utils/date";
import * as constants from "containers/MemberProfile/constants";
import { Lock, LockProps } from "../Lock";

const DEFAULT_PADDING: React.CSSProperties = {
  padding: 5,
};

const EDIT_STYLE: React.CSSProperties = {
  marginLeft: "auto",
  marginRight: "0.5rem",
  color: COLORS.midBlue,
  fontSize: "0.75rem",
  fontWeight: "normal",
  cursor: "pointer",
};

const CATEGORY_STYLE: React.CSSProperties = {
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: "#667580",
};
const TIME_STYLE: React.CSSProperties = {
  marginRight: "0.25rem",
  fontSize: "1.125rem",
};

const CONTAINER_STYLE: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  marginBottom: "0.5rem",
};

const CONTENT_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

const LOGO_STYLE: React.CSSProperties = {
  backgroundColor: "#d8d8d8",
  borderRadius: "50%",
  width: "2.1875rem",
  height: "2.1875rem",
  marginRight: "0.5rem",
};

export interface ReminderType {
  id: string;
  checkInCategory: string;
  monTime: string;
  startDate?: string;
  endDate?: string;
  windowAfterMinutes?: number;
  windowBeforeMinutes?: number;
  isRequired?: boolean;
  interval?: string;
  isDemo?: boolean;
}

interface ReminderModalClickProps {
  reminderId?: string;
  method: string;
}

export interface ReminderProps extends Partial<LockProps> {
  reminders: ReminderType[];
  onReminderModalClick?: (value: ReminderModalClickProps) => void;
  lockReminders: boolean;
  editReminders: boolean;
  programTemplate?: string;
}

export const Reminder: React.FC<ReminderProps> = ({
  handleReminderLockChange,
  isReminderEditingOpen,
  reminders,
  onReminderModalClick,
  lockReminders,
  editReminders,
}: ReminderProps) => {
  const renderLockReminders = () =>
    lockReminders ? (
      <Lock
        handleReminderLockChange={handleReminderLockChange}
        isReminderEditingOpen={isReminderEditingOpen}
      />
    ) : null;

  const renderEditReminders = (reminder) =>
    editReminders &&
    (dayIsInTheFuture(reminder.endDate, moment.tz.guess()) ||
      !reminder.endDate) ? (
      <span
        style={EDIT_STYLE}
        onClick={() =>
          onReminderModalClick({
            reminderId: reminder.id,
            method: constants.EDIT,
          })
        }
      >
        Edit
      </span>
    ) : (
      <span
        style={EDIT_STYLE}
        onClick={() =>
          onReminderModalClick({
            reminderId: reminder.id,
            method: constants.INFO,
          })
        }
      >
        Info
      </span>
    );

  const renderReminderList = () =>
    reminders.map((reminder: ReminderType) => (
      <div style={CONTAINER_STYLE} key={reminder.id}>
        <div style={LOGO_STYLE} />
        <div style={CONTENT_STYLE}>
          <span style={CATEGORY_STYLE}>
            {reminder.isDemo
              ? `${reminder.checkInCategory} (DEMO)`
              : reminder.checkInCategory}
          </span>
          <div>
            <span style={TIME_STYLE}>
              {moment(reminder.monTime, TIME_FORMAT).format(TIME_FORMAT)}
            </span>
            {reminder.interval === "DAY" ? (
              <span style={CATEGORY_STYLE}>every day</span>
            ) : (
              <span style={CATEGORY_STYLE}>
                every {moment(reminder.startDate).format("dddd")}
              </span>
            )}
          </div>
        </div>
        {renderEditReminders(reminder)}
      </div>
    ));

  return (
    <div>
      {reminders.length > 0 ? (
        <div>
          {renderLockReminders()}
          <div style={DEFAULT_PADDING}>{renderReminderList()}</div>
        </div>
      ) : (
        <span>No Data</span>
      )}
    </div>
  );
};

export default Reminder;
