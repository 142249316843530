import React, { useState } from "react";
import { message as alert } from "@wellth/web-ui";
import { TagsCard, Tag } from "components/TagsCard";
import {
  useAllAdminTagConfigsQuery,
  useAddIdentityTagMutation,
  useRemoveIdentityTagMutation,
  useActiveIdentityTagsByWellthIdQuery,
  ActiveIdentityTagsByWellthIdDocument as activeIdentityTagsQuery,
} from "hooks/graphql";

export interface IdentityTagProps {
  wellthId: string;
}

export const IdentityTag: React.FC<IdentityTagProps> = ({ wellthId }) => {
  const [isInputVisible, setIsInputVisible] = useState(false);

  const {
    data: { allAdminTagConfigs = [] } = {},
  } = useAllAdminTagConfigsQuery();

  const activeIdentityTagRefetchQuery = [
    { query: activeIdentityTagsQuery, variables: { wellthId } },
  ];

  const [addIdentityTag] = useAddIdentityTagMutation({
    onError: ({ message }) => alert.error(message),
    refetchQueries: activeIdentityTagRefetchQuery,
    awaitRefetchQueries: true,
  });
  const [removeIdentityTag] = useRemoveIdentityTagMutation({
    onError: ({ message }) => alert.error(message),
    refetchQueries: activeIdentityTagRefetchQuery,
    awaitRefetchQueries: true,
  });

  const {
    data: { activeIdentityTagsByWellthId = [] } = {},
  } = useActiveIdentityTagsByWellthIdQuery({ variables: { wellthId } });

  const identityTags: Tag[] = activeIdentityTagsByWellthId.map(
    ({
      id: tagId,
      backgroundColor,
      borderColor,
      name: title,
      isAdminRemovable,
    }) => ({
      id: tagId,
      title,
      backgroundColor,
      borderColor,
      removable: isAdminRemovable,
    }),
  );

  return (
    <TagsCard
      title="Tags"
      tags={identityTags}
      onRemoveTag={async (deletedTagId: string) => {
        await removeIdentityTag({
          variables: {
            input: {
              id: deletedTagId,
            },
          },
        });
      }}
      onAddTag={async (tagConfigId) => {
        setIsInputVisible(false);
        await addIdentityTag({
          variables: {
            input: { tagConfigId, wellthId },
          },
        });
      }}
      isInputVisible={isInputVisible}
      handleOpenInput={() => setIsInputVisible(true)}
      handleCloseInput={() => setIsInputVisible(false)}
      allAdminTagConfigs={allAdminTagConfigs
        .filter(({ name }) => !identityTags.some((tag) => tag.title === name))
        .sort((a, b) => a.name.localeCompare(b.name))}
    />
  );
};

export default IdentityTag;
