"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.FadeOutView = exports.FadeInView = exports.FadeView = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _assertThisInitialized2 = _interopRequireDefault(require("@babel/runtime/helpers/assertThisInitialized"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/FadeView/index.tsx";
function _createSuper(t) {
  var r = _isNativeReflectConstruct();
  return function () {
    var e,
      o = (0, _getPrototypeOf2["default"])(t);
    if (r) {
      var s = (0, _getPrototypeOf2["default"])(this).constructor;
      e = Reflect.construct(o, arguments, s);
    } else e = o.apply(this, arguments);
    return (0, _possibleConstructorReturn2["default"])(this, e);
  };
}
function _isNativeReflectConstruct() {
  try {
    var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
  } catch (t) {}
  return (_isNativeReflectConstruct = function _isNativeReflectConstruct() {
    return !!t;
  })();
}
var FadeView = function (_React$Component) {
  (0, _inherits2["default"])(FadeView, _React$Component);
  var _super = _createSuper(FadeView);
  function FadeView(props) {
    var _this;
    (0, _classCallCheck2["default"])(this, FadeView);
    _this = _super.call(this, props);
    (0, _defineProperty2["default"])((0, _assertThisInitialized2["default"])(_this), "fadeAnimation", new _reactNative.Animated.Value(0));
    var startValue = props.startValue;
    _this.fadeAnimation.setValue(startValue);
    return _this;
  }
  (0, _createClass2["default"])(FadeView, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var shouldAutoplay = this.props.autoplay;
      if (shouldAutoplay) {
        this.playAnimation();
      }
    }
  }, {
    key: "playAnimation",
    value: function playAnimation() {
      var _this$props = this.props,
        _this$props$onFinish = _this$props.onFinish,
        onFinish = _this$props$onFinish === void 0 ? function () {} : _this$props$onFinish,
        _this$props$duration = _this$props.duration,
        duration = _this$props$duration === void 0 ? 500 : _this$props$duration,
        delay = _this$props.delay,
        startValue = _this$props.startValue,
        finishValue = _this$props.finishValue;
      this.fadeAnimation.setValue(startValue);
      _reactNative.Animated.timing(this.fadeAnimation, {
        toValue: finishValue,
        duration: duration,
        delay: delay,
        useNativeDriver: true
      }).start(function () {
        onFinish(false);
      });
    }
  }, {
    key: "reverseAnimation",
    value: function reverseAnimation() {
      var _this$props2 = this.props,
        _this$props2$onFinish = _this$props2.onFinish,
        onFinish = _this$props2$onFinish === void 0 ? function () {} : _this$props2$onFinish,
        duration = _this$props2.duration,
        delay = _this$props2.delay,
        startValue = _this$props2.startValue,
        finishValue = _this$props2.finishValue;
      this.fadeAnimation.setValue(finishValue);
      _reactNative.Animated.timing(this.fadeAnimation, {
        toValue: startValue,
        duration: duration,
        delay: delay,
        useNativeDriver: true
      }).start(function () {
        onFinish(true);
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props3 = this.props,
        _this$props3$children = _this$props3.children,
        children = _this$props3$children === void 0 ? null : _this$props3$children,
        _this$props3$onLayout = _this$props3.onLayout,
        onLayout = _this$props3$onLayout === void 0 ? null : _this$props3$onLayout,
        _this$props3$style = _this$props3.style,
        style = _this$props3$style === void 0 ? {} : _this$props3$style;
      return _react["default"].createElement(_reactNative.Animated.View, {
        style: [style, {
          opacity: this.fadeAnimation
        }],
        onLayout: onLayout,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 86,
          columnNumber: 7
        }
      }, children);
    }
  }]);
  return FadeView;
}(_react["default"].Component);
exports.FadeView = FadeView;
var FadeInViewRFC = function FadeInViewRFC(_ref, ref) {
  var _ref$children = _ref.children,
    children = _ref$children === void 0 ? null : _ref$children,
    _ref$autoplay = _ref.autoplay,
    autoplay = _ref$autoplay === void 0 ? true : _ref$autoplay,
    delay = _ref.delay,
    duration = _ref.duration,
    style = _ref.style,
    onFinish = _ref.onFinish;
  return _react["default"].createElement(FadeView, {
    ref: ref,
    autoplay: autoplay,
    style: style,
    delay: delay,
    duration: duration,
    startValue: 0,
    finishValue: 1,
    onFinish: onFinish,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 104,
      columnNumber: 3
    }
  }, children);
};
var FadeInView = _react["default"].forwardRef(FadeInViewRFC);
exports.FadeInView = FadeInView;
var FadeOutViewRFC = function FadeOutViewRFC(_ref2, ref) {
  var _ref2$children = _ref2.children,
    children = _ref2$children === void 0 ? null : _ref2$children,
    _ref2$autoplay = _ref2.autoplay,
    autoplay = _ref2$autoplay === void 0 ? true : _ref2$autoplay,
    delay = _ref2.delay,
    duration = _ref2.duration,
    style = _ref2.style,
    onFinish = _ref2.onFinish;
  return _react["default"].createElement(FadeView, {
    ref: ref,
    autoplay: autoplay,
    style: style,
    delay: delay,
    duration: duration,
    startValue: 1,
    finishValue: 0,
    onFinish: onFinish,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 131,
      columnNumber: 3
    }
  }, children);
};
var FadeOutView = _react["default"].forwardRef(FadeOutViewRFC);
exports.FadeOutView = FadeOutView;
var _default = FadeView;
exports["default"] = _default;