import React, { useRef } from "react";
import { Form, FormInputType, message as alert, Modal } from "@wellth/web-ui";
import { useDeleteMemberMutation } from "hooks/graphql";
import { ProspectStatusEnum } from "types/globalTypes";

export interface DeleteMemberModalProps {
  id: string;
  goBack: () => void;
  goHome: () => void;
}

const WARNING_MESSAGE_STYLE: React.CSSProperties = {
  color: "#ff4d4f",
  fontSize: "1rem",
};

export const DeleteMemberModal = ({
  id,
  goBack,
  goHome,
}: DeleteMemberModalProps) => {
  const formRef = useRef(null);
  const [deleteMember] = useDeleteMemberMutation({
    onCompleted: () => {
      void alert.success("Successfully deleted member!");
      goHome();
    },
    onError: ({ graphQLErrors }) =>
      graphQLErrors.forEach(({ message }) => {
        void alert.error(`Error deleting member: ${message}`);
      }),
  });

  return (
    <Modal
      okText="Delete"
      okType="danger"
      title="Delete Member"
      visible
      onCancel={goBack}
      onOk={async () => {
        const { note } = formRef.current.props.form.getFieldsValue();
        await deleteMember({
          variables: {
            input: {
              id,
              desiredProspectStatus: ProspectStatusEnum.Open,
              note,
            },
          },
        });
      }}
    >
      <>
        <Form
          wrappedComponentRef={formRef}
          inputs={[
            {
              type: FormInputType.TextArea,
              title: "Add note (optional)",
              key: "note",
              autosize: { minRows: 2 },
            },
          ]}
        />
        <span style={WARNING_MESSAGE_STYLE}>
          Are you sure you wish to delete this member?
        </span>
      </>
    </Modal>
  );
};

export default DeleteMemberModal;
