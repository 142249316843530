import React from "react";
import { Card } from "@wellth/web-ui";
import MessageInput from "../MessageInput";
import CARD_STYLES from "../styles";

export interface InputCardProps {
  sendMessage: (text: string) => void;
  title: string;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  innerBodyStyle?: React.CSSProperties;
  buttonTitle: string;
  popconfirm: boolean;
  placeholder: string;
  popconfirmTitle?: string;
}

interface State {
  newText: string;
}

const INNER_BODY_STYLE: React.CSSProperties = {
  overflow: "scroll",
  height: "18.75rem",
  borderWidth: " 0 0 0.0625rem 0",
  borderStyle: "solid",
  borderColor: "#e5e5ea",
};

const BODY_STYLE: React.CSSProperties = {
  ...CARD_STYLES.body,
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
};

export default class InputCard extends React.Component<InputCardProps, State> {
  constructor(props: InputCardProps) {
    super(props);
    this.state = {
      newText: "",
    };
  }

  handleChange = (value) => {
    this.setState({
      newText: value,
    });
  };

  render() {
    const {
      title,
      children,
      sendMessage,
      bodyStyle,
      style,
      innerBodyStyle,
      ...rest
    } = this.props;

    const { newText } = this.state;

    return (
      <>
        <Card
          title={title}
          headStyle={CARD_STYLES.head}
          bodyStyle={{ ...BODY_STYLE, ...bodyStyle }}
          style={{ ...style, ...CARD_STYLES.card }}
        >
          <div style={{ ...INNER_BODY_STYLE, ...innerBodyStyle }}>
            {children}
          </div>
          <MessageInput
            {...rest}
            value={newText}
            onChange={this.handleChange}
            onConfirm={() => {
              sendMessage(newText);
              this.setState({ newText: "" });
            }}
          />
        </Card>
      </>
    );
  }
}
