import React, { CSSProperties, useRef, useState } from "react";
import { FormInputType, Form, Button } from "@wellth/web-ui";
import { BUTTON_CONTAINER_STYLES } from "containers/MemberProfile/styles";

const CREDIT_APPOINTMENT_REASONS = [
  { title: "App Issue / Bug", value: "App Issue / Bug" },
  {
    title: "Connection / Internet Issue",
    value: "Connection / Internet Issue",
  },
  { title: "Phone Issue", value: "Phone Issue" },
  {
    title: "Insufficient supplies / needs refill",
    value: "Insufficient supplies / needs refill",
  },
  { title: "Travel or moving", value: "Travel or moving" },
  { title: "Personal Emergency", value: "Personal Emergency" },
  {
    title: "Admitted into a Facility / Hospital",
    value: "Admitted into a Facility / Hospital",
  },
  { title: "Illness", value: "Illness" },
  {
    title: "Education / Misunderstanding",
    value: "Education / Misunderstanding",
  },
  { title: "Regimen has changed", value: "Regimen has changed" },
  { title: "Member Expired", value: "Member Expired" },
  { title: "Other Technical Issue(s)", value: "Other Technical Issue(s)" },
  { title: "Work-Related Issue", value: "Work-Related Issue" },
  { title: "Doctors Appointment", value: "Doctors Appointment" },
];

const CREDIT_CHECK_IN_REASONS = [
  { title: "App Issue / Bug", value: "App Issue / Bug" },
  {
    title: "Connection / Internet Issue",
    value: "Connection / Internet Issue",
  },
  { title: "Phone Issue", value: "Phone Issue" },
  {
    title: "Insufficient supplies / needs refill",
    value: "Insufficient supplies / needs refill",
  },
  { title: "Travel or moving", value: "Travel or moving" },
  { title: "Personal Emergency", value: "Personal Emergency" },
  {
    title: "Admitted into a Facility / Hospital",
    value: "Admitted into a Facility / Hospital",
  },
  { title: "Illness", value: "Illness" },
  {
    title: "Education / Misunderstanding",
    value: "Education / Misunderstanding",
  },
  { title: "Regimen has changed", value: "Regimen has changed" },
  { title: "Member Expired", value: "Member Expired" },
  { title: "Other Technical Issue(s)", value: "Other Technical Issue(s)" },
  { title: "Work-Related Issue", value: "Work-Related Issue" },
  { title: "Doctors Appointment", value: "Doctors Appointment" },
  {
    title: "Workflow: Retention / Intervention",
    value: "Workflow: Retention / Intervention",
  },
  {
    title: "Workflow: To Be Activated",
    value: "Workflow: To Be Activated",
  },
];

const REASON_OPTIONS = {
  "check-in": CREDIT_CHECK_IN_REASONS,
  appointment: CREDIT_APPOINTMENT_REASONS,
};

const CREDIT_TASK_STYLE = {
  padding: "0.5rem 0 0 0.5rem",
  fontWeight: "normal",
} as CSSProperties;

interface CreditTaskCardProps {
  creditTask: (reason: string) => Promise<void>;
  loading: boolean;
  type: "check-in" | "appointment";
}

const CreditTaskCard: React.FC<CreditTaskCardProps> = ({
  creditTask,
  loading,
  type,
}) => {
  const formRef = useRef(null);
  const [reason, setReason] = useState(null);
  return (
    <div style={CREDIT_TASK_STYLE}>
      <Form
        wrappedComponentRef={formRef}
        onValuesChange={(changedValues) => {
          setReason(changedValues.reason);
        }}
        inputs={[
          {
            type: FormInputType.Dropdown,
            title: "Reason",
            key: "reason",
            placeholder: "Select a reason",
            style: { height: "1rem", width: "20rem", paddingBottom: "1rem" },
            options: REASON_OPTIONS[type],
          },
        ]}
        mapPropsToFields={(_props, createFormField) => ({
          reason: createFormField({
            value: reason,
          }),
        })}
      />
      <div style={BUTTON_CONTAINER_STYLES}>
        <Button
          loading={loading}
          disabled={!reason}
          type="primary"
          onClick={() => {
            void creditTask(reason);
          }}
        >
          {`Credit ${type}`}
        </Button>
      </div>
    </div>
  );
};

export default CreditTaskCard;
