import React from "react";

export const ROW_STYLES: React.CSSProperties = {
  width: "100%",
};

export const TEXT_ALIGN: React.CSSProperties = {
  textAlign: "center",
};

export const CONTAINER_STYLES: React.CSSProperties = {
  padding: "1rem 1rem 0 1rem",
  background: "#e6ecf0",
};

export const CONTENT_STYLES: React.CSSProperties = {
  marginBottom: "1.25rem",
};

export const DISABLE_SCREEN_STYLES: React.CSSProperties = {
  backgroundColor: "grey",
  opacity: ".4",
  position: "absolute",
  height: "100%",
  width: "100%",
  zIndex: 20,
};

export const ACTIVITY_CARD: React.CSSProperties = { height: "100vh" };
