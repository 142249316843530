import React from "react";
import EditEmailAddressModal from "components/EditEmailAddressFormModal";
import { ContactType } from "types/globalTypes";
import {
  useEditEmailAddressQuery,
  useUpdateEmailAddressMutation,
  MemberEmailAddressesDocument as memberEmailAddressesQuery,
  ProspectEmailAddressesDocument as prospectEmailAddressesQuery,
} from "hooks/graphql";

export interface EditEmailAddressFormModalProps {
  id: string;
  contactId: string;
  contactType: ContactType;
  goBack: () => void;
}

export const EditEmailAddressFormModal: React.FC<
  EditEmailAddressFormModalProps
> = ({
  id,
  contactId,
  contactType,
  goBack,
}: EditEmailAddressFormModalProps) => {
  const {
    loading,
    data: { emailAddress: { isPrimary = false, emailAddress = "" } = {} } = {},
  } = useEditEmailAddressQuery({
    variables: {
      emailId: id,
    },
  });

  const [
    updateEmailAddress,
    { loading: submissionLoading },
  ] = useUpdateEmailAddressMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [
      contactType === ContactType.Member
        ? { query: memberEmailAddressesQuery, variables: { id: contactId } }
        : { query: prospectEmailAddressesQuery, variables: { id: contactId } },
    ],
  });

  return (
    <EditEmailAddressModal
      type={contactType}
      title="Update Email Address"
      emailAddress={emailAddress}
      isPrimary={isPrimary}
      loading={loading}
      submissionLoading={submissionLoading}
      cancel={goBack}
      disablePrimary={contactType === ContactType.Prospect}
      submit={async (emailAddressPatch) => {
        await updateEmailAddress({
          variables: {
            input: {
              emailAddressPatch: {
                id,
                ...emailAddressPatch,
              },
            },
          },
        });
      }}
    />
  );
};

export default EditEmailAddressFormModal;
