"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const styles = {
  container: {
    fontSize: "20px"
  },
  default: {
    backgroundColor: "white",
    border: 0,
    borderRadius: "5px",
    padding: "2rem",
    margin: "20px 0 20px 0",
    boxShadow: "0 .25rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0 .125rem 0 rgba(0, 0, 0, 0.12)"
  },
  header: {}
};
exports.default = styles;