import React, { useState, useEffect, useRef } from "react";
import { isEmpty, head } from "lodash";
import moment from "moment-timezone";
import { message as alert } from "@wellth/web-ui";
import Manage from "components/ManageMembers";
import { TableRow, TableSort } from "components/ManageMembers/components/Table";
import { useAllProgramsQuery, useAllMembersLazyQuery } from "hooks/graphql";
import {
  MemberOrderBy,
  MemberStatusValue,
  AllMembersQueryVariables,
} from "types/globalTypes";

const orderByForSortParameters = ({
  key,
  order,
}: TableSort): MemberOrderBy | null => {
  const ascending = order === "ascending";

  switch (key) {
    case "name":
      return ascending
        ? MemberOrderBy.MemberNameAsc
        : MemberOrderBy.MemberNameDesc;
    case "programCode":
      return ascending
        ? MemberOrderBy.ProgramCodeAsc
        : MemberOrderBy.ProgramCodeDesc;
    case "site":
      return ascending ? MemberOrderBy.SiteAsc : MemberOrderBy.SiteDesc;
    case "externalId":
      return ascending
        ? MemberOrderBy.CustomerIdAsc
        : MemberOrderBy.CustomerIdDesc;
    case "enrollmentDate":
      return ascending
        ? MemberOrderBy.EnrollmentDateAsc
        : MemberOrderBy.EnrollmentDateDesc;
    case "shortId":
      return ascending ? MemberOrderBy.ShortIdAsc : MemberOrderBy.ShortIdDesc;
    case "status":
      return ascending ? MemberOrderBy.StatusAsc : MemberOrderBy.StatusDesc;
    case "activationDate":
      return ascending
        ? MemberOrderBy.ActivationDateAsc
        : MemberOrderBy.ActivationDateDesc;
    default:
      return null;
  }
};

const limit = 100;

export interface ManageMembersProps {
  goBack: () => void;
  routeToMember: (member: TableRow) => void;
}

interface VariableState
  extends Omit<
    AllMembersQueryVariables,
    "offset" | "first" | "after" | "orderBy"
  > {
  page: number;
  orderBy: MemberOrderBy;
}

export const ManageMembers: React.FC<ManageMembersProps> = ({
  routeToMember,
}: ManageMembersProps) => {
  const [variables, setVariables] = useState<VariableState>({
    page: 0,
    orderBy: MemberOrderBy.StatusAsc,
  });

  const {
    data: { allPrograms: { nodes: programCodes = [] } = {} } = {},
  } = useAllProgramsQuery();

  const [
    getAllMembers,
    {
      data: { allMembers: { totalCount = 0, nodes: members = [] } = {} } = {},
      loading,
    },
  ] = useAllMembersLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors }) =>
      graphQLErrors.forEach(({ message }) => {
        void alert.error(`Error fetching members: ${message}`);
      }),
  });

  const initialRender = useRef(true);
  useEffect(
    () => {
      if (initialRender.current) {
        initialRender.current = false;
        return;
      }

      const { page, orderBy, ...rest } = variables;
      const offset = (page - 1) * limit;
      getAllMembers({
        variables: {
          ...rest,
          orderBy: [orderBy].filter(Boolean),
          first: limit,
          offset,
        },
      });
    },
    [getAllMembers, variables],
  );
  return (
    <Manage
      loading={loading}
      pagination={{
        pageSize: limit,
        total: totalCount,
        current: variables.page + 1,
      }}
      members={members.map(
        ({
          id,
          createdAt,
          siteName,
          externalId,
          status: { value, statusTransitionDate, reason },
          program: { programCode },
          person: { shortId, primaryFirstName, primaryLastName },
          statuses,
        }) => ({
          id,
          shortId,
          programCode,
          externalId,
          name: `${primaryFirstName} ${primaryLastName}`,
          status: value,
          site: siteName,
          enrollmentDate: moment(createdAt),
          activationDate: isEmpty(statuses)
            ? undefined
            : moment(head(statuses).statusTransitionDate),
          statusChangedAt: moment(statusTransitionDate),
          statusChangeReason: reason,
        }),
      )}
      programCodes={programCodes.map(({ programCode }) => programCode)}
      onFilterSetChanged={({
        programCodes: programCodeFilters = [],
        statuses = [],
        externalId = "",
      }) => {
        setVariables({
          ...variables,
          page: 1,
          condition: {
            statuses: statuses as MemberStatusValue[],
            programCodes: programCodeFilters,
            externalId,
          },
        });
      }}
      routeToMember={routeToMember}
      onTablePaginationChanged={({ currentPage, sort }) => {
        const orderBy = orderByForSortParameters(sort);

        if (orderBy !== variables.orderBy) {
          setVariables({
            ...variables,
            orderBy,
            page: 1,
          });
        } else {
          setVariables({
            ...variables,
            page: currentPage,
          });
        }
      }}
    />
  );
};

export default ManageMembers;
