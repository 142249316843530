import {
  PULSE_CONDITIONS_DATA_MART_API_URI,
  GET_PERSONS_CONDITIONS_SHARED_SECRETS,
} from "constants/config";
import { createUrl } from "utils";

export const BASE_URL = `${PULSE_CONDITIONS_DATA_MART_API_URI}/api`;

export const getConditionsByExternalIdProgramCode = (
  externalId: string,
  programCode: string,
) => [
  createUrl(
    BASE_URL,
    `/conditions/person/${externalId}/program/${programCode}`,
  ),
  {
    method: "GET",
    headers: { Authorization: GET_PERSONS_CONDITIONS_SHARED_SECRETS },
  },
];

const ConditionService = {
  getConditionsByExternalIdProgramCode,
};

export default ConditionService;
