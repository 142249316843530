import React from "react";
import { Card } from "@wellth/web-ui";
import CARD_STYLES from "../styles";

const CONTAINER: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
};

const CONTENT: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "1.0rem",
};

const LABEL: React.CSSProperties = {
  fontSize: "0.75rem",
  color: "#667580",
};

const BODY_STYLE: React.CSSProperties = {
  ...CARD_STYLES.body,
  fontSize: "1.125rem",
  color: "#1f2226",
  fontWeight: "bold",
};

export interface RewardsProps {
  latestBalance: string;
  balanceAsOfDate: string;
  nextPayOutAmount: number;
  nextPayoutDate: string;
  maximumCurrentPayout: number;
  onRewardModalClick: () => void;
}

export const Rewards: React.FC<RewardsProps> = ({
  latestBalance,
  balanceAsOfDate,
  nextPayOutAmount,
  nextPayoutDate,
  maximumCurrentPayout,
  onRewardModalClick,
}) => (
  <Card
    headStyle={CARD_STYLES.head}
    bodyStyle={BODY_STYLE}
    style={CARD_STYLES.card}
    title="Rewards"
    extra={<a onClick={onRewardModalClick}>See all</a>}
  >
    <div style={CONTAINER}>
      <div style={CONTENT}>
        <span>Reward Card Balance</span>
        <span style={LABEL}>As Of {balanceAsOfDate}</span>
      </div>
      <span>{`$${latestBalance}`}</span>
    </div>
    <div style={CONTAINER}>
      <div style={CONTENT}>
        <span>Next Payout Amount</span>
        <span style={LABEL}>{nextPayoutDate}</span>
      </div>
      <span>{`$${nextPayOutAmount}/$${maximumCurrentPayout}`}</span>
    </div>
  </Card>
);

export default Rewards;
