import React from "react";
import { Icon, Table } from "antd";
import moment from "moment-timezone";
import TitledBoxLayout from "components/TitledBoxLayout";

const DATE_FORMAT = "ll";
const TABLE_SIZE = "middle";
const NUMERICAL_COLUMN_WIDTH = "5rem";

enum ColumnKey {
  Name = "name",
  ProgramCode = "programCode",
  ExpiryDate = "expiryDate",
  CreatedAt = "createdAt",
  CustomerName = "customerName",
  TotalActiveMembers = "totalActiveMembers",
  CurrentActiveMembers = "currentActiveMembers",
  Edit = "edit",
  Sector = "sector",
  inactivateAfterPausedDays = "inactivateAfterPausedDays",
  pauseAfterNoActivityDays = "pauseAfterNoActivityDays",
  programTemplate = "programTemplate",
}

export interface Program {
  id: string;
  programCode: string;
  name: string;
  customerId: string;
  customerName: string;
  createdAt: Date;
  expiry: string;
  totalActiveMembers: number;
  currentActiveMembers: number;
  sector: string;
  inactivateAfterPausedDays: number;
  pauseAfterNoActivityDays: number;
  programTemplate: string;
}

export const Programs: React.FC<{
  programs: Program[];
  loading: boolean;
  routeEditProgram: (program: Program) => void;
  routeProgramModal: (program: Program) => void;
}> = ({ programs, loading, routeEditProgram, routeProgramModal }) => (
  <TitledBoxLayout title="Manage Program" secondaryTitle="">
    <Table
      size={TABLE_SIZE}
      loading={loading}
      pagination={false}
      dataSource={programs}
      scroll={{ x: true }}
      rowKey={({ id }) => id}
      expandRowByClick
      columns={[
        {
          title: "Program Code",
          key: ColumnKey.ProgramCode,
          dataIndex: ColumnKey.ProgramCode,
          fixed: "left",
          render: (_, { programCode }) => <a>{programCode}</a>,
          onCell: (record) => ({
            onClick: () => routeProgramModal(record),
          }),
        },
        { title: "Name", key: ColumnKey.Name, dataIndex: ColumnKey.Name },
        {
          title: "Customer Name",
          key: ColumnKey.CustomerName,
          dataIndex: ColumnKey.CustomerName,
        },
        {
          title: "Program Created Date",
          key: ColumnKey.CreatedAt,
          dataIndex: ColumnKey.CreatedAt,
          render: (_, { createdAt }) => moment(createdAt).format(DATE_FORMAT),
        },
        {
          title: "Expiry Date",
          key: ColumnKey.ExpiryDate,
          dataIndex: ColumnKey.ExpiryDate,
          render: (_, { expiry }) => (
            <div style={{ paddingRight: "2.5rem" }}>
              {moment(expiry).format(DATE_FORMAT)}
            </div>
          ),
        },
        {
          title: "Program Template",
          key: ColumnKey.programTemplate,
          dataIndex: ColumnKey.programTemplate,
        },
        { title: "Sector", key: ColumnKey.Sector, dataIndex: ColumnKey.Sector },
        {
          title: "Total Lifetime Active Members",
          key: ColumnKey.TotalActiveMembers,
          dataIndex: ColumnKey.TotalActiveMembers,
          width: NUMERICAL_COLUMN_WIDTH,
        },
        {
          title: "Current Active Members",
          key: ColumnKey.CurrentActiveMembers,
          dataIndex: ColumnKey.CurrentActiveMembers,
          width: NUMERICAL_COLUMN_WIDTH,
        },
        {
          title: "Paused Days Until Inactivation",
          key: ColumnKey.inactivateAfterPausedDays,
          dataIndex: ColumnKey.inactivateAfterPausedDays,
          width: NUMERICAL_COLUMN_WIDTH,
        },
        {
          title: "Consecutive Missed Days Until Paused",
          key: ColumnKey.pauseAfterNoActivityDays,
          dataIndex: ColumnKey.pauseAfterNoActivityDays,
          width: NUMERICAL_COLUMN_WIDTH,
        },

        {
          title: "Edit",
          key: ColumnKey.Edit,
          render: (_, record) => (
            <Icon type="edit" onClick={() => routeEditProgram(record)} />
          ),
        },
      ]}
    />
  </TitledBoxLayout>
);

export default Programs;
