"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../../../constants/colors"));
var _default = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: _colors["default"].GREY_LIGHT,
    borderRadius: 11
  },
  titleContainer: {
    marginLeft: 12,
    marginTop: 12
  },
  titleLabel: {
    fontSize: 20,
    fontWeight: "600",
    lineHeight: 24
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  imageAsset: {
    height: 100,
    margin: 9
  }
});
exports["default"] = _default;