import React, { RefAttributes } from "react";
import orderBy from "lodash/orderBy";
import {
  Button,
  FormInputType,
  Popconfirm,
  SectionProps,
  SectionType,
  Icon,
} from "@wellth/web-ui";
import { SectionHeader } from "components/SectionHeader";
import { MailingAddressSectionProps as BaseProps, Address } from "../types";
import styles from "./styles";

const formatAddress = ({
  street,
  street2,
  city,
  state,
  zip,
  country,
}: Omit<Address, "id">) => (
  <>
    {street}
    <br />
    {street2 ? (
      <>
        {street2}
        <br />
      </>
    ) : null}
    {`${city}, ${state} ${zip}`}
    <br />
    {country}
  </>
);

interface MailingAddressActions {
  onEditMailingAddress: (mailingAddress: Address) => void;
  onCreateMailingAddress: () => void;
  onDeleteMailingAddress: (mailingAddress: Address) => void;
}

export interface MailingAddressesSectionProps
  extends BaseProps,
    RefAttributes<any>,
    MailingAddressActions {}

export const Inputs = ({
  ref,
  mailingAddresses = [],
  onEditMailingAddress,
  onCreateMailingAddress,
  onDeleteMailingAddress,
}: MailingAddressesSectionProps): SectionProps<any> => ({
  ref,
  title: (
    <SectionHeader
      title="Mailing address(es)"
      submitButtonType="plus-circle"
      onClick={onCreateMailingAddress}
    />
  ),
  key: "mailingAddresses",
  type: SectionType.Form,
  data: {
    mapPropsToFields: (props, createFormField) =>
      mailingAddresses.reduce(
        (fields, { id, ...address }) => ({
          ...fields,
          [`address:${id}`]: createFormField({ value: formatAddress(address) }),
        }),
        {},
      ),
    inputs: orderBy(mailingAddresses, "isPrimary", "desc").map((address) => ({
      type: FormInputType.Info,
      title: "Address",
      style: address.isPrimary
        ? { ...styles.verifiedInput, lineHeight: "1rem" }
        : { lineHeight: "1rem" },
      key: `address:${address.id}`,
      inputTrailer: () => (
        <div style={styles.trailerContainer}>
          {address.isPrimary ? (
            <span style={styles.trailer}>
              <Icon
                type="check-circle"
                theme="filled"
                style={styles.verifiedIcon}
              />
            </span>
          ) : null}
          <Button
            disabled={address.isPrimary}
            onClick={() => onEditMailingAddress(address)}
            style={styles.trailer}
          >
            <Icon type="edit" />
          </Button>

          <span style={styles.trailer}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => onDeleteMailingAddress(address)}
            >
              <Button disabled={address.isPrimary}>
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          </span>
        </div>
      ),
    })),
    labelCol: {
      sm: 4,
    },
  },
});

export default Inputs;
