import React from "react";

export const LocationPinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#7B8794"
      d="M10 2.5a5.006 5.006 0 0 0-5 5c0 2.194 3.125 7.516 4.467 9.703a.625.625 0 0 0 1.066-.001C11.875 15.016 15 9.696 15 7.5a5.006 5.006 0 0 0-5-5zm0 7.188a2.188 2.188 0 1 1 0-4.376 2.188 2.188 0 0 1 0 4.375z"
    />
  </svg>
);

export default LocationPinIcon;
