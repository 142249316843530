import React from "react";
import moment from "moment-timezone";
import { Table, Icon } from "@wellth/web-ui";
import store from "constants/store";

export interface ProspectTableProps {
  data: any;
}

export default ({ data }: ProspectTableProps) => (
  <div>
    <Table
      columns={[
        {
          title: "Program Code",
          dataIndex: "programCode",
          key: "programCode",
        },
        {
          title: "Date Submitted",
          dataIndex: "dateSubmitted",
          key: "dateSubmitted",
          render: (text: Date) => (
            <div>{moment(text).format("MM-DD-YYYY")}</div>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          align: "center",
        },
        {
          title: "Download",
          dataIndex: "downloadLink",
          key: "downloadLink",
          align: "center",
          render: (url: string) =>
            url ? (
              <a
                onClick={async () => {
                  const {
                    session: { token },
                  } = store.getState();

                  const response = await fetch(url, {
                    method: "GET",
                    headers: new Headers({ Authorization: `Bearer ${token}` }),
                  });

                  const blob = await response.blob();

                  const anchor = document.createElement("a");
                  document.body.appendChild(anchor);
                  const objectUrl = window.URL.createObjectURL(blob);
                  anchor.href = objectUrl;
                  anchor.download = "prospect-upload-result.csv";
                  anchor.click();
                  window.URL.revokeObjectURL(objectUrl);
                }}
                style={{ textAlign: "center" }}
              >
                <Icon type="download" />
              </a>
            ) : null,
        },
      ]}
      dataSource={data}
      pagination={false}
      rowKey="id"
    />
  </div>
);
