const styles: { [key: string]: React.CSSProperties } = {
  container: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 50,
    paddingBottom: 50,
  },
  title: {
    fontWeight: "bold",
    fontSize: "2.125rem",
  },
};

export default styles;
