import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import moment from "moment-timezone";
import TitledBoxLayout from "components/TitledBoxLayout";
import "./index.css";

const DATE_FORMAT = "ll";
const TABLE_SIZE = "middle";
const EXPIRED = "expired";
const ICON_WIDTH = "30rem";

enum ColumnKey {
  Name = "name",
  ProgramCode = "programCode",
  EndDate = "endDate",
  StartDate = "startDate",
  RewardAmount = "rewardAmount",
  Note = "note",
  Pages = "pages",
  Title = "title",
  Detail = "detail",
  Type = "type",
  Icon = "icon",
}

enum BonusBehaviorPageTypeValue {
  Instruction = "instruction",
  Submission = "submission",
  Verify = "verify",
  Success = "success",
}

type BonusBehaviorPage = {
  title: string;
  detail: string;
  type: BonusBehaviorPageTypeValue;
  icon: string;
};

interface BonusBehaviorPagesProps {
  pages: BonusBehaviorPage[];
  loading: boolean;
}

const BonusBehaviorPages: React.FC<BonusBehaviorPagesProps> = ({
  pages,
  loading,
}) => (
  <Table
    size={TABLE_SIZE}
    loading={loading}
    pagination={false}
    dataSource={pages}
    rowKey={({ title }) => title}
    columns={[
      {
        title: "Type",
        key: ColumnKey.Type,
        dataIndex: ColumnKey.Type,
      },
      {
        title: "Title",
        key: ColumnKey.Title,
        dataIndex: ColumnKey.Title,
      },
      {
        title: "Detail",
        key: ColumnKey.Detail,
        dataIndex: ColumnKey.Detail,
      },
      {
        title: "Icon",
        key: ColumnKey.Icon,
        dataIndex: ColumnKey.Icon,
        render: (_, { icon }) =>
          icon ? (
            <img src={icon} alt="Page Icon" width={ICON_WIDTH} />
          ) : (
            "No icon"
          ),
      },
    ]}
  />
);

type BonusBehaviorConfig = {
  id: string;
  name: string;
  programCode: string;
  endDate: string;
  startDate: string;
  rewardAmount: number;
  note: string;
  bonusBehaviorPagesetId: string;
  icon: string;
};

interface BonusBehaviorConfigProps {
  bonusBehaviorConfigs: BonusBehaviorConfig[];
  loading: boolean;
  getPagesByPagesetId: (pagesetId: string) => void;
  pages: BonusBehaviorPage[];
  pageLoading: boolean;
  routeToBonusBehaviorModal: () => void;
}

const BonusBehaviorConfigs: React.FC<BonusBehaviorConfigProps> = ({
  bonusBehaviorConfigs,
  loading,
  getPagesByPagesetId,
  pages,
  pageLoading,
  routeToBonusBehaviorModal,
}) => {
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [configPages, setConfigPages] = useState({});
  useEffect(
    () => {
      setConfigPages((c) => ({
        ...c,
        [expandedRowId]: pages,
      }));
    },
    [pages, expandedRowId],
  );
  return (
    <TitledBoxLayout
      title="Manage Program Bonus Behaviors"
      secondaryTitle={
        <Button onClick={routeToBonusBehaviorModal}>
          Create Bonus Behavior
        </Button>
      }
    >
      <Table
        size={TABLE_SIZE}
        rowClassName={({ endDate }) =>
          moment(endDate).isBefore(moment().add(1, "day")) ? EXPIRED : null
        }
        loading={loading}
        pagination={false}
        dataSource={bonusBehaviorConfigs}
        rowKey={({ id }) => id}
        expandRowByClick
        expandedRowRender={({ bonusBehaviorPagesetId }) => (
          <BonusBehaviorPages
            pages={configPages[bonusBehaviorPagesetId]}
            loading={pageLoading}
          />
        )}
        onExpand={(_, { bonusBehaviorPagesetId }) => {
          setExpandedRowId(bonusBehaviorPagesetId);
          if (!configPages[bonusBehaviorPagesetId]) {
            getPagesByPagesetId(bonusBehaviorPagesetId);
          }
        }}
        columns={[
          {
            title: "Name",
            key: ColumnKey.Name,
            dataIndex: ColumnKey.Name,
          },
          {
            title: "Program Code",
            key: ColumnKey.ProgramCode,
            dataIndex: ColumnKey.ProgramCode,
          },
          {
            title: "Reward Amount",
            key: ColumnKey.RewardAmount,
            dataIndex: ColumnKey.RewardAmount,
            render: (_, { rewardAmount }) => <>${rewardAmount}</>,
          },
          {
            title: "Start Date",
            key: ColumnKey.StartDate,
            dataIndex: ColumnKey.StartDate,
            render: (_, { startDate }) => moment(startDate).format(DATE_FORMAT),
          },
          {
            title: "End Date",
            key: ColumnKey.EndDate,
            dataIndex: ColumnKey.EndDate,
            render: (_, { endDate }) => moment(endDate).format(DATE_FORMAT),
          },
          {
            title: "Note",
            key: ColumnKey.Note,
            dataIndex: ColumnKey.Note,
          },
          {
            title: "Card Icon",
            key: ColumnKey.Icon,
            dataIndex: ColumnKey.Icon,
            render: (_, { icon }) => (
              <img src={icon} width={ICON_WIDTH} alt="Card Icon" />
            ),
          },
        ]}
      />
    </TitledBoxLayout>
  );
};

export default BonusBehaviorConfigs;
