import React from "react";
import { Modal } from "components/Modal";
import {
  ProspectInformationForm,
  ProspectInformationFormConfiguration,
} from "components/ProspectInformationForm";

export interface ProspectInformationModalProps
  extends ProspectInformationFormConfiguration {
  loading: boolean;
  cancel: () => void;
}

export const ProspectInformationModal: React.FC<
  ProspectInformationModalProps
> = ({
  cancel,
  loading = false,
  ...formProps
}: ProspectInformationModalProps) => (
  <Modal
    title="View Information"
    loading={loading}
    onCancel={cancel}
    footer={null}
    width={700}
  >
    <ProspectInformationForm {...formProps} />
  </Modal>
);

export default ProspectInformationModal;
