/* eslint-disable no-nested-ternary */
import React from "react";
import moment from "moment-timezone";
import { Tooltip } from "antd";
import { Modal, Button, Tabs, Table } from "@wellth/web-ui";
import {
  useAllAccountTransactionsQuery,
  useAllBonusBehaviorsQuery,
  useRewardPeriodBalancesQuery,
  useRewardCardDetailsByMemberIdQuery,
} from "hooks/graphql";
import {
  AccountTransactionsOrderBy,
  BonusBehavior,
  PauseStatus,
  RewardBalanceRelativeTo,
} from "types/globalTypes";
import { startCase } from "lodash";
import { BonusBehaviors } from "./BonusBehaviors";

const { TabPane } = Tabs;
const DASHES = "--";
const PAUSED_TOOLTIP_MESSAGE =
  "Member is currently in a paused state. Rewards are unavailable at this time.";
const NOT_ISSUED = "NOT ISSUED";

const TransactionReasonDescription = {
  LOTTERY_TRANSFER: "---",
  REWARD_TRANSFER:
    "Transfer to internal Wellth account. (Not yet paid out to debit card.)",
  MANUAL_ADJUSTMENT: "Adjustment to rewards made manually by an admin.",
  OUTGOING_PAYMENT: "Transfer of rewards to debit card.",
  BONUS_BEHAVIOR_CREDIT: "Completion of a bonus behavior.",
  WINBACK: "Member was eligibile for WinBack rewards.",
  AUTO_ADJUSTMENT:
    "Adjustment to rewards made automatically from Wellth system.",
};

const TransactionReason = {
  LOTTERY_TRANSFER: "Lottery",
  REWARD_TRANSFER: "Reward Transfer",
  MANUAL_ADJUSTMENT: "Manual Adjustment",
  OUTGOING_PAYMENT: "Outgoing Payment",
  BONUS_BEHAVIOR_CREDIT: "Bonus Behavior Credit",
  WINBACK: "Winback",
  AUTO_ADJUSTMENT: "Auto Adjustment",
};

enum ColumnKey {
  RewardPeriodId = "rewardPeriodId",
  PeriodStart = "periodStart",
  PeriodEnd = "periodEnd",
  SettleDate = "settleDate",
  PeriodDuration = "periodDuration",
  RewardsEarned = "rewardsEarned",
  PeriodReward = "periodReward",
  DailyLoss = "periodLossPerDayMax",
  Date = "date",
  Amount = "amount",
  Currency = "currency",
  Reason = "reason",
  Note = "note",
}

interface Input {
  title: string;
  body: string;
}

const BOLD_STYLE: React.CSSProperties = {
  fontWeight: "bold",
};

const CONTAINER_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

const TITLE_STYLE: React.CSSProperties = {
  padding: "0.3125rem",
  textAlign: "right",
};

const BODY_STYLE: React.CSSProperties = {
  padding: "0.3125rem",
  textAlign: "left",
  fontWeight: "bold",
};

export interface RewardsProps {
  memberId: string;
  goBack: () => void;
  routeManualCredit: () => void;
  periodSum: number;
  periodEnd: Date;
  periodStart: Date;
  settleDate: Date;
  periodReward: number;
  isAdmin: boolean;
  rewardPeriodId: string;
  pauseStatus: PauseStatus;
  routeEditPaymentOption: () => void;
}

const isActiveStatus = (pauseStatus) =>
  pauseStatus === PauseStatus.Active || pauseStatus === PauseStatus.Pausing;

const CurrentPeriodDecorator: React.FC<{
  currentId: string;
  rewardPeriodId: string;
  flag?: boolean;
  pauseStatus: PauseStatus;
}> = ({ currentId, rewardPeriodId, flag = false, pauseStatus, children }) => (
  <>
    {isActiveStatus(pauseStatus) && currentId === rewardPeriodId ? (
      <div style={BOLD_STYLE}>
        {children}
        {flag ? "*" : ""}
      </div>
    ) : (
      children
    )}
  </>
);

export const Rewards: React.FC<RewardsProps> = ({
  memberId,
  goBack,
  periodStart,
  periodEnd,
  periodSum,
  periodReward,
  settleDate,
  routeManualCredit,
  isAdmin,
  rewardPeriodId,
  pauseStatus,
  routeEditPaymentOption,
}) => {
  const {
    data: { rewardPeriodBalances: { nodes: rewardBalances } = {} } = {},
    loading: balanceLoading,
  } = useRewardPeriodBalancesQuery({
    variables: {
      memberId,
      relativeTo: RewardBalanceRelativeTo.StartDateAsc,
    },
  });

  const {
    data: { allAccountTransactions: { nodes } = {} } = {},
    loading,
  } = useAllAccountTransactionsQuery({
    variables: {
      memberId,
      orderBy: AccountTransactionsOrderBy.DateDesc,
    },
  });

  const {
    data: { rewardCardDetailsByMemberId } = {},
  } = useRewardCardDetailsByMemberIdQuery({
    variables: {
      id: memberId,
    },
  });

  const {
    data: { allBonusBehaviors = [] } = {},
    loading: bonusLoading,
  } = useAllBonusBehaviorsQuery({
    variables: {
      memberId,
    },
  });

  const PAYOUT_DATE = settleDate
    ? moment(settleDate).format("ll")
    : "No Upcoming Payouts";

  const {
    id: debitCardId = undefined,
    createdAt = undefined,
    processor = undefined,
    latestBalance = undefined,
    updatedAt = undefined,
    packId = undefined,
    orderDate = undefined,
    shippedDate = undefined,
    activationDate = undefined,
    rewardCardChoice = undefined,
  } = rewardCardDetailsByMemberId || {};

  const processorName = {
    alder: "Incomm - Alder",
    incomm: "Incomm - GPRGo",
    default: processor ? startCase(processor) : NOT_ISSUED,
  };

  const showWhenIsPaused = ["Reward Card Type", "Reward Card Status"];

  const rewardCardStatus = (
    <div>
      {!debitCardId ? (
        <>Reward Card not yet Ordered</>
      ) : (
        <div>
          <div>Reward Card Order Created: {moment(createdAt).format("ll")}</div>
          {orderDate ? (
            <div>
              Reward Card Order Confirmed: {moment(orderDate).format("ll")}
            </div>
          ) : null}
          {shippedDate ? (
            <div>
              Reward Card Shipped/Fulfilled: {moment(shippedDate).format("ll")}
            </div>
          ) : null}
          {activationDate ? (
            <div>
              Reward Card Activated: {moment(activationDate).format("ll")}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );

  const inputs = [
    {
      title: "Reward Card Choice",
      body: (
        <>
          {rewardCardChoice || DASHES}
          <Button
            size="small"
            style={{ margin: "0 1rem 0 1rem" }}
            onClick={() => routeEditPaymentOption()}
            disabled={!isAdmin}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      title: "Reward Card Processor",
      body: processorName[processor] || processorName.default,
    },
    {
      title: "Reward Card Pack ID",
      body: packId || DASHES,
    },
    {
      title: "Reward Card Status",
      body: rewardCardStatus,
    },
    {
      title: "Reward Card Balance",
      body: latestBalance ? (
        <div>
          ${latestBalance} as of {moment(updatedAt).format("ll")}
        </div>
      ) : (
        DASHES
      ),
    },

    { title: "Current Reward Period Kept Amount", body: `$${periodSum}*` },
    { title: "Current Reward Period Maximum Amount", body: `$${periodReward}` },
    {
      title: "Current Reward Period Start Date",
      body: moment(periodStart).format("ll"),
    },
    {
      title: "Current Reward Period End Date",
      body: moment(periodEnd).format("ll"),
    },
    { title: "Payout Date", body: PAYOUT_DATE },
  ];

  const decoratorItems = {
    currentId: rewardPeriodId,
    pauseStatus,
  };

  return (
    <Modal
      width="60rem"
      visible
      onCancel={goBack}
      bodyStyle={{ padding: "1rem" }}
      footer={null}
    >
      <Tabs defaultActiveKey="rewards" size="small" tabBarGutter={0}>
        <TabPane tab="View Reward Periods" key="rewards">
          <div style={{ justifyContent: "center" }}>
            {inputs.map(({ title, body }: Input, index) => (
              <div key={index} style={CONTAINER_STYLE}>
                <div style={TITLE_STYLE}>{title}: </div>
                <div style={BODY_STYLE}>
                  {isActiveStatus(pauseStatus) ? (
                    body
                  ) : showWhenIsPaused.includes(title) ? (
                    body
                  ) : (
                    <Tooltip title={PAUSED_TOOLTIP_MESSAGE}>{DASHES}</Tooltip>
                  )}
                </div>
              </div>
            ))}
            <Table
              style={{ padding: "1rem" }}
              size="small"
              loading={balanceLoading}
              pagination={false}
              dataSource={rewardBalances}
              rowKey={({ rewardPeriodId: rowId }) => rowId}
              columns={[
                {
                  title: "Reward Period",
                  key: ColumnKey.RewardPeriodId,
                  dataIndex: ColumnKey.RewardPeriodId,
                  fixed: "left",
                  render: (_, { rewardPeriodId: rewardId }, index) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/restrict-plus-operands
                    const rewardPeriodNumber = index + 1;
                    return (
                      <CurrentPeriodDecorator
                        rewardPeriodId={rewardId}
                        {...decoratorItems}
                      >
                        Reward Period {rewardPeriodNumber}
                      </CurrentPeriodDecorator>
                    );
                  },
                },
                {
                  title: "Start Date",
                  key: ColumnKey.PeriodStart,
                  dataIndex: ColumnKey.PeriodStart,
                  align: "center",
                  render: (
                    _,
                    { periodStart: startDate, rewardPeriodId: rewardId },
                  ) => (
                    <CurrentPeriodDecorator
                      rewardPeriodId={rewardId}
                      {...decoratorItems}
                    >
                      {moment(startDate).format("ll")}
                    </CurrentPeriodDecorator>
                  ),
                },
                {
                  title: "End Date",
                  key: ColumnKey.PeriodEnd,
                  dataIndex: ColumnKey.PeriodEnd,
                  align: "center",
                  render: (
                    _,
                    { periodEnd: endDate, rewardPeriodId: rewardId },
                  ) => (
                    <CurrentPeriodDecorator
                      rewardPeriodId={rewardId}
                      {...decoratorItems}
                    >
                      {moment(endDate).format("ll")}
                    </CurrentPeriodDecorator>
                  ),
                },
                {
                  title: "Payout Date",
                  key: ColumnKey.SettleDate,
                  dataIndex: ColumnKey.SettleDate,
                  align: "center",
                  render: (
                    _,
                    { settleDate: rewardPayout, rewardPeriodId: rewardId },
                  ) => (
                    <CurrentPeriodDecorator
                      rewardPeriodId={rewardId}
                      {...decoratorItems}
                    >
                      {moment(rewardPayout).format("ll")}
                    </CurrentPeriodDecorator>
                  ),
                },
                {
                  title: "Period Duration",
                  key: ColumnKey.PeriodDuration,
                  dataIndex: ColumnKey.PeriodDuration,
                  width: "7rem",
                  align: "center",
                  render: (
                    _,
                    {
                      periodStart: startDate,
                      periodEnd: endDate,
                      rewardPeriodId: rewardId,
                    },
                  ) => (
                    <CurrentPeriodDecorator
                      rewardPeriodId={rewardId}
                      {...decoratorItems}
                    >
                      {moment(endDate).diff(startDate, "day") + 1} days
                    </CurrentPeriodDecorator>
                  ),
                },
                {
                  title: "Kept Rewards",
                  key: ColumnKey.RewardsEarned,
                  dataIndex: ColumnKey.RewardsEarned,
                  width: "7rem",
                  align: "center",
                  render: (_, { rewardsEarned, rewardPeriodId: rewardId }) => (
                    <CurrentPeriodDecorator
                      rewardPeriodId={rewardId}
                      flag
                      {...decoratorItems}
                    >
                      ${rewardsEarned}
                    </CurrentPeriodDecorator>
                  ),
                },
                {
                  title: "Maximum Reward",
                  key: ColumnKey.PeriodReward,
                  dataIndex: ColumnKey.PeriodReward,
                  width: "7rem",
                  align: "center",
                  render: (
                    _,
                    { periodReward: rewardAmount, rewardPeriodId: rewardId },
                  ) => (
                    <CurrentPeriodDecorator
                      rewardPeriodId={rewardId}
                      {...decoratorItems}
                    >
                      ${rewardAmount}
                    </CurrentPeriodDecorator>
                  ),
                },
                {
                  title: "Daily Loss",
                  key: ColumnKey.DailyLoss,
                  dataIndex: ColumnKey.DailyLoss,
                  align: "center",
                  render: (
                    _,
                    {
                      periodLossPerDayMax: dailyLoss,
                      rewardPeriodId: rewardId,
                    },
                  ) => (
                    <CurrentPeriodDecorator
                      rewardPeriodId={rewardId}
                      {...decoratorItems}
                    >
                      ${dailyLoss}
                    </CurrentPeriodDecorator>
                  ),
                },
              ]}
            />
            <div style={{ ...CONTAINER_STYLE, ...BOLD_STYLE }}>
              {isActiveStatus(pauseStatus) && rewardPeriodId ? (
                <div>*Rewards currently in progress</div>
              ) : (
                <div style={{ color: "red" }}>
                  *Reward periods will most likely be adjusted once member
                  returns to an active state
                </div>
              )}
            </div>
          </div>
        </TabPane>

        <TabPane tab="Transaction History" key="transactionHistory">
          <Table
            size="small"
            loading={loading}
            pagination={false}
            dataSource={nodes}
            rowKey={({ id: transactionId }) => transactionId}
            columns={[
              {
                title: "Transaction Date",
                key: ColumnKey.Date,
                dataIndex: ColumnKey.Date,
                render: (_, { date }) => moment(date).format("ll"),
              },
              {
                title: "Amount",
                key: ColumnKey.Amount,
                dataIndex: ColumnKey.Amount,
                render: (_, { amount }) => <>${Math.abs(amount)}</>,
              },
              {
                title: "Reason",
                key: ColumnKey.Reason,
                dataIndex: ColumnKey.Reason,
                render: (_, { reason }) => (
                  <Tooltip title={TransactionReasonDescription[reason]}>
                    {TransactionReason[reason]}
                  </Tooltip>
                ),
              },
              { title: "Note", key: ColumnKey.Note, dataIndex: ColumnKey.Note },
            ]}
          />
        </TabPane>
        <TabPane tab="Manual Credit" key="manualCredit" disabled={!isAdmin}>
          <div style={CONTAINER_STYLE}>
            <Button type="primary" onClick={(): void => routeManualCredit()}>
              Manual Credit
            </Button>
          </div>
        </TabPane>
        <TabPane tab="Action Rewards" key="bonusBehaviors">
          <BonusBehaviors
            bonusBehaviors={allBonusBehaviors as BonusBehavior[]}
            loading={bonusLoading}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default Rewards;
