import isEmpty from "lodash/isEmpty";
import { AddressInput } from "types/globalTypes";

export type Address = Partial<Omit<AddressInput, "id">>;

export const normalizeAddress = ({
  street,
  street2,
  city,
  state,
  country,
  zip,
  isPrimary = false,
}: Address): AddressInput | null => {
  if (
    !!street &&
    !isEmpty(street) &&
    !!city &&
    !isEmpty(city) &&
    !!state &&
    !isEmpty(state) &&
    !!country &&
    !isEmpty(country) &&
    !!zip &&
    !isEmpty(zip)
  ) {
    return {
      street,
      street2,
      city,
      state,
      zip,
      country,
      isPrimary,
    };
  }

  return null;
};
