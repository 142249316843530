import React, { useImperativeHandle, forwardRef } from "react";
import { Form, FormInputType } from "@wellth/web-ui";
import { PAUSE_REASON_OPTIONS } from "components/ChangePauseStatusForm";

export interface PauseFormProps {
  reason: string;
  note?: string;
}

export interface EditPauseFormProps extends Partial<PauseFormProps> {
  inputStyle?: React.CSSProperties;
}

const EditPauseForm: React.RefForwardingComponent<any, EditPauseFormProps> = (
  {
    inputStyle = {
      height: "2.1875rem",
      width: "18.75rem",
    },
    ...formProps
  }: EditPauseFormProps,
  ref,
) => {
  const formRef = React.useRef(null);
  useImperativeHandle(ref, () => ({
    getReason: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      {...formProps}
      wrappedComponentRef={formRef}
      mapPropsToFields={({ reason, note }, createFormField) => ({
        reason: createFormField({ value: reason }),
        note: createFormField({
          value: note,
        }),
      })}
      inputs={[
        {
          type: FormInputType.Dropdown,
          title: "Reason:",
          key: "reason",
          placeholder: "Select a reason",
          style: inputStyle,
          options: PAUSE_REASON_OPTIONS,
          decoratorOptions: {
            rules: [{ required: true, message: "Please provide a reason." }],
          },
        },
        {
          type: FormInputType.TextArea,
          title: "Add note (optional):",
          placeholder: "Write a note...",
          key: "note",
          style: { minWidth: inputStyle.width },
          autosize: { minRows: 4 },
        },
      ]}
      labelCol={{ sm: { span: 7 }, xs: { span: 7 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
      colValue={12}
    />
  );
};

export default forwardRef(EditPauseForm);
