import { CheckInCategory } from "types/globalTypes";
import {
  EnrollmentSubmission,
  FormState,
} from "components/EnrollmentForm/index";
import { SectionProps, SectionType } from "@wellth/web-ui";
import { MemberProfile } from "components/EnrollmentForm/sections/memberProfile";

export const checkIfAltProgram = (programCode: string) =>
  programCode.includes("ALT");

export const programTemplatesNotAllowingMedReminders = [
  "MA - DSNP",
  "MA - Payer",
];

export const InfoMisMatchError =
  "Several key identifiers such as Date of Birth, Name, and Phone Number do not match. Prospect cannot be enrolled as a Member at this time.";

export type ValidationAlertContent = Array<{
  message: string;
  description: string;
}>;

export type ValidFormState = Pick<
  FormState,
  "isProgramCodeValid" | "hasValidatedAddress" | "reminders" | "programConfig"
>;

export function getValidationAlertList(
  programTemplate: string,
  {
    isProgramCodeValid,
    hasValidatedAddress,
    reminders,
    programConfig,
  }: ValidFormState,
): ValidationAlertContent {
  const validationAlertList: ValidationAlertContent = [];
  if (isProgramCodeValid) {
    if (!hasValidatedAddress) {
      validationAlertList.push({
        message: "Validate mailing address",
        description:
          "You haven't validated the mailing address yet. Be sure to validate before submitting the form.",
      });
    }
    if (
      !(reminders.length && reminders.some((r) => r.isRequired)) &&
      !checkIfAltProgram(programConfig.programCode)
    ) {
      validationAlertList.push({
        message: "Required Reminder",
        description:
          "There must be at least one reminder with 'required' toggled on.",
      });
    }
    if (
      programTemplate &&
      !checkIfAltProgram(programConfig.programCode) &&
      programTemplatesNotAllowingMedReminders.includes(programTemplate) &&
      reminders.some(
        (r) => r.isRequired && r.checkInCategory === CheckInCategory.Medication,
      )
    ) {
      validationAlertList.push({
        message: "Invalid Program Reminder",
        description: `${
          programConfig.programCode
        } is an ${programTemplate} program, which does NOT allow Medication reminders to be required.`,
      });
    }
  }
  return validationAlertList;
}

export type ValidateFormDataParams = {
  propSections: SectionProps<any>[];
  programTemplate: string;
  initialMemberProfile: Partial<MemberProfile>;
  isBlankEnroll: boolean;
  prospectPhones: string[];
  isAltProgram: boolean;
};

export async function getValidatedFormData({
  propSections,
  programTemplate,
  initialMemberProfile,
  isBlankEnroll,
  prospectPhones,
  isAltProgram,
}: ValidateFormDataParams): Promise<{
  validatedFormData: EnrollmentSubmission;
  changes: string[];
  confirmDOB: boolean;
}> {
  const { tableSections, formSections } = propSections
    .filter(({ ref }) => !!ref && !!ref.current)
    .reduce(
      (sections, section) => {
        switch (section.type) {
          case SectionType.Form:
            return {
              ...sections,
              formSections: [...sections.formSections, section],
            };
          case SectionType.Table:
            return {
              ...sections,
              tableSections: [...sections.tableSections, section],
            };
          default:
            return { ...sections };
        }
      },
      { tableSections: [], formSections: [] },
    );

  const validatedTableData = await tableSections.reduce(
    async (tableData, { ref, key }) => {
      const values = await tableData;
      const { dataSource } = ref.current.props;

      const hasMAWithRequiredMedication =
        key === "reminders" &&
        programTemplatesNotAllowingMedReminders.includes(programTemplate) &&
        dataSource.some(
          (r) =>
            r.isRequired && r.checkInCategory === CheckInCategory.Medication,
        );

      const hasRequiredReminders =
        key === "reminders" &&
        (dataSource.length && dataSource.some((remind) => remind.isRequired));

      const tableValues = await new Promise((resolve, reject) => {
        dataSource.forEach((data) => {
          const nonNullableFields = Object.keys(data).reduce(
            (obj, k) => ({ ...obj, [k]: data[k] }),
            {},
          );

          if (Object.values(nonNullableFields).includes(null)) {
            reject(
              new Error(
                "Invalid reminder data. Please check all fields are provided!",
              ),
            );
          }
        });
        if (!isAltProgram) {
          if (key === "reminders" && !hasRequiredReminders) {
            reject(
              new Error(
                "There must be at least one reminder with 'required' toggled on.",
              ),
            );
          }
          if (key === "reminders" && hasMAWithRequiredMedication) {
            reject(
              new Error(
                `${programTemplate} Programs cannot have a Medication reminder with 'Required' toggled on.`,
              ),
            );
          }
        }
        resolve(dataSource);
      });

      return {
        ...values,
        [key]: tableValues,
      };
    },
    {},
  );

  const validatedFormData: EnrollmentSubmission = await formSections.reduce(
    async (promise, { ref, key }) => {
      const values = await promise;
      const formValues = await new Promise((resolve, reject) => {
        ref.current.props.form.validateFieldsAndScroll((errors, fieldValues) =>
          errors
            ? reject(
                new Error(
                  "Invalid values. Please update the invalid values and try again.",
                ),
              )
            : resolve(fieldValues),
        );
      });
      return {
        ...values,
        [key]: formValues,
      };
    },
    validatedTableData,
  );

  const changes: string[] = [];
  if (!isBlankEnroll) {
    const sameFirst =
      initialMemberProfile.firstName ===
      validatedFormData.memberProfile.firstName;
    const sameLast =
      initialMemberProfile.lastName ===
      validatedFormData.memberProfile.lastName;
    const sameDOB =
      initialMemberProfile.birthdate ===
      validatedFormData.memberProfile.birthdate;

    const phone = validatedFormData.memberProfile.phoneNumber.replace(/-/g, "");
    const samePhone = prospectPhones.includes(phone);

    if (!sameDOB && sameFirst && sameLast) {
      return {
        validatedFormData,
        changes,
        confirmDOB: true,
      };
    }

    if (sameDOB && !sameFirst && !sameLast && !samePhone) {
      throw Error(InfoMisMatchError);
    }

    if (!sameDOB) {
      throw Error(InfoMisMatchError);
    }

    if (!sameFirst) {
      changes.push(
        `Updating First Name from '${initialMemberProfile.firstName}' to '${
          validatedFormData.memberProfile.firstName
        }'`,
      );
    }

    if (!sameLast) {
      changes.push(
        `Updating Last Name from '${initialMemberProfile.lastName}' to '${
          validatedFormData.memberProfile.lastName
        }'`,
      );
    }
  }
  return {
    validatedFormData,
    changes,
    confirmDOB: false,
  };
}
