import { SectionType, FormInputType } from "@wellth/web-ui";
import { CheckInCategory } from "types/globalTypes";
import { AnySectionBinding } from "./types";
import { RemindersInputOptions } from "./reminders";

enum ColumnKey {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  CheckInCategory = "checkInCategory",
  IsDemo = "isDemo",
  StartDate = "startDate",
  EndDate = "endDate",
}

const editableTableRows: ColumnKey[] = [
  ColumnKey.IsDemo,
  ColumnKey.CheckInCategory,
  ColumnKey.StartDate,
  ColumnKey.EndDate,
];

const isEditable = (dataIndex: ColumnKey) =>
  editableTableRows.includes(dataIndex);

// eslint-disable-next-line no-underscore-dangle
const isMomentObject = (value: any) => value && value._isAMomentObject;

export const Inputs: AnySectionBinding<
  Omit<RemindersInputOptions, "addReminder" | "deleteReminder">
> = ({ dataSource, saveReminder, ...props }) => ({
  ...props,
  title: "Demo",
  key: "demoReminders",
  type: SectionType.Table,
  data: {
    dataSource,
    rowKey: (record, index) => String(index),
    columns: [
      {
        title: "Type",
        width: "33%",
        key: ColumnKey.CheckInCategory,
        onCell: (record, index: number) => ({
          record,
          editable: isEditable(ColumnKey.CheckInCategory),
          type: FormInputType.Dropdown,
          name: ColumnKey.CheckInCategory,
          onChange: (checkInCategory) =>
            saveReminder({ ...record, checkInCategory }, index),
          options: [
            { title: "DEMO - Medication", value: CheckInCategory.Medication },
            { title: "DEMO - Glucose", value: CheckInCategory.Glucose },
            {
              title: "DEMO - Blood Pressure",
              value: CheckInCategory.BloodPressure,
            },
            { title: "DEMO - Meal", value: CheckInCategory.Meal },
          ],
        }),
      },
      {
        title: "Start Date",
        key: ColumnKey.StartDate,
        width: "33%",
        onCell: (record, index: number) => ({
          record,
          editable: isEditable(ColumnKey.StartDate),
          onChange: (startDate) => {
            if (!startDate || isMomentObject(startDate)) {
              saveReminder({ ...record, startDate }, index);
            }
          },
          decoratorOptions: {
            rules: [{ required: true, message: "Start Date is required" }],
          },
          type: FormInputType.Date,
          name: ColumnKey.StartDate,
        }),
      },
      {
        title: "End Date",
        key: ColumnKey.EndDate,
        width: "33%",
        onCell: (record, index: number) => ({
          record,
          editable: isEditable(ColumnKey.EndDate),
          onChange: (endDate) => {
            if (!endDate || isMomentObject(endDate)) {
              saveReminder({ ...record, endDate }, index);
            }
          },
          decoratorOptions: {
            rules: [{ required: true, message: "End Date is required" }],
          },
          type: FormInputType.Date,
          name: ColumnKey.EndDate,
        }),
      },
    ],
  },
});

export default Inputs;
