import React from "react";
import EditPauseModal from "components/EditPauseFormModal";
import { useUpdatePauseObjectMutation } from "hooks/graphql";

export interface EditPauseFormModalProps {
  goBack: () => void;
  id: string;
  reason: string;
  note: string;
}

export const EditPauseFormModal: React.FC<EditPauseFormModalProps> = ({
  goBack,
  id,
  reason,
  note,
}: EditPauseFormModalProps) => {
  const [
    updatePause,
    { loading: submissionLoading },
  ] = useUpdatePauseObjectMutation({
    onCompleted: goBack,
  });
  return (
    <EditPauseModal
      title="Update Reason"
      reason={reason}
      note={note}
      loading={false}
      submissionLoading={submissionLoading}
      cancel={goBack}
      submit={async (formValues) => {
        await updatePause({
          variables: {
            pausePatch: {
              id,
              ...formValues,
            },
          },
        });
      }}
    />
  );
};

export default EditPauseFormModal;
