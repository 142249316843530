/* eslint-disable no-underscore-dangle */
import React from "react";
import moment from "moment-timezone";
import isEmpty from "lodash/isEmpty";
import EditReminderFormModalUI from "components/EditReminderFormModal";
import { ReminderMethod } from "components/EditReminderForm/inputs";
import {
  useReminderQuery,
  useCreateReminderMutation,
  useUpdateReminderMutation,
  useDeleteReminderMutation,
  MemberRemindersDocument as memberRemindersQuery,
  MemberCalendarDocument as memberCalendarQuery,
} from "hooks/graphql";
import { DATE_FORMAT, REMINDER_DUE_TIME_FORMAT } from "utils/date";
import { ReminderInput } from "types/globalTypes";
import { AdminPrivilege } from "constants/adminPrivilege";
import { message as alert } from "antd";
import * as constants from "containers/MemberProfile/constants";
import { isReminderIncentivized } from "utils/programSpecific";

export interface EditReminderFormModalProps {
  title: React.ReactNode;
  memberId: string;
  timezone: string;
  reminderId?: string;
  method: ReminderMethod;
  goBack: () => void;
  adminRole: AdminPrivilege;
  programTemplate: string;
}

export const EditReminderFormModal: React.FC<EditReminderFormModalProps> = ({
  goBack,
  memberId,
  method,
  timezone,
  reminderId,
  title,
  adminRole,
  programTemplate,
}: EditReminderFormModalProps) => {
  if (
    method !== ReminderMethod.Create &&
    (!reminderId || isEmpty(reminderId))
  ) {
    throw new Error("Cannot edit/view a reminder without a reminder id!");
  }
  const isCreate = method === ReminderMethod.Create;

  const {
    loading,
    data: {
      reminderById: {
        monTime = "",
        startDate: reminderStartDate = "",
        endDate: reminderEndDate = "",
        ...sanitizedReminder
      } = {},
    } = {},
  } = useReminderQuery({
    skip: isCreate,
    variables: {
      id: reminderId,
    },
  });

  const [
    createReminder,
    { loading: createLoading },
  ] = useCreateReminderMutation({
    refetchQueries: [
      { query: memberRemindersQuery, variables: { id: memberId } },
      {
        query: memberCalendarQuery,
        variables: {
          id: memberId,
          totalStartDate: constants.totalStartDate,
          endDate: constants.endDate,
        },
      },
    ],
  });

  const [
    updateReminder,
    { loading: updateLoading },
  ] = useUpdateReminderMutation({
    refetchQueries: [
      { query: memberRemindersQuery, variables: { id: memberId } },
      {
        query: memberCalendarQuery,
        variables: {
          id: memberId,
          totalStartDate: constants.totalStartDate,
          endDate: constants.endDate,
        },
      },
    ],
  });

  const [
    deleteReminder,
    { loading: deleteLoading },
  ] = useDeleteReminderMutation({
    refetchQueries: [
      { query: memberRemindersQuery, variables: { id: memberId } },
      {
        query: memberCalendarQuery,
        variables: {
          id: memberId,
          totalStartDate: constants.totalStartDate,
          endDate: constants.endDate,
        },
      },
    ],
  });

  const createNewReminder = async (reminderInput: ReminderInput) => {
    await createReminder({
      variables: {
        input: {
          reminder: {
            ...reminderInput,
            // non medication reminders are always incentivized
            // check program specifics for medication reminders
            isRequired: isReminderIncentivized(
              programTemplate,
              reminderInput.checkInCategory,
            ),
          },
        },
      },
    });
  };

  return (
    <EditReminderFormModalUI
      {...sanitizedReminder}
      adminRole={adminRole}
      startDate={
        !isEmpty(reminderStartDate)
          ? moment(reminderStartDate, DATE_FORMAT)
          : null
      }
      endDate={
        !isEmpty(reminderEndDate) ? moment(reminderEndDate, DATE_FORMAT) : null
      }
      dueTime={!isCreate ? moment(monTime, REMINDER_DUE_TIME_FORMAT) : null}
      loading={loading}
      submissionLoading={createLoading || updateLoading}
      cancel={goBack}
      method={method}
      timezone={timezone}
      title={title}
      programTemplate={programTemplate}
      submit={async ({
        checkInCategory,
        isDemo,
        dueTime,
        windowAfterMinutes,
        windowBeforeMinutes,
        startDate,
        endDate,
        interval,
        isRequired,
      }) => {
        const time = dueTime.seconds(0).format(REMINDER_DUE_TIME_FORMAT);

        const reminderData = {
          checkInCategory,
          isDemo,
          isRequired,
          interval,
          startDate: startDate.format(DATE_FORMAT),
          endDate: endDate.format(DATE_FORMAT),
          monTime: time,
          tueTime: time,
          wedTime: time,
          thuTime: time,
          friTime: time,
          satTime: time,
          sunTime: time,
          windowAfterMinutes: parseInt(windowAfterMinutes, 10),
          windowBeforeMinutes: parseInt(windowBeforeMinutes, 10),
        };

        switch (method) {
          case ReminderMethod.Create:
            await createNewReminder({
              ...reminderData,
              patientId: memberId,
            });
            break;
          case ReminderMethod.Edit:
            await updateReminder({
              variables: {
                uuid: reminderId,
                reminderPatch: {
                  ...reminderData,
                },
              },
            });
            break;
          default:
            break;
        }

        goBack();
      }}
      deleteReminder={async (id) => {
        try {
          await deleteReminder({
            variables: {
              uuid: id,
            },
          });

          goBack();
        } catch ({ message }) {
          void alert.error(message, 10);
        }
      }}
      deleteLoading={deleteLoading}
    />
  );
};

export default EditReminderFormModal;
