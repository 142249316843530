"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CHECK_IN_CATEGORY", {
  enumerable: true,
  get: function get() {
    return _utils.CHECK_IN_CATEGORY;
  }
});
exports.isWindows = exports.isWeb = exports.isMacOS = exports.isIOS = exports.isAndroid = exports.ISO_DATE_FORMAT_STRING = exports.AbsoluteFillStyle = exports.FullScreenStyle = exports.FullScreenImageStyle = exports.ScreenDimensions = exports.ScreenHeight = exports.ScreenWidth = void 0;
var _reactNative = require("react-native");
var _utils = require("@wellth/utils");
var _Dimensions$get = _reactNative.Dimensions.get("window"),
  width = _Dimensions$get.width,
  height = _Dimensions$get.height;
var ScreenWidth = width;
exports.ScreenWidth = ScreenWidth;
var ScreenHeight = height;
exports.ScreenHeight = ScreenHeight;
var ScreenDimensions = {
  width: width,
  height: height
};
exports.ScreenDimensions = ScreenDimensions;
var FullScreenImageStyle = {
  position: "absolute",
  width: width,
  height: height
};
exports.FullScreenImageStyle = FullScreenImageStyle;
var FullScreenStyle = FullScreenImageStyle;
exports.FullScreenStyle = FullScreenStyle;
var AbsoluteFillStyle = _reactNative.StyleSheet.absoluteFillObject;
exports.AbsoluteFillStyle = AbsoluteFillStyle;
var ISO_DATE_FORMAT_STRING = "YYYY-MM-DD";
exports.ISO_DATE_FORMAT_STRING = ISO_DATE_FORMAT_STRING;
var isAndroid = _reactNative.Platform.OS === "android";
exports.isAndroid = isAndroid;
var isIOS = _reactNative.Platform.OS === "ios";
exports.isIOS = isIOS;
var isMacOS = _reactNative.Platform.OS === "macos";
exports.isMacOS = isMacOS;
var isWeb = _reactNative.Platform.OS === "web";
exports.isWeb = isWeb;
var isWindows = _reactNative.Platform.OS === "windows";
exports.isWindows = isWindows;