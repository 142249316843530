import { CSSProperties } from "react";

export const styles: { [key: string]: CSSProperties } = {
  validationAlert: { marginBottom: 10 },
  altProgramAlert: {
    backgroundColor: "rgb(254, 251, 232)",
    padding: "20px",
    margin: "20px 0",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    borderColor: "rgb(243, 196, 97)",
    borderWidth: "1px",
    borderStyle: "solid",
    textAlign: "left",
  },
  altProgramAlertSpanBold: {
    fontWeight: "bold",
    marginBottom: "10px",
    fontSize: "16px",
  },
  altProgramAlertSpan: { display: "block" },
  altProgramAlertIcon: {
    color: "rgb(243, 196, 97)",
    marginRight: "10px",
    fontSize: "16px",
  },
  altProgramAlertBold: { fontWeight: "bold" },
  altProgramAlertText: {
    backgroundColor: "rgb(254, 251, 232)",
    padding: "20px",
    margin: "20px 0",
    borderRadius: "5px",
    display: "block",
    alignItems: "center",
    color: "black",
    borderColor: "rgb(243, 196, 97)",
    borderWidth: "1px",
    borderStyle: "solid",
    justifyContent: "left",
  },
};

export default styles;
