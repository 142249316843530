import React, { useRef } from "react";
import {
  FormInputType,
  FormInputProps,
  Form,
  message as alert,
} from "@wellth/web-ui";
import { Modal } from "components/Modal";
import {
  AllSmsOutreachMessageTemplatesDocument as allSmsOutreachMessageTemplatesQuery,
  useTestSmsMessageMutation,
} from "hooks/graphql";
import { COLUMN_FULL_WIDTH } from "constants/grid";

export interface SmsTemplate {
  id: string;
  createdAt: string;
  message: string;
  title: string;
  updatedAt: string;
}

export interface TestSMSTemplateProps {
  goBack: () => void;
  template: SmsTemplate;
}

const formInputs: FormInputProps[] = [
  {
    type: FormInputType.Text,
    title: "Title",
    key: "title",
    disabled: true,
    colValue: COLUMN_FULL_WIDTH,
  },
  {
    type: FormInputType.TextArea,
    title: "Message",
    key: "message",
    disabled: true,
    colValue: COLUMN_FULL_WIDTH,
  },
  {
    type: FormInputType.Text,
    title: "Recipient firstName",
    key: "firstName",
    placeholder: "Enter a firstName... ",
    colValue: COLUMN_FULL_WIDTH,
  },
  {
    type: FormInputType.Text,
    title: "Recipient lastName",
    key: "lastName",
    placeholder: "Enter a lastName... ",
    colValue: COLUMN_FULL_WIDTH,
  },
  {
    type: FormInputType.Text,
    title: "Recipient Number",
    key: "phoneNumber",
    placeholder: "Enter a mobile phone number... ",
    colValue: COLUMN_FULL_WIDTH,
  },
];

export default ({ goBack, template }: TestSMSTemplateProps) => {
  const testSmsTemplateRef = useRef(null);
  const { id, message: templateMessage, title: templateTitle } = template;
  const [
    sendTestMessage,
    { loading: submissionLoading },
  ] = useTestSmsMessageMutation({
    onError: ({ message }) =>
      alert.error(`There was an error updating sms template: ${message}`),
    onCompleted: () => {
      void alert.success("A sms template test message was successfully sent!");
      goBack();
    },
  });

  return (
    <Modal
      okText="Submit"
      visible
      loading={false}
      submissionLoading={submissionLoading}
      title="Test SMS Template"
      onOk={async () => {
        const {
          firstName,
          lastName,
          phoneNumber,
        } = testSmsTemplateRef.current.props.form.getFieldsValue();

        await sendTestMessage({
          variables: {
            recipient: {
              firstName,
              lastName,
              phoneNumber,
            },
            messageTemplateId: id,
          },
          refetchQueries: [{ query: allSmsOutreachMessageTemplatesQuery }],
        });
      }}
      onCancel={goBack}
    >
      <Form
        wrappedComponentRef={testSmsTemplateRef}
        inputs={formInputs}
        mapPropsToFields={(_props, createFormField) => ({
          title: createFormField({
            value: templateTitle,
          }),
          message: createFormField({
            value: templateMessage,
          }),
        })}
      />
    </Modal>
  );
};
