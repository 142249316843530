import React from "react";
import { Card, Tabs, Icon } from "@wellth/web-ui";
import * as constants from "containers/MemberProfile/constants";
import CARD_STYLES from "../styles";
import { Reminder, ReminderProps } from "./components/Reminder";

const { TabPane } = Tabs;

const BODY_STYLE: React.CSSProperties = {
  ...CARD_STYLES.body,
  fontSize: "1.125rem",
  color: "#1f2226",
  fontWeight: "bold",
};

const TABBAR_STYLE: React.CSSProperties = {
  fontSize: "0.3125rem",
  padding: 0,
  margin: 0,
};

const ICON_SIZE: React.CSSProperties = {
  fontSize: "1.25rem",
};

export type RemindersArray = ReminderProps["reminders"];

export interface RemindersProps
  extends Omit<ReminderProps, "reminders" | "lockReminders" | "editReminders"> {
  activeReminders: RemindersArray;
  archivedReminders: RemindersArray;
  futureReminders: RemindersArray;
  programTemplate: string;
}

export const Reminders: React.FC<RemindersProps> = ({
  archivedReminders,
  activeReminders,
  futureReminders,
  onReminderModalClick,
  isReminderEditingOpen,
  handleReminderLockChange,
  programTemplate,
}: RemindersProps) => (
  <Card
    headStyle={CARD_STYLES.head}
    bodyStyle={BODY_STYLE}
    style={CARD_STYLES.card}
    title="Reminders"
    extra={
      <Icon
        type="plus-circle"
        theme="filled"
        style={ICON_SIZE}
        onClick={() => onReminderModalClick({ method: constants.CREATE })}
      />
    }
  >
    <Tabs
      defaultActiveKey="active"
      size="small"
      tabBarGutter={0}
      tabBarStyle={TABBAR_STYLE}
    >
      <TabPane tab="Active" key="active">
        <Reminder
          lockReminders
          editReminders
          handleReminderLockChange={handleReminderLockChange}
          isReminderEditingOpen={isReminderEditingOpen}
          reminders={activeReminders}
          onReminderModalClick={onReminderModalClick}
          programTemplate={programTemplate}
        />
      </TabPane>
      <TabPane tab="Archived" key="archived">
        <Reminder
          lockReminders={false}
          editReminders={false}
          reminders={archivedReminders}
          onReminderModalClick={onReminderModalClick}
          programTemplate={programTemplate}
        />
      </TabPane>
      <TabPane tab="Future" key="future">
        <Reminder
          lockReminders={false}
          editReminders
          reminders={futureReminders}
          onReminderModalClick={onReminderModalClick}
          programTemplate={programTemplate}
        />
      </TabPane>
    </Tabs>
  </Card>
);

export default Reminders;
