import { SectionType, FormInputType, Icon } from "@wellth/web-ui";
import React from "react";
import {
  BindFormSectionOptions,
  AnySectionBinding,
} from "../../sections/types";
import { styles } from "../../styles";

export interface AltEnrollment {
  text: string;
  title: string;
  key: string;
}

export type AltEnrollmentInputOptions = BindFormSectionOptions<AltEnrollment>;

const CustomTextInput = ({ text }) => (
  <div style={styles.altProgramAlertText}>
    <Icon type="exclamation-circle-o" style={styles.altProgramAlertIcon} />
    {text}
  </div>
);

export const Inputs: AnySectionBinding<AltEnrollmentInputOptions> = ({
  extraProps: { text = "", title = "", key = "altEnrollment" } = {},
  ...props
}) => ({
  ...props,
  title,
  key,
  type: SectionType.Form,
  data: {
    inputs: [
      {
        type: FormInputType.Text,
        key: "altProgramIndicator",
        hidden: true,
        itemProps: {
          extra: <CustomTextInput text={text} />,
        },
      },
    ],
  },
});
