import React, { useRef } from "react";
import { Form, message as alert } from "@wellth/web-ui";
import { Modal } from "components/Modal";
import {
  useCurrentAdminQuery,
  useCreateSmsOutreachMessageTemplateMutation,
  AllSmsOutreachMessageTemplatesDocument as allSmsOutreachMessageTemplatesQuery,
} from "hooks/graphql";
import { COLUMN_FULL_WIDTH } from "constants/grid";
import { formInputs } from "./constants";

interface Props {
  goBack: () => void;
}

export default ({ goBack }: Props) => {
  const createSmsTemplateRef = useRef(null);
  const [
    createSmsOutreachTemplate,
    { loading: submissionLoading },
  ] = useCreateSmsOutreachMessageTemplateMutation({
    onError: ({ message }) =>
      alert.error(`There was an error creating sms template: ${message}`),
    onCompleted: () => {
      void alert.success("Successfully created message template");
      goBack();
    },
    refetchQueries: [{ query: allSmsOutreachMessageTemplatesQuery }],
  });
  const {
    data: { currentAdmin: { id = "" } = {} } = {},
  } = useCurrentAdminQuery();

  return (
    <Modal
      okText="Submit"
      title="Create SMS Template"
      loading={false}
      submissionLoading={submissionLoading}
      visible
      onOk={async () => {
        const {
          title: templateTitle,
          message: templateMessage,
        } = createSmsTemplateRef.current.props.form.getFieldsValue();

        await createSmsOutreachTemplate({
          variables: {
            input: {
              smsOutreachMessageTemplate: {
                title: templateTitle,
                message: templateMessage,
                adminId: id,
              },
            },
          },
        });
      }}
      onCancel={goBack}
    >
      <Form
        wrappedComponentRef={createSmsTemplateRef}
        inputs={formInputs}
        colValue={COLUMN_FULL_WIDTH}
        mapPropsToFields={(_props, createFormField) => ({
          mergeTags: createFormField({
            value: (
              <span>
                <div>{"<firstName> = Member's First Name"}</div>
                <div>{"<lastName> = Member's Last Name"}</div>
              </span>
            ),
          }),
        })}
      />
    </Modal>
  );
};
