import React from "react";
import { Button, SectionType, FormInputType } from "@wellth/web-ui";
import { BindFormSectionOptions, AnySectionBinding } from "./types";

export interface MailingAddress {
  street: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
}

export interface MailingAddressInputOptions
  extends BindFormSectionOptions<MailingAddress> {
  validateAddress: () => Promise<void>;
}

export const Inputs: AnySectionBinding<MailingAddressInputOptions> = ({
  onValuesChange,
  onFieldsChange,
  extraProps: { street, street2, city, state, zip } = {},
  validateAddress,
  ...props
}) => ({
  ...props,
  title: "Mailing Address",
  key: "mailingAddress",
  type: SectionType.Form,
  footer: <Button onClick={validateAddress}>Validate Mailing Address</Button>,
  data: {
    onValuesChange,
    onFieldsChange,
    mapPropsToFields: (_props, createFormField: any) => ({
      street: createFormField({
        value: street,
      }),
      street2: createFormField({
        value: street2,
      }),
      city: createFormField({ value: city }),
      state: createFormField({ value: state }),
      zip: createFormField({ value: zip }),
    }),
    inputs: [
      {
        type: FormInputType.Text,
        key: "street",
        title: "Address Street",
        placeholder: "Address Street",
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a street.",
            },
          ],
        },
      },
      {
        type: FormInputType.Text,
        key: "street2",
        title: "Address Street 2",
        placeholder: "Address Street 2",
      },
      {
        type: FormInputType.Text,
        key: "city",
        title: "City",
        placeholder: "City",
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a city.",
            },
          ],
        },
      },
      {
        type: FormInputType.Text,
        key: "state",
        title: "State",
        placeholder: "State",
        decoratorOptions: {
          rules: [
            {
              required: true,
              pattern: /^[A-Z]{2}$/i,
              message: "Please provide a two letter state code.",
            },
          ],
        },
      },
      {
        type: FormInputType.Text,
        key: "zip",
        title: "Zip code",
        placeholder: "Zip code",
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a 5-10 digit zip code",
            },
          ],
        },
      },
    ],
  },
});
