"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.KeyboardAvoidingView = void 0;
var _react = _interopRequireWildcard(require("react"));
var _reactNative = require("react-native");
var _useKeyboard2 = require("../../hooks/useKeyboard");
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/KeyboardAvoidingView/index.tsx";
function _getRequireWildcardCache(e) {
  if ("function" != typeof WeakMap) return null;
  var r = new WeakMap(),
    t = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(e) {
    return e ? t : r;
  })(e);
}
function _interopRequireWildcard(e, r) {
  if (!r && e && e.__esModule) return e;
  if (null === e || "object" != _typeof(e) && "function" != typeof e) return {
    "default": e
  };
  var t = _getRequireWildcardCache(r);
  if (t && t.has(e)) return t.get(e);
  var n = {
      __proto__: null
    },
    a = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var u in e) {
    if ("default" !== u && {}.hasOwnProperty.call(e, u)) {
      var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
      i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
    }
  }
  return n["default"] = e, t && t.set(e, n), n;
}
var animateTo = function animateTo(animation, toValue, duration) {
  _reactNative.Animated.timing(animation, {
    toValue: toValue,
    duration: duration,
    useNativeDriver: false
  }).start();
};
var KeyboardAvoidingView = function KeyboardAvoidingView(_ref) {
  var _ref$offset = _ref.offset,
    offset = _ref$offset === void 0 ? 0 : _ref$offset,
    _ref$style = _ref.style,
    style = _ref$style === void 0 ? null : _ref$style,
    children = _ref.children;
  var heightAnimation = (0, _react.useRef)(new _reactNative.Animated.Value(0)).current;
  var _useKeyboard = (0, _useKeyboard2.useKeyboard)(),
    _useKeyboard$animatio = _useKeyboard.animation,
    duration = _useKeyboard$animatio.duration,
    isActive = _useKeyboard$animatio.active,
    type = _useKeyboard$animatio.type,
    end = _useKeyboard.coordinates.end;
  (0, _react.useEffect)(function () {
    if (isActive) {
      var toValue = type === "show" ? end.height : 0;
      animateTo(heightAnimation, toValue + offset, duration);
    }
  }, [end, duration, isActive, heightAnimation, type, offset]);
  return _react["default"].createElement(_react["default"].Fragment, null, children, _react["default"].createElement(_reactNative.Animated.View, {
    style: [style, {
      height: heightAnimation
    }],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 46,
      columnNumber: 7
    }
  }));
};
exports.KeyboardAvoidingView = KeyboardAvoidingView;
var _default = KeyboardAvoidingView;
exports["default"] = _default;