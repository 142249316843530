import React from "react";
import moment from "moment-timezone";
import styles from "../../styles";

const NOTE_FORMAT = "h:mm A, MMMM Do YYYY";

export interface NoteProps {
  body: string;
  adminName?: string;
  createdAt: Date;
}

const Note: React.FC<NoteProps> = ({
  body,
  adminName,
  createdAt,
}: NoteProps) => (
  <div style={styles.noteContainer}>
    <div style={styles.noteHeader}>
      {adminName ? <h5 style={styles.noteAdmin}>{adminName}</h5> : null}
      <h5 style={styles.noteDate}>{moment(createdAt).format(NOTE_FORMAT)}</h5>
    </div>
    <span style={styles.note}>{body}</span>
  </div>
);

export default Note;
