import React from "react";
import EditNameModal from "components/EditNameFormModal";
import { ContactType } from "types/globalTypes";
import {
  useCreateNameMutation,
  MemberNamesDocument as memberNamesQuery,
  ProspectNamesDocument as prospectNamesQuery,
} from "hooks/graphql";

export interface CreateNameFormModalProps {
  contactId: string;
  contactType: ContactType;
  goBack: () => void;
}

export const CreateNameFormModal: React.FC<CreateNameFormModalProps> = ({
  goBack,
  contactId,
  contactType,
}: CreateNameFormModalProps) => {
  const [createName, { loading: submissionLoading }] = useCreateNameMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [
      contactType === ContactType.Member
        ? { query: memberNamesQuery, variables: { id: contactId } }
        : { query: prospectNamesQuery, variables: { id: contactId } },
    ],
  });

  return (
    <EditNameModal
      type={contactType}
      title="Create Name"
      submissionLoading={submissionLoading}
      cancel={goBack}
      submit={async ({ firstName, lastName, isPrimary = false }) => {
        await createName({
          variables: {
            contactId,
            contactType,
            input: {
              name: {
                firstName,
                lastName,
                isPrimary,
              },
            },
          },
        });
      }}
    />
  );
};

export default CreateNameFormModal;
