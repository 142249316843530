/* eslint-disable no-underscore-dangle */
import {
  createStore,
  applyMiddleware,
  compose,
  Store as ReduxStore,
} from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import reducers, { State } from "reducers";

export type Store = ReduxStore<State>;

const logger = createLogger({
  collapsed: true,
  duration: true,
  diff: true,
});

const config = {
  storage,
  key: "Wellth",
  whitelist: ["session"],
  debounce: 3000,
};

const getStore = (): Store => {
  const middleware = [logger];

  const newStore: Store = createStore(
    persistCombineReducers(config, reducers),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
    compose(applyMiddleware(...middleware)),
  );

  persistStore(newStore);

  return newStore;
};

const store = getStore();

export default store;
