import React from "react";
import { message as alertMessage } from "@wellth/web-ui";
import { useSendOneOffMessageMutation } from "hooks/graphql";
import { DownloadLinkPage } from "components/DownloadLinkPage";
import { PhoneNumber, PhoneNumberConfig } from "types/globalTypes";

export interface DownloadLinkSenderProps {
  phoneNumbers: Pick<PhoneNumber, "phoneNumber" | "isPrimary">[];
  programCode: string;
  language: string;
  firstName: string;
  wellthId: string;
}

export const DownloadLinkSender: React.FC<DownloadLinkSenderProps> = ({
  phoneNumbers,
  language,
  programCode,
  firstName,
  wellthId,
}) => {
  const [sendMessage] = useSendOneOffMessageMutation({
    onError: (error) => {
      error.graphQLErrors.forEach(({ message }) => {
        void alertMessage.error(`Unable to send SMS: ${message}`);
      });
    },
    onCompleted: () => {
      void alertMessage.success("SMS Download Link Sent");
    },
  });

  return (
    <DownloadLinkPage
      phoneNumbers={phoneNumbers}
      language={language}
      programCode={programCode}
      firstName={firstName}
      wellthId={wellthId}
      sendMessage={async (
        phoneNumber: string,
        message: string,
      ): Promise<void> => {
        await sendMessage({
          variables: {
            input: {
              recipientPhoneNumber: phoneNumber,
              message,
              phoneNumberConfig: PhoneNumberConfig.ShortCode,
              wellthId,
            },
          },
        });
      }}
    />
  );
};

export default DownloadLinkSender;
