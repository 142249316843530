import React, { useRef } from "react";
import { Modal } from "components/Modal";
import EditProfileForm, {
  EditProfileFormProps,
  EditProfileFormRef,
  ProfileProps,
} from "components/EditProfileForm";

export interface EditProfileFormModalProps extends EditProfileFormProps {
  loading?: boolean;
  submissionLoading?: boolean;
  submit: (values: ProfileProps) => void;
  cancel: () => void;
}

export const EditProfileFormModal: React.FC<EditProfileFormModalProps> = ({
  loading = false,
  submissionLoading = false,
  submit,
  cancel,
  ...formProps
}) => {
  const formRef = useRef<EditProfileFormRef>(null);

  return (
    <Modal
      title="Edit Profile"
      okText="Submit"
      loading={loading}
      submissionLoading={submissionLoading}
      onCancel={cancel}
      width={700}
      onOk={async () => submit(await formRef.current.getProfile())}
    >
      <EditProfileForm {...formProps} ref={formRef} />
    </Modal>
  );
};

export default EditProfileFormModal;
