"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PageIndicator = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/PageIndicator/index.tsx";
var TWO = 2;
var DEFAULT_INDICATOR_SIZE = {
  width: 10,
  height: 10
};
var PageIndicator = function PageIndicator(_ref) {
  var style = _ref.style,
    _ref$numberOfPages = _ref.numberOfPages,
    numberOfPages = _ref$numberOfPages === void 0 ? 1 : _ref$numberOfPages,
    _ref$selectedIndex = _ref.selectedIndex,
    selectedIndex = _ref$selectedIndex === void 0 ? 1 : _ref$selectedIndex,
    _ref$indicatorSize = _ref.indicatorSize,
    indicatorSize = _ref$indicatorSize === void 0 ? DEFAULT_INDICATOR_SIZE : _ref$indicatorSize,
    indicatorStyleProp = _ref.indicatorStyle,
    _ref$indicatorTintCol = _ref.indicatorTintColor,
    indicatorTintColor = _ref$indicatorTintCol === void 0 ? _colors["default"].GREY_DARK : _ref$indicatorTintCol,
    _ref$activeIndicatorS = _ref.activeIndicatorSize,
    activeIndicatorSize = _ref$activeIndicatorS === void 0 ? DEFAULT_INDICATOR_SIZE : _ref$activeIndicatorS,
    activeIndicatorStyleProp = _ref.activeIndicatorStyle,
    _ref$activeIndicatorT = _ref.activeIndicatorTintColor,
    activeIndicatorTintColor = _ref$activeIndicatorT === void 0 ? _colors["default"].BLACK : _ref$activeIndicatorT,
    _ref$onSelectIndex = _ref.onSelectIndex,
    onSelectIndex = _ref$onSelectIndex === void 0 ? function () {} : _ref$onSelectIndex;
  var indicatorSizeWidth = indicatorSize.width,
    indicatorSizeHeight = indicatorSize.height;
  var activeIndicatorSizeWidth = activeIndicatorSize.width,
    activeIndicatorSizeHeight = activeIndicatorSize.height;
  var defaultStyle = {
    height: Math.max(indicatorSizeHeight, activeIndicatorSizeHeight)
  };
  var defaultIndicatorStyle = {
    width: indicatorSizeWidth,
    height: indicatorSizeHeight,
    borderRadius: indicatorSizeHeight / TWO,
    marginLeft: 5,
    marginRight: 5
  };
  var indicatorStyle = [defaultIndicatorStyle, {
    backgroundColor: indicatorTintColor
  }, indicatorStyleProp];
  var defaultActiveIndicatorStyle = (0, _objectSpread2["default"])({}, defaultIndicatorStyle, {
    width: activeIndicatorSizeWidth,
    height: activeIndicatorSizeHeight,
    borderRadius: activeIndicatorSizeHeight / TWO
  });
  var activeIndicatorStyle = [defaultActiveIndicatorStyle, {
    backgroundColor: activeIndicatorTintColor
  }, activeIndicatorStyleProp];
  var pageIndicators = (0, _toConsumableArray2["default"])(Array(numberOfPages).keys()).map(function (_, index) {
    var pageIndicatorStyle = index === selectedIndex ? activeIndicatorStyle : indicatorStyle;
    return _react["default"].createElement(_reactNative.TouchableWithoutFeedback, {
      key: index,
      onPress: function onPress() {
        onSelectIndex(index);
      },
      __source: {
        fileName: _jsxFileName,
        lineNumber: 91,
        columnNumber: 7
      }
    }, _react["default"].createElement(_reactNative.View, {
      style: pageIndicatorStyle,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 97,
        columnNumber: 9
      }
    }));
  });
  return _react["default"].createElement(_reactNative.View, {
    style: [_styles["default"].container, defaultStyle, style],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 103,
      columnNumber: 5
    }
  }, pageIndicators);
};
exports.PageIndicator = PageIndicator;
var _default = PageIndicator;
exports["default"] = _default;