import React from "react";
import { Upload, Icon } from "@wellth/web-ui";

const { Dragger } = Upload;

interface Props {
  accept: string;
  name?: string;
  multiple?: boolean;
  action?: string;
  onChange?: (value: any) => void;
  customRequest?: (value: any) => void;
  showUploadList?: boolean;
  disabled?: boolean;
}

export default ({
  accept,
  name,
  multiple = false,
  action,
  onChange,
  customRequest,
  showUploadList = false,
  disabled = false,
}: Props) => (
  <Dragger
    accept={accept}
    name={name}
    multiple={multiple}
    action={action}
    onChange={onChange}
    customRequest={customRequest}
    showUploadList={showUploadList}
    disabled={disabled}
  >
    <p className="ant-upload-drag-icon">
      <Icon type="inbox" />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
  </Dragger>
);
