import { SectionType, FormInputType } from "@wellth/web-ui";
import { BindFormSectionOptions, AnySectionBinding } from "./types";

export interface MemberProfile {
  firstName: string;
  lastName: string;
  birthdate: string;
  phoneNumber: string;
  email: string;
  language: string;
}

export type MemberProfileInputOptions = BindFormSectionOptions<MemberProfile>;

export const Inputs: AnySectionBinding<MemberProfileInputOptions> = ({
  onValuesChange,
  onFieldsChange,
  extraProps: {
    firstName = "",
    lastName = "",
    birthdate = "",
    phoneNumber = "",
    email = "",
    language = null,
  } = {},
  ...props
}) => ({
  ...props,
  title: "Member Profile",
  key: "memberProfile",
  type: SectionType.Form,
  data: {
    onValuesChange,
    onFieldsChange,
    mapPropsToFields: (_props, createFormField: any) => ({
      firstName: createFormField({
        value: firstName,
      }),
      lastName: createFormField({
        value: lastName,
      }),
      birthdate: createFormField({
        value: birthdate,
      }),
      phoneNumber: createFormField({
        value: phoneNumber,
      }),
      email: createFormField({
        value: email,
      }),
      language: createFormField({
        value: language,
      }),
    }),
    inputs: [
      {
        type: FormInputType.Text,
        key: "firstName",
        title: "First Name",
        placeholder: "First Name",
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a first name.",
            },
          ],
        },
      },
      {
        type: FormInputType.Text,
        key: "lastName",
        title: "Last Name",
        placeholder: "Last Name",
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a last name.",
            },
          ],
        },
      },
      {
        type: FormInputType.Mask,
        key: "birthdate",
        title: "Birthdate",
        mask: "99/99/9999",
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a birth date.",
            },
          ],
        },
      },
      {
        type: FormInputType.Mask,
        key: "phoneNumber",
        title: "Verified Phone Number",
        mask: "+9-999-999-9999",
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a valid phone number.",
            },
          ],
        },
      },
      {
        type: FormInputType.Email,
        key: "email",
        title: "E-mail",
      },
      {
        type: FormInputType.Dropdown,
        key: "language",
        title: "Language",
        options: [
          { title: "English", value: "EN" },
          { title: "Spanish", value: "ES" },
          { title: "Russian", value: "RU" },
        ],
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a value.",
            },
          ],
        },
      },
    ],
  },
});
