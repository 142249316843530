import React from "react";
import {
  Reminders as RemindersUI,
  RemindersProps as RemindersUIProps,
} from "components/Reminders";
import {
  getRemindersByType,
  isActive,
  isArchived,
  isFuture,
} from "@wellth/utils";
import { FullReminderFragment } from "types/globalTypes";

interface RemindersProps
  extends Pick<
    RemindersUIProps,
    | "isReminderEditingOpen"
    | "onReminderModalClick"
    | "handleReminderLockChange"
  > {
  reminders: FullReminderFragment[];
  programTemplate: string;
}

export const Reminders: React.FC<RemindersProps> = ({
  reminders,
  programTemplate,
  isReminderEditingOpen,
  onReminderModalClick,
  handleReminderLockChange,
}) => {
  const activeReminders = getRemindersByType<FullReminderFragment>(
    reminders,
    isActive,
  );

  const archivedReminders = getRemindersByType<FullReminderFragment>(
    reminders,
    isArchived,
  );

  const futureReminders = getRemindersByType<FullReminderFragment>(
    reminders,
    isFuture,
  );

  return (
    <RemindersUI
      activeReminders={activeReminders}
      archivedReminders={archivedReminders}
      futureReminders={futureReminders}
      onReminderModalClick={onReminderModalClick}
      isReminderEditingOpen={isReminderEditingOpen}
      handleReminderLockChange={handleReminderLockChange}
      programTemplate={programTemplate}
    />
  );
};

export default Reminders;
