import React from "react";
import { Button, SectionType, FormInputType } from "@wellth/web-ui";
import USTimezones from "constants/usTimezoneArray";
import { BindFormSectionOptions, AnySectionBinding } from "./types";

export interface ProgramConfig {
  programCode: string;
  timezone: string;
}

export interface ProgramCodeInputOptions
  extends BindFormSectionOptions<ProgramConfig> {
  programCodeOptions: string[];
  isProgramCodeValid: boolean;
  submitProgramCode: (code: string, timezone: string) => Promise<void>;
}

export const Inputs: AnySectionBinding<ProgramCodeInputOptions> = ({
  submitProgramCode,
  isProgramCodeValid,
  programCodeOptions,
  extraProps: { programCode = null, timezone = null } = {},
  ...props
}) => ({
  ...props,
  title: "Program Config",
  key: "programConfig",
  type: SectionType.Form,
  data: {
    mapPropsToFields: (_props, createFormField) => ({
      programCode: createFormField({
        value: programCode,
      }),
      timezone: createFormField({
        value: timezone,
      }),
    }),
    inputs: [
      {
        title: "Program code:",
        type: FormInputType.Dropdown,
        key: "programCode",
        disabled: isProgramCodeValid,
        options: programCodeOptions.map((code) => ({
          title: code,
          value: code,
        })),
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a valid program code",
            },
          ],
        },
      },
      {
        type: FormInputType.Dropdown,
        key: "timezone",
        title: "Primary Timezone:",
        options: USTimezones,
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Please provide a value",
            },
          ],
        },
      },
    ],
    submitButton: (formProps, form) => (
      <Button
        htmlType="submit"
        disabled={isProgramCodeValid}
        onClick={async () => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const { programCode, timezone } = form.getFieldsValue();
          await submitProgramCode(programCode, timezone);
        }}
      >
        Submit Program Code
      </Button>
    ),
  },
});
