import React from "react";
import moment from "moment-timezone";
import EditProspectProfileModal from "components/EditProfileFormModal";
import {
  useUpdateProspectByIdMutation,
  useGetProspectByIdQuery,
  GetProspectByIdDocument as prospectProfileQuery,
} from "hooks/graphql";
import { IsoLanguage } from "types/globalTypes";
import { DATE_FORMAT } from "utils/date";

export interface EditProspectProfileFormModalProps {
  id: string;
  goBack: () => void;
}

export const EditProspectProfileFormModal: React.FC<
  EditProspectProfileFormModalProps
> = ({ id, goBack }: EditProspectProfileFormModalProps) => {
  const {
    data: {
      getProspectById: {
        externalId = "",
        siteName = "",
        eligibilityEndDate = null,
        medicaidId = "",
        pcpName = "",
        pcpNumber = "",
        person: {
          primaryLanguage: language = IsoLanguage.En,
          primaryTimezone: timezone = "",
          birthDate: birthdate = "",
          ...person
        } = {},
        program: { programTemplate = "" } = {},
      } = {},
    } = {},
    loading,
  } = useGetProspectByIdQuery({
    variables: {
      id,
    },
  });

  const [
    updateProspect,
    { loading: submissionLoading },
  ] = useUpdateProspectByIdMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: prospectProfileQuery, variables: { id } }],
  });

  return (
    <EditProspectProfileModal
      contactType="prospect"
      loading={loading}
      submissionLoading={submissionLoading}
      cancel={goBack}
      language={language}
      timezone={timezone}
      eligibilityEndDate={
        eligibilityEndDate ? moment(eligibilityEndDate, DATE_FORMAT) : null
      }
      birthdate={birthdate ? moment(birthdate, DATE_FORMAT) : null}
      externalId={externalId}
      siteName={siteName}
      medicaidId={medicaidId}
      programTemplate={programTemplate}
      pcpName={pcpName}
      pcpNumber={pcpNumber}
      {...person}
      submit={async ({
        siteName: submittedSiteName,
        externalId: submittedExternalId,
        birthdate: submittedBirthdate,
        language: submittedLanguage,
        gender,
        timezone: submittedTimezone,
        eligibilityEndDate: submittedEligibilityEndDate,
        race: submittedRace,
        ethnicity: submittedEthnicity,
      }): Promise<void> => {
        await updateProspect({
          variables: {
            id,
            prospectPatch: {
              externalId: submittedExternalId,
              siteName: submittedSiteName,
              eligibilityEndDate: submittedEligibilityEndDate
                ? moment(submittedEligibilityEndDate).format(DATE_FORMAT)
                : null,
              person: {
                primaryLanguage: submittedLanguage,
                birthDate: moment(submittedBirthdate).format(DATE_FORMAT),
                gender,
                primaryTimezone: submittedTimezone,
                race: submittedRace,
                ethnicity: submittedEthnicity,
              },
            },
          },
        });
      }}
    />
  );
};

export default EditProspectProfileFormModal;
