import React from "react";
import { Modal, message as alert } from "@wellth/web-ui";
import {
  useChangeProspectStatusMutation,
  useCreateProspectForInactiveMemberMutation,
  GetProspectByIdDocument as prospectByIdQuery,
} from "hooks/graphql";
import { MODAL_WIDTH } from "utils";
import { ProspectStatus } from "components/ProspectPage";

export interface ReOpenProspectModalProps {
  id: string;
  userType: "member" | "prospect";
  programCode: string;
  shortId: string;
  goBack: () => void;
  routeToProspectPage: (prospectId: string) => void;
}

const HEADER_STYLE: React.CSSProperties = {
  margin: "2rem",
  fontSize: "1rem",
};

const WARNING_MESSAGE_STYLE: React.CSSProperties = {
  color: "#ff4d4f",
};

export const ReOpenProspectModal = ({
  id,
  userType,
  programCode,
  shortId,
  goBack,
  routeToProspectPage,
}: ReOpenProspectModalProps) => {
  const [reOpenProspect] = useCreateProspectForInactiveMemberMutation({
    onCompleted: ({
      createProspectForInactiveMember: {
        prospect: { id: prospectId },
      },
    }) => {
      routeToProspectPage(prospectId);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message }) => {
        void alert.error(`Error re-opening prospect: ${message}`);
      });
    },
  });

  const [changeProspectStatus] = useChangeProspectStatusMutation({
    refetchQueries: [{ query: prospectByIdQuery, variables: { id } }],
    onError: ({ message }) => alert.error(message),
    onCompleted: goBack,
  });

  return (
    <Modal
      width={MODAL_WIDTH}
      okText="Re-Open Prospect"
      okType="danger"
      title="Re-Open Prospect"
      visible
      onCancel={goBack}
      bodyStyle={{
        textAlign: "center",
      }}
      onOk={async () => {
        if (userType === "member") {
          await reOpenProspect({
            variables: {
              input: {
                id,
              },
            },
          });
        }
        if (userType === "prospect") {
          await changeProspectStatus({
            variables: { id, status: ProspectStatus.open, reason: "Re-open" },
          });
        }
      }}
    >
      <>
        <div>
          <div style={HEADER_STYLE}>Do you wish to re-open this prospect?</div>
          <div style={WARNING_MESSAGE_STYLE}>
            {userType === "member"
              ? `Doing this will create a new OPEN prospect in program ${programCode} 
            with ${shortId} person information`
              : "Doing this will set this current prospect's status to open"}
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ReOpenProspectModal;
