import { Action, handleActions } from "redux-actions";
import { SESSION_CLEAR } from "actions/sessionClear";
import { SESSION_TOKEN_SET } from "actions/sessionTokenSet";
import { SESSION_REFRESH_TOKEN_SET } from "actions/sessionRefreshTokenSet";

export interface State {
  token?: string;
  refreshToken?: string;
}

const initialState: State = {
  token: null,
  refreshToken: null,
};

export const sessionReducer = handleActions(
  {
    [SESSION_TOKEN_SET]: (state: State, action: Action<string>) => ({
      ...state,
      token: action.payload,
    }),
    [SESSION_REFRESH_TOKEN_SET]: (state: State, action: Action<string>) => ({
      ...state,
      refreshToken: action.payload,
    }),
    [SESSION_CLEAR]: () => initialState,
  },
  initialState,
);

export default sessionReducer;
