import {
  MemberDocument as memberProfileQuery,
  GetProspectByIdDocument as prospectByIdQuery,
} from "hooks/graphql";
import * as constants from "../containers/MemberProfile/constants";

export const MODAL_WIDTH = "43.750rem";

export const MEMBER_PROFILE_REFETCH = (id: string) => [
  {
    query: memberProfileQuery,
    variables: {
      id,
      last7StartDate: constants.last7StartDate,
      last30StartDate: constants.last30StartDate,
      totalStartDate: constants.totalStartDate,
      today: constants.today,
      endDate: constants.endDate,
      MEMBER_PROFILE_REFETCH,
    },
  },
];

export const PROSPECT_PROFILE_REFETCH = (id: string) => [
  {
    query: prospectByIdQuery,
    variables: {
      id,
      PROSPECT_PROFILE_REFETCH,
    },
  },
];

export const createUrl = (base: string, path: string): string =>
  `${base}${path}`;
