export enum AdminPrivilege {
  MemberEducator = "member_educator",
  Admin = "admin",
  SuperAdmin = "super_admin",
  CallCenterAgent = "call_center_agent",
}

export const privilegeForString = (value: string): AdminPrivilege | null => {
  switch (value) {
    case AdminPrivilege.Admin:
      return AdminPrivilege.Admin;
    case AdminPrivilege.MemberEducator:
      return AdminPrivilege.MemberEducator;
    case AdminPrivilege.SuperAdmin:
      return AdminPrivilege.SuperAdmin;
    case AdminPrivilege.CallCenterAgent:
      return AdminPrivilege.CallCenterAgent;
    default:
      return null;
  }
};
