import React from "react";
import { EditPaymentOptionModal as EditPaymentOptionModalUI } from "components/EditPaymentOptionModal";
import {
  RewardCardDetailsByMemberIdDocument,
  useRewardCardDetailsByMemberIdQuery,
  useProgramPaymentOptionsQuery,
  useUpdateMemberByIdMutation,
} from "hooks/graphql";

const NO_PAYMENT_OPTION = "DOES NOT HAVE A PAYMENT OPTION";

export interface EditPaymentOptionModalProps {
  goBack: () => void;
  memberId: string;
  programCode: string;
}

export const EditPaymentOptionModal: React.FC<EditPaymentOptionModalProps> = ({
  goBack,
  memberId,
  programCode,
  ...props
}) => {
  const {
    data: { rewardCardDetailsByMemberId } = {},
  } = useRewardCardDetailsByMemberIdQuery({
    variables: {
      id: memberId,
    },
  });

  const {
    data: { programPaymentOptions = [] } = {},
  } = useProgramPaymentOptionsQuery({
    variables: {
      condition: {
        programCode,
      },
    },
  });

  const [
    updateMember,
    { loading: submissionLoading },
  ] = useUpdateMemberByIdMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: RewardCardDetailsByMemberIdDocument,
        variables: { id: memberId },
      },
    ],
  });

  const { rewardCardChoice } = rewardCardDetailsByMemberId || {};

  return (
    <EditPaymentOptionModalUI
      {...props}
      submissionLoading={submissionLoading}
      goBack={goBack}
      disableSubmitButton={!rewardCardChoice}
      editPaymentOption={async (newPaymentOptionId) =>
        updateMember({
          variables: {
            id: memberId,
            memberPatch: {
              paymentOptionsId: newPaymentOptionId,
            },
          },
        })
      }
      memberPaymentOption={rewardCardChoice || NO_PAYMENT_OPTION}
      programPaymentOptions={programPaymentOptions.map(({ name, id }) => ({
        title: name,
        value: id,
        disabled: rewardCardChoice === name,
      }))}
    />
  );
};

export default EditPaymentOptionModal;
