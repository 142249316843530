"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  KeyboardOffset: true
};
exports.KeyboardOffset = void 0;
var _reactNative = require("react-native");
var _constants = require("./constants");
Object.keys(_constants).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _constants[key];
    }
  });
});
var KeyboardOffset = _reactNative.Platform.select({
  ios: 66,
  android: 0,
  web: 0,
  macos: 0,
  windows: 0
});
exports.KeyboardOffset = KeyboardOffset;