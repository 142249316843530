import React from "react";
import NotesUI from "components/PatientNotes";
import {
  useCreateProspectNoteMutation,
  GetProspectByIdDocument,
} from "hooks/graphql";
import { Admin } from "constants/admin";

export interface ProspectNote {
  id: string;
  createdAt: Date;
  note: string;
  admin: Admin;
}

export interface ProspectNoteProps {
  prospectId: string;
  prospectNotes: ProspectNote[];
}

export const ProspectNotes: React.FC<ProspectNoteProps> = ({
  prospectId,
  prospectNotes,
}) => {
  const [createNote] = useCreateProspectNoteMutation({
    context: { requireOnline: true },
    refetchQueries: [
      { query: GetProspectByIdDocument, variables: { id: prospectId } },
    ],
  });

  return (
    <NotesUI
      notes={prospectNotes.map(({ note, id, createdAt, admin }) => ({
        id,
        createdAt,
        body: note,
        adminName: admin ? `${admin.firstName} ${admin.lastName}` : "System",
      }))}
      sendMessage={async (text) =>
        createNote({
          variables: {
            input: {
              prospectNoteInput: {
                prospectId,
                note: text,
              },
            },
          },
        })
      }
    />
  );
};

export default ProspectNotes;
