import React from "react";
import { Tabs } from "@wellth/web-ui";
import SmsTemplates from "containers/SmsTemplates";
import SmsBulkUploader from "containers/smsOutreachUploader";
import SmsOutreachBulkSender from "containers/SmsOutreachBulkSender";
import SmsOutreachAutoApproveList from "containers/SmsOutreachAutoApproveList";

const { TabPane } = Tabs;

const MESSAGE_TEMPLATE = "Message Template";
const MESSAGE_UPLOADER = "Message Uploader";
const MESSAGE_SENDER = "Message Sender";
const QUEUED_MESSAGES = "Queued Messages";

const SmsOutreach = (props) => (
  <Tabs
    defaultActiveKey={MESSAGE_TEMPLATE}
    tabPosition="left"
    destroyInactiveTabPane
  >
    <TabPane tab={MESSAGE_TEMPLATE} key={MESSAGE_TEMPLATE}>
      <SmsTemplates {...props} />
    </TabPane>
    <TabPane tab={MESSAGE_UPLOADER} key={MESSAGE_UPLOADER}>
      <SmsBulkUploader />
    </TabPane>
    <TabPane tab={MESSAGE_SENDER} key={MESSAGE_SENDER}>
      <SmsOutreachBulkSender />
    </TabPane>
    <TabPane tab={QUEUED_MESSAGES} key={QUEUED_MESSAGES}>
      <SmsOutreachAutoApproveList />
    </TabPane>
  </Tabs>
);

export default SmsOutreach;
