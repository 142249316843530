import React from "react";

export const GenderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g fill="#657580" clipPath="url(#prefix__clip0)">
      <path d="M8.15 12.241c.03.041.07.074.115.098.046.023.096.035.147.036h1.533c.039 0 .076.013.105.037.03.025.05.058.057.096l.614 3.231v.005c.017.073.058.139.117.186.058.046.13.072.206.073h1.98c.074 0 .147-.027.205-.074.059-.047.1-.112.116-.185v-.005l.614-3.23c.008-.038.028-.072.057-.097.03-.024.067-.037.105-.037h1.548c.05 0 .1-.013.145-.036.046-.022.085-.055.116-.095.03-.04.052-.087.062-.137.01-.05.008-.1-.005-.15-1.31-4.774-1.253-5.03-2.004-5.733-.036-.032-.079-.057-.125-.071-.046-.015-.095-.019-.143-.013-.048.007-.094.023-.135.05-.041.025-.076.06-.102.1l-1.157 1.817c-.03.046-.071.084-.12.11-.049.027-.103.04-.159.04-.055 0-.11-.013-.158-.04-.049-.026-.09-.064-.12-.11l-1.149-1.833c-.026-.04-.06-.075-.101-.1-.041-.027-.087-.043-.135-.05-.048-.006-.096-.002-.143.013-.046.014-.088.038-.124.07-.77.71-.71.98-2.018 5.748-.013.049-.015.1-.006.15.01.05.031.096.062.136zM.333 12.043h1.194c.041 0 .081.015.112.043.03.028.049.067.052.108l.292 3.503c.007.082.044.159.104.215.06.056.14.087.222.088h2.638c.082 0 .162-.032.222-.088.061-.056.099-.132.106-.215l.292-3.503c.004-.041.022-.08.053-.108.03-.028.07-.043.111-.043h1.194c.088-.001.172-.037.234-.1.062-.061.096-.146.096-.234V9.405c.007-.48-.082-.958-.261-1.404-.18-.446-.446-.851-.784-1.193-.337-.343-.74-.614-1.183-.8-.443-.185-.919-.28-1.4-.28-.48 0-.956.095-1.399.28-.443.186-.845.457-1.183.8C.708 7.15.441 7.555.262 8c-.18.446-.268.923-.262 1.404v2.308c0 .043.009.087.025.127.017.04.042.077.073.107.03.031.068.055.108.072.04.016.084.024.127.024z" />
      <path d="M8.15 12.241c.03.041.07.074.115.098.046.023.096.035.147.036h1.533c.039 0 .076.013.105.037.03.025.05.058.057.096l.614 3.231v.005c.017.073.058.139.117.186.058.046.13.072.206.073h1.98c.074 0 .147-.027.205-.074.059-.047.1-.112.116-.185v-.005l.614-3.23c.008-.038.028-.072.057-.097.03-.024.067-.037.105-.037h1.548c.05 0 .1-.013.145-.036.046-.022.085-.055.116-.095.03-.04.052-.087.062-.137.01-.05.008-.1-.005-.15-1.31-4.774-1.253-5.03-2.004-5.733-.036-.032-.079-.057-.125-.071-.046-.015-.095-.019-.143-.013-.048.007-.094.023-.135.05-.041.025-.076.06-.102.1l-1.157 1.817c-.03.046-.071.084-.12.11-.049.027-.103.04-.159.04-.055 0-.11-.013-.158-.04-.049-.026-.09-.064-.12-.11l-1.149-1.833c-.026-.04-.06-.075-.101-.1-.041-.027-.087-.043-.135-.05-.048-.006-.096-.002-.143.013-.046.014-.088.038-.124.07-.77.71-.71.98-2.018 5.748-.013.049-.015.1-.006.15.01.05.031.096.062.136zM.333 12.043h1.194c.041 0 .081.015.112.043.03.028.049.067.052.108l.292 3.503c.007.082.044.159.104.215.06.056.14.087.222.088h2.638c.082 0 .162-.032.222-.088.061-.056.099-.132.106-.215l.292-3.503c.004-.041.022-.08.053-.108.03-.028.07-.043.111-.043h1.194c.088-.001.172-.037.234-.1.062-.061.096-.146.096-.234V9.405c.007-.48-.082-.958-.261-1.404-.18-.446-.446-.851-.784-1.193-.337-.343-.74-.614-1.183-.8-.443-.185-.919-.28-1.4-.28-.48 0-.956.095-1.399.28-.443.186-.845.457-1.183.8C.708 7.15.441 7.555.262 8c-.18.446-.268.923-.262 1.404v2.308c0 .043.009.087.025.127.017.04.042.077.073.107.03.031.068.055.108.072.04.016.084.024.127.024z" />
      <circle cx="3.7" cy="2.7" r="2.7" />
      <circle cx="11.9" cy="2.7" r="2.7" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0H16V16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default GenderIcon;
