import React from "react";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "row",
  },
  addressSection: {
    flex: 1,
    padding: 15,
    display: "flex",
    flexDirection: "column",
  },
  suggestedAddressSection: {
    borderColor: "#e4e7eb",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: "#f5f7fa",
  },
  enteredAddressButton: {
    borderStyle: "solid",
    borderColor: "#d9d9d9",
  },
  suggestedAddressButton: {
    backgroundColor: "#1890ff",
    fontSize: 14,
    color: "white",
  },
};

export default styles;
