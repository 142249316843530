import LocalizedStrings from "react-localization";
import english from "./en";
import spanish from "./es";
import russian from "./ru";

export * from "./enumTitles";

export default new LocalizedStrings({
  en: english,
  es: spanish,
  "es-US": spanish,
  ru: russian,
});
