import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Button, Form, FormInputType } from "@wellth/web-ui";

const BUTTON_STYLE: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "1.5rem",
};

type MemberStatus = "enrolled" | "onboarded" | "active" | "inactive";

export interface FormValues {
  programCodes?: string[];
  statuses?: MemberStatus[];
  externalId?: string;
}

export interface ChangeFiltersFormRef {
  getFilters: () => Promise<any>;
}

export interface FiltersProps {
  programCodes: string[];
  selectedProgramCodes?: string[];
  externalId?: string;
  statuses?: MemberStatus[];
  submit: (values: FormValues) => void;
}

const FiltersFC: React.RefForwardingComponent<
  ChangeFiltersFormRef,
  FiltersProps
> = (
  { programCodes, selectedProgramCodes = [], statuses, externalId, submit },
  ref,
) => {
  const formRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getFilters: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));
  return (
    <Form
      wrappedComponentRef={formRef}
      mapPropsToFields={(props, createFormField) => ({
        programCodes: createFormField({
          value: selectedProgramCodes,
        }),
        statuses: createFormField({
          value: statuses,
        }),
        externalId: createFormField({
          value: externalId,
        }),
      })}
      inputs={[
        {
          type: FormInputType.Dropdown,
          title: "Program Code",
          key: "programCodes",
          allowClear: true,
          options: programCodes.map((code) => ({
            title: code,
            value: code,
          })),
          mode: "multiple",
        },
        {
          type: FormInputType.Dropdown,
          title: "Member Status",
          key: "statuses",
          allowClear: true,
          options: [
            { title: "Enrolled", value: "enrolled" },
            { title: "Onboarded", value: "onboarded" },
            { title: "Active", value: "active" },
            { title: "Inactive", value: "inactive" },
          ],
          mode: "multiple",
        },
        { type: FormInputType.Text, title: "External Id", key: "externalId" },
      ]}
      submitButton={(props, form) => (
        <div style={BUTTON_STYLE}>
          <Button
            type="primary"
            onClick={() => submit(form.getFieldsValue() as FormValues)}
          >
            Update List
          </Button>
        </div>
      )}
    />
  );
};

export const Filters = forwardRef(FiltersFC);
export default Filters;
