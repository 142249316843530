import React from "react";
import { Modal } from "components/Modal";
import {
  MemberInformationForm,
  MemberInformationFormConfiguration,
} from "components/MemberInformationForm";

export interface MemberInformationModalProps
  extends MemberInformationFormConfiguration {
  loading: boolean;
  cancel: () => void;
}

export const MemberInformationModal: React.FC<MemberInformationModalProps> = ({
  cancel,
  loading = false,
  ...formProps
}: MemberInformationModalProps) => (
  <Modal
    title="View Information"
    loading={loading}
    onCancel={cancel}
    footer={null}
    width={700}
  >
    <MemberInformationForm {...formProps} />
  </Modal>
);

export default MemberInformationModal;
