import React from "react";
import moment from "moment-timezone";
import { SectionType } from "@wellth/web-ui";
import { BindTableSectionOptions, AnySectionBinding } from "./types";

export interface ProgramDetail {
  periodStartDate: moment.Moment;
  periodEndDate: moment.Moment;
  periodLength: number;
  rewards: number;
  lossPerDay: number;
  payoutDate: moment.Moment;
}

export type ProgramDetails = ProgramDetail[];

enum ColumnKey {
  PeriodStartDate = "periodStartDate",
  PeriodLength = "periodLength",
  Reward = "reward",
  LossPerDay = "lossPerDay",
  PayoutDate = "payoutDate",
  PeriodEndDate = "periodEndDate",
}

export interface ProgramDetailsInputOptions
  extends BindTableSectionOptions<ProgramDetail> {
  rewardCurrencySymbol: string;
}

export const Inputs: AnySectionBinding<ProgramDetailsInputOptions> = ({
  rewardCurrencySymbol,
  dataSource,
  ...props
}) => ({
  ...props,
  title: "Program Details",
  key: "programDetails",
  type: SectionType.Table,
  data: {
    dataSource,
    columns: [
      {
        title: "Reward Period Start Date",
        key: ColumnKey.PeriodStartDate,
        render: ({ periodStartDate }: ProgramDetail) => (
          <div>{periodStartDate.format("ll")}</div>
        ),
        onCell: (record) => ({
          record,
          name: ColumnKey.PeriodStartDate,
        }),
      },
      {
        title: "Reward Period End Date",
        key: ColumnKey.PeriodEndDate,
        render: ({ periodEndDate }: ProgramDetail) => (
          <div>{periodEndDate.format("ll")}</div>
        ),
        onCell: (record) => ({
          record,
          name: ColumnKey.PeriodEndDate,
        }),
      },
      {
        title: "Reward Period Duration",
        key: ColumnKey.PeriodLength,
        render: ({ periodLength }: ProgramDetail) => (
          <div>{periodLength} days</div>
        ),
        onCell: (record) => ({
          record,
          name: ColumnKey.PeriodLength,
        }),
      },
      {
        title: "Reward Amount",
        key: ColumnKey.Reward,
        render: ({ rewards }: ProgramDetail) => (
          <div>
            {rewardCurrencySymbol}
            {rewards}
          </div>
        ),
        onCell: (record) => ({
          record,
          name: ColumnKey.Reward,
        }),
      },
      {
        title: "Loss per day",
        key: ColumnKey.LossPerDay,
        render: ({ lossPerDay }: ProgramDetail) => (
          <div>
            {rewardCurrencySymbol}
            {lossPerDay}
          </div>
        ),
        onCell: (record) => ({
          record,
          name: ColumnKey.LossPerDay,
        }),
      },
      {
        title: "Payout date",
        key: ColumnKey.PayoutDate,
        render: ({ payoutDate }: ProgramDetail) => (
          <div>{payoutDate.format("ll")}</div>
        ),
        onCell: (record) => ({
          record,
          name: ColumnKey.PayoutDate,
        }),
      },
    ],
  },
});
