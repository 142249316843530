import React, { useRef } from "react";
import { Form, message as alert, FormInputType } from "@wellth/web-ui";
import { Modal } from "components/Modal";
import { MEMBER_PROFILE_REFETCH } from "utils";
import { useUpdateMemberByIdMutation } from "hooks/graphql";

interface TestModalProps {
  goBack: () => void;
  isReminderMessagingEnabled: boolean;
  isLocalNotificationEnabled: boolean;
  isProductTourRecurring: boolean;
  id: string;
}

export default ({
  goBack,
  isReminderMessagingEnabled,
  isLocalNotificationEnabled,
  isProductTourRecurring,
  id,
}: TestModalProps) => {
  const formRef = useRef(null);
  const [updateMember] = useUpdateMemberByIdMutation({
    refetchQueries: MEMBER_PROFILE_REFETCH(id),
    awaitRefetchQueries: true,
    onError: () => alert.error("Unable to update member"),
    onCompleted: () => {
      void alert.success("Successfully updated member");
      goBack();
    },
  });

  return (
    <Modal
      okText="Submit"
      title="Test User Config"
      loading={false}
      visible
      onCancel={goBack}
      onOk={async () => {
        const formValues = formRef.current.props.form.getFieldsValue();
        await updateMember({
          variables: {
            id,
            memberPatch: {
              person: {},
              isReminderMessagingEnabled: formValues.isReminderMessagingEnabled,
              isLocalNotificationEnabled: formValues.isLocalNotificationEnabled,
              isProductTourRecurring: formValues.isProductTourRecurring,
            },
          },
        });
      }}
    >
      <Form
        wrappedComponentRef={formRef}
        mapPropsToFields={(_props, createFormField) => ({
          isReminderMessagingEnabled: createFormField({
            value: isReminderMessagingEnabled,
          }),
          isLocalNotificationEnabled: createFormField({
            value: isLocalNotificationEnabled,
          }),
          isProductTourRecurring: createFormField({
            value: isProductTourRecurring,
          }),
        })}
        inputs={[
          {
            type: FormInputType.Switch,
            title: "Disable Reward Payouts",
            disabled: true,
            key: "disableRewardPayouts",
          },
          {
            type: FormInputType.Switch,
            title: "Disable SMS Notifications",
            disabled: false,
            key: "isReminderMessagingEnabled",
            decoratorOptions: {
              valuePropName: "checked",
            },
          },
          {
            type: FormInputType.Switch,
            title: "Disable Local Notifications",
            disabled: false,
            key: "isLocalNotificationEnabled",
            decoratorOptions: {
              valuePropName: "checked",
            },
          },
          {
            type: FormInputType.Switch,
            title: "Turn on recurring Product Tour",
            disabled: false,
            key: "isProductTourRecurring",
            decoratorOptions: {
              valuePropName: "checked",
            },
          },
        ]}
      />
    </Modal>
  );
};
