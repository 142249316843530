"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.StepLayout = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("react"));
var _reactNative = require("react-native");
var _utils = require("../../utils");
var _CircularImageButton = require("../../components/CircularImageButton");
var _KeyboardObserver = require("../../components/KeyboardObserver");
var _styles = _interopRequireWildcard(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/layout/StepLayout/index.tsx";
function _getRequireWildcardCache(e) {
  if ("function" != typeof WeakMap) return null;
  var r = new WeakMap(),
    t = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(e) {
    return e ? t : r;
  })(e);
}
function _interopRequireWildcard(e, r) {
  if (!r && e && e.__esModule) return e;
  if (null === e || "object" != _typeof(e) && "function" != typeof e) return {
    "default": e
  };
  var t = _getRequireWildcardCache(r);
  if (t && t.has(e)) return t.get(e);
  var n = {
      __proto__: null
    },
    a = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var u in e) {
    if ("default" !== u && {}.hasOwnProperty.call(e, u)) {
      var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
      i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
    }
  }
  return n["default"] = e, t && t.set(e, n), n;
}
var StepLayout = function StepLayout(_ref) {
  var _ref$renderSubmitButt = _ref.renderSubmitButton,
    renderSubmitButton = _ref$renderSubmitButt === void 0 ? true : _ref$renderSubmitButt,
    submit = _ref.submit,
    children = _ref.children,
    style = _ref.style,
    backgroundColor = _ref.backgroundColor,
    buttonProps = _ref.buttonProps;
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    isLoading = _useState2[0],
    setIsLoading = _useState2[1];
  var cancellablePromise = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    return function () {
      if (cancellablePromise.current) {
        cancellablePromise.current.cancel();
      }
    };
  }, []);
  var onSubmitPress = (0, _react.useCallback)((0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            setIsLoading(true);
            cancellablePromise.current = (0, _utils.makeCancellable)(submit());
            _context.prev = 2;
            _context.next = 5;
            return cancellablePromise.current.promise;
          case 5:
            _context.prev = 5;
            setIsLoading(false);
            return _context.finish(5);
          case 8:
            cancellablePromise.current = null;
          case 9:
          case "end":
            return _context.stop();
        }
      }
    }, _callee, null, [[2,, 5, 8]]);
  })), [setIsLoading, submit]);
  return _react["default"].createElement(_KeyboardObserver.KeyboardObserver, {
    style: style,
    backgroundColor: backgroundColor,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 60,
      columnNumber: 5
    }
  }, function (keyboardIsVisible) {
    return _react["default"].createElement(_reactNative.SafeAreaView, {
      style: _styles["default"].safeArea,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 62,
        columnNumber: 9
      }
    }, typeof children === "function" ? children(keyboardIsVisible) : children, renderSubmitButton ? _react["default"].createElement(_CircularImageButton.CircularImageButton, (0, _extends2["default"])({
      containerStyle: _styles["default"].submitButton,
      size: _styles.BUTTON_SIZE
    }, buttonProps, {
      onPress: onSubmitPress,
      loading: isLoading,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 68,
        columnNumber: 13
      }
    })) : null);
  });
};
exports.StepLayout = StepLayout;
var _default = StepLayout;
exports["default"] = _default;