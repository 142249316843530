import React, { useState, useCallback, useImperativeHandle } from "react";
import { Radio, Input, Select } from "antd";
import { BoxLayout } from "@wellth/web-ui";

const CONTAINER_COLUMN_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

const BOX_LAYOUT_STYLE: React.CSSProperties = {
  boxShadow: "none",
  border: "solid 0.0625rem #e4e7eb",
};

const TEXT_AREA_STYLE: React.CSSProperties = {
  border: "solid 0.0625rem #e4e7eb",
};

const REASONS_STYLE: React.CSSProperties = {
  width: "11.25rem",
  margin: "0 0.3125rem",
};

const { TextArea } = Input;

export interface ReasonValues {
  title: string;
  value: string;
}

export interface ReviewBonusSubmissionInputValues {
  approved: boolean;
  reason: string | null;
  note: string | null;
}

export interface ReviewBonusSubmissionInputRef {
  getSubmissionReviewInput: () => ReviewBonusSubmissionInputValues;
}

export interface ReviewBonusSubmissionInputProps {
  reasons: ReasonValues[];
  selectedReason?: ReasonValues["value"];
}

const ReviewBonusSubmissionInput: React.RefForwardingComponent<
  ReviewBonusSubmissionInputRef,
  ReviewBonusSubmissionInputProps
> = ({ reasons = [], selectedReason: selectedReasonProp }, ref) => {
  const [selectedSection, setSelectedSection] = useState(true);
  const [selectedReason, setSelectedReason] = useState(selectedReasonProp);
  const [note, setNote] = useState(null);

  // Callback that the ref uses to get the latest input state
  const getSubmissionReviewInput = useCallback(
    (): ReviewBonusSubmissionInputValues => {
      if (!selectedSection && !selectedReason) {
        throw new Error("Reason is not provided");
      }

      return {
        note,
        approved: selectedSection,
        reason: selectedReason,
      };
    },
    [note, selectedSection, selectedReason],
  );

  // Create the ref the consumer uses
  useImperativeHandle(ref, () => ({
    getSubmissionReviewInput,
  }));

  // Callback to update the selected reason. Used
  // by the `Select` component.
  const selectReason = useCallback(
    (reason: string) => {
      setSelectedReason(reason);
    },
    [setSelectedReason],
  );

  return (
    <>
      <div style={CONTAINER_COLUMN_STYLE}>
        <Radio.Group
          value={selectedSection}
          onChange={({ target: { value } }): void => {
            setSelectedSection(value);
          }}
          style={{
            flexDirection: "column",
          }}
        >
          <BoxLayout title="" style={BOX_LAYOUT_STYLE}>
            <Radio value>Approve</Radio>
          </BoxLayout>
          <BoxLayout title="" style={BOX_LAYOUT_STYLE}>
            <Radio value={false}>
              Reject, with reason:
              <Select
                allowClear
                value={selectedReason}
                onChange={selectReason}
                style={REASONS_STYLE}
              >
                {reasons.map(({ title, value }, index) => (
                  <Select.Option key={`${index}-${title}`} value={value}>
                    {title}
                  </Select.Option>
                ))}
              </Select>
            </Radio>
          </BoxLayout>
        </Radio.Group>
        <TextArea
          value={note}
          placeholder="Add a note..."
          onChange={({ target: { value } }): void => setNote(value)}
          style={TEXT_AREA_STYLE}
        />
      </div>
    </>
  );
};

export default React.forwardRef(ReviewBonusSubmissionInput);
