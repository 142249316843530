import React from "react";
import { Table, Button } from "@wellth/web-ui";

const OUTSIDE_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  marginTop: "3rem",
  width: "75rem",
  marginLeft: "auto",
  marginRight: "auto",
};

const CONTAINER_STYLES: React.CSSProperties = {
  width: "100%",
};

const HEADING_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "1rem",
};

const HEADING_STYLES: React.CSSProperties = {
  fontSize: "1.5rem",
  lineHeight: 1.33,
};

interface SmsTemplate {
  id: string;
  template: any;
  actions: any;
}

interface Props {
  data: SmsTemplate[];
  onCreateTemplate: any;
}

export default ({ data, onCreateTemplate }: Props) => (
  <div style={OUTSIDE_CONTAINER_STYLES}>
    <div style={CONTAINER_STYLES}>
      <div style={HEADING_CONTAINER_STYLES}>
        <span style={HEADING_STYLES}>SMS Templates</span>
        <Button
          onClick={() => {
            onCreateTemplate("createTemplate");
          }}
          type="primary"
        >
          Create SMS Template
        </Button>
      </div>

      <Table
        dataSource={data}
        pagination={false}
        columns={[
          { title: "Template", dataIndex: "template", width: "90%" },
          { title: "Actions", dataIndex: "actions", width: "10%" },
        ]}
        rowClassName={() => "row"}
        bordered={false}
        rowKey="id"
      />
    </div>
  </div>
);
