import React, { CSSProperties } from "react";

const containerStyle: CSSProperties = {
  textAlign: "center",
};

export default () => (
  <div style={containerStyle}>
    <h1>UNAUTHORIZED</h1>
  </div>
);
