export const BUTTON_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const TEXT_ALIGN: React.CSSProperties = {
  textAlign: "center",
};

export const CALENDAR_STYLES: React.CSSProperties = {
  height: "100vh",
};

export const DISABLE_SCREEN_STYLES: React.CSSProperties = {
  backgroundColor: "grey",
  opacity: ".4",
  position: "absolute",
  height: "120%",
  width: "100%",
  zIndex: 20,
};
