import { SectionType } from "@wellth/web-ui";
import React from "react";

enum ColumnKey {
  Name = "name",
}

// eslint-disable-next-line import/prefer-default-export
export const Inputs = ({ dataSource, ...props }) => ({
  ...props,
  title: "Tags",
  key: "tags",
  type: SectionType.Table,
  data: {
    dataSource,
    rowKey: (record, index) => String(index),
    columns: [
      {
        title: "Name",
        width: "100%",
        key: ColumnKey.Name,
        onCell: (record) => ({ record, name: ColumnKey.Name }),
        render: ({ name }) => <div>{name}</div>,
      },
    ],
  },
});
