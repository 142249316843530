"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CircularButton = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _CircularView = require("../CircularView");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/CircularButton/index.tsx";
var Touchable = _reactNative.TouchableOpacity;
var centerContentStyles = {
  alignItems: "center",
  justifyContent: "center"
};
var CircularButton = function CircularButton(_ref) {
  var onPress = _ref.onPress,
    style = _ref.style,
    containerStyle = _ref.containerStyle,
    children = _ref.children,
    _ref$backgroundColor = _ref.backgroundColor,
    backgroundColor = _ref$backgroundColor === void 0 ? _colors["default"].WHITE : _ref$backgroundColor,
    _ref$disabled = _ref.disabled,
    disabled = _ref$disabled === void 0 ? false : _ref$disabled,
    props = (0, _objectWithoutProperties2["default"])(_ref, ["onPress", "style", "containerStyle", "children", "backgroundColor", "disabled"]);
  return _react["default"].createElement(Touchable, {
    style: [centerContentStyles, containerStyle],
    disabled: disabled,
    onPress: onPress,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 37,
      columnNumber: 3
    }
  }, _react["default"].createElement(_CircularView.CircularView, (0, _extends2["default"])({
    style: [centerContentStyles, {
      backgroundColor: backgroundColor
    }, style]
  }, props, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 42,
      columnNumber: 5
    }
  }), children));
};
exports.CircularButton = CircularButton;
var _default = CircularButton;
exports["default"] = _default;