import { CSSProperties } from "react";

const styles: { [key: string]: CSSProperties } = {
  addressComponentLabel: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: -0.2,
    marginBottom: 1.2,
  },
  addressComponentsSection: {
    marginBottom: 24,
  },
  titleLabel: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.43,
    letterSpacing: -0.32,
    color: "#616e7c",
    marginBottom: 5,
  },
  noteLabel: { color: "red", fontSize: "10px" },
  useAddressButton: {
    borderRadius: 4,
    borderWidth: 1,
    marginTop: "auto",
  },
};

export default styles;
