"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _default = _reactNative.StyleSheet.create({
  container: {
    backgroundColor: _colors["default"].TRANSPARENT,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  }
});
exports["default"] = _default;