import React, { RefAttributes } from "react";
import moment from "moment-timezone";
import { SectionType, FormInputType, SectionProps } from "@wellth/web-ui";
import {
  titleForGender,
  titleForLanguageCode,
  titleForTimezone,
} from "constants/strings";
import { SectionHeader } from "components/SectionHeader";
import { ProfileSectionProps as BaseProps } from "../types";
import styles from "./styles";

export interface ProfileSectionProps extends BaseProps, RefAttributes<any> {
  onEditButtonPressed: () => void;
}

export const Inputs = ({
  birthdate,
  language,
  gender,
  timezone,
  externalId,
  siteName,
  eligibilityEndDate,
  race,
  ethnicity,
  medicareId,
  medicaidId,
  programTemplate,
  coverageType,
  planName,
  pcpName,
  pcpNumber,
  onEditButtonPressed,
  ref,
}: ProfileSectionProps): SectionProps<any> => ({
  ref,
  title: (
    <SectionHeader
      title="Profile"
      submitButtonType="edit"
      onClick={onEditButtonPressed}
    />
  ),
  key: "profile",
  type: SectionType.Form,
  data: {
    mapPropsToFields: (props, createFormField) => ({
      birthdate: createFormField({
        value: birthdate ? moment(birthdate).format("L") : "",
      }),
      language: createFormField({
        value: titleForLanguageCode(language),
      }),
      gender: createFormField({
        value: titleForGender(gender),
      }),
      timezone: createFormField({
        value: titleForTimezone(timezone),
      }),
      localTime: createFormField({
        value: timezone ? moment.tz(timezone).format("h:mm A (z)") : "",
      }),
      externalId: createFormField({
        value: externalId,
      }),
      siteName: createFormField({
        value: siteName,
      }),
      eligibilityEndDate: createFormField({
        value: eligibilityEndDate ? moment(eligibilityEndDate).format("L") : "",
      }),
      race: createFormField({
        value: race,
      }),
      ethnicity: createFormField({
        value: ethnicity,
      }),
      medicareId: createFormField({
        value: medicareId,
      }),
      medicaidId: createFormField({
        value: medicaidId,
      }),
      programTemplate: createFormField({
        value: programTemplate,
      }),
      coverageType: createFormField({
        value: coverageType,
      }),
      planName: createFormField({
        value: planName,
      }),
      pcpName: createFormField({
        value: pcpName,
      }),
      pcpNumber: createFormField({
        value: pcpNumber,
      }),
    }),
    inputs: [
      {
        type: FormInputType.Info,
        title: "Birthday",
        key: "birthdate",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Language",
        key: "language",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Gender",
        key: "gender",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Race",
        key: "race",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Ethnicity",
        key: "ethnicity",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        key: "timezone",
        title: "Primary Timezone",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Local Time",
        key: "localTime",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Customer Provided ID",
        key: "externalId",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Site Name",
        key: "siteName",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Eligibility End Date",
        key: "eligibilityEndDate",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Medicare ID",
        key: "medicareId",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Medicaid ID",
        key: "medicaidId",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Program Template",
        key: "programTemplate",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Coverage Type",
        key: "coverageType",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Plan Name",
        key: "planName",
        style: styles.inputStyles,
      },
      {
        type: FormInputType.Info,
        title: "Primary Care Provider",
        key: "pcpName",
        style: styles.inputStyle,
      },
      {
        type: FormInputType.Info,
        title: "Primary Care Provider Contact",
        key: "pcpNumber",
        style: styles.inputStyle,
      },
    ],
    labelCol: { sm: 8 },
    colValue: 12,
  },
});

export default Inputs;
