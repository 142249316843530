import React, { useState } from "react";
import {
  useUploadBulkSmsMutation,
  useAllSmsOutreachMessageTemplatesQuery,
} from "hooks/graphql";
import { Spin, message } from "@wellth/web-ui";
import { Select } from "antd";
import Upload from "../../components/Upload";

const { Option } = Select;

const CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const UPLOAD_STYLES: React.CSSProperties = {
  width: "31.25rem",
};

const MESSAGE_TEMPLATE_STYLES: React.CSSProperties = {
  marginTop: "1rem",
};

const SmsBulkUploader = () => {
  const [shouldSpin, setShouldSpin] = useState(false);
  const [messageTemplateId, setMessageTemplateId] = useState("");
  const [uploadBulkSms] = useUploadBulkSmsMutation();
  const {
    data: { allSmsOutreachMessageTemplates: { nodes = [] } = {} } = {},
  } = useAllSmsOutreachMessageTemplatesQuery();

  const displayMessageTemplate = () => {
    if (!messageTemplateId) {
      return null;
    }
    const messageTemplate = nodes.find((m) => m.id === messageTemplateId);
    if (!messageTemplate) {
      return null;
    }
    return (
      <div style={MESSAGE_TEMPLATE_STYLES}>
        <h3>Message Template</h3>
        {messageTemplate.message}
      </div>
    );
  };

  return (
    <div>
      <div style={CONTAINER_STYLES}>
        <Select
          showSearch
          placeholder="Select a Message Template"
          optionFilterProp="children"
          onChange={(id) => setMessageTemplateId(id as string)}
          style={{ ...UPLOAD_STYLES, marginBottom: "0.5rem" }}
        >
          {nodes.map(({ id, title }) => (
            <Option key={id} value={id}>
              {title}
            </Option>
          ))}
        </Select>
        <Spin spinning={shouldSpin} />
        <div style={UPLOAD_STYLES}>
          <Upload
            accept=".csv"
            customRequest={({ file, onError, onSuccess }) => {
              setShouldSpin(true);
              uploadBulkSms({
                variables: { file, messageTemplateId },
              })
                .then((res) => {
                  void message.success(`Successfully uploaded ${file.name}`);
                  onSuccess(res);
                })
                .catch((err) => {
                  void message.error("Unable to upload file.");
                  onError(err);
                })
                .finally(() => {
                  setShouldSpin(false);
                });
            }}
            disabled={!messageTemplateId}
          />
          <div>{displayMessageTemplate()}</div>
        </div>
      </div>
    </div>
  );
};

export default SmsBulkUploader;
