"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.ButtonGroup = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("react"));
var _reactNative = require("react-native");
var _Button = require("./components/Button");
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/ButtonGroup/index.tsx";
function _getRequireWildcardCache(e) {
  if ("function" != typeof WeakMap) return null;
  var r = new WeakMap(),
    t = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(e) {
    return e ? t : r;
  })(e);
}
function _interopRequireWildcard(e, r) {
  if (!r && e && e.__esModule) return e;
  if (null === e || "object" != _typeof(e) && "function" != typeof e) return {
    "default": e
  };
  var t = _getRequireWildcardCache(r);
  if (t && t.has(e)) return t.get(e);
  var n = {
      __proto__: null
    },
    a = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var u in e) {
    if ("default" !== u && {}.hasOwnProperty.call(e, u)) {
      var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
      i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
    }
  }
  return n["default"] = e, t && t.set(e, n), n;
}
var ButtonGroup = function ButtonGroup(_ref) {
  var buttons = _ref.buttons,
    style = _ref.style;
  var _useState = (0, _react.useState)(0),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    listHeight = _useState2[0],
    setListHeight = _useState2[1];
  return _react["default"].createElement(_reactNative.View, {
    style: style,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 18,
      columnNumber: 5
    }
  }, _react["default"].createElement(_reactNative.View, {
    style: {
      height: listHeight
    },
    __source: {
      fileName: _jsxFileName,
      lineNumber: 19,
      columnNumber: 7
    }
  }, _react["default"].createElement(_reactNative.FlatList, {
    data: buttons,
    ItemSeparatorComponent: function ItemSeparatorComponent() {
      return _react["default"].createElement(_reactNative.View, {
        style: _styles["default"].separator,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 22,
          columnNumber: 41
        }
      });
    },
    keyExtractor: function keyExtractor(item, index) {
      return index.toString();
    },
    renderItem: function renderItem(_ref2) {
      var _ref2$item = _ref2.item,
        iconSource = _ref2$item.iconSource,
        label = _ref2$item.label,
        onPress = _ref2$item.onPress,
        buttonProps = _ref2$item.buttonProps,
        index = _ref2.index;
      return _react["default"].createElement(_Button.Button, {
        key: index,
        iconSource: iconSource,
        label: label,
        onPress: onPress,
        position: "Standalone",
        buttonProps: buttonProps,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 28,
          columnNumber: 13
        }
      });
    },
    scrollEnabled: false,
    onContentSizeChange: function onContentSizeChange(contentHeight) {
      setListHeight(contentHeight);
    },
    __source: {
      fileName: _jsxFileName,
      lineNumber: 20,
      columnNumber: 9
    }
  })));
};
exports.ButtonGroup = ButtonGroup;
var _default = ButtonGroup;
exports["default"] = _default;