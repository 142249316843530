import React, { useImperativeHandle } from "react";
import { Form, FormInputType, OptionProps } from "@wellth/web-ui";
import { dayIsInThePast } from "utils/date";
import moment, { Moment } from "moment-timezone";

const INPUT_STYLE: React.CSSProperties = {
  height: "2.1875rem",
  width: "22rem",
};

const FormInputs = {
  PAGESET_PROGRAM_COMBO: "pagesetProgramCombo",
  PROGRAM: "program",
  START_DATE: "startDate",
  END_DATE: "endDate",
  REWARD_AMOUNT: "rewardAmount",
};

export type CreateBonusBehaviorFormInputs = {
  pagesetProgramCombo: string;
  program: string;
  startDate: Moment;
  endDate: Moment;
  rewardAmount: number;
};

export interface CreateBonusBehaviorFormProps {
  bonusBehaviorConfigs: OptionProps[];
  availablePrograms: OptionProps[];
}

const shouldPreventPastDates = (current: Date, timezone: string) =>
  current && dayIsInThePast(current, timezone);

const CreateBonusBehaviorForm: React.RefForwardingComponent<
  any,
  CreateBonusBehaviorFormProps
> = ({ bonusBehaviorConfigs, availablePrograms }, ref) => {
  const formRef = React.useRef(null);

  useImperativeHandle(ref, () => ({
    getBonusBehavior: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      wrappedComponentRef={formRef}
      inputs={[
        {
          type: FormInputType.Dropdown,
          key: FormInputs.PAGESET_PROGRAM_COMBO,
          style: INPUT_STYLE,
          title: "Existing Bonus Behavior Config",
          placeholder: "Choose an existing config",
          options: bonusBehaviorConfigs,
          decoratorOptions: {
            rules: [
              {
                required: true,
                message: "Please choose an existing bonus behavior config",
              },
            ],
          },
        },
        {
          type: FormInputType.Dropdown,
          key: FormInputs.PROGRAM,
          style: INPUT_STYLE,
          title: "Desired Program you wish to create a NEW Bonus Behavior for",
          placeholder: "Choose a program",
          options: availablePrograms,
          decoratorOptions: {
            rules: [{ required: true, message: "Please pick a program" }],
          },
        },
        {
          type: FormInputType.Date,
          format: "LL",
          title: "Start Date",
          key: FormInputs.START_DATE,
          decoratorOptions: {
            rules: [{ required: true, message: "Start Date is required" }],
          },
          disabledDate: (current) =>
            shouldPreventPastDates(current.toDate(), moment.tz.guess()),
        },
        {
          type: FormInputType.Date,
          title: "End Date",
          format: "LL",
          key: FormInputs.END_DATE,
          decoratorOptions: {
            rules: [{ required: true, message: "End Date is required" }],
          },
          disabledDate: (current) =>
            shouldPreventPastDates(current.toDate(), moment.tz.guess()),
        },
        {
          type: FormInputType.InputNumber,
          title: "Reward Amount",
          key: FormInputs.REWARD_AMOUNT,
          min: 0,
          max: 99,
          placeholder: "Amount",
          decoratorOptions: {
            rules: [
              { required: true, message: "Please specify reward amount" },
            ],
          },
        },
      ]}
    />
  );
};

export default React.forwardRef(CreateBonusBehaviorForm);
