import React, { useImperativeHandle } from "react";
import { Form, FormInputType, FormInputProps } from "@wellth/web-ui";

export interface MailingAddressFormProps {
  street: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  isPrimary: boolean;
}

export interface EditMailingAddressFormProps
  extends Partial<MailingAddressFormProps> {
  type: "member" | "prospect";
  disablePrimary?: boolean;
  inputStyle?: React.CSSProperties;
}

const EditMailingAddressForm: React.RefForwardingComponent<
  any,
  EditMailingAddressFormProps
> = (
  {
    type,
    inputStyle = { height: "2.1875rem", width: "18.75rem" },
    disablePrimary = false,
    ...formProps
  }: EditMailingAddressFormProps,
  ref,
) => {
  const formRef = React.useRef(null);
  const isMember = type === "member";
  useImperativeHandle(ref, () => ({
    getMailingAddress: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      {...formProps}
      wrappedComponentRef={formRef}
      mapPropsToFields={(
        { street, street2, city, state, zip, isPrimary = false },
        createFormField,
      ) => ({
        street: createFormField({ value: street }),
        street2: createFormField({ value: street2 }),
        city: createFormField({ value: city }),
        state: createFormField({ value: state }),
        zip: createFormField({ value: zip }),
        isPrimary: createFormField({ value: isPrimary }),
      })}
      inputs={[
        {
          type: FormInputType.Text,
          title: "Address 1",
          key: "street",
          style: inputStyle,
          decoratorOptions: {
            rules: [
              {
                required: true,
                message: "Please provide a street address.",
              },
            ],
          },
        },
        {
          type: FormInputType.Text,
          title: "Address 2",
          key: "street2",
          style: inputStyle,
        },
        {
          type: FormInputType.Text,
          title: "City",
          key: "city",
          style: inputStyle,
          decoratorOptions: {
            rules: [
              {
                required: true,
                message: "Please provide a city.",
              },
            ],
          },
        },
        {
          type: FormInputType.Text,
          title: "State",
          key: "state",
          style: inputStyle,
          decoratorOptions: {
            rules: [
              {
                required: true,
                pattern: /^[A-Z]{2}$/i,
                message: "Please provide a two letter state code.",
              },
            ],
          },
        },
        {
          type: FormInputType.Text,
          title: "Zipcode",
          key: "zip",
          style: inputStyle,
          decoratorOptions: {
            rules: [
              {
                required: true,
                message: "Please provide a zip code.",
              },
            ],
          },
        },
        ...(isMember
          ? [
              {
                type: FormInputType.Switch,
                title: "Set as Verified",
                key: "isPrimary",
                disabled: disablePrimary,
                decoratorOptions: { valuePropName: "checked" },
              } as FormInputProps,
            ]
          : []),
      ]}
      labelCol={{
        span: 7,
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 12 },
      }}
      colValue={12}
    />
  );
};

export default React.forwardRef(EditMailingAddressForm);
