import * as React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useMemberTimelineLazyQuery } from "hooks/graphql";
import ActivityTimeline, { TimelineEvent } from "components/ActivityTimeline";
import moment from "moment-timezone";
import { Card, Button } from "@wellth/web-ui";
import CARD_STYLES from "components/styles";
import { MemberTimelineQuery } from "types/globalTypes";
import { useState } from "react";

const CONTAINER_STYLE: React.CSSProperties = {
  marginTop: "2rem",
  display: "flex",
  justifyContent: "center",
};
const LOADING_STYLE: React.CSSProperties = { fontSize: "2rem" };

const CARD_STYLE: React.CSSProperties = {
  ...CARD_STYLES.card,
  boxShadow: "none",
  margin: "1.25rem",
};

const DATE_FORMAT = "MM/DD/YYYY";
const excludeEventCriteria = ["Missed", "Animation", "Navigation"];

interface ActivityProps {
  wellthId: string;
  timezone: string;
}

function insertDates(eventArr: Array<TimelineEvent>, timezone: string = "UTC") {
  const newArray = [];
  let curDate = moment("1/1/1970").tz(timezone);

  // eslint-disable-next-line no-restricted-syntax
  for (const item of eventArr) {
    const thisDate = moment(item.timestamp).tz(timezone);
    if (!curDate.isSame(thisDate, "day")) {
      newArray.push({
        id: thisDate.format(DATE_FORMAT),
        name: "DATE",
        timestamp: thisDate.format(DATE_FORMAT),
        properties: "{}",
      });
      curDate = thisDate;
    }
    newArray.push(item);
  }
  return newArray;
}

export const ActivityContainer: React.FC<ActivityProps> = ({
  wellthId,
  timezone,
}) => {
  const [lastTimestamp, setLastTimestamp] = useState<Date>();
  const [
    getMemberTimeline,
    { data: { memberTimelineEvents = [] } = {}, loading, fetchMore },
  ] = useMemberTimelineLazyQuery({
    variables: {
      wellthId,
      excludeEventCriteria,
    },
  });

  const timelineEvents: TimelineEvent[] = insertDates(
    memberTimelineEvents,
    timezone,
  );

  async function handleButtonClick() {
    if (!fetchMore) {
      setLastTimestamp(moment().toDate());
      getMemberTimeline();
    } else {
      let startTimestamp = moment().toDate();
      if (memberTimelineEvents && memberTimelineEvents.length) {
        startTimestamp = moment(
          memberTimelineEvents[memberTimelineEvents.length - 1].timestamp,
        ).toDate();
      }
      if (
        lastTimestamp &&
        !moment(startTimestamp).isBefore(lastTimestamp, "day")
      ) {
        startTimestamp = moment(lastTimestamp)
          .subtract(3, "day")
          .toDate();
      }
      setLastTimestamp(startTimestamp);
      await fetchMore({
        variables: {
          wellthId,
          excludeEventCriteria,
          startTimestamp,
        },
        updateQuery: (prev: MemberTimelineQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            memberTimelineEvents: [
              ...prev.memberTimelineEvents,
              ...fetchMoreResult.memberTimelineEvents,
            ],
          };
        },
      });
    }
  }

  let dateRange = "";
  if (lastTimestamp) {
    const begin = moment()
      .tz(timezone)
      .format(DATE_FORMAT);
    const end = moment(lastTimestamp)
      .subtract(3, "days")
      .format(DATE_FORMAT);
    dateRange = `${end} - ${begin}`;
  }

  return (
    <div>
      <Card
        title={
          <div style={CARD_STYLES.titleStyle}>
            Event Timeline
            <span>{dateRange}</span>
            <Button onClick={handleButtonClick} type="primary">
              {timelineEvents.length > 0 ? "Load 3 More Days" : "Load 3 Days"}
            </Button>
          </div>
        }
        bodyStyle={CONTAINER_STYLE}
        style={CARD_STYLE}
        bordered={false}
      >
        {loading ? (
          <LoadingOutlined style={LOADING_STYLE} />
        ) : (
          <ActivityTimeline events={timelineEvents} timezone={timezone} />
        )}
      </Card>
    </div>
  );
};

export default ActivityContainer;
