import * as React from "react";
import moment from "moment-timezone";
import COLORS from "utils/colors";
import { TIME_FORMAT } from "utils/date";

const SHARED_STYLES = {
  fontWeight: 500,
  fontSize: "0.938rem",
  padding: 0,
  margin: 0,
  fontFamily: "InterUI",
};

const IMAGE_STYLES: React.CSSProperties = {
  width: "11.25rem",
  height: "20rem",
};

const CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
};

const TITLE_STYLES: React.CSSProperties = {
  color: COLORS.lightGray,
  ...SHARED_STYLES,
};

const CONTENT_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  width: "21.875rem",
  marginBottom: "1.5rem",
};

const CONTENT_STYLES: React.CSSProperties = {
  ...SHARED_STYLES,
  color: COLORS.black,
};

interface ReviewTaskEventProps {
  checkInStatus: string | null;
  checkInLocalTime: any | null;
  checkInTimezone: any | null;
  windowBeforeMinutes: number | null;
  windowAfterMinutes: number | null;
  frequency: string | null;
  reminderIsRequired: boolean | null;
  photoUrl: string | null;
  windowBeforeTime: string;
  windowAfterTime: string;
}

const ReviewTaskEvent: React.FC<ReviewTaskEventProps> = ({
  checkInStatus,
  checkInLocalTime,
  checkInTimezone,
  windowBeforeMinutes,
  windowAfterMinutes,
  frequency,
  reminderIsRequired,
  photoUrl,
  windowAfterTime,
  windowBeforeTime,
}: ReviewTaskEventProps) => (
  <div>
    <div style={CONTAINER_STYLES}>
      <div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Status:</p>
          <p style={CONTENT_STYLES}>
            {checkInStatus}
            {checkInLocalTime
              ? ` at ${moment(checkInLocalTime, TIME_FORMAT).format(
                  TIME_FORMAT,
                )} ${moment.tz(checkInTimezone).format("z")}`
              : null}
          </p>
        </div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Window Before (min):</p>
          <p style={CONTENT_STYLES}>{windowBeforeMinutes}</p>
        </div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Window Before (time):</p>
          <p style={CONTENT_STYLES}>{windowBeforeTime}</p>
        </div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Window After (min):</p>
          <p style={CONTENT_STYLES}>{windowAfterMinutes}</p>
        </div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Window After (time):</p>
          <p style={CONTENT_STYLES}>{windowAfterTime}</p>
        </div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Frequency</p>
          <p style={CONTENT_STYLES}>{frequency}</p>
        </div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Required / Incentivized</p>
          <p style={CONTENT_STYLES}>{reminderIsRequired ? "True" : "False"}</p>
        </div>
      </div>
      <div>
        {photoUrl ? (
          <img style={IMAGE_STYLES} src={photoUrl} alt="check-in" />
        ) : null}
      </div>
    </div>
  </div>
);

export default ReviewTaskEvent;
