"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.TextButton = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/TextButton/index.tsx";
var TextButton = function TextButton(_ref) {
  var style = _ref.style,
    title = _ref.title,
    labelStyle = _ref.labelStyle,
    onPress = _ref.onPress;
  return _react["default"].createElement(_reactNative.TouchableOpacity, {
    onPress: onPress,
    activeOpacity: 0.5,
    style: style,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 27,
      columnNumber: 3
    }
  }, _react["default"].createElement(_reactNative.Text, {
    style: [_styles["default"].label, labelStyle],
    numberOfLines: 1,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 28,
      columnNumber: 5
    }
  }, title));
};
exports.TextButton = TextButton;
var _default = TextButton;
exports["default"] = _default;