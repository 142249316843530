"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CircularView = exports.circularStyles = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/CircularView/index.tsx";
var half = function half(value) {
  return value / 2;
};
var circularStyles = function circularStyles(size) {
  return {
    borderRadius: half(size),
    width: size,
    height: size,
    overflow: "hidden"
  };
};
exports.circularStyles = circularStyles;
var CircularView = function CircularView(_ref) {
  var _ref$children = _ref.children,
    children = _ref$children === void 0 ? null : _ref$children,
    _ref$size = _ref.size,
    size = _ref$size === void 0 ? 60 : _ref$size,
    style = _ref.style,
    props = (0, _objectWithoutProperties2["default"])(_ref, ["children", "size", "style"]);
  return _react["default"].createElement(_reactNative.View, (0, _extends2["default"])({}, props, {
    style: [style, circularStyles(size)],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 23,
      columnNumber: 3
    }
  }), children);
};
exports.CircularView = CircularView;
var _default = CircularView;
exports["default"] = _default;