import React from "react";
import { message as alert } from "@wellth/web-ui";
import { Header, HeaderProps } from "components/Header";
import { useReindexAllPersonsMutation } from "hooks/graphql";

type HeaderContainerProps = Omit<HeaderProps, "reindexPersons" | "email">;

export const HeaderContainer = (props: HeaderContainerProps) => {
  const [reindexPersons] = useReindexAllPersonsMutation({
    onError: ({ graphQLErrors }) =>
      graphQLErrors.forEach(({ message }) => {
        void alert.error(message);
      }),
  });

  return (
    <Header
      {...props}
      reindexPersons={async () => {
        await reindexPersons();
        void alert.info("Reindexing all persons was initialized.");
      }}
    />
  );
};

export default HeaderContainer;
