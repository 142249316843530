import React from "react";

const styles: { [key: string]: React.CSSProperties } = {
  select: {
    width: "100%",
    marginTop: "0.5rem",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "1.5rem",
  },
};

export default styles;
