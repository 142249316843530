import { Gender, IsoLanguage } from "types/globalTypes";
import timezoneOptions from "../usTimezoneArray";

export const titleForGender = (gender: Gender): string => {
  switch (gender) {
    case Gender.Male:
      return "Male";
    case Gender.Female:
      return "Female";
    case Gender.Other:
      return "Other / Declined to specify";
    case Gender.Unknown:
      return "Unknown";
    default:
      return "";
  }
};

export const titleForLanguageCode = (languageCode: IsoLanguage): string => {
  switch (languageCode) {
    case IsoLanguage.En:
      return "English";
    case IsoLanguage.Es:
      return "Spanish";
    case IsoLanguage.Ru:
      return "Russian";
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Invalid IsoLanguage provided: ${languageCode}`);
  }
};

export const titleForTimezone = (timezone: string): string => {
  const foundTimezone = timezoneOptions.find(
    (option) => option.value === timezone,
  );
  if (!foundTimezone) {
    return timezone;
  }
  return foundTimezone.title;
};
