import { formatPhoneNumber } from "@wellth/utils";
import moment from "moment-timezone";
import { normalizeAddress } from "utils/form";
import { FormValues } from "components/CreateProspectForm";
import { CreateProspectMutationVariables as CreateProspectVariables } from "types/globalTypes";
import { DATE_FORMAT } from "utils/date";

export const normalizeFormValues = ({
  programCode,
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
  birthDate,
  street,
  street2,
  city,
  state,
  country,
  zip,
  isTestUser,
}: FormValues & { country: string }): CreateProspectVariables => ({
  input: {
    prospectInput: {
      programCode,
      isTestProspect: isTestUser,
      person: {
        birthDate: moment(new Date(birthDate)).format(DATE_FORMAT),
        firstName,
        lastName,
        emailAddress,
        phoneNumber: formatPhoneNumber(phoneNumber),
        address: normalizeAddress({
          street,
          street2,
          city,
          state,
          country,
          zip,
        }),
      },
    },
  },
});

export default normalizeFormValues;
