import React from "react";
import { ManualCreditModal as ManualCreditModalUI } from "components/ManualCreditModal";
import { useCreateAccountTransactionCreditMutation } from "hooks/graphql";

export interface ManualCreditModalProps {
  goBack: () => void;
  memberId: string;
}

export const ManualCreditModal: React.FC<ManualCreditModalProps> = ({
  goBack,
  memberId,
}) => {
  const [
    createAccountTransactionCredit,
    { loading },
  ] = useCreateAccountTransactionCreditMutation({
    refetchQueries: ["MemberNotes"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      goBack();
    },
  });

  return (
    <ManualCreditModalUI
      submissionLoading={loading}
      goBack={goBack}
      createManualCredit={(creditAmount, currencySymbol, note): void => {
        void createAccountTransactionCredit({
          variables: {
            input: {
              memberId,
              note,
              currencySymbol,
              creditAmount: creditAmount.toString(),
            },
          },
        });
      }}
    />
  );
};

export default ManualCreditModal;
