import { Gender, IsoLanguage } from "types/globalTypes";
import { titleForGender, titleForLanguageCode } from "constants/strings";

export { default as timezoneDropdownOptions } from "constants/usTimezoneArray";

export const genderDropdownOptions = [
  Gender.Unknown,
  Gender.Male,
  Gender.Female,
  Gender.Other,
].map((value) => ({
  title: titleForGender(value),
  value,
}));

export const languageDropdownOptions = [
  IsoLanguage.En,
  IsoLanguage.Es,
  IsoLanguage.Ru,
].map((value) => ({ title: titleForLanguageCode(value), value }));
