import React, { useImperativeHandle } from "react";
import { Form, FormInputType, FormInputProps } from "@wellth/web-ui";

export interface EmailAddressFormProps {
  emailAddress: string;
  isPrimary: boolean;
}

export interface EditEmailAddressFormProps
  extends Partial<EmailAddressFormProps> {
  type: "member" | "prospect";
  disablePrimary?: boolean;
  inputStyle?: React.CSSProperties;
}

const EditEmailAddressForm: React.RefForwardingComponent<
  any,
  EditEmailAddressFormProps
> = (
  {
    type,
    inputStyle = { height: "2.1875rem", width: "18.75rem" },
    disablePrimary = false,
    ...formProps
  }: EditEmailAddressFormProps,
  ref,
) => {
  const formRef = React.useRef(null);
  const isMember = type === "member";
  useImperativeHandle(ref, () => ({
    getEmailAddress: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      {...formProps}
      wrappedComponentRef={formRef}
      mapPropsToFields={(
        { emailAddress, isPrimary = false },
        createFormField,
      ) => ({
        emailAddress: createFormField({ value: emailAddress }),
        isPrimary: createFormField({ value: isPrimary }),
      })}
      inputs={[
        {
          type: FormInputType.Email,
          title: "Email:",
          key: "emailAddress",
          style: inputStyle,
          decoratorOptions: {
            rules: [
              {
                required: true,
                message: "Please provide a valid email address.",
                pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              },
            ],
          },
        },
        ...(isMember
          ? [
              {
                type: FormInputType.Switch,
                title: "Set as Verified?",
                key: "isPrimary",
                disabled: disablePrimary,
                decoratorOptions: { valuePropName: "checked" },
              } as FormInputProps,
            ]
          : []),
      ]}
      labelCol={{
        span: 7,
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 12 },
      }}
      colValue={12}
    />
  );
};

export default React.forwardRef(EditEmailAddressForm);
