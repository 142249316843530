const styles: { [key: string]: React.CSSProperties } = {
  trailerContainer: {
    lineHeight: 0,
  },
  trailer: {
    margin: "0.3125rem",
  },
  verifiedIcon: {
    color: "#199473",
    marginLeft: "0.625rem",
  },
  verifiedInput: {
    margin: "5x",
    color: "#199473",
  },
  header: {
    marginBottom: "1.25rem",
  },
};

export default styles;
