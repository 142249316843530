"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.KeyboardObserver = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _KeyboardAvoidingView = require("../KeyboardAvoidingView");
var _hooks = require("../../hooks");
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/KeyboardObserver/index.tsx";
var KeyboardObserver = function KeyboardObserver(_ref) {
  var style = _ref.style,
    offset = _ref.offset,
    backgroundColor = _ref.backgroundColor,
    children = _ref.children;
  var _useKeyboard = (0, _hooks.useKeyboard)(),
    isKeyboardShown = _useKeyboard.shown;
  return _react["default"].createElement(_KeyboardAvoidingView.KeyboardAvoidingView, {
    offset: offset,
    style: {
      backgroundColor: backgroundColor
    },
    __source: {
      fileName: _jsxFileName,
      lineNumber: 27,
      columnNumber: 5
    }
  }, _react["default"].createElement(_reactNative.TouchableWithoutFeedback, {
    onPress: _reactNative.Keyboard.dismiss,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 33,
      columnNumber: 7
    }
  }, _react["default"].createElement(_reactNative.View, {
    style: style,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 34,
      columnNumber: 9
    }
  }, children(isKeyboardShown))));
};
exports.KeyboardObserver = KeyboardObserver;
var _default = KeyboardObserver;
exports["default"] = _default;