import React, { useState } from "react";
import { ReviewBonusSubmissionInputValues } from "components/ReviewBonusSubmissionInput";
import { Button } from "@wellth/web-ui";
import { Input, Switch } from "antd";
import ReviewSubmissionCard, {
  ReviewCardProps,
  BonusBehaviorSubmissionRecord,
} from "./components/ReviewCard";

const HeaderStyle: React.CSSProperties = {
  marginLeft: "1.25rem",
  fontWeight: "bold",
  fontSize: "2.125rem",
};

const CenterFeedStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const ControlsStyle: React.CSSProperties = {
  alignSelf: "center",
  margin: "1.25rem",
  display: "flex",
  flexDirection: "row",
  width: "50rem",
  justifyContent: "space-between",
};

const LoadMoreStyle: React.CSSProperties = {
  width: "6.25rem",
  alignSelf: "center",
  margin: "1.25rem",
};

type BonsuBehaviorSubmissionNode = BonusBehaviorSubmissionRecord & {
  memberId: string;
};

interface BonusBehaviorSubmissionEdge {
  node: BonsuBehaviorSubmissionNode;
  cursor: string;
}

export interface ManageBonusBehaviorSubmissionsProps
  extends Pick<ReviewCardProps, "onError"> {
  submissions: BonusBehaviorSubmissionEdge[];
  disableLoadMore: boolean;
  loadMore: () => void;
  submitSubmissionReview: (
    review: ReviewBonusSubmissionInputValues & { id: string },
  ) => Promise<void>;
  routeToMember: (id: string) => void;
}

const asc = (a: BonusBehaviorSubmissionEdge, b: BonusBehaviorSubmissionEdge) =>
  Date.parse(b.node.timestamp) - Date.parse(a.node.timestamp);
const desc = (a: BonusBehaviorSubmissionEdge, b: BonusBehaviorSubmissionEdge) =>
  Date.parse(a.node.timestamp) - Date.parse(b.node.timestamp);

export const ManageBonusBehaviorSubmissions: React.FC<
  ManageBonusBehaviorSubmissionsProps
> = ({
  submissions,
  loadMore,
  onError,
  submitSubmissionReview,
  disableLoadMore,
  routeToMember,
}) => {
  const [isSortAsc, setIsSortAsc] = useState(true);
  const [programFilter, setProgramFilter] = useState("");
  const [showCount, setShowCount] = useState(10);
  submissions.sort(isSortAsc ? asc : desc);
  const filteredList = submissions
    .filter((s) =>
      s.node.programCode.toUpperCase().startsWith(programFilter.toUpperCase()),
    )
    .slice(0, showCount);

  function handleLoadMore() {
    if (showCount < submissions.length) {
      setShowCount(showCount + 2);
      return;
    }

    loadMore();
  }

  return (
    <>
      <h1 style={HeaderStyle}>Manage Bonus Submissions</h1>

      <div style={CenterFeedStyle}>
        <div style={ControlsStyle}>
          <Input
            style={{ width: "20rem" }}
            placeholder="Program Code Filter"
            onChange={(e) => setProgramFilter(e.target.value)}
          />
          <span style={{ width: "20rem" }}>
            Order submissions by date{" "}
            <Switch
              defaultChecked
              onChange={setIsSortAsc}
              checkedChildren="DESC"
              unCheckedChildren="ASC"
            />
          </span>
        </div>
        {filteredList.map(
          ({
            node: {
              id,
              name,
              status,
              shortId,
              memberId,
              timestamp,
              firstName,
              lastName,
              programCode,
              rewardAmount,
              rewardCurrency,
              photoUrl,
            },
          }) => (
            <ReviewSubmissionCard
              key={`${id}-card`}
              id={id}
              name={name}
              status={status}
              shortId={shortId}
              timestamp={timestamp}
              firstName={firstName}
              lastName={lastName}
              programCode={programCode}
              rewardAmount={rewardAmount}
              rewardCurrency={rewardCurrency}
              photoUrl={photoUrl}
              routeToMemberPage={() => routeToMember(memberId)}
              onError={({ message }): void =>
                onError(new Error(`${message} for id: ${id}`))
              }
              submit={(review): Promise<void> =>
                submitSubmissionReview({ ...review, id })
              }
            />
          ),
        )}
        <Button
          type="primary"
          style={LoadMoreStyle}
          onClick={handleLoadMore}
          disabled={disableLoadMore && showCount >= submissions.length}
        >
          Load More
        </Button>
      </div>
    </>
  );
};

export default ManageBonusBehaviorSubmissions;
