import React, { useRef } from "react";
import { Modal } from "components/Modal";
import { MODAL_WIDTH } from "utils";
import { Currency } from "types/globalTypes";
import {
  CreateManualCreditForm,
  ManualCreditFormRef,
} from "./components/ManualCreditForm";

export interface ManualCreditModalProps {
  goBack: () => void;
  submissionLoading: boolean;
  createManualCredit: (
    creditAmount: number,
    currencySymbol: Currency,
    note: string,
  ) => void;
}

export const ManualCreditModal: React.FC<ManualCreditModalProps> = ({
  goBack,
  submissionLoading,
  createManualCredit,
}) => {
  const formRef = useRef<ManualCreditFormRef>(null);
  return (
    <Modal
      title="Manual Credit"
      width={MODAL_WIDTH}
      onCancel={goBack}
      submissionLoading={submissionLoading}
      onOk={async () => {
        const {
          creditAmount,
          note,
        } = await formRef.current.getManualCreditInput();
        createManualCredit(creditAmount, Currency.Usd, note);
      }}
    >
      <CreateManualCreditForm ref={formRef} />
    </Modal>
  );
};

export default ManualCreditModal;
