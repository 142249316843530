import * as constants from "containers/MemberProfile/constants";
import { TaskCalendarEvent } from "containers/MemberProfile/components/TaskCalendar";
import { Button } from "@wellth/web-ui";
import React from "react";
import moment, { min, max } from "moment-timezone";
import { getCheckInTimezoneString, TIME_FORMAT } from "utils/date";
import ReviewTaskModalContent from "../ReviewTaskEventModalContent";

const TASK_INFO_CONTAINER_STYLE = { padding: "1.5rem 0 0 0.5rem" };
interface ReviewTaskInfoProps {
  rejectCheckIn: () => Promise<void>;
  loading: boolean;
  calendarData: TaskCalendarEvent;
  timezone: string;
}

export const adjustReminderWindowForWellthDay = (
  reminderDate: string,
  reminderTime: string,
  timezone: string,
  minutesToAdd: number,
) => {
  const windowTime = moment.tz(`${reminderDate} ${reminderTime}`, timezone);
  if (
    windowTime.isBetween(
      moment.tz(`${reminderDate} 0:00`, timezone),
      moment.tz(`${reminderDate} 04:00`, timezone),
    )
  ) {
    windowTime.add(1, "day").add(minutesToAdd, "minutes");
  } else {
    windowTime.add(minutesToAdd, "minutes");
  }
  return windowTime;
};

export const clampWindowAfterTime = (
  fourAm: moment.Moment,
  windowAfterTime: moment.Moment,
) => {
  const windowClamp = fourAm.add(1, "day");
  return min(windowClamp, windowAfterTime).format(TIME_FORMAT);
};

export const clampWindowBeforeTime = (
  fourAm: moment.Moment,
  windowBeforeTime: moment.Moment,
) => max(fourAm, windowBeforeTime).format(TIME_FORMAT);

const ReviewTaskInfo: React.FC<ReviewTaskInfoProps> = ({
  calendarData: {
    checkInStatus,
    checkInLocalTime,
    checkInTimezone,
    windowBeforeMinutes,
    windowAfterMinutes,
    frequency,
    reminderIsRequired,
    photoUrl,
    checkInIsValid,
    reminderTime,
    reminderDate,
  },
  rejectCheckIn,
  loading,
  timezone,
}) => {
  const showRejectButton =
    checkInStatus === constants.SUBMITTED || checkInIsValid;

  const windowAfterTime = adjustReminderWindowForWellthDay(
    reminderDate,
    reminderTime,
    timezone,
    windowAfterMinutes,
  );

  const windowBeforeTime = adjustReminderWindowForWellthDay(
    reminderDate,
    reminderTime,
    timezone,
    windowBeforeMinutes * -1,
  );

  const fourAm = moment.tz(`${reminderDate} 04:00:00`, timezone);

  return (
    <div style={TASK_INFO_CONTAINER_STYLE}>
      <ReviewTaskModalContent
        windowAfterTime={`${clampWindowAfterTime(
          fourAm.clone(),
          windowAfterTime,
        )} ${getCheckInTimezoneString(checkInTimezone, timezone)}`}
        windowBeforeTime={`${clampWindowBeforeTime(
          fourAm.clone(),
          windowBeforeTime,
        )} ${getCheckInTimezoneString(checkInTimezone, timezone)}`}
        checkInStatus={checkInStatus}
        checkInLocalTime={checkInLocalTime}
        checkInTimezone={checkInTimezone}
        windowBeforeMinutes={windowBeforeMinutes}
        windowAfterMinutes={windowAfterMinutes}
        frequency={frequency}
        reminderIsRequired={reminderIsRequired}
        photoUrl={photoUrl}
      />
      {showRejectButton ? (
        <Button loading={loading} type="danger" onClick={rejectCheckIn}>
          Reject check-in
        </Button>
      ) : null}
    </div>
  );
};

export default ReviewTaskInfo;
