import React from "react";
import { AddressSection, Address } from "./components/AddressSection";
import Validity from "./components/Validity";
import styles from "./styles";

export interface AddressSuggestionProps {
  addressToVerify: Address | null;
  suggestionAddress: Address | null;
  isValid: boolean;
  note?: string;
  submitAddress: (address: Address) => void;
}

export const AddressSuggestion: React.FC<AddressSuggestionProps> = ({
  addressToVerify,
  suggestionAddress,
  submitAddress,
  note,
  isValid = false,
}) => (
  <div style={styles.container}>
    <AddressSection
      title="Entered Address:"
      style={{ ...styles.addressSection }}
      buttonStyle={
        !suggestionAddress && isValid
          ? styles.suggestedAddressButton
          : styles.enteredAddressButton
      }
      {...addressToVerify}
      submit={() => {
        submitAddress(addressToVerify);
      }}
    />
    {suggestionAddress ? (
      <AddressSection
        title="Suggested Address:"
        style={{ ...styles.addressSection, ...styles.suggestedAddressSection }}
        buttonStyle={styles.suggestedAddressButton}
        {...suggestionAddress}
        note={note}
        submit={() => {
          submitAddress(suggestionAddress);
        }}
      />
    ) : (
      <Validity
        style={{ ...styles.addressSection, ...styles.suggestedAddressSection }}
        isValid={isValid}
      />
    )}
  </div>
);

export default AddressSuggestion;
