import React, { useRef } from "react";
import { Modal } from "components/Modal";
import EditProgramForm, {
  EditProgramFormProps,
  ProgramFormProps,
} from "components/EditProgramForm";

export interface EditProgramFormModalProps extends EditProgramFormProps {
  title: React.ReactNode;
  loading?: boolean;
  submissionLoading?: boolean;
  submit: (values: ProgramFormProps) => void;
  cancel: () => void;
}

export const EditProgramFormModal: React.FC<EditProgramFormModalProps> = ({
  loading = false,
  submissionLoading = false,
  submit,
  cancel,
  title,
  ...formProps
}: EditProgramFormModalProps) => {
  const formRef = useRef(null);

  return (
    <Modal
      title={title}
      okText="Submit"
      loading={loading}
      submissionLoading={submissionLoading}
      onCancel={cancel}
      onOk={async () => submit(await formRef.current.getProgramValues())}
      width={700}
    >
      <EditProgramForm {...formProps} ref={formRef} />
    </Modal>
  );
};

export default EditProgramFormModal;
