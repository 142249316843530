import * as React from "react";
import COLORS from "utils/colors";

const SHARED_STYLES = {
  fontWeight: 500,
  fontSize: "0.938rem",
  padding: 0,
  margin: 0,
  fontFamily: "InterUI",
};

const CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
};

const TITLE_STYLES: React.CSSProperties = {
  color: COLORS.lightGray,
  ...SHARED_STYLES,
};

const CONTENT_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  width: "21.875rem",
  marginBottom: "1.5rem",
};

const CONTENT_STYLES: React.CSSProperties = {
  ...SHARED_STYLES,
  color: COLORS.black,
};

interface ReviewTaskEventProps {
  status: string | null;
  type: string | null;
  locationName: string | null;
}

const ReviewTaskEvent: React.FC<ReviewTaskEventProps> = ({
  status,
  type,
  locationName,
}: ReviewTaskEventProps) => (
  <div>
    <div style={CONTAINER_STYLES}>
      <div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Status:</p>
          <p style={CONTENT_STYLES}>{status}</p>
        </div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Type:</p>
          <p style={CONTENT_STYLES}>{type}</p>
        </div>
        <div style={CONTENT_CONTAINER_STYLES}>
          <p style={TITLE_STYLES}>Location Name:</p>
          <p style={CONTENT_STYLES}>{locationName}</p>
        </div>
      </div>
    </div>
  </div>
);

export default ReviewTaskEvent;
