import React from "react";
import { Form, FormInputType, Table, Icon } from "@wellth/web-ui";
import moment from "moment-timezone";
import { DATE_FORMAT } from "utils/date";

const DEFAULT_STYLE: React.CSSProperties = {
  color: "#199473",
};

export type Program = {
  id: string;
  programCode: string;
  name: string;
  customerId: string;
  customerName: string;
  programTemplate: string;
  sector: string;
  inactivateAfterPausedDays: number;
  pauseAfterNoActivityDays: number;
  expiry: string;
  rewardCardInfoUrl: string;
};

type PaymentOption = {
  id: string;
  name: string;
  processor: string;
  packId: string;
  default: boolean;
};

interface PaymentOptionTableProps {
  paymentOptionsLoading: boolean;
  paymentOptions: PaymentOption[];
}

const PaymentOptionTable: React.FC<PaymentOptionTableProps> = ({
  paymentOptions,
  paymentOptionsLoading,
}) => (
  <Table
    style={{ padding: "1rem" }}
    title={() => "Payment Options"}
    size="small"
    loading={paymentOptionsLoading}
    pagination={false}
    dataSource={paymentOptions}
    rowKey={({ id: rowId }) => rowId}
    columns={[
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: "20rem",
        render: (_, { name, default: isDefault }) =>
          isDefault ? <div style={DEFAULT_STYLE}>{name}</div> : name,
      },
      {
        title: "Processor",
        key: "processor",
        dataIndex: "processor",
        align: "center",
        render: (_, { processor, default: isDefault }) =>
          isDefault ? <div style={DEFAULT_STYLE}>{processor}</div> : processor,
      },
      {
        title: "Pack Id",
        key: "packId",
        dataIndex: "packId",
        align: "center",
        render: (_, { packId, default: isDefault }) =>
          isDefault ? <div style={DEFAULT_STYLE}>{packId}</div> : packId,
      },
      {
        title: "Is Default",
        key: "default",
        dataIndex: "default",
        align: "center",
        render: (_, { default: isDefault }) =>
          isDefault ? <div style={DEFAULT_STYLE}>TRUE</div> : "FALSE",
      },
    ]}
  />
);

export interface ProgramInfoFormProps extends Program, PaymentOptionTableProps {
  inputStyle?: React.CSSProperties;
  routeEditProgram: () => void;
}

export const ProgramInfoForm: React.FC<ProgramInfoFormProps> = ({
  inputStyle = {
    height: "2.1875rem",
    width: "18.75rem",
  },
  programCode,
  paymentOptionsLoading,
  paymentOptions,
  routeEditProgram,
  ...formProps
}) => (
  <div>
    <Icon
      type="edit"
      style={{ display: "flex", justifyContent: "flex-end" }}
      onClick={() => routeEditProgram()}
    />
    <Form
      {...formProps}
      mapPropsToFields={(
        {
          name: programName,
          customerName,
          sector,
          inactivateAfterPausedDays,
          pauseAfterNoActivityDays,
          expiry,
          rewardCardInfoUrl,
          programTemplate,
        },
        createFormField,
      ) => ({
        programName: createFormField({ value: programName }),
        customerName: createFormField({
          value: customerName,
        }),
        programTemplate: createFormField({
          value: programTemplate,
        }),
        sector: createFormField({
          value: sector,
        }),

        inactivateAfterPausedDays: createFormField({
          value: inactivateAfterPausedDays,
        }),
        pauseAfterNoActivityDays: createFormField({
          value: pauseAfterNoActivityDays,
        }),
        expiry: createFormField({
          value: moment(expiry).format(DATE_FORMAT),
        }),
        rewardCardInfoUrl: createFormField({ value: rewardCardInfoUrl }),
      })}
      inputs={[
        {
          type: FormInputType.Info,
          title: "Program Name:",
          key: "programName",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Customer Name",
          key: "customerName",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Program Template",
          key: "programTemplate",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Sector:",
          key: "sector",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Paused Days Until Inactivation:",
          key: "inactivateAfterPausedDays",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Consecutive Missed Days Until Pause:",
          key: "pauseAfterNoActivityDays",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Expiry Date",
          key: "expiry",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Reward Card Info URL",
          key: "rewardCardInfoUrl",
          style: inputStyle,
        },
      ]}
      labelCol={{ sm: { span: 10 }, xs: { span: 8 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
      colValue={12}
    />
    <PaymentOptionTable
      paymentOptionsLoading={paymentOptionsLoading}
      paymentOptions={paymentOptions}
    />
  </div>
);

export default ProgramInfoForm;
