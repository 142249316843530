import React from "react";
import { useAllProgramsQuery } from "hooks/graphql";
import { Program, Programs } from "components/ManagePrograms";

export const ManagePrograms: React.FC<{
  routeEditProgram: (program: Program) => void;
  routeProgramModal: (program: Program) => void;
}> = ({ routeEditProgram, routeProgramModal }) => {
  const {
    data: { allPrograms: { nodes: programs = [] } = {} } = {},
    loading,
  } = useAllProgramsQuery();

  return (
    <Programs
      programs={programs.map(({ customerByCustomerId: { name }, ...data }) => ({
        ...data,
        customerName: name,
      }))}
      loading={loading}
      routeEditProgram={routeEditProgram}
      routeProgramModal={routeProgramModal}
    />
  );
};

export default ManagePrograms;
