"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _default = _reactNative.StyleSheet.create({
  separator: {
    height: 10
  }
});
exports["default"] = _default;