import React from "react";
import EditNameModal from "components/EditNameFormModal";
import { ContactType } from "types/globalTypes";
import {
  useEditNameQuery,
  useUpdateNameMutation,
  MemberNamesDocument as memberNamesQuery,
  ProspectNamesDocument as prospectNamesQuery,
} from "hooks/graphql";

export interface EditNameFormModalProps {
  id: string;
  contactId: string;
  contactType: ContactType;
  goBack: () => void;
}

export const EditNameFormModal: React.FC<EditNameFormModalProps> = ({
  id,
  contactId,
  contactType,
  goBack,
}: EditNameFormModalProps) => {
  const {
    loading,
    data: {
      name: {
        isPrimary = false,
        firstName = "",
        middleName = "",
        lastName = "",
      } = {},
    } = {},
  } = useEditNameQuery({
    variables: {
      nameId: id,
    },
  });

  const [updateName, { loading: submissionLoading }] = useUpdateNameMutation({
    ignoreResults: true,
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [
      contactType === ContactType.Member
        ? { query: memberNamesQuery, variables: { id: contactId } }
        : { query: prospectNamesQuery, variables: { id: contactId } },
    ],
  });

  return (
    <EditNameModal
      type={contactType}
      title="Update Name"
      isPrimary={isPrimary}
      firstName={firstName}
      middleName={middleName}
      lastName={lastName}
      loading={loading}
      submissionLoading={submissionLoading}
      cancel={goBack}
      disablePrimary={contactType === ContactType.Prospect}
      submit={async (name) => {
        await updateName({
          variables: {
            input: {
              namePatch: {
                id,
                ...name,
              },
            },
          },
        });
      }}
    />
  );
};

export default EditNameFormModal;
