"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  arrowBackShaded: require("./arrowBackShaded.png"),
  arrowForwardBlack: require("./arrowForwardBlack.png"),
  arrowForwardWhite: require("./arrowForwardWhite.png"),
  arrowForwardWhite50: require("./arrowForwardWhite50.png"),
  arrowNextShaded: require("./arrowNextShaded.png"),
  call: require("./call.png"),
  camera: require("./camera.png"),
  disclosureIndicator: require("./disclosureIndicator.png"),
  failure: require("./failure.png"),
  faq: require("./faq.png"),
  flagUsa: require("./flagUSA.png"),
  flashOff: require("./flashOff.png"),
  flashOn: require("./flashOn.png"),
  help: require("./help.png"),
  helpIconQuestionMark: require("./helpIconQuestionMark.png"),
  howItWorks: require("./howItWorks.png"),
  iconAccepted: require("./iconAccepted.png"),
  iconBloodPressureCuff: require("./iconBloodPressureCuff.png"),
  iconCamera: require("./iconCamera.png"),
  iconCheckInNow: require("./iconCheckInNow.png"),
  iconDenied: require("./iconDenied.png"),
  iconEmptyRingWhite: require("./iconEmptyRingWhite.png"),
  iconGlucometer: require("./iconGlucometer.png"),
  iconHealth: require("./iconHealth.png"),
  iconHelp: require("./iconHelp.png"),
  iconHelp2: require("./iconHelp2.png"),
  iconHome: require("./iconHome.png"),
  iconInstructions: require("./iconInstructions.png"),
  iconMaybe: require("./iconMaybe.png"),
  iconMeal: require("./iconMeal.png"),
  iconMessages: require("./iconMessages.png"),
  iconMissed: require("./iconMissed.png"),
  iconMoney: require("./iconMoney.png"),
  iconPushNotification: require("./iconPushNotification.png"),
  iconReminderClock: require("./iconReminderClock.png"),
  iconReminders: require("./iconReminders.png"),
  iconRewards: require("./iconRewards.png"),
  iconScale: require("./iconScale.png"),
  iconSettings: require("./iconSettings.png"),
  iconSuccess: require("./iconSuccess.png"),
  iconTablet: require("./iconTablet.png"),
  iconWarning: require("./iconWarning.png"),
  notebook: require("./notebook.png"),
  plus: require("./plus.png"),
  postCheckinSuccessIcon: require("./postCheckinSuccessIcon.png"),
  programInformation: require("./programInformation.png"),
  retakeX: require("./retakeX.png"),
  rewardsInformation: require("./rewardsInformation.png"),
  slideArrowIcon: require("./slideArrowIcon.png"),
  submit: require("./submit.png"),
  success: require("./success.png"),
  text: require("./text.png"),
  verifyCheck: require("./verifyCheck.png"),
  wellthLogo: require("./wellthLogo.png"),
  wellthLogoLight: require("./wellthLogoLight.png"),
  wellthLogoNew: require("./wellthLogoNew.png")
};
exports["default"] = _default;