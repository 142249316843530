import { FormInputType, FormInputProps } from "@wellth/web-ui";
import { COLUMN_FULL_WIDTH } from "constants/grid";

export const formInputs: FormInputProps[] = [
  {
    type: FormInputType.Text,
    title: "Title",
    key: "title",
    placeholder: "Enter a title...",
    colValue: COLUMN_FULL_WIDTH,
  },
  {
    type: FormInputType.TextArea,
    title: "Message",
    key: "message",
    placeholder: "Enter a message...",
    autosize: { minRows: 4 },
    colValue: COLUMN_FULL_WIDTH,
  },
  {
    type: FormInputType.Info,
    key: "mergeTags",
    title: "Merge Tags",
    colValue: COLUMN_FULL_WIDTH,
  },
];

export default formInputs;
