import React, { useState, useRef } from "react";
import { message as alert } from "@wellth/web-ui";
import { EnrollmentPage, EnrollmentPageProps } from "components/EnrollmentPage";
import {
  useAvailableProgramsQuery,
  useGetConfigsForProgramCodeLazyQuery,
  useEnrollMemberMutation,
  useEnrollPaperMutationMutation,
} from "hooks/graphql";
import { ProgramConfig } from "components/EnrollmentForm";
import {
  AddressSuggestionModal,
  AddressSuggestionModalProps,
} from "containers/AddressSuggestionModal";
import { IdentityTag } from "types/globalTypes";
import { AdminPrivilege } from "constants/adminPrivilege";
import { isAdminOrSuperAdmin } from "utils/roles";
import {
  normalizeEnrollMemberFormValues,
  normalizeEnrollPaperMemberFormValues,
  normalizeProgramCodeResponse,
} from "./utils";

type BaseProps = Pick<EnrollmentPageProps, "memberProfile" | "siteInformation">;
type ProgramConfigOptions = Omit<
  ProgramConfig,
  "programCodeOptions" | "submit"
>;

export interface EnrollmentProps extends BaseProps {
  title?: string;
  adminRole?: AdminPrivilege;
  prospectId?: string;
  programConfig?: ProgramConfigOptions;
  mailingAddress?: AddressSuggestionModalProps["addressToVerify"];
  activeIdentityTags?: Partial<IdentityTag>[];
  routeMemberProfile: (memberId: string) => void;
  prospectPhones?: string[];
}

export const Enrollment: React.FC<EnrollmentProps> = ({
  title = "Enroll",
  adminRole,
  routeMemberProfile,
  memberProfile,
  programConfig,
  siteInformation,
  mailingAddress,
  prospectId,
  activeIdentityTags,
  prospectPhones = [],
}) => {
  const mailingAddressRef = useRef(mailingAddress);
  const [patientTimezone, setPatientTimezone] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [addressValidated, setAddressValidated] = useState(false);
  const [programTemplate, setProgramTemplate] = useState("");
  const [isAltProgram, setIsAltProgram] = useState(false);

  const checkIfAltProgram = (programCode: string) =>
    programCode.includes("ALT");

  const {
    loading,
    data: { availablePrograms = [] } = {},
  } = useAvailableProgramsQuery();

  const [
    submitProgramCode,
    {
      data: {
        getConfigsForProgram: {
          rewardPeriodConfigs = [],
          reminderConfigs = [],
          paymentOptions = [],
        } = {},
      } = {},
    } = {},
  ] = useGetConfigsForProgramCodeLazyQuery();

  const [
    enrollMember,
    { loading: submissionLoading },
  ] = useEnrollMemberMutation({
    onError: ({ message }) => alert.error(message),
    onCompleted: ({ enrollMember: { memberId } }) =>
      routeMemberProfile(memberId),
  });

  const [
    enrollPaperMember,
    { loading: enrollPaperMemberSubmissionLoading },
  ] = useEnrollPaperMutationMutation({
    onError: ({ message }) => alert.error(message),
    onCompleted: ({ enrollPaperMember: { memberId } }) =>
      routeMemberProfile(memberId),
  });

  return (
    <>
      <EnrollmentPage
        title={title}
        loading={loading}
        submissionLoading={
          isAltProgram ? enrollPaperMemberSubmissionLoading : submissionLoading
        }
        memberProfile={memberProfile}
        siteInformation={siteInformation}
        mailingAddress={mailingAddressRef.current}
        programTemplate={programTemplate}
        programConfig={{
          ...programConfig,
          programCodeOptions: availablePrograms
            .map(({ programCode }) => programCode)
            .filter(
              (value) =>
                !value.endsWith("-ALT") || isAdminOrSuperAdmin(adminRole),
            )
            .filter(
              (value) =>
                !programConfig ||
                !programConfig.programCode ||
                value.includes(programConfig.programCode),
            ),
          submit: (programCode: string, timezone: string) => {
            const isAlt = checkIfAltProgram(programCode);
            setIsAltProgram(isAlt);
            setPatientTimezone(timezone);
            setProgramTemplate(
              availablePrograms.filter((i) => i.programCode === programCode)[0]
                .programTemplate,
            );
            submitProgramCode({
              variables: { programCode },
            });
          },
        }}
        otherInformation={{ paymentOptions }}
        {...normalizeProgramCodeResponse({
          reminderConfigs,
          rewardPeriodConfigs,
          timezone: patientTimezone,
        })}
        validateMailingAddress={(address) => {
          // Track the selected address in the ref so it makes
          // it's way to the modal
          mailingAddressRef.current = address;
          setShowModal(true);
        }}
        submit={async (values) => {
          if (isAltProgram) {
            await enrollPaperMember({
              variables: normalizeEnrollPaperMemberFormValues(
                values,
                prospectId,
              ),
            });
          } else {
            await enrollMember({
              variables: normalizeEnrollMemberFormValues(
                values,
                prospectId,
                addressValidated,
              ),
            });
          }
          setAddressValidated(false);
        }}
        activeIdentityTags={activeIdentityTags}
        prospectPhones={prospectPhones}
      />
      <AddressSuggestionModal
        visible={showModal}
        goBack={() => setShowModal(false)}
        addressToVerify={mailingAddressRef.current}
        submitAddress={(address) => {
          // Track the selected address in the ref so it makes
          // it's way to the form.
          setAddressValidated(true);
          mailingAddressRef.current = address;
          setShowModal(false);
        }}
      />
    </>
  );
};

export default Enrollment;
