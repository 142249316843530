import React from "react";
import { isNull } from "lodash";
import moment from "moment-timezone";
import { Admin } from "constants/admin";
import InputCard, { InputCardProps } from "../InputCard";
import MessageList, { Recipient } from "./components/Message";
import styles from "./styles";

const WELLTH = "Wellth";
const PATIENT = "PATIENT";

const isAutomatedMessage = (recipient, admin) =>
  recipient === PATIENT && (isNull(admin) || admin.firstName === WELLTH);

interface Messages {
  createdAt: Date;
  image?: any;
  message: string;
  id: string;
  admin?: Admin;
  recipient: string;
}

export interface PatientChatProps extends Pick<InputCardProps, "sendMessage"> {
  messages?: Messages[];
  loadMore?: () => void;
}

export default class PatientChat extends React.Component<PatientChatProps> {
  private patientChatEnd: any;

  componentDidMount() {
    this.patientChatEnd.scrollIntoView(false);
  }

  componentDidUpdate() {
    this.patientChatEnd.scrollIntoView(false);
  }

  render() {
    const { sendMessage, messages, loadMore } = this.props;
    return (
      <InputCard
        title="Chat"
        popconfirmTitle="Sure to send?"
        sendMessage={sendMessage}
        buttonTitle="Send"
        popconfirm
        bodyStyle={styles.bodyStyle}
        innerBodyStyle={styles.innerBodyStyle}
        placeholder="Enter text..."
      >
        <div style={styles.loadMore} onClick={loadMore}>
          load more
        </div>
        {messages
          .sort((a: Messages, b: Messages) =>
            moment.utc(a.createdAt).diff(moment.utc(b.createdAt)),
          )
          .map((message) => (
            <MessageList
              key={message.id}
              createdAt={message.createdAt}
              messageBody={message.message}
              automated={isAutomatedMessage(message.recipient, message.admin)}
              recipient={message.recipient as Recipient}
            />
          ))}
        <div
          ref={(el) => {
            this.patientChatEnd = el;
          }}
        />
      </InputCard>
    );
  }
}
