import React, { useRef } from "react";
import EditReminderForm, {
  EditReminderFormProps,
} from "components/EditReminderForm";
import { FormProps } from "components/EditReminderForm/inputs";
import { Modal } from "components/Modal";

export interface EditReminderFormModalProps extends EditReminderFormProps {
  loading?: boolean;
  submissionLoading?: boolean;
  title?: React.ReactNode;
  submit: (values: FormProps) => void;
  cancel: () => void;
  programTemplate: string;
}

export const EditReminderFormModal: React.FC<EditReminderFormModalProps> = ({
  submissionLoading = false,
  loading = false,
  cancel,
  submit,
  title,
  ...formProps
}: EditReminderFormModalProps) => {
  const formRef = useRef(null);
  const isReviewing = formProps.method === "info";
  return (
    <Modal
      title={title}
      okText="Submit"
      loading={loading}
      submissionLoading={submissionLoading}
      onCancel={cancel}
      width={700}
      footer={isReviewing ? null : undefined}
      onOk={async () => submit(await formRef.current.getReminder())}
      okButtonProps={{ disabled: formProps.deleteLoading }}
      cancelButtonProps={{
        disabled: submissionLoading || formProps.deleteLoading,
      }}
    >
      <EditReminderForm {...formProps} ref={formRef} />
    </Modal>
  );
};

export default EditReminderFormModal;
