export default [
  { title: "Eastern Time (ET)", value: "America/New_York" },
  { title: "Central Time (CT)", value: "America/Chicago" },
  { title: "Mountain Time (MT) - Phoenix", value: "America/Phoenix" },
  { title: "Mountain Time (MT) - Denver", value: "America/Denver" },
  { title: "Pacific Time (PT)", value: "America/Los_Angeles" },
  { title: "Alaska Time (AKT)", value: "America/Anchorage" },
  { title: "Hawaii Time (HT)", value: "Pacific/Honolulu" },
  { title: "Chamorro Time Zone (ChT)", value: "Pacific/Guam" },
];
