import React from "react";
import {
  useAllBonusBehaviorConfigsQuery,
  useGetBonusBehaviorPagesByPagesetLazyQuery,
} from "hooks/graphql";
import BonusBehaviorsConfigs from "components/ManageBonusBehaviors";
import { BonusBehaviorConfig } from "types/globalTypes";

interface ManageBonusBehaviorsProps {
  routeToBonusBehaviorModal: (
    allBonusBehaviorConfigs: BonusBehaviorConfig[],
  ) => void;
}

export const ManageBonusBehaviors: React.FC<ManageBonusBehaviorsProps> = ({
  routeToBonusBehaviorModal,
}) => {
  const {
    data: { allBonusBehaviorConfigs = [] } = {},
    loading,
  } = useAllBonusBehaviorConfigsQuery();

  const [
    getPages,
    {
      data: { getBonusBehaviorPagesByPageset = [] } = {},
      loading: pageLoading,
    },
  ] = useGetBonusBehaviorPagesByPagesetLazyQuery();

  return (
    <BonusBehaviorsConfigs
      bonusBehaviorConfigs={allBonusBehaviorConfigs}
      loading={loading}
      getPagesByPagesetId={(pagesetId) => {
        getPages({ variables: { id: pagesetId } });
      }}
      pages={getBonusBehaviorPagesByPageset}
      pageLoading={pageLoading}
      routeToBonusBehaviorModal={() =>
        routeToBonusBehaviorModal(allBonusBehaviorConfigs)
      }
    />
  );
};

export default ManageBonusBehaviors;
