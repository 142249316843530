const styles: { [key: string]: React.CSSProperties } = {
  messageBubble: {
    borderRadius: 20,
    borderWidth: 1,
    width: "fit-content",
    maxWidth: "80%",
    fontSize: "0.875rem",
    padding: "0.625rem 0.9375rem 0.625rem 0.9375rem",
    textAlign: "left",
  },
  admin: {
    backgroundColor: "#0a86f9",
    color: "white",
    right: 0,
    marginLeft: "auto",
  },
  auto: {
    fontSize: "0.675rem",
  },
  patient: {
    backgroundColor: "#e5e5ea",
  },
  loadMore: {
    backgroundColor: "#696969",
    color: "white",
    borderRadius: 10,
    margin: 0,
    fontSize: "0.675rem",
  },
  sentDate: {
    fontWeight: "normal",
    fontSize: "0.675rem",
  },
  bubbleContainer: {
    padding: "0.3125rem",
  },
  bodyStyle: {
    padding: 0,
  },
  innerBodyStyle: {
    padding: "0.3125rem",
  },
};

export default styles;
