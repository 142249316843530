import React, { RefAttributes } from "react";
import orderBy from "lodash/orderBy";
import {
  Button,
  FormInputType,
  Popconfirm,
  SectionType,
  SectionProps,
  Icon,
} from "@wellth/web-ui";
import formatName from "utils/formatName";
import { SectionHeader } from "components/SectionHeader";
import { NamesSectionProps as BaseProps, Name } from "../types";
import styles from "./styles";

export interface NamesSectionProps extends BaseProps, RefAttributes<any> {
  onEditName: (name: Name) => void;
  onCreateName: () => void;
  onDeleteName: (name: Name) => void;
  onVerifyName: (name: Name) => void;
}

export const Inputs = ({
  names = [],
  onEditName,
  onCreateName,
  onDeleteName,
  onVerifyName,
  ref,
}: NamesSectionProps): SectionProps<any> => ({
  ref,
  title: (
    <SectionHeader
      title="Name(s)"
      submitButtonType="plus-circle"
      onClick={onCreateName}
    />
  ),
  key: "names",
  type: SectionType.Form,
  data: {
    mapPropsToFields: (props, createFormField) =>
      names.reduce(
        (fields, { id, ...name }) => ({
          ...fields,
          [`name:${id}`]: createFormField({
            value: formatName(name),
          }),
        }),
        {},
      ),
    inputs: orderBy(names, "isPrimary", "desc").map((name) => ({
      type: FormInputType.Info,
      title: "Name",
      key: `name:${name.id}`,
      style: name.isPrimary ? styles.verifiedInput : {},
      inputTrailer: () => (
        <div style={styles.trailerContainer}>
          {name.isPrimary ? (
            <span style={styles.trailer}>
              <Icon
                type="check-circle"
                theme="filled"
                style={styles.verifiedIcon}
              />
            </span>
          ) : null}
          <Button
            disabled={name.isPrimary}
            onClick={() => onEditName(name)}
            style={styles.trailer}
          >
            <Icon type="edit" />
          </Button>
          <Button
            disabled={name.isPrimary}
            onClick={() => onVerifyName(name)}
            style={styles.trailer}
          >
            Set as Verified
          </Button>
          <span style={styles.trailer}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => onDeleteName(name)}
            >
              <Button disabled={name.isPrimary}>
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          </span>
        </div>
      ),
    })),
    labelCol: {
      sm: 4,
    },
  },
});

export default Inputs;
