import React from "react";

const styles: { [key: string]: React.CSSProperties } = {
  inputContainerStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  itemStyle: {
    width: "65%",
  },
};

export default styles;
