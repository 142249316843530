import React from "react";
import { message as alert } from "antd";
import { CreateBonusBehaviorModal as CreateBBModalComponent } from "components/CreateBonusBehaviorModal";
import { BonusBehaviorConfig } from "types/globalTypes";
import {
  AllBonusBehaviorConfigsDocument as allBonusBehaviorConfigQuery,
  useAvailableProgramsQuery,
  useCreateBonusBehaviorConfigMutation,
} from "hooks/graphql";

interface CreateBonusBehaviorModalProps {
  goBack: () => void;
  bonusBehaviorConfigs: BonusBehaviorConfig[];
}

export const CreateBonusBehaviorModal: React.FC<
  CreateBonusBehaviorModalProps
> = ({ goBack, bonusBehaviorConfigs }) => {
  const {
    data: { availablePrograms = [] } = {},
    loading,
  } = useAvailableProgramsQuery();

  const [
    createBonusBehaviorConfig,
    { loading: createLoading },
  ] = useCreateBonusBehaviorConfigMutation({
    onCompleted: goBack,
    onError: ({ message }) => alert.error(message),
    awaitRefetchQueries: true,
    refetchQueries: [{ query: allBonusBehaviorConfigQuery }],
  });
  return (
    <CreateBBModalComponent
      title="Create Bonus Behavior"
      loading={createLoading}
      programLoading={loading}
      cancel={goBack}
      submissionLoading={createLoading}
      submit={async (props) => {
        const {
          pagesetProgramCombo,
          program,
          endDate,
          startDate,
          rewardAmount,
        } = props;
        const pagesetId = pagesetProgramCombo.split(",")[0];
        const bonusConfig = bonusBehaviorConfigs.find(
          ({ bonusBehaviorPagesetId }) => bonusBehaviorPagesetId === pagesetId,
        );
        await createBonusBehaviorConfig({
          variables: {
            input: {
              name: bonusConfig.name,
              pagesetId,
              programId: program,
              endDate: endDate.startOf("day").toString(),
              startDate: startDate.startOf("day").toString(),
              rewardAmount,
              rewardCurrency: bonusConfig.rewardCurrency,
              icon: bonusConfig.icon,
            },
          },
        });
      }}
      bonusBehaviorConfigs={bonusBehaviorConfigs}
      availablePrograms={availablePrograms}
    />
  );
};

export default CreateBonusBehaviorModal;
