import React, { useRef } from "react";
import { Modal } from "components/Modal";
import EditNameForm, {
  EditNameFormProps,
  NameFormProps,
} from "components/EditNameForm";

export interface EditNameFormModalProps extends EditNameFormProps {
  title: React.ReactNode;
  loading?: boolean;
  submissionLoading?: boolean;
  submit: (values: NameFormProps) => void;
  cancel: () => void;
}

export const EditNameFormModal: React.FC<EditNameFormModalProps> = ({
  loading = false,
  submissionLoading = false,
  submit,
  cancel,
  title,
  ...formProps
}: EditNameFormModalProps) => {
  const formRef = useRef(null);

  return (
    <Modal
      title={title}
      okText="Submit"
      loading={loading}
      submissionLoading={submissionLoading}
      onCancel={cancel}
      onOk={async () => submit(await formRef.current.getName())}
      width={700}
    >
      <EditNameForm {...formProps} ref={formRef} />
    </Modal>
  );
};

export default EditNameFormModal;
