import React, { useImperativeHandle, useRef } from "react";
import moment from "moment-timezone";
import { Form, FormInputType, AntForm } from "@wellth/web-ui";
import { Gender, LanguageCode, IsoLanguage } from "types/globalTypes";
import {
  timezoneDropdownOptions,
  genderDropdownOptions,
  languageDropdownOptions,
} from "./constants";

const eligibilityEndDateInput = (style) => ({
  title: "Eligibility End Date",
  key: "eligibilityEndDate",
  style,
});

export interface ProfileProps {
  birthdate: moment.Moment;
  language: IsoLanguage;
  gender: Gender;
  timezone: string;
  externalId: string;
  siteName: string;
  localTime: string;
  eligibilityEndDate?: moment.Moment;
  race: string;
  ethnicity: string;
  medicaidId: string;
  medicareId: string;
  programTemplate: string;
  coverageType: string;
  planName: string;
  pcpName: string;
  pcpNumber: string;
}

export interface EditProfileFormRef {
  getProfile: () => Promise<ProfileProps>;
}

export interface EditProfileFormProps extends Partial<ProfileProps> {
  inputStyle?: React.CSSProperties;
  contactType: "prospect" | "member";
}

const EditProfileForm: React.RefForwardingComponent<
  EditProfileFormRef,
  EditProfileFormProps
> = (
  {
    contactType,
    birthdate,
    language,
    gender,
    timezone,
    externalId,
    siteName,
    race,
    ethnicity,
    medicaidId,
    medicareId,
    programTemplate,
    coverageType,
    planName,
    pcpName,
    pcpNumber,
    eligibilityEndDate,
    inputStyle = {
      height: "2.1875rem",
      width: "18.75rem",
    },
  },
  ref,
) => {
  const formRef = useRef<AntForm>(null);
  const isMember = contactType === "member";

  useImperativeHandle(ref, () => ({
    getProfile: (): Promise<ProfileProps> =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields(
          (
            errors,
            {
              birthdate: formBirthdate,
              eligibilityEndDate: formEligibilityEndDate,
              ...values
            },
          ) =>
            // !!!: Since birthdate is required, it will throw if missing
            errors
              ? reject(errors)
              : resolve({
                  birthdate: moment(formBirthdate, "MM-DD-YYYY"),
                  eligibilityEndDate: formEligibilityEndDate
                    ? moment(formEligibilityEndDate)
                    : null,
                  ...values,
                }),
        ),
      ),
  }));

  return (
    <Form
      wrappedComponentRef={formRef}
      mapPropsToFields={(_props, createFormField) => ({
        birthdate: createFormField({
          value: birthdate.format("MM-DD-YYYY"),
        }),
        language: createFormField({
          value: language,
        }),
        gender: createFormField({
          value: gender,
        }),
        timezone: createFormField({
          value: timezone,
        }),
        localTime: createFormField({
          value: timezone ? moment.tz(timezone).format("h:mm A (z)") : "",
        }),
        externalId: createFormField({
          value: externalId,
        }),
        siteName: createFormField({
          value: siteName,
        }),
        eligibilityEndDate: createFormField({
          value: eligibilityEndDate
            ? eligibilityEndDate.format("MM-DD-YYYY")
            : null,
        }),
        race: createFormField({
          value: race,
        }),
        ethnicity: createFormField({
          value: ethnicity,
        }),
        medicaidId: createFormField({
          value: medicaidId,
        }),
        medicareId: createFormField({
          value: medicareId,
        }),
        programTemplate: createFormField({
          value: programTemplate,
        }),
        coverageType: createFormField({
          value: coverageType,
        }),
        planName: createFormField({
          value: planName,
        }),
        pcpName: createFormField({
          value: pcpName,
        }),
        pcpNumber: createFormField({
          value: pcpNumber,
        }),
      })}
      inputs={[
        {
          type: FormInputType.Mask,
          title: "Birthday",
          key: "birthdate",
          placeholder: "MM-DD-YYYY",
          mask: "99-99-9999",
          decoratorOptions: {
            rules: [
              {
                pattern: /(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-([12]\d{3})/,
                message: "A valid birthdate is required",
              },
            ],
          },
          style: inputStyle,
        },
        {
          type: FormInputType.Dropdown,
          title: "Language",
          key: "language",
          options: languageDropdownOptions,
          decoratorOptions: {
            initialValue: LanguageCode.En,
            rules: [{ required: false }],
          },
          style: inputStyle,
        },
        {
          type: FormInputType.Dropdown,
          title: "Gender",
          key: "gender",
          options: genderDropdownOptions,
          decoratorOptions: {
            initialValue: Gender.Unknown,
            rules: [{ required: false }],
          },
          style: inputStyle,
        },
        {
          type: FormInputType.Dropdown,
          key: "timezone",
          title: isMember ? "Latest Timezone:" : "Primary Timezone",
          options: timezoneDropdownOptions,
          disabled: isMember,
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Local Time",
          key: "localTime",
          style: inputStyle,
        },
        {
          type: FormInputType.Text,
          title: "Customer Provided ID",
          key: "externalId",
          style: inputStyle,
        },
        {
          type: FormInputType.Text,
          title: "Site Name",
          key: "siteName",
          style: inputStyle,
        },
        {
          type: FormInputType.Mask,
          ...eligibilityEndDateInput(inputStyle),
          placeholder: "MM-DD-YYYY",
          mask: "99-99-9999",
          decoratorOptions: { rules: [{ required: false }] },
        },
        {
          type: FormInputType.Text,
          title: "Race",
          key: "race",
          style: inputStyle,
        },
        {
          type: FormInputType.Text,
          title: "Ethnicity",
          key: "ethnicity",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Medicare ID",
          key: "medicareId",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Medicaid ID",
          key: "medicaidId",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Program Template",
          key: "programTemplate",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Coverage Type",
          key: "coverageType",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Plan Name",
          key: "planName",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Primary Care Provider",
          key: "pcpName",
          style: inputStyle,
        },
        {
          type: FormInputType.Info,
          title: "Primary Care Provider Contact",
          key: "pcpNumber",
          style: inputStyle,
        },
      ]}
      labelCol={{ span: 8 }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
      colValue={12}
    />
  );
};

export default React.forwardRef(EditProfileForm);
