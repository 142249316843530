const CARD_STYLES: { [key: string]: React.CSSProperties } = {
  head: {
    fontFamily: "HelveticaNeue",
    color: "#1f2226",
    fontWeight: "bold",
    padding: "0 0.5rem",
    maxHeight: "2.25rem",
  },
  body: {
    listStyle: "none",
    padding: "0.5rem",
  },
  card: {
    borderRadius: 10,
    boxShadow:
      "0 .25rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0 .125rem 0 rgba(0, 0, 0, 0.12)",
  },
  titleStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

export default CARD_STYLES;
