import React, { RefAttributes } from "react";
import orderBy from "lodash/orderBy";
import {
  Button,
  Popconfirm,
  SectionProps,
  SectionType,
  FormInputType,
  Icon,
} from "@wellth/web-ui";
import { SectionHeader } from "components/SectionHeader";
import { EmailAddressesSectionProps as BaseProps, Email } from "../types";
import styles from "./styles";

export interface EmailAddressesSectionProps
  extends BaseProps,
    RefAttributes<any> {
  onEditEmailAddress: (emailAddress: Email) => void;
  onCreateEmailAddress: () => void;
  onDeleteEmailAddress: (emailAddress: Email) => void;
  onVerifyEmailAddress: (emailAddress: Email) => void;
}

export const Inputs = ({
  emailAddresses = [],
  onEditEmailAddress,
  onCreateEmailAddress,
  onDeleteEmailAddress,
  onVerifyEmailAddress,
  ref,
}: EmailAddressesSectionProps): SectionProps<any> => ({
  ref,
  title: (
    <SectionHeader
      title="Email address(es)"
      submitButtonType="plus-circle"
      onClick={onCreateEmailAddress}
    />
  ),
  key: "emailAddresses",
  type: SectionType.Form,
  data: {
    mapPropsToFields: (props, createFormField) =>
      emailAddresses.reduce(
        (fields, { id, emailAddress }) => ({
          ...fields,
          [`emailAddress:${id}`]: createFormField({
            value: emailAddress,
          }),
        }),
        {},
      ),
    inputs: orderBy(emailAddresses, "isPrimary", "desc").map(
      (emailAddress) => ({
        type: FormInputType.Info,
        title: "Email:",
        key: `emailAddress:${emailAddress.id}`,
        style: emailAddress.isPrimary ? styles.verifiedInput : {},
        inputTrailer: () => (
          <div style={styles.trailerContainer}>
            {emailAddress.isPrimary ? (
              <span style={styles.trailer}>
                <Icon
                  type="check-circle"
                  theme="filled"
                  style={styles.verifiedIcon}
                />
              </span>
            ) : null}
            <Button
              disabled={emailAddress.isPrimary}
              onClick={() => onEditEmailAddress(emailAddress)}
              style={styles.trailer}
            >
              <Icon type="edit" />
            </Button>
            <Button
              disabled={emailAddress.isPrimary}
              onClick={() => onVerifyEmailAddress(emailAddress)}
              style={styles.trailer}
            >
              Set as Verified
            </Button>
            <span style={styles.trailer}>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => onDeleteEmailAddress(emailAddress)}
              >
                <Button disabled={emailAddress.isPrimary}>
                  <Icon type="delete" />
                </Button>
              </Popconfirm>
            </span>
          </div>
        ),
      }),
    ),
    labelCol: {
      sm: 4,
    },
  },
});

export default Inputs;
