import React from "react";
import { Table as TableUI } from "antd";
import { upperFirst } from "lodash";
import { IndividualType } from "types/globalTypes";

export interface TableRow {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  programCode: string;
  status: string;
  shortId: string;
  externalId?: string;
  individualType: IndividualType;
  phoneNumber: string;
}

enum ColumnKey {
  FirstName = "firstName",
  LastName = "lastName",
  BirthDate = "birthDate",
  ProgramCode = "programCode",
  Status = "status",
  ShortId = "shortId",
  ExternalId = "externalId",
  PhoneNumber = "phoneNumber",
}

export interface TableProps {
  loading?: boolean;
  rows: TableRow[];
  onClick: (record: TableRow, index: number) => void;
}

export const Table: React.FC<TableProps> = ({
  onClick,
  rows,
  loading = false,
}) => (
  <TableUI
    bordered
    loading={loading}
    dataSource={rows}
    rowKey={({ shortId }) => shortId}
    scroll={{ x: 1000 }}
    columns={[
      {
        title: "Short ID",
        key: ColumnKey.ShortId,
        dataIndex: ColumnKey.ShortId,
        render: (_, { shortId }) => (
          <a style={{ cursor: "pointer" }}>{shortId}</a>
        ),
      },
      {
        title: "First Name",
        key: ColumnKey.FirstName,
        dataIndex: ColumnKey.FirstName,
      },
      {
        title: "Last Name",
        key: ColumnKey.LastName,
        dataIndex: ColumnKey.LastName,
      },
      {
        title: "Date of Birth",
        key: ColumnKey.BirthDate,
        dataIndex: ColumnKey.BirthDate,
      },
      {
        title: "Program Code",
        key: ColumnKey.ProgramCode,
        dataIndex: ColumnKey.ProgramCode,
      },
      {
        title: "Status",
        key: ColumnKey.Status,
        dataIndex: ColumnKey.Status,
        render: (_, { status }) => upperFirst(status),
      },
      {
        title: "External ID",
        key: ColumnKey.ExternalId,
        dataIndex: ColumnKey.ExternalId,
        render: (_, { externalId }) => externalId || "-",
      },
      {
        title: "Phone Number",
        key: ColumnKey.PhoneNumber,
        dataIndex: ColumnKey.PhoneNumber,
      },
    ]}
    onRow={(record, rowIndex) => ({ onClick: () => onClick(record, rowIndex) })}
    pagination={false}
  />
);

export default Table;
