"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Placeholder = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/Placeholder/index.tsx";
var Placeholder = function Placeholder(_ref) {
  var width = _ref.width,
    height = _ref.height;
  var sizeStyle = {
    width: width,
    height: height
  };
  return _react["default"].createElement(_reactNative.View, {
    style: [_styles["default"].container, sizeStyle],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 16,
      columnNumber: 10
    }
  });
};
exports.Placeholder = Placeholder;
var _default = Placeholder;
exports["default"] = _default;