import React from "react";
import { Icon } from "@wellth/web-ui";

export interface SectionHeaderProps {
  title: string;
  submitButtonType?: string;
  onClick?: () => void;
  submitButtonTheme?: "filled" | "outlined" | "twoTone";
}

export const SectionHeader = ({
  title,
  submitButtonType,
  onClick,
  submitButtonTheme = "filled",
}: SectionHeaderProps) => (
  <>
    {title}
    {onClick ? (
      <Icon
        type={submitButtonType}
        onClick={onClick}
        theme={submitButtonTheme}
      />
    ) : null}
  </>
);

export default SectionHeader;
