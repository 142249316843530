"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../../../constants/colors"));
var _default = _reactNative.StyleSheet.create({
  container: {
    width: 327,
    height: 57,
    flexDirection: "row",
    alignItems: "center"
  },
  pressedStateUp: {
    backgroundColor: _colors["default"].GREY_DARKER
  },
  pressedStateDown: {
    backgroundColor: _colors["default"].GREY
  },
  positionTop: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  positionMiddle: {
    borderRadius: 0
  },
  positionBottom: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
  positionStandalone: {
    borderRadius: 8
  },
  imageView: {
    height: 20,
    width: 20,
    margin: 18
  },
  label: {
    fontFamily: "System",
    fontSize: 20,
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: 25,
    letterSpacing: 0.38,
    textAlign: "left",
    color: _colors["default"].WHITE,
    margin: 16,
    marginLeft: 8
  }
});
exports["default"] = _default;