"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormInputType = void 0;
var FormInputType;
(function (FormInputType) {
  FormInputType["Checkbox"] = "checkbox";
  FormInputType["CheckboxGroup"] = "checkboxGroup";
  FormInputType["Date"] = "date";
  FormInputType["Dropdown"] = "dropdown";
  FormInputType["Email"] = "email";
  FormInputType["Info"] = "info";
  FormInputType["Mask"] = "mask";
  FormInputType["Radio"] = "radio";
  FormInputType["RadioButton"] = "radioButton";
  FormInputType["Switch"] = "switch";
  FormInputType["Text"] = "text";
  FormInputType["TextArea"] = "textArea";
  FormInputType["Time"] = "time";
  FormInputType["InputNumber"] = "inputNumber";
})(FormInputType = exports.FormInputType || (exports.FormInputType = {}));