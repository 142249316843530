import moment from "moment-timezone";
import { formatPhoneNumber } from "@wellth/utils";
import { isEmpty, sortBy } from "lodash";
import { ProgramDetails as ProgramDetailsState } from "components/EnrollmentForm/sections/programDetails";
import {
  Reminders as RemindersState,
  Reminders,
} from "components/EnrollmentForm/sections/reminders";
import { EnrollmentSubmission } from "components/EnrollmentForm";
import strings from "constants/strings";
import {
  ReminderConfigLiteInput,
  LanguageCode,
  EnrollMemberMutationVariables as EnrollMemberVariables,
  FullReminderConfigFragment as ReminderConfig,
  FullRewardPeriodConfigFragment as RewardPeriodConfig,
  EnrollPaperMutationMutationVariables as EnrollPaperMutationVariables,
} from "types/globalTypes";
import { DATE_FORMAT } from "utils/date";
import { EnrollmentPageProps } from "components/EnrollmentPage";

const DAY_UNIT_OF_TIME = "days";
const YEAR_MONTH_DAY_FORMAT = "YYYY-MM-DD";
const BIRTHDATE_FORMAT = "MM/DD/YYYY";
const TIME_FORMAT = "HH:mm:ss";
const RESTFUL_URI = "https://wellthapp.com/restfulapp";
const RESTFUL_PROGRAM_CODES = ["RESTFUL", "RESTFUL_TEST", "RESTFUL_BETA"];

const appDownloadURI = (): string => "https://wellthapp.com/app";

export const mapRemindersConfigResponse = (
  configurations: ReminderConfig[],
  patientStartDate: moment.Moment,
): RemindersState =>
  configurations.map(
    (
      {
        id,
        startDayIndex,
        lengthInDays,
        checkInCategory,
        isDemo,
        isRequired,
        monTime,
        interval,
        windowAfterMinutes,
        windowBeforeMinutes,
      },
      index,
    ) => {
      const startDate = patientStartDate
        .clone()
        .add(startDayIndex, DAY_UNIT_OF_TIME);

      const endDate = patientStartDate
        .clone()
        .add(lengthInDays + startDayIndex - 1, DAY_UNIT_OF_TIME);

      return {
        key: index,
        checkInCategory,
        isDemo,
        dueTime: moment(monTime, TIME_FORMAT),
        startDate,
        endDate,
        interval,
        dayOfWeek: startDate.day(),
        windowAfterMinutes,
        windowBeforeMinutes,
        reminderConfigId: id,
        isRequired,
      };
    },
  );

export const mapProgramDetailsResponse = (
  configurations: RewardPeriodConfig[],
  patientStartDate: moment.Moment,
): ProgramDetailsState =>
  sortBy(
    configurations.map(
      ({ lengthInDays, rewardAmount, lossPerDayMax, startDayIndex }, index) => {
        const periodStartDate = patientStartDate
          .clone()
          .add(startDayIndex, DAY_UNIT_OF_TIME);

        const payoutDate = periodStartDate
          .clone()
          .add(lengthInDays, DAY_UNIT_OF_TIME);

        const periodEndDate = payoutDate.clone().subtract(1, DAY_UNIT_OF_TIME);

        return {
          key: index,
          periodLength: lengthInDays,
          rewards: rewardAmount,
          lossPerDay: lossPerDayMax,
          periodStartDate,
          periodEndDate,
          payoutDate,
        };
      },
    ),
    ({ periodStartDate }) => periodStartDate,
  );

export const nextDateMatchingDayOfWeek = (
  referenceDate: Date,
  dayOfWeekIndex: number,
): Date => {
  const referenceMoment = moment(referenceDate);
  if (referenceMoment.day() === dayOfWeekIndex) {
    return referenceDate;
  }

  const nextReferenceDate = referenceMoment.add(1, DAY_UNIT_OF_TIME).toDate();
  return nextDateMatchingDayOfWeek(nextReferenceDate, dayOfWeekIndex);
};

export const configureStartDayIndex = (
  startDate: Date,
  referenceDate: Date,
): number => moment(startDate).diff(referenceDate, DAY_UNIT_OF_TIME);

export const configureWeeklyStartDayIndex = (
  startDate: Date,
  referenceDate: Date,
  dayOfWeek: number,
): number =>
  configureStartDayIndex(
    nextDateMatchingDayOfWeek(startDate, dayOfWeek),
    referenceDate,
  );

const normalizeBirthdate = (birthdate: string): string =>
  moment(birthdate, BIRTHDATE_FORMAT).format(DATE_FORMAT);

const normalizeAddress = ({
  address,
  address2,
  city,
  state,
  zipCode,
  country,
}: {
  address: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}) => ({
  addressStreet: address,
  addressStreet2: isEmpty(address2) ? undefined : address2,
  addressCity: city,
  addressState: state,
  addressZip: zipCode,
  addressCountry: country,
});

const normalizeReminders = (
  reminders: Reminders,
  patientStartDate: moment.Moment,
): ReminderConfigLiteInput[] =>
  reminders.map(
    ({
      startDate,
      endDate,
      checkInCategory,
      dueTime,
      isDemo,
      interval,
      windowAfterMinutes,
      windowBeforeMinutes,
      reminderConfigId,
      isRequired,
    }) => {
      const dueTimeString = dueTime.format(TIME_FORMAT);
      const startDateReference = startDate
        .clone()
        .startOf(DAY_UNIT_OF_TIME)
        .toDate();
      const defaultReferenceDate = patientStartDate
        .clone()
        .startOf(DAY_UNIT_OF_TIME)
        .toDate();

      return {
        id: reminderConfigId,
        interval,
        intervalCount: 1,
        checkInCategory,
        isDemo,
        monTime: dueTimeString,
        tueTime: dueTimeString,
        wedTime: dueTimeString,
        thuTime: dueTimeString,
        friTime: dueTimeString,
        satTime: dueTimeString,
        sunTime: dueTimeString,
        lengthInDays: endDate
          ? endDate.diff(startDate, DAY_UNIT_OF_TIME) + 1
          : null,
        startDayIndex: configureStartDayIndex(
          startDateReference,
          defaultReferenceDate,
        ),
        windowAfterMinutes,
        windowBeforeMinutes,
        isRequired,
      };
    },
  );

export interface SubmissionOutput {
  memberId: string;
  firstName: string;
  lastName: string;
}

const isRestfulProgramCode = (programCode: string) =>
  RESTFUL_PROGRAM_CODES.includes(programCode);

export const welcomeMessage = (
  programCode: string,
  language: string,
  firstName: string,
): string => {
  strings.setLanguage(language.toLowerCase());

  return isRestfulProgramCode(programCode)
    ? strings
        .formatString(strings.restfulWelcomeMessage, firstName)
        .toString() + RESTFUL_URI
    : strings
        .formatString(strings.rewardsWelcomeMessage, firstName)
        .toString() + appDownloadURI();
};

export const calculatePatientStartDate = (patientTimezone: string) =>
  moment
    .tz(patientTimezone)
    .startOf("day")
    .add(1, "days");

interface ProgramCodeResponseOptions {
  reminderConfigs: ReminderConfig[];
  rewardPeriodConfigs: RewardPeriodConfig[];
  timezone: string;
}

export const normalizeProgramCodeResponse = ({
  reminderConfigs = [],
  rewardPeriodConfigs = [],
  timezone,
}: ProgramCodeResponseOptions): Pick<
  EnrollmentPageProps,
  "reminders" | "programDetails"
> => {
  // Today's date relative to the patient's timezone
  const patientDate = calculatePatientStartDate(timezone);

  return {
    reminders: mapRemindersConfigResponse(reminderConfigs, patientDate),
    programDetails: mapProgramDetailsResponse(rewardPeriodConfigs, patientDate),
  };
};

export const normalizeEnrollPaperMemberFormValues = (
  {
    memberProfile: { firstName, lastName, phoneNumber, email },
    mailingAddress: { street, street2, city, state, zip },
    programConfig: { programCode },
    siteInformation: { customerId = "" },
    otherInformation: { paymentOptionsId },
  }: EnrollmentSubmission,
  prospectId?: string,
): EnrollPaperMutationVariables => ({
  input: {
    firstName,
    lastName,
    email,
    programCode,
    customerId,
    phoneNumber,
    ...normalizeAddress({
      address: street,
      address2: street2,
      city,
      state,
      zipCode: zip,
      country: "USA",
    }),
    prospectId,
    paymentOptionsId,
  },
});

export const normalizeEnrollMemberFormValues = (
  {
    memberProfile: {
      firstName,
      lastName,
      phoneNumber,
      birthdate,
      email,
      language,
    },
    mailingAddress: { street, street2, city, state, zip },
    programConfig: { programCode, timezone },
    reminders,
    demoReminders,
    siteInformation: { customerId = "", site: siteName = "" },
    otherInformation: {
      introductionToWellth,
      typeOfEnrollment,
      paymentOptionsId,
    },
  }: EnrollmentSubmission,
  prospectId?: string,
  addressValidated?: boolean,
): EnrollMemberVariables => {
  const patientStartDate = calculatePatientStartDate(timezone);
  return {
    input: {
      firstName,
      lastName,
      email,
      programCode,
      timezone,
      language: language.toUpperCase() as LanguageCode,
      customerId,
      siteName,
      introductionToWellth,
      typeOfEnrollment,
      ...normalizeAddress({
        address: street,
        address2: street2,
        city,
        state,
        zipCode: zip,
        country: "USA",
      }),
      phoneNumber: formatPhoneNumber(phoneNumber),
      birthdate: normalizeBirthdate(birthdate),
      reminderList: normalizeReminders(
        (reminders || []).concat(demoReminders || []),
        patientStartDate,
      ),
      welcomeMessage: welcomeMessage(programCode, language, firstName),
      asOfDate: patientStartDate.format(YEAR_MONTH_DAY_FORMAT),
      prospectId,
      paymentOptionsId,
      addressValidated,
    },
  };
};
