"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Button = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("react"));
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../../../constants/colors"));
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/ButtonGroup/components/Button/index.tsx";
function _getRequireWildcardCache(e) {
  if ("function" != typeof WeakMap) return null;
  var r = new WeakMap(),
    t = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(e) {
    return e ? t : r;
  })(e);
}
function _interopRequireWildcard(e, r) {
  if (!r && e && e.__esModule) return e;
  if (null === e || "object" != _typeof(e) && "function" != typeof e) return {
    "default": e
  };
  var t = _getRequireWildcardCache(r);
  if (t && t.has(e)) return t.get(e);
  var n = {
      __proto__: null
    },
    a = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var u in e) {
    if ("default" !== u && {}.hasOwnProperty.call(e, u)) {
      var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
      i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
    }
  }
  return n["default"] = e, t && t.set(e, n), n;
}
var containerStyleForPressedState = function containerStyleForPressedState(isPressed) {
  return isPressed ? _styles["default"].pressedStateDown : _styles["default"].pressedStateUp;
};
var containerStyleForPosition = function containerStyleForPosition(position) {
  switch (position) {
    case "Top":
      return _styles["default"].positionTop;
    case "Middle":
      return _styles["default"].positionMiddle;
    case "Bottom":
      return _styles["default"].positionBottom;
    case "Standalone":
      return _styles["default"].positionStandalone;
    default:
      throw new Error("Invalid position: ".concat(position));
  }
};
var Button = function Button(_ref) {
  var iconSource = _ref.iconSource,
    label = _ref.label,
    onPress = _ref.onPress,
    position = _ref.position,
    _ref$buttonProps = _ref.buttonProps,
    buttonProps = _ref$buttonProps === void 0 ? {} : _ref$buttonProps;
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    isPressed = _useState2[0],
    setIsPressed = _useState2[1];
  var _buttonProps$testID = buttonProps.testID,
    testID = _buttonProps$testID === void 0 ? "button" : _buttonProps$testID;
  return _react["default"].createElement(_reactNative.TouchableHighlight, {
    onPress: onPress,
    onHideUnderlay: function onHideUnderlay() {
      return setIsPressed(false);
    },
    onShowUnderlay: function onShowUnderlay() {
      return setIsPressed(true);
    },
    underlayColor: _colors["default"].TRANSPARENT,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 55,
      columnNumber: 5
    }
  }, _react["default"].createElement(_reactNative.View, {
    style: [_styles["default"].container, containerStyleForPressedState(isPressed), containerStyleForPosition(position)],
    accessibilityLabel: testID,
    testID: testID,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 61,
      columnNumber: 7
    }
  }, _react["default"].createElement(_reactNative.Image, {
    source: iconSource,
    style: _styles["default"].imageView,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 70,
      columnNumber: 9
    }
  }), _react["default"].createElement(_reactNative.Text, {
    style: _styles["default"].label,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 71,
      columnNumber: 9
    }
  }, label)));
};
exports.Button = Button;
var _default = Button;
exports["default"] = _default;