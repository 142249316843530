import React from "react";
import { AlertTriangle } from "assets/alertTriangle";
import { Checkmark } from "assets/checkMark";
import styles from "./styles";

export interface AddressValidityProps {
  isValid: boolean;
  style?: React.CSSProperties;
}

const AddressValidity: React.FC<AddressValidityProps> = ({
  isValid,
  style,
}: AddressValidityProps) => (
  <div
    style={{
      ...styles.container,
      ...style,
    }}
  >
    {isValid ? <Checkmark /> : <AlertTriangle />}
    <p style={styles.addressValidityLabel}>
      {isValid
        ? "Address is valid as entered."
        : "Unable to validate mailing address."}
    </p>
  </div>
);

export default AddressValidity;
