const styles: { [key: string]: React.CSSProperties } = {
  noteDate: {
    fontWeight: "normal",
    padding: "0 0.3125rem",
    color: "#777777",
  },
  noteHeader: {
    display: "flex",
  },
  noteAdmin: {
    color: "#777777",
  },
  noteContainer: {
    textAlign: "left",
    padding: "0.625rem",
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5ea",
    borderBottomStyle: "solid",
  },
  note: {
    color: "black",
  },
  bodyStyle: {
    textAlign: "left",
    padding: 0,
  },
};

export default styles;
