import React from "react";
import { useValidateAddressQuery } from "hooks/graphql";
import AddressSuggestionModalUI, {
  AddressSuggestionModalProps as AddressSuggestionModalUIProps,
} from "components/AddressSuggestionModal";

export type AddressSuggestionModalProps = Pick<
  AddressSuggestionModalUIProps,
  "addressToVerify" | "submitAddress" | "visible" | "goBack" | "submitAddress"
>;

export const AddressSuggestionModal: React.FC<AddressSuggestionModalProps> = ({
  addressToVerify,
  visible,
  ...props
}) => {
  const {
    data: {
      validateAddress: {
        note = null,
        isValid = false,
        correctedAddress = null,
      } = {},
    } = {},
    loading,
  } = useValidateAddressQuery({
    fetchPolicy: "network-only",
    skip: !visible,
    variables: {
      input: {
        ...addressToVerify,
        street: (addressToVerify && addressToVerify.street) || "",
        city: (addressToVerify && addressToVerify.city) || "",
        state: (addressToVerify && addressToVerify.state) || "",
        zip: (addressToVerify && addressToVerify.zip) || "",
      },
    },
  });

  return (
    <AddressSuggestionModalUI
      {...props}
      note={note}
      visible={visible}
      addressToVerify={addressToVerify}
      loading={loading}
      isValid={isValid}
      suggestionAddress={correctedAddress}
    />
  );
};

export default AddressSuggestionModal;
