import React from "react";
import EditPhoneNumberModal from "components/EditPhoneNumberFormModal";
import { ContactType } from "types/globalTypes";
import {
  useCreatePhoneNumberMutation,
  MemberPhoneNumbersDocument as memberPhoneNumbersQuery,
  ProspectPhoneNumbersDocument as prospectPhoneNumbersQuery,
} from "hooks/graphql";
import { formatPhoneNumber } from "@wellth/utils";
import { handlePhoneNumberError } from "containers/utils/phoneNumberUtils";

export interface CreatePhoneNumberFormModalProps {
  goBack: () => void;
  contactId: string;
  contactType: ContactType;
}

export const CreatePhoneNumberFormModal: React.FC<
  CreatePhoneNumberFormModalProps
> = ({ goBack, contactType, contactId }: CreatePhoneNumberFormModalProps) => {
  const [
    createPhoneNumber,
    { loading: submissionLoading },
  ] = useCreatePhoneNumberMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [
      contactType === ContactType.Member
        ? { query: memberPhoneNumbersQuery, variables: { id: contactId } }
        : { query: prospectPhoneNumbersQuery, variables: { id: contactId } },
    ],
  });

  return (
    <EditPhoneNumberModal
      type={contactType}
      title="Create Phone Number"
      submissionLoading={submissionLoading}
      cancel={goBack}
      submit={async ({ phoneNumber, isPrimary = false }) => {
        try {
          await createPhoneNumber({
            variables: {
              contactId,
              contactType,
              input: {
                phoneNumber: {
                  phoneNumber: formatPhoneNumber(phoneNumber, "US"),
                  isPrimary,
                },
              },
            },
          });
        } catch (err) {
          const { message } = err as { message: string };
          handlePhoneNumberError(message);
        }
      }}
    />
  );
};

export default CreatePhoneNumberFormModal;
