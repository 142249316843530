// mask numbers (xxx) xxx-xxxx
export const formatPhoneNumberToDisplayString = (
  phoneNumber: string,
): string => {
  if (phoneNumber) {
    return phoneNumber
      .substring(2)
      .replace(/\D+/g, "")
      .replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "($1) $2-$3");
  }
  return "";
};

export default formatPhoneNumberToDisplayString;
