import React, { useRef, useState } from "react";
import TitledBoxLayout from "components/TitledBoxLayout";
import { Filters, FiltersProps, FormValues } from "./components/Filters";
import {
  Table,
  TableRow,
  TablePagination,
  TableFilter,
} from "./components/Table";

type FilterState = Omit<FiltersProps, "submit">;

export interface ManageMembersProps extends FilterState {
  loading: boolean;
  members: TableRow[];
  pagination: TablePagination;
  onFilterSetChanged: (values: FormValues) => void;
  onTablePaginationChanged: (filters: TableFilter) => void;
  routeToMember: (member: TableRow) => void;
}

const ManageMembers: React.FC<ManageMembersProps> = ({
  loading,
  members,
  programCodes,
  pagination,
  onFilterSetChanged,
  onTablePaginationChanged,
  routeToMember,
}: ManageMembersProps) => {
  const filterRef = useRef(null);
  const [filterState, setFilterState] = useState<FilterState>({
    statuses: [],
    programCodes: [],
    externalId: "",
  });

  return (
    <TitledBoxLayout title="Members" secondaryTitle="">
      <Filters
        ref={filterRef}
        programCodes={programCodes}
        selectedProgramCodes={filterState.programCodes}
        externalId={filterState.externalId}
        statuses={filterState.statuses}
        submit={({
          statuses,
          programCodes: programCodeFilters,
          externalId,
        }) => {
          setFilterState({
            statuses,
            programCodes: programCodeFilters,
            externalId,
          });

          onFilterSetChanged({
            statuses,
            programCodes: programCodeFilters,
            externalId,
          });
        }}
      />
      <Table
        loading={loading}
        rows={members}
        pagination={pagination}
        onClick={(record) => {
          routeToMember(record);
        }}
        onFilterSetChanged={onTablePaginationChanged}
      />
    </TitledBoxLayout>
  );
};

export default ManageMembers;
