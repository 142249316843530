import React, { PropsWithChildren } from "react";
import { BoxLayout } from "@wellth/web-ui";
import styles from "./style";

interface TitledBoxLayoutProps {
  title: string;
  secondaryTitle: string | React.CElement<any, any>;
}

export default ({
  title,
  secondaryTitle,
  children,
}: PropsWithChildren<TitledBoxLayoutProps>) => (
  <div style={styles.container}>
    <h1 style={styles.title}>{title}</h1>
    <BoxLayout title={secondaryTitle}>{children}</BoxLayout>
  </div>
);
