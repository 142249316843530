"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _default = _reactNative.StyleSheet.create({
  container: {
    flex: 1
  },
  blurView: {
    flex: 1,
    zIndex: 2
  },
  compatView: {
    backgroundColor: _colors["default"].BLACK50
  }
});
exports["default"] = _default;