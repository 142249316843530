import React from "react";
import { Card } from "@wellth/web-ui";
import { Tag as TagUI, Select, Popconfirm } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "./styles";

export interface Tag {
  id: string;
  backgroundColor?: string;
  borderColor?: string;
  title: string;
  removable: boolean;
}

export interface AllAdminTagConfigs {
  id: string;
  name: string;
}

export interface TagsCardProps {
  title?: string;
  tags: Tag[];
  showAddTag?: boolean;
  onRemoveTag: (deletedTagId: string) => void;
  onAddTag: (tagConfigId: string) => Promise<void>;
  handleOpenInput: () => void;
  handleCloseInput: () => void;
  allAdminTagConfigs: AllAdminTagConfigs[];
  isInputVisible: boolean;
}

const { Option }: any = Select;

export const TagsCard: React.FC<TagsCardProps> = ({
  title = "Tags",
  tags,
  onRemoveTag,
  isInputVisible,
  handleOpenInput,
  handleCloseInput,
  onAddTag,
  allAdminTagConfigs,
}) => (
  <Card
    title={title}
    style={styles.card}
    headStyle={styles.header}
    bodyStyle={styles.body}
  >
    <div>
      {tags.map(
        ({ backgroundColor, borderColor, title: tagTitle, removable, id }) => (
          <Popconfirm
            key={`${backgroundColor}-${tagTitle}`}
            title="Are you sure you want to delete this?"
            onConfirm={() => onRemoveTag(id)}
            okText="Yes"
            cancelText="No"
            disabled={!removable}
          >
            <TagUI
              key={`${backgroundColor}-${title}`}
              style={{
                backgroundColor,
                borderColor: borderColor || null,
                borderWidth: borderColor ? 1 : 0,
                marginBottom: "0.25rem",
              }}
              closable={false}
              onClose={(e) => e.preventDefault()}
            >
              {tagTitle}
              {removable ? <CloseOutlined /> : null}
            </TagUI>
          </Popconfirm>
        ),
      )}
      {!isInputVisible ? (
        <div style={styles.addTagContainer} onClick={handleOpenInput}>
          <TagUI style={styles.addTag}>
            <PlusOutlined /> Add Tag
          </TagUI>
        </div>
      ) : (
        <span>
          <Select
            showSearch
            labelInValue
            onSelect={async (_, { key }: any) => {
              await onAddTag(key);
            }}
            style={styles.select}
          >
            {allAdminTagConfigs.map(({ id, name }) => (
              <Option key={id} value={name}>
                {name}
              </Option>
            ))}
          </Select>
          <CloseOutlined onClick={handleCloseInput} />
        </span>
      )}
    </div>
  </Card>
);

export default TagsCard;
