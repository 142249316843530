export interface Name {
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

const formatName = (name: Name): string =>
  `${name.firstName} ${name.middleName ? `${name.middleName} ` : ""}${
    name.lastName
  }`;

export default formatName;
