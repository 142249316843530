import React from "react";
import InputCard from "../InputCard";
import Note, { NoteProps } from "./components/Note";
import styles from "./styles";

export type PatientNote = NoteProps & { id: string };

export interface PatientNotesProps {
  sendMessage: (text: string) => void;
  notes: PatientNote[];
}

export default class PatientNotes extends React.Component<PatientNotesProps> {
  private patientNotesEnd: HTMLElement;

  componentDidMount() {
    this.patientNotesEnd.scrollIntoView(false);
  }

  componentDidUpdate() {
    this.patientNotesEnd.scrollIntoView(false);
  }

  render() {
    const { sendMessage, notes } = this.props;

    return (
      <InputCard
        title="Notes"
        sendMessage={sendMessage}
        bodyStyle={styles.bodyStyle}
        buttonTitle="Add"
        popconfirm
        popconfirmTitle="Ok to add Note?"
        placeholder="Type here to add a note"
      >
        {notes.map(({ id, body, adminName, createdAt }) => (
          <Note
            key={id}
            body={body}
            adminName={adminName}
            createdAt={createdAt}
          />
        ))}
        <div
          ref={(el) => {
            this.patientNotesEnd = el;
          }}
        />
      </InputCard>
    );
  }
}
