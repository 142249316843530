import * as React from "react";
import { useDeviceDetailsLazyQuery } from "hooks/graphql";
import MixpanelTimeline from "components/MixpanelTimeline";

interface MixpanelContainerProps {
  wellthId: string;
}

const MixpanelContainer: React.FC<MixpanelContainerProps> = ({
  wellthId,
}: MixpanelContainerProps) => {
  const [
    getDeviceDetails,
    { loading: loadingDeviceDetails, data, error },
  ] = useDeviceDetailsLazyQuery({ variables: { wellthId } });

  const { deviceDetails = undefined } = data || {};
  return (
    <MixpanelTimeline
      loadingDeviceDetails={loadingDeviceDetails}
      isError={!!error}
      getProperties={() => {
        getDeviceDetails();
      }}
      deviceDetails={deviceDetails}
    />
  );
};

export default MixpanelContainer;
