import { programTemplatesNotAllowingMedReminders } from "components/EnrollmentForm/EnrollmentPolicy";
import { CheckInCategory } from "types/globalTypes";

// eslint-disable-next-line import/prefer-default-export
export function reminderStateChangeHandler(
  formState,
  changedValues,
  programTemplate,
  setIsRequiredOverride,
) {
  if (changedValues.checkInCategory) {
    if (
      programTemplatesNotAllowingMedReminders.includes(programTemplate) &&
      changedValues.checkInCategory === CheckInCategory.Medication
    ) {
      setIsRequiredOverride(true);
    } else {
      setIsRequiredOverride(false);
    }
  }

  const newState = { ...formState, ...changedValues };

  if (newState.isDemo) {
    newState.windowBeforeMinutes = 1440;
    newState.windowAfterMinutes = 1440;
  } else if (
    Object.keys(changedValues).includes("checkInCategory") ||
    Object.keys(changedValues).includes("isDemo")
  ) {
    if (newState.checkInCategory === CheckInCategory.Meal) {
      newState.windowBeforeMinutes = 360;
      newState.windowAfterMinutes = 360;
    } else {
      newState.windowBeforeMinutes = 360;
      newState.windowAfterMinutes = 180;
    }
  }

  return newState;
}
