import React, { useRef } from "react";
import { Modal } from "components/Modal";
import { MODAL_WIDTH } from "utils";
import { Button } from "@wellth/web-ui";
import {
  EditPaymentOptionForm,
  EditPaymentOptionFormProps,
  EditPaymentOptionFormRef,
} from "./components/EditPaymentOptionForm";

export interface EditPaymentOptionModalProps
  extends EditPaymentOptionFormProps {
  goBack: () => void;
  submissionLoading: boolean;
  editPaymentOption: (newPaymentOption: string) => void;
  disableSubmitButton: boolean;
}

export const EditPaymentOptionModal: React.FC<EditPaymentOptionModalProps> = ({
  goBack,
  submissionLoading,
  editPaymentOption,
  disableSubmitButton,
  ...props
}) => {
  const formRef = useRef<EditPaymentOptionFormRef>(null);

  return (
    <Modal
      title="Edit Payment Option"
      width={MODAL_WIDTH}
      submissionLoading={submissionLoading}
      onCancel={goBack}
      footer={[
        <Button key="cancel" onClick={goBack}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={disableSubmitButton}
          onClick={async () => {
            const {
              desiredPaymentOption,
            } = await formRef.current.getPaymentOptionInput();
            editPaymentOption(desiredPaymentOption);
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <EditPaymentOptionForm ref={formRef} {...props} />
    </Modal>
  );
};

export default EditPaymentOptionModal;
