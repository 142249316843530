"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Row = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/Row/index.tsx";
var Row = function Row(_ref) {
  var style = _ref.style,
    contentStyle = _ref.contentStyle,
    children = _ref.children,
    props = (0, _objectWithoutProperties2["default"])(_ref, ["style", "contentStyle", "children"]);
  return _react["default"].createElement(_reactNative.TouchableWithoutFeedback, (0, _extends2["default"])({}, props, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 22,
      columnNumber: 3
    }
  }), _react["default"].createElement(_reactNative.View, {
    style: style,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 23,
      columnNumber: 5
    }
  }, _react["default"].createElement(_reactNative.View, {
    testID: "content",
    style: [_styles["default"].contentContainer, contentStyle],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 24,
      columnNumber: 7
    }
  }, children)));
};
exports.Row = Row;
var _default = Row;
exports["default"] = _default;