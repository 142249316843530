import React, { useRef, useCallback, useState } from "react";
import { SubmissionReviewCard } from "components/SubmissionReviewCard";
import ReviewBonusSubmissionInput, {
  ReviewBonusSubmissionInputRef,
  ReviewBonusSubmissionInputValues,
} from "components/ReviewBonusSubmissionInput";
import { formatCurrency } from "@wellth/utils";
import { RejectionReasonsOptions } from "./constants";

export interface BonusBehaviorSubmissionRecord {
  id: string;
  name: string;
  status: string;
  timestamp: string;
  shortId: string;
  firstName: string;
  lastName: string;
  programCode: string;
  rewardAmount: number;
  rewardCurrency: string;
  photoUrl: string;
}

export interface ReviewCardProps extends BonusBehaviorSubmissionRecord {
  submit: (review: ReviewBonusSubmissionInputValues) => Promise<void>;
  onError: (error: Error) => void;
  routeToMemberPage: () => void;
}

export const ReviewCard: React.FC<ReviewCardProps> = ({
  submit,
  id,
  name,
  shortId,
  timestamp,
  firstName,
  lastName,
  programCode,
  rewardAmount,
  rewardCurrency,
  photoUrl,
  onError,
  routeToMemberPage,
}) => {
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const inputRef = useRef<ReviewBonusSubmissionInputRef>(null);

  const onSubmit = useCallback(
    () => {
      const submitForm = async (): Promise<void> => {
        setSubmitEnabled(false);
        try {
          const reviewValues = inputRef.current.getSubmissionReviewInput();
          await submit(reviewValues);
        } catch (error) {
          setSubmitEnabled(true);
          onError(error);
        }
      };

      void submitForm();
    },
    [onError, submit],
  );

  return (
    <SubmissionReviewCard
      key={`${id}-${shortId}`}
      title={name}
      footerButtons={[
        {
          key: "submitButton",
          title: "Submit",
          disabled: !submitEnabled,
          onClick: onSubmit,
        },
      ]}
      details={{
        submittedOn: new Date(timestamp),
        submittedBy: `${firstName} ${lastName} (${shortId})`,
        programCode,
        reward: formatCurrency(rewardAmount, rewardCurrency),
        submissionId: id,
        routeToMemberPage,
      }}
      photoUrl={photoUrl}
    >
      <ReviewBonusSubmissionInput
        ref={inputRef}
        reasons={RejectionReasonsOptions}
      />
    </SubmissionReviewCard>
  );
};

export default ReviewCard;
