import React, { RefAttributes } from "react";
import orderBy from "lodash/orderBy";
import {
  Button,
  FormInputType,
  Popconfirm,
  SectionProps,
  SectionType,
  Icon,
} from "@wellth/web-ui";
import { SectionHeader } from "components/SectionHeader";
import { PhoneNumbersSectionProps as BaseProps, PhoneNumber } from "../types";
import styles from "./styles";

export interface PhoneNumberSectionProps extends BaseProps, RefAttributes<any> {
  onEditPhoneNumber: (phoneNumber: PhoneNumber) => void;
  onCreatePhoneNumber: () => void;
  onDeletePhoneNumber: (phoneNumber: PhoneNumber) => void;
}

export const Inputs = ({
  phoneNumbers = [],
  onEditPhoneNumber,
  onCreatePhoneNumber,
  onDeletePhoneNumber,
  ref,
}: PhoneNumberSectionProps): SectionProps<any> => ({
  ref,
  title: (
    <SectionHeader
      title="Phone number(s)"
      submitButtonType="plus-circle"
      onClick={onCreatePhoneNumber}
    />
  ),
  key: "phoneNumbers",
  type: SectionType.Form,
  data: {
    // Create a field that binds to the `key` as specified in
    // `fieldForPhoneNumber`
    mapPropsToFields: (props, createFormField) =>
      phoneNumbers.reduce(
        (fields, { id, phoneNumber }) => ({
          ...fields,
          [`phoneNumber:${id}`]: createFormField({
            value: phoneNumber.slice(2),
          }),
        }),
        {},
      ),
    // Map all the phone numbers to a field
    inputs: orderBy(phoneNumbers, "isPrimary", "desc").map((phoneNumber) => ({
      type: FormInputType.Info,
      title: "Phone Number",
      key: `phoneNumber:${phoneNumber.id}`,
      style: phoneNumber.isPrimary ? styles.verifiedInput : {},
      inputTrailer: () => (
        <div style={styles.trailerContainer}>
          {phoneNumber.isPrimary ? (
            <span style={styles.trailer}>
              <Icon
                type="check-circle"
                theme="filled"
                style={styles.verifiedIcon}
              />
            </span>
          ) : null}
          <Button
            disabled={phoneNumber.isPrimary}
            onClick={() => onEditPhoneNumber(phoneNumber)}
            style={styles.trailer}
          >
            <Icon type="edit" />
          </Button>

          <span style={styles.trailer}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => onDeletePhoneNumber(phoneNumber)}
            >
              <Button disabled={phoneNumber.isPrimary}>
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          </span>
        </div>
      ),
    })),
    labelCol: {
      sm: 5,
    },
  },
});

export default Inputs;
