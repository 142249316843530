import moment from "moment-timezone";

export const TIME_FORMAT = "hh:mm A";
export const REMINDER_DUE_TIME_FORMAT = "HH:mm:ss";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "MMM Do, h:mm:ss A (z)";
export const DATE_TIME_FORMAT_YEAR_APOSTROPHE = "MMM Do 'YY, h:mm A (z)";

export const dayIsTodayOrInFuture = (date: Date, timezone: string) =>
  moment
    .tz(moment(date), timezone)
    .isSameOrAfter(moment.tz(moment(), timezone).format(DATE_FORMAT));

export const dayIsInThePast = (date: Date, timezone: string) =>
  moment
    .tz(moment(date), timezone)
    .isBefore(moment.tz(moment(), timezone).format(DATE_FORMAT));

export const dayIsInTheFuture = (date: Date, timezone: string) =>
  moment
    .tz(moment(date), timezone)
    .isAfter(moment.tz(moment(), timezone).format(DATE_FORMAT));

export const dayIsTodayOrInThePast = (date: Date, timezone: string) =>
  moment
    .tz(moment(date), timezone)
    .isSameOrBefore(moment.tz(moment(), timezone).format(DATE_FORMAT));

export const getCheckInTimezoneString = (
  checkInTimezone: string,
  timezone: string,
) => moment.tz(checkInTimezone || timezone).format("z");
