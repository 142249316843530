import React, { useState, useRef, useImperativeHandle } from "react";
import { Form } from "@wellth/web-ui";
import { AdminPrivilege } from "constants/adminPrivilege";
import formInputs, { CONSTANTS } from "./inputs";

const setReasonDetailDisabled = (reason: string) => {
  const reasons = [CONSTANTS.unenrollProgramComplete, CONSTANTS.duplicate];

  return !reasons.includes(reason);
};

export interface ChangeStatusFormRef {
  getChangeStatusReason: () => Promise<any>;
}

export interface ChangeStatusFormProps {
  status: string;
  adminRole: AdminPrivilege;
}

const ChangeStatusForm: React.RefForwardingComponent<
  ChangeStatusFormRef,
  ChangeStatusFormProps
> = ({ status, adminRole }: ChangeStatusFormProps, ref) => {
  const formRef = useRef(null);
  const [formValues, setFormValues] = useState({
    type: null,
    reason: null,
    reasonDetail: null,
    note: null,
  });
  const [showReason, setShowReason] = useState(false);
  const [showReasonDetail, setShowReasonDetail] = useState(false);
  const [reason, setReason] = useState(null);
  const [reasonRequired, setReasonRequired] = useState(false);
  const [reasonDetailRequired, setReasonDetailRequired] = useState(false);
  const [noteRequired, setNoteRequired] = useState(false);

  useImperativeHandle(ref, () => ({
    getChangeStatusReason: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      wrappedComponentRef={formRef}
      onValuesChange={(changedValues, _allValues) => {
        if ("type" in changedValues) {
          if (changedValues.type) {
            setShowReason(true);
            setFormValues({ ...formValues, ..._allValues });
          }
          if (changedValues.type === CONSTANTS.closed) {
            setReasonRequired(true);
          }
        }

        if ("reason" in changedValues) {
          if (changedValues.reason) {
            setReason(changedValues.reason);
            setFormValues({
              ...formValues,
              ..._allValues,
              reasonDetail: null,
              note: null,
            });
            setShowReasonDetail(setReasonDetailDisabled(changedValues.reason));
            setReasonDetailRequired(false);
            setNoteRequired(false);
          }
          if ([CONSTANTS.ineligble].includes(changedValues.reason)) {
            setReasonDetailRequired(true);
          }
        }
        if (changedValues.reasonDetail) {
          setFormValues({ ...formValues, ..._allValues });
          if (changedValues.reasonDetail === "Other") {
            setNoteRequired(true);
          } else {
            setNoteRequired(false);
          }
        }
      }}
      inputs={formInputs(
        status,
        showReason,
        showReasonDetail,
        reason,
        reasonRequired,
        reasonDetailRequired,
        noteRequired,
        adminRole,
      )}
      mapPropsToFields={(_props, createFormField) => ({
        type: createFormField({
          value: formValues.type,
        }),
        reason: createFormField({
          value: formValues.reason,
        }),
        reasonDetail: createFormField({
          value: formValues.reasonDetail,
        }),
        note: createFormField({
          value: formValues.note,
        }),
      })}
    />
  );
};

export default React.forwardRef(ChangeStatusForm);
