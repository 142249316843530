import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import * as constants from "containers/MemberProfile/constants";
import { LoadingScreen } from "@wellth/ui";
import { Card, Button } from "@wellth/web-ui";
import CARD_STYLES from "../styles";

const DEFAULT_PADDING: React.CSSProperties = {
  padding: "0.625rem",
};

const MIXPANEL_CONTAINER: React.CSSProperties = {
  ...DEFAULT_PADDING,
};

const PROP_BODY_STYLE: React.CSSProperties = {
  ...CARD_STYLES.body,
  padding: 25,
  justifyContent: "center",
};

const CARD_STYLE: React.CSSProperties = {
  ...CARD_STYLES.card,
  boxShadow: "none",
  margin: "1.25rem",
};

const NO_DATA: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  color: "#8c8c8c",
};

const ROW_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
};

const PROP_TEXT_STYLE: React.CSSProperties = {
  paddingRight: ".5rem",
  fontWeight: "normal",
};

interface MixpanelTimelineProps {
  loadingDeviceDetails: boolean;
  getProperties: () => void;
  deviceDetails?: DeviceDetailsType;
  isError: boolean;
}

type DeviceDetailsType = {
  id: string;
  wellthId: string;
  lastSeen: string;
  cameraPermission: string;
  notificationsPermission: string;
  locationPermission: string;
  appBuildNumber: string;
  appVersion: string;
  brand: string;
  carrier: string;
  manufacturer: string;
  model: string;
  os: string;
  osVersion: string;
};

const mixpanelProperties = (deviceDetails: DeviceDetailsType | null) =>
  !deviceDetails ? (
    <span style={NO_DATA}>No data</span>
  ) : (
    <>
      <>
        <div style={ROW_STYLE}>
          <span style={PROP_TEXT_STYLE}>Last Seen:</span>
          {moment(deviceDetails.lastSeen).fromNow() || constants.UNKNOWN}
        </div>
        <div style={ROW_STYLE}>
          <span style={PROP_TEXT_STYLE}>Device Brand:</span>
          {deviceDetails.brand || constants.UNKNOWN}
        </div>
        <div style={ROW_STYLE}>
          <span style={PROP_TEXT_STYLE}>Device Model:</span>
          {deviceDetails.model || constants.UNKNOWN}
        </div>
        <div style={ROW_STYLE}>
          <span style={PROP_TEXT_STYLE}>App Version:</span>
          {deviceDetails.appVersion || constants.UNKNOWN}
        </div>
        <div style={ROW_STYLE}>
          <span style={PROP_TEXT_STYLE}>App Build Number:</span>
          {deviceDetails.appBuildNumber || constants.UNKNOWN}
        </div>
      </>
      <div style={ROW_STYLE}>
        <span style={PROP_TEXT_STYLE}>Notification Permission:</span>
        {deviceDetails.notificationsPermission || constants.UNKNOWN}
      </div>
      <div style={ROW_STYLE}>
        <span style={PROP_TEXT_STYLE}>Camera Permission:</span>
        {deviceDetails.cameraPermission || constants.UNKNOWN}
      </div>
    </>
  );

const defaultData = <span style={NO_DATA}>Press button for data</span>;

const MixpanelTimeline: React.FC<MixpanelTimelineProps> = ({
  loadingDeviceDetails,
  deviceDetails,
  getProperties,
  isError,
}: MixpanelTimelineProps) => {
  const [propertiesData, setProperties] = useState<React.ReactNode>(
    defaultData,
  );

  useEffect(
    () => {
      if (loadingDeviceDetails && !isError) {
        setProperties(<LoadingScreen showLoadingIndicator />);
      } else if (isError) {
        setProperties("Error fetching data. Please try again.");
      } else {
        setProperties(mixpanelProperties(deviceDetails));
      }
    },
    [loadingDeviceDetails, deviceDetails, isError],
  );

  return (
    <div style={MIXPANEL_CONTAINER}>
      <Card
        title={
          <div style={CARD_STYLES.titleStyle}>
            Device Details
            <Button onClick={getProperties} type="primary">
              Get Data
            </Button>
          </div>
        }
        bodyStyle={PROP_BODY_STYLE}
        style={CARD_STYLE}
      >
        {propertiesData}
      </Card>
    </div>
  );
};

export default MixpanelTimeline;
