import React from "react";
import ManageBonusBehaviorSubmissionsUI from "components/ManageBonusBehaviorSubmissions";
import { message as alert } from "@wellth/web-ui";
import {
  useChangeBonusBehaviorSubmissionStatusMutation,
  useAllBonusBehaviorSubmissionPayloadsQuery,
} from "hooks/graphql";
import {
  BonusBehaviorSubmissionStatusValue,
  AllBonusBehaviorSubmissionPayloadsQuery,
} from "types/globalTypes";

interface MemberSubmissionContainerProps {
  routeToMember: (id: string) => void;
}

export const ManageBonusBehaviorSubmissions: React.FC<
  MemberSubmissionContainerProps
> = ({ routeToMember }) => {
  const {
    data: {
      allBonusBehaviorSubmissionPayloads: {
        edges: submissions = [],
        pageInfo: { endCursor = null, hasNextPage = true } = {},
      } = {},
    } = {},
    fetchMore,
  } = useAllBonusBehaviorSubmissionPayloadsQuery({
    variables: {
      statuses: [BonusBehaviorSubmissionStatusValue.UnderReview],
      first: 500,
    },
    notifyOnNetworkStatusChange: true,
    onError: ({ graphQLErrors }) =>
      graphQLErrors.forEach(({ message }) => {
        void alert.error(`Error fetching submissions: ${message}`, 10);
      }),
  });

  const [
    changeBonusBehaviorSubmissionStatus,
  ] = useChangeBonusBehaviorSubmissionStatusMutation({
    onCompleted: () => alert.success("Review submission was successful"),
    onError: ({ graphQLErrors }) =>
      graphQLErrors.forEach(({ message }) => {
        void alert.error(`Unable to submit: ${message}`, 10);
      }),
  });

  return submissions.length < 1 ? (
    <div>No submissions need reviewing</div>
  ) : (
    <ManageBonusBehaviorSubmissionsUI
      routeToMember={(id) => routeToMember(id)}
      submissions={submissions}
      onError={({ message }) => alert.error(message, 10)}
      disableLoadMore={!hasNextPage}
      loadMore={() => {
        void fetchMore({
          variables: {
            statuses: [BonusBehaviorSubmissionStatusValue.UnderReview],
            first: 2,
            after: endCursor,
          },
          updateQuery: (
            previousResult: AllBonusBehaviorSubmissionPayloadsQuery,
            { fetchMoreResult },
          ) => {
            if (!fetchMoreResult) return previousResult;
            return {
              ...previousResult,
              allBonusBehaviorSubmissionPayloads: {
                ...fetchMoreResult.allBonusBehaviorSubmissionPayloads,
                edges: [
                  ...previousResult.allBonusBehaviorSubmissionPayloads.edges,
                  ...fetchMoreResult.allBonusBehaviorSubmissionPayloads.edges,
                ],
              },
            };
          },
        });
      }}
      submitSubmissionReview={async ({
        note,
        approved,
        reason,
        id,
      }): Promise<void> => {
        const status = approved
          ? BonusBehaviorSubmissionStatusValue.Approved
          : BonusBehaviorSubmissionStatusValue.Rejected;
        await changeBonusBehaviorSubmissionStatus({
          variables: {
            input: {
              id,
              status,
              reason: approved ? "Submission Approved" : reason,
              note,
            },
          },
        });
      }}
    />
  );
};

export default ManageBonusBehaviorSubmissions;
