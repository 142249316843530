import React from "react";
import { pickBy, identity, first } from "lodash";
import moment from "moment-timezone";
import { LoadingScreen } from "@wellth/ui";
import { Enrollment, EnrollmentProps } from "containers/Enrollment";
import { useGetProspectByIdQuery } from "hooks/graphql";

export interface ProspectEnrollmentProps
  extends Pick<EnrollmentProps, "routeMemberProfile" | "adminRole"> {
  id: string;
}

export const ProspectEnrollment: React.FC<ProspectEnrollmentProps> = ({
  adminRole,
  id,
  routeMemberProfile,
}: ProspectEnrollmentProps) => {
  const {
    loading,
    data: {
      getProspectById: {
        externalId: customerId = null,
        siteName: site = null,
        person: {
          names = [],
          emailAddresses = [],
          addresses = [],
          primaryLanguage: language = null,
          primaryTimezone: timezone = null,
          birthDate: birthdate = null,
          phoneNumbers = [],
        } = {},
        program: { programCode = null } = {},
        activeIdentityTags = [],
      } = {},
    } = {},
  } = useGetProspectByIdQuery({
    fetchPolicy: "network-only",
    variables: { id },
  });

  if (loading) return <LoadingScreen showLoadingIndicator />;

  const prospectAddress = first(addresses);
  const prospectName = first(names);
  const prospectEmailAddress = first(emailAddresses);
  const prospectPhones = phoneNumbers.map((item) => item.phoneNumber);

  const {
    street: address = null,
    street2: address2 = null,
    city = null,
    state = null,
    zip = null,
  } = prospectAddress || {};

  const { firstName = null, lastName = null } = prospectName || {};
  const { emailAddress: email = null } = prospectEmailAddress || {};

  return (
    <Enrollment
      title="Enroll Prospect"
      adminRole={adminRole}
      memberProfile={{
        firstName,
        lastName,
        email,
        language,
        ...pickBy(
          {
            birthdate: moment(birthdate).format("MM/DD/YYYY"),
          },
          identity,
        ),
      }}
      mailingAddress={{
        street: address,
        street2: address2,
        city,
        state,
        zip,
      }}
      siteInformation={{
        customerId,
        site,
      }}
      programConfig={{
        timezone,
        programCode,
      }}
      routeMemberProfile={routeMemberProfile}
      prospectId={id}
      activeIdentityTags={activeIdentityTags}
      prospectPhones={prospectPhones}
    />
  );
};

export default ProspectEnrollment;
