"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.TitleRow = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _Row = require("../Row");
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/TitleRow/index.tsx";
var TitleRow = function TitleRow(_ref) {
  var title = _ref.title,
    titleLabelStyle = _ref.titleLabelStyle,
    children = _ref.children,
    props = (0, _objectWithoutProperties2["default"])(_ref, ["title", "titleLabelStyle", "children"]);
  return _react["default"].createElement(_Row.Row, (0, _extends2["default"])({}, props, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 16,
      columnNumber: 3
    }
  }), _react["default"].createElement(_reactNative.Text, {
    style: titleLabelStyle,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 17,
      columnNumber: 5
    }
  }, title), children);
};
exports.TitleRow = TitleRow;
var _default = TitleRow;
exports["default"] = _default;