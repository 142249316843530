import React from "react";
import { camelCaseToSentenceCase } from "@wellth/utils";
import { Card, Icon } from "@wellth/web-ui";
import { MemberSinceIcon } from "assets/memberSince";
import { GenderIcon } from "assets/genderIcon";
import { BirthdateIcon } from "assets/birthdateIcon";
import { ScoreIcon } from "assets/score";
import moment from "moment-timezone";
import { Score } from "types/globalTypes";
import CARD_STYLES from "../styles";

const SHARED_STYLES = {
  marginRight: "0.25rem",
};

const LIST_STYLES = {
  listStyle: "none",
  padding: 0,
  color: "#657786",
  fontFamily: "HelveticaNeue",
  fontSize: "0.875rem",
};

const LIST_ITEM_STYLES = {
  margin: "0.25rem 0",
};

const ADDRESS_STYLES = {
  marginLeft: "1.25rem",
  ...SHARED_STYLES,
};

const VERIFIED_STYLES = {
  color: "#199473",
};

const ENROLLED_BY_STYLES = {
  paddingLeft: "1.25rem",
};

const memberItemStyles = (isPrimary: boolean, styles?: React.CSSProperties) =>
  isPrimary ? { ...styles, ...VERIFIED_STYLES } : styles;

interface Email {
  emailAddress: string;
  isPrimary: boolean;
}

interface PhoneNumber {
  phoneNumber: string;
  isPrimary: boolean;
}

interface Address {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  isPrimary: boolean;
}

export interface PersonProfile {
  gender: string;
  birthdate: string;
  language: string;
  phoneNumbers: PhoneNumber[];
  emailAddresses: Email[];
  addresses: Address[];
  personSince?: string;
}

export interface PersonInformationCardProps {
  localTime: string;
  personProfile: PersonProfile;
  onInfoModalClick: () => void;
  type: "Member" | "Prospect";
  overallScore?: Partial<Score>;
  isSelfServe?: boolean;
  enroller?: string;
}

export const PersonInformationCard: React.FC<PersonInformationCardProps> = ({
  personProfile: {
    gender,
    birthdate,
    language,
    phoneNumbers,
    emailAddresses,
    addresses,
    personSince,
  },
  localTime,
  overallScore,
  onInfoModalClick,
  type,
  isSelfServe,
  enroller,
}: PersonInformationCardProps) => (
  <Card
    headStyle={CARD_STYLES.head}
    bodyStyle={CARD_STYLES.body}
    size="small"
    extra={<a onClick={() => onInfoModalClick()}>See all</a>}
    title="Information"
    style={CARD_STYLES.card}
  >
    <ul style={LIST_STYLES}>
      <li style={LIST_ITEM_STYLES}>
        <Icon style={SHARED_STYLES} type="global" />
        <span>{language}</span>
      </li>
      {overallScore && (
        <li>
          <Icon style={SHARED_STYLES} component={ScoreIcon} />
          <span>
            Score Overall: {overallScore.scoreValue} (last updated on{" "}
            {moment(overallScore.updatedAt).format("MM/DD/YYYY")})
          </span>
        </li>
      )}
      {gender && (
        <li style={LIST_ITEM_STYLES}>
          <Icon style={SHARED_STYLES} component={GenderIcon} />
          <span>{camelCaseToSentenceCase(gender)}</span>
        </li>
      )}
      <li style={LIST_ITEM_STYLES}>
        <Icon style={SHARED_STYLES} component={BirthdateIcon} />
        <span>{moment(birthdate).format("MM/DD/YYYY")}</span>
      </li>
      {localTime && (
        <li style={LIST_ITEM_STYLES}>
          <Icon style={SHARED_STYLES} type="clock-circle" />
          <span>{localTime}</span>
        </li>
      )}
      {personSince && (
        <li style={LIST_ITEM_STYLES}>
          <Icon style={SHARED_STYLES} component={MemberSinceIcon} />
          <span>
            {type} since {personSince}
          </span>
        </li>
      )}
      {type === "Member" ? (
        <li style={{ ...LIST_ITEM_STYLES, ...ENROLLED_BY_STYLES }}>
          <span>
            {isSelfServe ? "Self-enrolled" : `Enrolled by ${enroller}`}
          </span>
        </li>
      ) : null}
      {phoneNumbers.map(({ phoneNumber, isPrimary }, i) => (
        <li
          key={`phoneNumber:${i}`}
          style={memberItemStyles(isPrimary, LIST_ITEM_STYLES)}
        >
          <Icon
            style={memberItemStyles(isPrimary, SHARED_STYLES)}
            type="phone"
            theme="filled"
          />
          <span>{phoneNumber}</span>
        </li>
      ))}
      {emailAddresses.map(({ emailAddress, isPrimary }, i) => (
        <li
          key={`emailAddress:${i}`}
          style={memberItemStyles(isPrimary, LIST_ITEM_STYLES)}
        >
          <Icon
            style={memberItemStyles(isPrimary, SHARED_STYLES)}
            type="mail"
          />
          <span>{emailAddress}</span>
        </li>
      ))}
      {addresses.map(({ street, street2, city, state, zip, isPrimary }, i) => (
        <li style={LIST_ITEM_STYLES} key={`address:${i}`}>
          <Icon
            style={memberItemStyles(isPrimary, SHARED_STYLES)}
            type="environment"
            theme="filled"
          />
          <span style={memberItemStyles(isPrimary)}>{street}</span>
          <br />
          {street2 && (
            <>
              <span style={memberItemStyles(isPrimary, ADDRESS_STYLES)}>
                {street2}
              </span>{" "}
              <br />
            </>
          )}
          <span style={memberItemStyles(isPrimary, ADDRESS_STYLES)}>
            {city},
          </span>
          <span style={memberItemStyles(isPrimary, SHARED_STYLES)}>
            {state}
          </span>
          <span style={memberItemStyles(isPrimary, SHARED_STYLES)}>{zip}</span>
        </li>
      ))}
    </ul>
  </Card>
);

export default PersonInformationCard;
