import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/react-hooks";
import store from "constants/store";
import graphQLClient from "constants/graphQLClient";
import AppContainer from "containers/App";
import { datadogRum } from "@datadog/browser-rum";
import * as serviceWorker from "./serviceWorker";

const App: React.FC<{}> = () => {
  useEffect(() => {
    datadogRum.init({
      applicationId: "b14e9b75-870e-4950-9a88-4a905248d5a6",
      clientToken: "pub3b7d3e61458cd86f78df7d6cd2c1862a",
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: "datadoghq.com",
      service: "admin-wellth",
      env: process.env.BUILD_TYPE,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.npm_package_version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask",
      allowedTracingUrls: [
        /https:\/\/.*\.wellth-dev\.com/,
        /https:\/\/.*\.wellth-staging\.com/,
        /https:\/\/.*\.wellthapp\.com/,
      ],
    });
  });

  return (
    <Provider store={store}>
      <ApolloProvider client={graphQLClient}>
        <AppContainer />
      </ApolloProvider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
