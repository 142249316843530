import React from "react";
import { message } from "@wellth/web-ui";
import { CreateProspectForm, FormValues } from "components/CreateProspectForm";
import TitledBoxLayout from "components/TitledBoxLayout";
import { useAllProgramsQuery, useCreateProspectMutation } from "hooks/graphql";
import { AdminPrivilege } from "constants/adminPrivilege";
import { isAdmin } from "utils/roles";
import { normalizeFormValues } from "./utils";

const ERROR_MESSAGE = "Unable to create prospect";

interface CreateProspectProps {
  routeToProspectPage: (prospect) => void;
  title?: string;
  adminRole?: AdminPrivilege;
}

export default ({
  routeToProspectPage,
  title = "Create New Prospect",
  adminRole,
}: CreateProspectProps) => {
  const {
    data: { allPrograms: { nodes = [] } = {} } = {},
  } = useAllProgramsQuery();

  const [createProspect] = useCreateProspectMutation({
    ignoreResults: true,
    onCompleted: ({ createProspect: { prospect } }) =>
      routeToProspectPage(prospect),
    onError: ({ graphQLErrors }) =>
      graphQLErrors.forEach(({ message: errorMessage }) => {
        void message.error(`${ERROR_MESSAGE}: ${errorMessage}`);
      }),
  });

  return (
    <TitledBoxLayout title={title} secondaryTitle="Prospect">
      <CreateProspectForm
        adminRole={adminRole}
        programs={nodes.map(({ programCode, isTestProgram }) => ({
          title: programCode,
          value: programCode,
          disabled: !isTestProgram && isAdmin(adminRole),
        }))}
        handleSubmit={async (values: FormValues) => {
          await createProspect({
            variables: normalizeFormValues({
              ...values,
              country: "USA",
            }),
          });
        }}
      />
    </TitledBoxLayout>
  );
};
