import React from "react";

type Props = {
  isTestUser: boolean;
  handleTestUser?: (event?: any) => void;
};

const TEST_TAG_STYLES: React.CSSProperties = {
  color: "#fa541c",
  margin: "10px",
  marginTop: "10px",
  borderRadius: "2px",
  borderWidth: "1px",
  backgroundColor: "#fff2e8",
  borderStyle: "solid",
  width: "44px",
  height: "22px",
  fontSize: "14px",
  textAlign: "center",
  cursor: "pointer",
};

const TestTag = ({ isTestUser, handleTestUser }: Props) => (
  <>
    {isTestUser ? (
      <div style={TEST_TAG_STYLES} onClick={handleTestUser}>
        TEST
      </div>
    ) : (
      ""
    )}
  </>
);

export default TestTag;
