import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Button, Form, FormInputType } from "@wellth/web-ui";
import { replace } from "lodash";

const BUTTON_STYLE: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "1.5rem",
};

const MASK_INPUT_STYLE: React.CSSProperties = {
  height: "32px",
  width: "100%",
  border: "1px solid #d9d9d9",
  borderRadius: "4px",
};

const cleanInputValues = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== "") {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export interface AdvancedSearchFormValues {
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  shortId?: string;
  externalId?: string;
  phoneNumber?: string;
}

export interface ChangeFiltersFormRef {
  getFilters: () => Promise<any>;
}

export interface FiltersProps {
  submit: (values: AdvancedSearchFormValues) => void;
  searchValues: AdvancedSearchFormValues;
}

const FiltersFC: React.RefForwardingComponent<
  ChangeFiltersFormRef,
  FiltersProps
> = ({ submit, searchValues }, ref) => {
  const formRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getFilters: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));
  return (
    <Form
      wrappedComponentRef={formRef}
      mapPropsToFields={(props, createFormField) => ({
        firstName: createFormField({
          value: searchValues.firstName,
        }),
        lastName: createFormField({
          value: searchValues.lastName,
        }),
        birthDate: createFormField({
          value: searchValues.birthDate,
        }),
        shortId: createFormField({
          value: searchValues.shortId,
        }),
        externalId: createFormField({
          value: searchValues.externalId,
        }),
        phoneNumber: createFormField({
          value: searchValues.phoneNumber,
        }),
      })}
      inputs={[
        { type: FormInputType.Text, title: "First Name", key: "firstName" },
        { type: FormInputType.Text, title: "Last Name", key: "lastName" },
        {
          type: FormInputType.Mask,
          key: "birthDate",
          title: "Birth Date (MM/DD/YYYY)",
          mask: "99/99/9999",
          style: MASK_INPUT_STYLE,
        },
        { type: FormInputType.Text, title: "Short Id", key: "shortId" },
        { type: FormInputType.Text, title: "External Id", key: "externalId" },
        {
          type: FormInputType.Mask,
          title: "Phone Number",
          key: "phoneNumber",
          mask: "999-999-9999",
          style: MASK_INPUT_STYLE,
        },
      ]}
      submitButton={(props, form) => (
        <div style={BUTTON_STYLE}>
          <Button
            type="primary"
            onClick={() => {
              const inputValues = form.getFieldsValue();
              submit(
                cleanInputValues({
                  ...inputValues,
                  phoneNumber: replace(inputValues.phoneNumber, /-/g, ""),
                } as AdvancedSearchFormValues),
              );
            }}
          >
            Update List
          </Button>
        </div>
      )}
    />
  );
};

export const Filters = forwardRef(FiltersFC);
export default Filters;
