import React, { useState } from "react";
import Component from "components/Acquisition/SmsOutreach";
import { LoadingScreen } from "@wellth/ui";
import { message as alert } from "@wellth/web-ui";
import {
  useAllSmsOutreachMessagesQuery,
  useApproveSmsOutreachMessageMutation,
} from "hooks/graphql";

const LIMIT = 20;

const SmsOutreachBulkSender: React.FC = () => {
  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const {
    loading,
    fetchMore,
    data: { allSmsOutreachMessages: { totalCount = 0 } = {} } = {},
  } = useAllSmsOutreachMessagesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      offset: 0,
      first: LIMIT,
      condition: { approvedBy: null, isAutoApprove: false },
    },
    onCompleted: ({ allSmsOutreachMessages }) => {
      setMessages(allSmsOutreachMessages.edges);
    },
  });
  const [approveSmsMessage] = useApproveSmsOutreachMessageMutation({
    onCompleted: () => {
      setMessages(messages.slice(1));
    },
    onError: ({ graphQLErrors }) =>
      graphQLErrors.forEach(({ message: errorMessage }) => {
        void alert.error(`${errorMessage}`);
      }),
  });

  if (loading) return <LoadingScreen showLoadingIndicator />;

  return (
    <div>
      <Component
        pagination={{
          pageSize: LIMIT,
          total: totalCount,
          current: page,
        }}
        isAutoApprove={false}
        data={messages.map(
          ({
            node: { id, message, smsOutreachRecipientByRecipientId: recipient },
          }) => {
            const {
              lastName = null,
              firstName = null,
              phoneNumber = null,
              programByProgramId,
            } = recipient;

            const { programCode = null } = programByProgramId || {};

            return {
              id,
              message,
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              fullName: lastName ? `${firstName} ${lastName[0]}` : firstName,
              phoneNumber,
              programCode,
            };
          },
        )}
        onSendMessageClick={async (messageId: string) => {
          await approveSmsMessage({ variables: { messageId } });
        }}
        onPaginationChange={(p: number) => {
          setPage(p);
          void fetchMore({
            variables: { offset: (p - 1) * LIMIT, first: LIMIT },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              setMessages(fetchMoreResult.allSmsOutreachMessages.edges);
              return { ...fetchMoreResult };
            },
          });
        }}
      />
    </div>
  );
};

export default SmsOutreachBulkSender;
