const styleSheet: { [key: string]: React.CSSProperties } = {
  searchBar: {
    width: "100%",
  },
  highlight: {
    color: "#008d68",
    backgroundColor: "transparent",
    padding: 0,
    margin: 0,
  },
  boldText: {
    fontWeight: 600,
  },
  searchResult: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0.25rem",
  },
  statusTagContainer: {
    width: "13rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    alignSelf: "center",
    paddingRight: "0.25rem",
  },
  shortId: {
    alignSelf: "center",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 1.2,
    letterSpacing: -0.2,
    color: "#1f2933",
  },
  icon: {
    alignSelf: "center",
    marginRight: "0.1rem",
  },
  caretIconContainer: {
    width: "1rem",
    alignSelf: "center",
    paddingLeft: "0.5rem",
  },
  caretIcon: {
    opacity: "0.2",
  },
  statusContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "6rem",
  },
  statusContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  contactInformationContainer: {
    justifyContent: "flex-start",
    alignContent: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row",
  },
  contactInformation: {
    display: "flex",
    marginRight: 5,
    alignItems: "center",
    justifyContent: "center",
  },
};

export default styleSheet;
