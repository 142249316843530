import React from "react";
import capitalize from "lodash/capitalize";
import { Tag } from "@wellth/web-ui";
import COLORS from "utils/colors";

const FONT_STYLES: React.CSSProperties = {
  fontSize: 14,
  lineHeight: 1.43,
  letterSpacing: 0.14,
};

const PROGRAM_CODE_STYLES: React.CSSProperties = {
  color: COLORS.midGray,
  fontWeight: 500,
  textOverflow: "ellipsis",
  overflow: "hidden",
  ...FONT_STYLES,
};

const STATUS_STYLES: React.CSSProperties = {
  color: COLORS.black,
  fontWeight: 600,
  ...FONT_STYLES,
};

const TAG_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "2.125rem",
};

interface StatusTagProps {
  backgroundColor?: string;
  programCode: string;
  status: string;
  style?: React.CSSProperties;
}

export const StatusTag: React.FC<StatusTagProps> = ({
  style = {},
  backgroundColor = COLORS.lightGrayBlue,
  programCode,
  status,
}) => (
  <Tag
    closable={false}
    style={{ ...TAG_STYLES, ...style }}
    color={backgroundColor}
  >
    <span style={PROGRAM_CODE_STYLES}>{`${programCode}: `}</span>
    <span style={STATUS_STYLES}>{capitalize(status)}</span>
  </Tag>
);

export default StatusTag;
