import React from "react";

export const ContactIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#7B8794"
      fillRule="evenodd"
      d="M10 1.667A8.334 8.334 0 1 0 18.333 10 8.343 8.343 0 0 0 10 1.667zm4.26 12.43a.52.52 0 0 1-.44.243H6.18a.521.521 0 0 1-.47-.744 4.746 4.746 0 0 1 8.58 0 .52.52 0 0 1-.03.501zm-1.69-6.84a2.57 2.57 0 1 0-5.14 0 2.57 2.57 0 0 0 5.14 0z"
      clipRule="evenodd"
    />
  </svg>
);

export default ContactIcon;
