"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.BUTTON_SIZE = void 0;
var _reactNative = require("react-native");
var BUTTON_SIZE = 65;
exports.BUTTON_SIZE = BUTTON_SIZE;
var _default = _reactNative.StyleSheet.create({
  safeArea: {
    flex: 1
  },
  container: {
    flex: 1
  },
  submitButton: {
    position: "absolute",
    bottom: 18,
    right: 18
  }
});
exports["default"] = _default;