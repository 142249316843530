import React from "react";
import { EnrollmentForm, EnrollmentFormProps } from "components/EnrollmentForm";
import styles from "./styles";

export interface EnrollmentPageProps extends EnrollmentFormProps {
  title: string;
  loading: boolean;
  programTemplate: string;
}

export const EnrollmentPage: React.FC<EnrollmentPageProps> = ({
  title,
  loading,
  programConfig,
  programTemplate,
  memberProfile,
  ...enrollmentFormProps
}: EnrollmentPageProps) => (
  <div style={styles.container}>
    <h1 style={styles.title}>{title}</h1>

    {loading ? null : (
      <EnrollmentForm
        programConfig={{ timezone: "America/New_York", ...programConfig }}
        memberProfile={{ language: "EN", ...memberProfile }}
        programTemplate={programTemplate}
        {...enrollmentFormProps}
      />
    )}
  </div>
);

export default EnrollmentPage;
