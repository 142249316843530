"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatNumber = formatNumber;
exports.roundUp = exports.autoTextSize = exports.timeBetweenNow = exports.timeBetween = exports.formatLocalizedTime = exports.reminderDueTimeStringFromDate = exports.formatTime = exports.formatBirthdate = exports.formatDate = exports.TIME_FORMAT_STRING = exports.DATE_FORMAT_STRING = exports.formatCurrency = exports.startCase = exports.sentenceCase = exports.makeCancellable = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _utils = require("@wellth/utils");
require("moment-duration-format");
var _lodash = require("lodash");
var makeCancellable = function makeCancellable(promise) {
  var hasCancelled = false;
  var wrappedPromise = new Promise(function (resolve, reject) {
    promise.then(function (val) {
      return hasCancelled ? reject({
        isCancelled: true
      }) : resolve(val);
    }, function (error) {
      return hasCancelled ? reject({
        isCancelled: true
      }) : reject(error);
    });
  });
  return {
    promise: wrappedPromise,
    cancel: function cancel() {
      hasCancelled = true;
    }
  };
};
exports.makeCancellable = makeCancellable;
var ZERO = 0;
var ONE = 1;
var TWO = 2;
var sentenceCase = function sentenceCase(sentence) {
  return sentence.replace(/.+?[.?!](\s|$)/g, function (text) {
    return text.charAt(ZERO).toUpperCase() + text.substr(ONE);
  });
};
exports.sentenceCase = sentenceCase;
var startCase = function startCase(value) {
  return (0, _lodash.startCase)((0, _lodash.lowerCase)(value));
};
exports.startCase = startCase;
var POSITIVE_NUMBER = 1;
var NEGATIVE_NUMBER = -1;
var POSITIVE_ZERO = 0;
var NEGATIVE_ZERO = -0;
function formatNumber(value, positive, negative) {
  switch (Math.sign(value)) {
    case POSITIVE_NUMBER:
    case POSITIVE_ZERO:
      return positive(value);
    case NEGATIVE_NUMBER:
    case NEGATIVE_ZERO:
      return negative(value);
    default:
      throw new Error("Invalid number");
  }
}
var formatCurrency = function formatCurrency(amount) {
  var currencyString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "USD";
  var fractionDigits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : TWO;
  var currencySymbol = (0, _utils.currencySymbolForString)(currencyString);
  return formatNumber(amount, function (value) {
    return "".concat(currencySymbol).concat(Math.abs(value).toFixed(fractionDigits));
  }, function (value) {
    return "-".concat(currencySymbol).concat(Math.abs(value).toFixed(fractionDigits));
  });
};
exports.formatCurrency = formatCurrency;
var DATE_FORMAT_STRING = "MMMM Do YYYY";
exports.DATE_FORMAT_STRING = DATE_FORMAT_STRING;
var TIME_FORMAT_STRING = "h:mm A";
exports.TIME_FORMAT_STRING = TIME_FORMAT_STRING;
var formatDate = function formatDate(date, formatString) {
  return (0, _moment["default"])(date).format(formatString);
};
exports.formatDate = formatDate;
var formatBirthdate = function formatBirthdate(date) {
  return formatDate(date, DATE_FORMAT_STRING);
};
exports.formatBirthdate = formatBirthdate;
var formatTime = function formatTime(date) {
  return formatDate(date, TIME_FORMAT_STRING);
};
exports.formatTime = formatTime;
var reminderDueTimeStringFromDate = function reminderDueTimeStringFromDate(date) {
  var newDate = new Date(date);
  newDate.setSeconds(ZERO);
  return newDate.toTimeString().split(" ")[ZERO];
};
exports.reminderDueTimeStringFromDate = reminderDueTimeStringFromDate;
var formatLocalizedTime = function formatLocalizedTime(date) {
  return (0, _moment["default"])(date).format("LT");
};
exports.formatLocalizedTime = formatLocalizedTime;
var timeBetween = function timeBetween(date, reference) {
  var MS_IN_HOUR = 3600000;
  var MS_IN_MINUTE = 60000;
  var differenceMs = (0, _moment["default"])(date).diff((0, _moment["default"])(reference));
  var duration = _moment["default"].duration(differenceMs, "ms");
  if (differenceMs > MS_IN_HOUR) {
    return duration.format("h[h]mm[m]");
  }
  if (differenceMs <= MS_IN_MINUTE) {
    return "1m";
  }
  return duration.format("m[m]");
};
exports.timeBetween = timeBetween;
var timeBetweenNow = function timeBetweenNow(date) {
  return timeBetween(date, new Date());
};
exports.timeBetweenNow = timeBetweenNow;
var autoTextSize = function autoTextSize(screenHeight, screenWidth, modifier, maxFontSize, minFontSize) {
  var fontSize = screenWidth / modifier;
  if (fontSize < minFontSize) {
    return minFontSize;
  }
  if (fontSize > maxFontSize) {
    return maxFontSize;
  }
  return fontSize;
};
exports.autoTextSize = autoTextSize;
var roundUp = function roundUp(value) {
  return value ? Math.ceil(value) : ZERO;
};
exports.roundUp = roundUp;