import React from "react";
import ProgramInfoModal from "components/ProgramInfoFormModal";
import {
  useProgramByIdQuery,
  useProgramPaymentOptionsQuery,
} from "hooks/graphql";
import { Program } from "components/ProgramInfoForm";

export interface ProgramInfoFormModalProps {
  id: string;
  program: Program;
  goBack: () => void;
  routeEditProgram: () => void;
}

export const ProgramInfoFormModal: React.FC<ProgramInfoFormModalProps> = ({
  program: { id, programCode },
  goBack,
  routeEditProgram,
}: ProgramInfoFormModalProps) => {
  const {
    data: { programPaymentOptions = [] } = {},
    loading,
  } = useProgramPaymentOptionsQuery({
    variables: {
      condition: {
        programCode,
      },
    },
    fetchPolicy: "network-only",
  });

  const {
    data: {
      programById: {
        name,
        customerId,
        customerByCustomerId: { name: customerName } = {},
        programTemplate,
        sector,
        inactivateAfterPausedDays,
        pauseAfterNoActivityDays,
        expiry,
        rewardCardInfoUrl,
      } = {},
    } = {},
  } = useProgramByIdQuery({
    variables: { id },
  });

  return (
    <ProgramInfoModal
      id={id}
      title={`Program ${programCode} Info`}
      programCode={programCode}
      name={name}
      customerId={customerId}
      customerName={customerName}
      programTemplate={programTemplate}
      sector={sector}
      inactivateAfterPausedDays={inactivateAfterPausedDays}
      pauseAfterNoActivityDays={pauseAfterNoActivityDays}
      expiry={expiry}
      rewardCardInfoUrl={rewardCardInfoUrl}
      paymentOptions={programPaymentOptions.map(
        ({ __typename, ...option }) => ({ ...option }),
      )}
      paymentOptionsLoading={loading}
      goBack={goBack}
      routeEditProgram={routeEditProgram}
    />
  );
};

export default ProgramInfoFormModal;
