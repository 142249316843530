import React, { useRef } from "react";
import { Form, message as alert } from "@wellth/web-ui";
import { Modal } from "components/Modal";
import {
  useUpdateSmsOutreachMessageTemplateByIdMutation,
  AllSmsOutreachMessageTemplatesDocument as allSmsOutreachMessageTemplatesQuery,
} from "hooks/graphql";
import { formInputs } from "./constants";

export interface SmsTemplate {
  id: string;
  createdAt: string;
  message: string;
  title: string;
  updatedAt: string;
}

export interface EditSMSTemplateProps {
  goBack: () => void;
  template: SmsTemplate;
}

export default ({ goBack, template }: EditSMSTemplateProps) => {
  const editSmsTemplateRef = useRef(null);
  const { id, message: templateMessage, title: templateTitle } = template;
  const [
    updateSmsTemplate,
    { loading: submissionLoading },
  ] = useUpdateSmsOutreachMessageTemplateByIdMutation({
    onError: ({ message }) =>
      alert.error(`There was an error updating sms template: ${message}`),
    onCompleted: () => {
      void alert.success("The sms template was successfully updated!");
      goBack();
    },
  });

  return (
    <Modal
      okText="Submit"
      visible
      loading={false}
      submissionLoading={submissionLoading}
      title="Edit SMS Template"
      onOk={async () => {
        const {
          title,
          message,
        } = editSmsTemplateRef.current.props.form.getFieldsValue();

        await updateSmsTemplate({
          variables: {
            input: {
              id,
              smsOutreachMessageTemplatePatch: {
                title,
                message,
              },
            },
          },
          refetchQueries: [{ query: allSmsOutreachMessageTemplatesQuery }],
        });
      }}
      onCancel={goBack}
    >
      <Form
        wrappedComponentRef={editSmsTemplateRef}
        inputs={formInputs}
        mapPropsToFields={(_props, createFormField) => ({
          title: createFormField({
            value: templateTitle,
          }),
          message: createFormField({
            value: templateMessage,
          }),
          mergeTags: createFormField({
            value: (
              <span>
                <div>{"<firstName> = Member's First Name"}</div>
                <div>{"<lastName> = Member's Last Name"}</div>
              </span>
            ),
          }),
        })}
      />
    </Modal>
  );
};
