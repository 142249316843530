"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CircularTitleButton = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _CircularButton = require("../CircularButton");
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/CircularTitleButton/index.tsx";
var CircularTitleButton = function CircularTitleButton(_ref) {
  var title = _ref.title,
    baseProps = (0, _objectWithoutProperties2["default"])(_ref, ["title"]);
  return _react["default"].createElement(_CircularButton.CircularButton, (0, _extends2["default"])({}, baseProps, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 13,
      columnNumber: 3
    }
  }), _react["default"].createElement(_reactNative.Text, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 14,
      columnNumber: 5
    }
  }, title));
};
exports.CircularTitleButton = CircularTitleButton;
var _default = CircularTitleButton;
exports["default"] = _default;