import React, { useState } from "react";
import { Card, Button } from "@wellth/web-ui";
import { Select } from "antd";
import { welcomeMessage } from "containers/Enrollment/utils";
import { DownloadLinkSenderProps } from "containers/DownloadLinkSender";
import CARD_STYLES from "../styles";
import styles from "./styles";

const { Option } = Select;

interface DownloadLinkProps extends DownloadLinkSenderProps {
  sendMessage: (phoneNumber: string, message: string) => void;
}

export const DownloadLinkPage: React.FC<DownloadLinkProps> = ({
  phoneNumbers,
  programCode,
  language,
  firstName,
  sendMessage,
}) => {
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  return (
    <Card
      title="Send Download Link SMS"
      headStyle={CARD_STYLES.head}
      bodyStyle={CARD_STYLES.body}
      style={CARD_STYLES.card}
    >
      <Select
        showSearch
        placeholder="Select Phone Number"
        onSelect={(phoneNumber): void => {
          setSelectedPhoneNumber(phoneNumber);
        }}
        style={styles.select}
      >
        {phoneNumbers.map(({ phoneNumber }, index) => (
          <Option key={`${phoneNumber}-${index}`} value={phoneNumber}>
            {phoneNumber}
          </Option>
        ))}
      </Select>
      <div style={styles.buttonContainer}>
        <Button
          disabled={!selectedPhoneNumber}
          onClick={(): void => {
            sendMessage(
              selectedPhoneNumber,
              welcomeMessage(programCode, language, firstName),
            );
          }}
          type="primary"
        >
          Send SMS
        </Button>
      </div>
    </Card>
  );
};

export default DownloadLinkPage;
