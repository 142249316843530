import { useEffect, useState } from "react";

const useApiResult = (request): [unknown, string | null] => {
  const [results, setResults] = useState(null);
  const [error, setError] = <
    [string | null, React.Dispatch<React.SetStateAction<{}>>]
  >useState(null);

  const [url, options] = <[string, Record<string, string>]>request;
  useEffect(
    () => {
      fetch(url, options)
        .then(async (response) => {
          if (response.ok) {
            setResults(await response.json());
            setError(null);
          } else {
            setError(await response.text());
          }
        })
        .catch((err) => {
          setError(err.message as string);
        });
    },
    [url, options, setError, setResults],
  );

  return [results, error];
};

export default useApiResult;
