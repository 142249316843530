"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.LoadingScreen = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/LoadingScreen/index.tsx";
var LoadingScreen = function LoadingScreen(_ref) {
  var _ref$activityIndicato = _ref.activityIndicatorColor,
    activityIndicatorColor = _ref$activityIndicato === void 0 ? "gray" : _ref$activityIndicato,
    _ref$activityIndicato2 = _ref.activityIndicatorSize,
    activityIndicatorSize = _ref$activityIndicato2 === void 0 ? "large" : _ref$activityIndicato2,
    activityIndicatorStyle = _ref.activityIndicatorStyle,
    message = _ref.message,
    imageSource = _ref.imageSource,
    imageStyle = _ref.imageStyle,
    labelStyle = _ref.labelStyle,
    _ref$showLoadingIndic = _ref.showLoadingIndicator,
    showLoadingIndicator = _ref$showLoadingIndic === void 0 ? false : _ref$showLoadingIndic,
    style = _ref.style,
    props = (0, _objectWithoutProperties2["default"])(_ref, ["activityIndicatorColor", "activityIndicatorSize", "activityIndicatorStyle", "message", "imageSource", "imageStyle", "labelStyle", "showLoadingIndicator", "style"]);
  return _react["default"].createElement(_reactNative.View, (0, _extends2["default"])({}, props, {
    style: [_styles["default"].container, style],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 40,
      columnNumber: 3
    }
  }), message ? _react["default"].createElement(_reactNative.Text, {
    style: [_styles["default"].label, labelStyle],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 41,
      columnNumber: 16
    }
  }, message) : null, imageSource ? _react["default"].createElement(_reactNative.Image, {
    resizeMode: "center",
    style: imageStyle,
    source: imageSource,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 44,
      columnNumber: 7
    }
  }) : null, showLoadingIndicator ? _react["default"].createElement(_reactNative.ActivityIndicator, {
    style: [_styles["default"].activityIndicator, activityIndicatorStyle],
    size: activityIndicatorSize,
    color: activityIndicatorColor,
    animating: true,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 48,
      columnNumber: 7
    }
  }) : null);
};
exports.LoadingScreen = LoadingScreen;
var _default = LoadingScreen;
exports["default"] = _default;