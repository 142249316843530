import React from "react";
import { Modal, ModalProps } from "components/Modal";
import {
  AddressSuggestion,
  AddressSuggestionProps,
} from "components/AddressSuggestion";

export interface AddressSuggestionModalProps
  extends AddressSuggestionProps,
    Omit<ModalProps, "onCancel"> {
  goBack: () => void;
}

export const AddressSuggestionModal: React.FC<AddressSuggestionModalProps> = ({
  goBack,
  addressToVerify,
  suggestionAddress,
  submitAddress,
  note,
  isValid,
  ...modalProps
}) => (
  <Modal
    title="Validate Mailing Address"
    {...modalProps}
    onCancel={goBack}
    footer={null}
  >
    <AddressSuggestion
      note={note}
      isValid={isValid}
      addressToVerify={addressToVerify}
      suggestionAddress={suggestionAddress}
      submitAddress={submitAddress}
    />
  </Modal>
);

export default AddressSuggestionModal;
