import { AdminPrivilege } from "constants/adminPrivilege";

const isSuperAdmin = (role: AdminPrivilege): boolean =>
  role === AdminPrivilege.SuperAdmin;

const isAdmin = (role: AdminPrivilege): boolean =>
  role === AdminPrivilege.Admin;

const isAdminOrSuperAdmin = (role: AdminPrivilege): boolean =>
  isSuperAdmin(role) || isAdmin(role);

const isMemberEducator = (role: AdminPrivilege): boolean =>
  role === AdminPrivilege.MemberEducator;

const isCallCenterAdmin = (role: AdminPrivilege): boolean =>
  role === AdminPrivilege.CallCenterAgent;

export {
  isSuperAdmin,
  isAdmin,
  isAdminOrSuperAdmin,
  isMemberEducator,
  isCallCenterAdmin,
};
