"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CircularLoadingButton = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _CircularButton = require("../CircularButton");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/CircularLoadingButton/index.tsx";
var CircularLoadingButton = function CircularLoadingButton(_ref) {
  var _ref$loading = _ref.loading,
    loading = _ref$loading === void 0 ? false : _ref$loading,
    children = _ref.children,
    disabled = _ref.disabled,
    props = (0, _objectWithoutProperties2["default"])(_ref, ["loading", "children", "disabled"]);
  return _react["default"].createElement(_CircularButton.CircularButton, (0, _extends2["default"])({
    disabled: disabled || loading,
    backgroundColor: disabled ? _colors["default"].GREY_DARK2 : _colors["default"].WHITE
  }, props, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 16,
      columnNumber: 3
    }
  }), loading ? _react["default"].createElement(_reactNative.ActivityIndicator, {
    animating: true,
    color: "gray",
    size: "small",
    __source: {
      fileName: _jsxFileName,
      lineNumber: 22,
      columnNumber: 7
    }
  }) : children);
};
exports.CircularLoadingButton = CircularLoadingButton;
var _default = CircularLoadingButton;
exports["default"] = _default;