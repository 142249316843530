"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.useKeyboard = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _reactNative = require("react-native");
var emptyCoordinates = Object.freeze({
  screenX: 0,
  screenY: 0,
  width: 0,
  height: 0
});
var initialCoordinates = {
  start: emptyCoordinates,
  end: emptyCoordinates
};
var initialAnimation = {
  duration: 0,
  active: false,
  type: "show"
};
var useKeyboard = function useKeyboard() {
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    isShown = _useState2[0],
    setIsShown = _useState2[1];
  var _useState3 = (0, _react.useState)(0),
    _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
    height = _useState4[0],
    setHeight = _useState4[1];
  var _useState5 = (0, _react.useState)(initialAnimation),
    _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
    animation = _useState6[0],
    setAnimation = _useState6[1];
  var _useState7 = (0, _react.useState)(initialCoordinates),
    _useState8 = (0, _slicedToArray2["default"])(_useState7, 2),
    coordinates = _useState8[0],
    setCoordinates = _useState8[1];
  var keyboardWillShowHandler = (0, _react.useCallback)(function (_ref) {
    var startCoordinates = _ref.startCoordinates,
      endCoordinates = _ref.endCoordinates,
      duration = _ref.duration;
    setAnimation({
      duration: duration,
      active: true,
      type: "show"
    });
    setCoordinates({
      start: startCoordinates,
      end: endCoordinates
    });
  }, [setAnimation, setCoordinates]);
  var keyboardDidShowHandler = (0, _react.useCallback)(function (_ref2) {
    var startCoordinates = _ref2.startCoordinates,
      endCoordinates = _ref2.endCoordinates,
      duration = _ref2.duration;
    setIsShown(true);
    setAnimation({
      duration: duration,
      active: false,
      type: "show"
    });
    setCoordinates({
      start: startCoordinates,
      end: endCoordinates
    });
    setHeight(endCoordinates.height);
  }, [setIsShown, setAnimation, setCoordinates, setHeight]);
  var keyboardWillHideHandler = (0, _react.useCallback)(function (_ref3) {
    var startCoordinates = _ref3.startCoordinates,
      endCoordinates = _ref3.endCoordinates,
      duration = _ref3.duration;
    setAnimation({
      duration: duration,
      active: true,
      type: "hide"
    });
    setCoordinates({
      start: startCoordinates,
      end: endCoordinates
    });
  }, [setAnimation, setCoordinates]);
  var keyboardDidHideHandler = (0, _react.useCallback)(function (event) {
    setIsShown(false);
    if (event) {
      setAnimation({
        duration: event.duration,
        active: false,
        type: "hide"
      });
      setCoordinates({
        start: event.startCoordinates,
        end: event.endCoordinates
      });
    } else {
      setAnimation(initialAnimation);
      setCoordinates(initialCoordinates);
      setHeight(0);
    }
  }, [setIsShown, setAnimation, setCoordinates, setHeight]);
  (0, _react.useEffect)(function () {
    var keyboardWillShowListener = _reactNative.Keyboard.addListener("keyboardWillShow", keyboardWillShowHandler);
    var keyboardWillHideListener = _reactNative.Keyboard.addListener("keyboardWillHide", keyboardWillHideHandler);
    var keyboardDidShowListener = _reactNative.Keyboard.addListener("keyboardDidShow", keyboardDidShowHandler);
    var keyboardDidHideListener = _reactNative.Keyboard.addListener("keyboardDidHide", keyboardDidHideHandler);
    return function () {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, [keyboardWillShowHandler, keyboardWillHideHandler, keyboardDidShowHandler, keyboardDidHideHandler]);
  return {
    shown: isShown,
    animation: animation,
    coordinates: coordinates,
    height: height
  };
};
exports.useKeyboard = useKeyboard;
var _default = useKeyboard;
exports["default"] = _default;