import React from "react";

export const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#7B8794"
      d="M10 14.286c.777 0 1.406-.64 1.406-1.428 0-.789-.63-1.429-1.406-1.429-.777 0-1.406.64-1.406 1.429s.63 1.428 1.406 1.428z"
    />
    <path
      fill="#7B8794"
      d="M11.875 9.138V8.096a.64.64 0 0 0-.183-.45.62.62 0 0 0-.884 0 .64.64 0 0 0-.183.45v.952h-1.25v-.952a.64.64 0 0 0-.183-.45.62.62 0 0 0-.884 0 .64.64 0 0 0-.183.45v1.041a5.3 5.3 0 0 0-3.134 1.849 5.453 5.453 0 0 0-1.241 3.458v1.27a.96.96 0 0 0 .275.674.93.93 0 0 0 .662.279h10.625a.93.93 0 0 0 .663-.28.96.96 0 0 0 .275-.673v-1.27a5.453 5.453 0 0 0-1.24-3.458 5.3 5.3 0 0 0-3.135-1.848zM10 10.478c.463 0 .917.139 1.302.4.386.262.686.634.863 1.069a2.416 2.416 0 0 1-.508 2.595c-.328.333-.745.56-1.2.651a2.31 2.31 0 0 1-1.354-.136 2.352 2.352 0 0 1-1.052-.877 2.408 2.408 0 0 1 .293-3.005A2.329 2.329 0 0 1 10 10.477z"
    />
    <path
      fill="#7B8794"
      d="M15.688 3.918a27.952 27.952 0 0 0-11.376 0 2.307 2.307 0 0 0-1.28.793A2.37 2.37 0 0 0 2.5 6.134v1.27a1.61 1.61 0 0 0 .458 1.122c.293.298.69.465 1.104.465h1.875a1.541 1.541 0 0 0 1.105-.465A1.589 1.589 0 0 0 7.5 7.404v-.515c0-.04.015-.079.042-.108a.157.157 0 0 1 .103-.052 27.914 27.914 0 0 1 4.71 0 .162.162 0 0 1 .145.16v.515a1.61 1.61 0 0 0 .458 1.122c.293.298.69.465 1.104.465h1.876a1.54 1.54 0 0 0 1.104-.465 1.592 1.592 0 0 0 .458-1.122v-1.27a2.372 2.372 0 0 0-.533-1.423 2.307 2.307 0 0 0-1.28-.793z"
    />
  </svg>
);

export default PhoneIcon;
