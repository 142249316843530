"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.SquareButtonList = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _Button = require("./components/Button");
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/SquareButtonList/index.tsx";
var NUMBER_OF_COLUMNS = 2;
var SquareButtonList = function SquareButtonList(_ref) {
  var style = _ref.style,
    scrollEnabled = _ref.scrollEnabled,
    items = _ref.items;
  return _react["default"].createElement(_reactNative.FlatList, {
    style: style,
    contentContainerStyle: _styles["default"].listColumnContainer,
    numColumns: NUMBER_OF_COLUMNS,
    scrollEnabled: scrollEnabled,
    data: items,
    keyExtractor: function keyExtractor(item) {
      return item.title;
    },
    renderItem: function renderItem(_ref2) {
      var item = _ref2.item;
      return _react["default"].createElement(_Button.Button, (0, _extends2["default"])({}, item, {
        style: _styles["default"].button,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 28,
          columnNumber: 31
        }
      }));
    },
    __source: {
      fileName: _jsxFileName,
      lineNumber: 21,
      columnNumber: 3
    }
  });
};
exports.SquareButtonList = SquareButtonList;
var _default = SquareButtonList;
exports["default"] = _default;