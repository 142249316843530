"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.SingleRowSwitch = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _assertThisInitialized2 = _interopRequireDefault(require("@babel/runtime/helpers/assertThisInitialized"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _Row = require("../Row");
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/SingleRowSwitch/index.tsx";
function _createSuper(t) {
  var r = _isNativeReflectConstruct();
  return function () {
    var e,
      o = (0, _getPrototypeOf2["default"])(t);
    if (r) {
      var s = (0, _getPrototypeOf2["default"])(this).constructor;
      e = Reflect.construct(o, arguments, s);
    } else e = o.apply(this, arguments);
    return (0, _possibleConstructorReturn2["default"])(this, e);
  };
}
function _isNativeReflectConstruct() {
  try {
    var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
  } catch (t) {}
  return (_isNativeReflectConstruct = function _isNativeReflectConstruct() {
    return !!t;
  })();
}
var SingleRowSwitch = function (_React$Component) {
  (0, _inherits2["default"])(SingleRowSwitch, _React$Component);
  var _super = _createSuper(SingleRowSwitch);
  function SingleRowSwitch(props) {
    var _this;
    (0, _classCallCheck2["default"])(this, SingleRowSwitch);
    _this = _super.call(this, props);
    (0, _defineProperty2["default"])((0, _assertThisInitialized2["default"])(_this), "onChange", function (value) {
      _this.setState({
        value: value
      });
      var onChange = _this.props.onChange;
      onChange(value);
    });
    _this.state = {
      value: props.value
    };
    return _this;
  }
  (0, _createClass2["default"])(SingleRowSwitch, [{
    key: "UNSAFE_componentWillReceiveProps",
    value: function UNSAFE_componentWillReceiveProps(props) {
      this.setState({
        value: props.value
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        title = _this$props.title,
        detail = _this$props.detail;
      var value = this.state.value;
      return _react["default"].createElement(_Row.Row, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 47,
          columnNumber: 7
        }
      }, _react["default"].createElement(_reactNative.View, {
        style: _styles["default"].leftPortion,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 48,
          columnNumber: 9
        }
      }, _react["default"].createElement(_reactNative.Text, {
        style: _styles["default"].titleLabel,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 49,
          columnNumber: 11
        }
      }, title), detail ? _react["default"].createElement(_reactNative.Text, {
        style: _styles["default"].valueLabel,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 50,
          columnNumber: 21
        }
      }, detail) : null), _react["default"].createElement(_reactNative.View, {
        style: _styles["default"].rightPortion,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 53,
          columnNumber: 9
        }
      }, _react["default"].createElement(_reactNative.Switch, {
        value: value,
        onValueChange: this.onChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 54,
          columnNumber: 11
        }
      })));
    }
  }]);
  return SingleRowSwitch;
}(_react["default"].Component);
exports.SingleRowSwitch = SingleRowSwitch;
var _default = SingleRowSwitch;
exports["default"] = _default;