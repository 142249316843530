import React, { useState } from "react";
import { MemberInformationModal } from "components/MemberInformationModal";
import { Gender } from "types/globalTypes";
import {
  useUpdateMemberByIdMutation,
  useMemberInformationQuery,
  useDeleteNameMutation,
  useDeleteEmailAddressMutation,
  useDeleteMailingAddressMutation,
  useDeletePhoneNumberMutation,
  useUpdateNameMutation,
  useUpdateEmailAddressMutation,
  useUpdateMailingAddressMutation,
  useUpdatePhoneNumberMutation,
  MemberProgramExperienceDocument as memberProgramExperienceQuery,
  MemberNamesDocument as memberNamesQuery,
  MemberEmailAddressesDocument as memberEmailAddressesQuery,
  MemberMailingAddressesDocument as memberMailingAddressesQuery,
  MemberPhoneNumbersDocument as memberPhoneNumbersQuery,
  useGetPcpInfoQuery,
} from "hooks/graphql";
import { formatPhoneNumber } from "@wellth/utils";
import moment from "moment-timezone";
import { DATE_FORMAT } from "utils/date";
import { handlePhoneNumberError } from "containers/utils/phoneNumberUtils";
import { AdminPrivilege } from "constants/adminPrivilege";

export interface MemberInformationModalContainerProps {
  id: string;
  adminRole: AdminPrivilege;
  routeEditProfile: () => void;
  routeEditPhoneNumber: (phoneNumberId: string) => void;
  routeCreatePhoneNumber: () => void;
  routeEditName: (nameId: string) => void;
  routeCreateName: () => void;
  routeEditEmailAddress: (emailAddressId: string) => void;
  routeCreateEmailAddress: () => void;
  routeEditMailingAddress: (mailingAddressId: string) => void;
  routeCreateMailingAddress: () => void;
  goBack: () => void;
}

export const MemberInformationModalContainer: React.FC<
  MemberInformationModalContainerProps
> = ({
  goBack,
  id: memberId,
  routeEditName,
  routeEditProfile,
  routeEditEmailAddress,
  routeEditPhoneNumber,
  routeEditMailingAddress,
  routeCreateName,
  routeCreateEmailAddress,
  routeCreatePhoneNumber,
  routeCreateMailingAddress,
  adminRole,
}: MemberInformationModalContainerProps) => {
  const {
    data: {
      member: {
        siteName = "",
        externalId = "",
        isTestMember = false,
        isAutoPauseEnabled = true,
        latestTimezone: { timezoneName: timezone = null } = {},
        isReminderMessagingEnabled = false,
        eligibilityEndDate,
        medicaidId = "",
        wellthId = "",
        person: {
          addresses = [],
          names = [],
          phoneNumbers = [],
          emailAddresses = [],
          birthDate: birthdate = null,
          primaryLanguage: language = null,
          gender = Gender.Unknown,
          race = "",
          ethnicity = "",
          medicareId = "",
          coverageType = "",
          planName = "",
        } = {},
        program: { programTemplate = "" } = {},
      } = {},
    } = {},
    loading,
  } = useMemberInformationQuery({
    variables: {
      id: memberId,
    },
  });
  const {
    data: { getProspectById: { pcpName = "", pcpNumber = "" } = {} } = {},
  } = useGetPcpInfoQuery({
    variables: {
      id: wellthId,
    },
    skip: !wellthId,
  });

  const [memberLoading, setLoading] = useState(false);
  const [updateMember] = useUpdateMemberByIdMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: memberProgramExperienceQuery, variables: { id: memberId } },
    ],
  });

  const [deleteName] = useDeleteNameMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [{ query: memberNamesQuery, variables: { id: memberId } }],
  });

  const [deletePhoneNumber] = useDeletePhoneNumberMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: memberPhoneNumbersQuery, variables: { id: memberId } },
    ],
  });

  const [deleteEmailAddress] = useDeleteEmailAddressMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: memberEmailAddressesQuery, variables: { id: memberId } },
    ],
  });

  const [deleteMailingAddress] = useDeleteMailingAddressMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: memberMailingAddressesQuery, variables: { id: memberId } },
    ],
  });

  const [updateName] = useUpdateNameMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [{ query: memberNamesQuery, variables: { id: memberId } }],
  });

  const [updatePhoneNumber] = useUpdatePhoneNumberMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: memberPhoneNumbersQuery, variables: { id: memberId } },
    ],
  });

  const [updateEmailAddress] = useUpdateEmailAddressMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: memberEmailAddressesQuery, variables: { id: memberId } },
    ],
  });

  const [updateMailingAddress] = useUpdateMailingAddressMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: memberMailingAddressesQuery, variables: { id: memberId } },
    ],
  });

  return (
    <MemberInformationModal
      loading={!loading ? memberLoading : loading}
      names={{
        names: names || [],
        onEditName: ({ id }) => routeEditName(id),
        onCreateName: routeCreateName,
        onVerifyName: async ({ id }) => {
          setLoading(true);
          await updateName({
            variables: {
              input: {
                namePatch: {
                  id,
                  isPrimary: true,
                },
              },
            },
          });
        },
        onDeleteName: async ({ id }) => {
          setLoading(true);
          await deleteName({ variables: { id } });
        },
      }}
      profile={{
        siteName,
        externalId,
        timezone,
        gender,
        race,
        ethnicity,
        language,
        birthdate: moment(birthdate, DATE_FORMAT).toDate(),
        onEditButtonPressed: routeEditProfile,
        eligibilityEndDate,
        medicareId,
        medicaidId,
        programTemplate,
        coverageType,
        planName,
        pcpName,
        pcpNumber,
      }}
      phoneNumbers={{
        phoneNumbers: phoneNumbers || [],
        onEditPhoneNumber: ({ id }) => routeEditPhoneNumber(id),
        onCreatePhoneNumber: routeCreatePhoneNumber,
        onVerifyPhoneNumber: async ({ id, phoneNumber }) => {
          try {
            setLoading(true);
            await updatePhoneNumber({
              variables: {
                input: {
                  phoneNumberPatch: {
                    id,
                    phoneNumber: formatPhoneNumber(phoneNumber),
                    isPrimary: true,
                  },
                },
              },
            });
          } catch (err) {
            setLoading(false);
            const { message } = err as { message: string };
            handlePhoneNumberError(message);
          }
        },
        onDeletePhoneNumber: async ({ id }) => {
          setLoading(true);
          await deletePhoneNumber({ variables: { id } });
        },
      }}
      emailAddresses={{
        emailAddresses: emailAddresses || [],
        onEditEmailAddress: ({ id }) => routeEditEmailAddress(id),
        onCreateEmailAddress: routeCreateEmailAddress,
        onVerifyEmailAddress: async ({ id }) => {
          setLoading(true);
          await updateEmailAddress({
            variables: {
              input: {
                emailAddressPatch: {
                  id,
                  isPrimary: true,
                },
              },
            },
          });
        },
        onDeleteEmailAddress: async ({ id }) => {
          setLoading(true);
          await deleteEmailAddress({ variables: { id } });
        },
      }}
      mailingAddresses={{
        mailingAddresses: addresses || [],
        onEditMailingAddress: ({ id }) => routeEditMailingAddress(id),
        onCreateMailingAddress: routeCreateMailingAddress,
        onVerifyMailingAddress: async ({ id }) => {
          setLoading(true);
          await updateMailingAddress({
            variables: {
              input: {
                addressPatch: {
                  id,
                  isPrimary: true,
                },
              },
            },
          });
        },
        onDeleteMailingAddress: async ({ id }) => {
          setLoading(true);
          await deleteMailingAddress({ variables: { id } });
        },
      }}
      programExperience={{
        adminRole,
        isTestUser: isTestMember,
        isTestUserToggled: async (isTestMemberValue) => {
          await updateMember({
            variables: {
              id: memberId,
              memberPatch: {
                isTestMember: isTestMemberValue,
              },
            },
          });
        },
        isReminderMessagingEnabled,
        isReminderMessagingEnabledToggled: async (
          isReminderMessagingEnabledValue,
        ) => {
          await updateMember({
            variables: {
              id: memberId,
              memberPatch: {
                isReminderMessagingEnabled: isReminderMessagingEnabledValue,
              },
            },
          });
        },
        isAutoPauseEnabled,
        isAutoPauseEnabledToggled: async (isAutoPauseEnabledValue) => {
          await updateMember({
            variables: {
              id: memberId,
              memberPatch: {
                isAutoPauseEnabled: isAutoPauseEnabledValue,
              },
            },
          });
        },
      }}
      cancel={goBack}
    />
  );
};

export default MemberInformationModalContainer;
