import React from "react";
import { Button } from "@wellth/web-ui";

interface SubmitButtonProps {
  submit: () => void;
  disabled: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  submit,
  disabled = false,
}) => (
  <Button
    type="primary"
    loading={disabled}
    onClick={submit}
    disabled={disabled}
  >
    {disabled ? "Enrolling" : "Submit"}
  </Button>
);

export default SubmitButton;
