import React from "react";
import ChangePauseStatusModalUI from "components/ChangePauseStatusModal";
import {
  useSubmitPauseRequestMutation,
  useSubmitUnpauseRequestMutation,
  useCancelPauseRequestMutation,
  useCancelUnpauseRequestMutation,
  useMemberPauseStatusQuery,
  MemberPauseStatusDocument,
  MemberCalendarDocument,
} from "hooks/graphql";
import { PauseStatus } from "types/globalTypes";
import * as constants from "containers/MemberProfile/constants";

export interface ChangePauseStatusModalProps {
  goBack: () => void;
  id: string;
}

export const ChangePauseStatusModal: React.FC<ChangePauseStatusModalProps> = ({
  goBack,
  id,
}: ChangePauseStatusModalProps) => {
  const {
    data: { member: { pauseStatus = null } = {} } = {},
    loading,
  } = useMemberPauseStatusQuery({
    variables: {
      id,
    },
  });

  const mutationContext = {
    variables: {
      input: {
        memberId: id,
      },
    },
    context: { requireOnline: true },
    refetchQueries: [
      { query: MemberPauseStatusDocument, variables: { id } },
      {
        query: MemberCalendarDocument,
        variables: {
          id,
          totalStartDate: constants.totalStartDate,
          endDate: constants.endDate,
        },
      },
    ],
  };

  const [
    submitPauseRequest,
    { loading: pauseRequestLoading = false },
  ] = useSubmitPauseRequestMutation(mutationContext);
  const [
    cancelPauseRequest,
    { loading: cancelPauseRequestLoading = false },
  ] = useCancelPauseRequestMutation(mutationContext);
  const [
    submitUnpauseRequest,
    { loading: unpauseRequestLoading = false },
  ] = useSubmitUnpauseRequestMutation(mutationContext);
  const [
    cancelUnpauseRequest,
    { loading: cancelUnpauseRequestLoading = false },
  ] = useCancelUnpauseRequestMutation(mutationContext);

  return (
    <ChangePauseStatusModalUI
      pauseStatus={pauseStatus}
      loading={loading}
      submissionLoading={
        pauseRequestLoading ||
        cancelPauseRequestLoading ||
        unpauseRequestLoading ||
        cancelUnpauseRequestLoading
      }
      goBack={goBack}
      submit={async ({ reason, note } = {}) => {
        switch (pauseStatus) {
          case PauseStatus.Active:
            // eslint-disable-next-line no-case-declarations
            const payload = {
              ...mutationContext,
              variables: {
                input: { ...mutationContext.variables.input, reason, note },
              },
            };
            await submitPauseRequest(payload);
            break;
          case PauseStatus.Pausing:
            await cancelPauseRequest();
            break;
          case PauseStatus.Paused:
            await submitUnpauseRequest();
            break;
          case PauseStatus.Unpausing:
            await cancelUnpauseRequest();
            break;
          default:
            throw new Error("Invalid pause status provided.");
        }

        goBack();
      }}
    />
  );
};

export default ChangePauseStatusModal;
