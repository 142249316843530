import React from "react";
import EditEmailAddressModal from "components/EditEmailAddressFormModal";
import { ContactType } from "types/globalTypes";
import {
  useCreateEmailAddressMutation,
  MemberEmailAddressesDocument as memberEmailAddressesQuery,
  ProspectEmailAddressesDocument as prospectEmailAddressesQuery,
} from "hooks/graphql";

export interface CreateEmailAddressFormModalProps {
  contactId: string;
  contactType: ContactType;
  goBack: () => void;
}

export const CreateEmailAddressFormModal: React.FC<
  CreateEmailAddressFormModalProps
> = ({ goBack, contactId, contactType }: CreateEmailAddressFormModalProps) => {
  const [
    createEmailAddress,
    { loading: submissionLoading },
  ] = useCreateEmailAddressMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [
      contactType === ContactType.Member
        ? { query: memberEmailAddressesQuery, variables: { id: contactId } }
        : { query: prospectEmailAddressesQuery, variables: { id: contactId } },
    ],
  });

  return (
    <EditEmailAddressModal
      type={contactType}
      title="Create Email Address"
      cancel={goBack}
      submissionLoading={submissionLoading}
      submit={async ({ emailAddress, isPrimary = false }) => {
        await createEmailAddress({
          variables: {
            contactId,
            contactType,
            input: {
              emailAddress: {
                emailAddress,
                isPrimary,
              },
            },
          },
        });
      }}
    />
  );
};

export default CreateEmailAddressFormModal;
