import React from "react";

export const Checkmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <g fill="none" fillRule="nonzero">
      <ellipse cx="22.5" cy="23" fill="#FFF" rx="15.5" ry="15" />
      <path
        fill="#199473"
        d="M22 0C9.85 0 0 9.85 0 22s9.85 22 22 22 22-9.85 22-22C43.985 9.856 34.144.015 22 0zm11.758 18.121l-9.832 13.116a5.926 5.926 0 0 1-8.936.626l-5.076-5.077A3.385 3.385 0 0 1 14.703 22l3.012 3.012a1.692 1.692 0 0 0 2.552-.18l8.076-10.769a3.385 3.385 0 0 1 5.415 4.062v-.004z"
      />
    </g>
  </svg>
);

export default Checkmark;
