import React from "react";
import OtherActionsUI from "components/OtherActions";
import { PauseStatus } from "types/globalTypes";
import moment from "moment-timezone";

interface OtherActionsProps {
  pauseStatus: PauseStatus;
  reason?: string;
  startDate?: string;
  endDate?: string;
  totalPausedDays?: number;
  previousReason?: string;
  previousStartDate?: string;
  previousEndDate?: string;
  isRestfulMember: boolean;
  id: string;
  onButtonClick: () => void;
  routeEditPauseModal: () => void;
  canEditPause: boolean;
}

export const OtherActions: React.FC<OtherActionsProps> = ({
  pauseStatus,
  reason,
  startDate,
  endDate,
  totalPausedDays,
  previousReason,
  previousStartDate,
  previousEndDate,
  onButtonClick,
  routeEditPauseModal,
  canEditPause,
}: OtherActionsProps) => (
  <OtherActionsUI
    canEditPause={canEditPause}
    onButtonClick={onButtonClick}
    pauseStatus={pauseStatus}
    reason={reason}
    startDate={moment(startDate).format("ll")}
    endDate={endDate ? moment(endDate).format("ll") : endDate}
    totalPausedDays={totalPausedDays}
    previousReason={previousReason}
    previousStartDate={previousStartDate}
    previousEndDate={previousEndDate}
    routeEditPauseModal={routeEditPauseModal}
  />
);

export default OtherActions;
