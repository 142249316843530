import React from "react";
import moment from "moment-timezone";
import EditProfileModal from "components/EditProfileFormModal";
import {
  useMemberPersonProfileQuery,
  useUpdateMemberByIdMutation,
  PersonProfileDocument as personProfileQuery,
  useGetPcpInfoQuery,
} from "hooks/graphql";
import { IsoLanguage } from "types/globalTypes";
import { DATE_FORMAT } from "utils/date";
import isEmpty from "lodash/isEmpty";

export interface EditMemberProfileFormModalProps {
  id: string;
  goBack: () => void;
}

export const EditMemberProfileFormModal: React.FC<
  EditMemberProfileFormModalProps
> = ({ id, goBack }: EditMemberProfileFormModalProps) => {
  const {
    data: {
      member: {
        externalId = "",
        siteName = "",
        latestTimezone: { timezoneName: timezone } = { timezoneName: "" },
        eligibilityEndDate,
        medicaidId = "",
        wellthId,
        person: {
          primaryLanguage: language = IsoLanguage.En,
          birthDate: birthdate = "",
          ...person
        } = {},
        program: { programTemplate = "" } = {},
      } = {},
    } = {},
    loading,
  } = useMemberPersonProfileQuery({
    variables: {
      id,
    },
  });

  const {
    data: { getProspectById: { pcpName = "", pcpNumber = "" } = {} } = {},
  } = useGetPcpInfoQuery({
    variables: {
      id: wellthId,
    },
    skip: !wellthId,
  });

  const [
    updateMember,
    { loading: submissionLoading },
  ] = useUpdateMemberByIdMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: personProfileQuery, variables: { id } }],
  });

  return (
    <EditProfileModal
      contactType="member"
      loading={loading}
      submissionLoading={submissionLoading}
      cancel={goBack}
      language={language}
      timezone={timezone}
      birthdate={!isEmpty(birthdate) ? moment(birthdate, DATE_FORMAT) : null}
      externalId={externalId}
      siteName={siteName}
      eligibilityEndDate={
        !isEmpty(eligibilityEndDate)
          ? moment(eligibilityEndDate, DATE_FORMAT)
          : null
      }
      medicaidId={medicaidId}
      programTemplate={programTemplate}
      pcpName={pcpName}
      pcpNumber={pcpNumber}
      {...person}
      submit={async ({
        siteName: memberSiteName,
        externalId: memberExternalId,
        birthdate: memberBirthdate,
        language: primaryLanguage,
        localTime,
        timezone: memberTimezone,
        eligibilityEndDate: memberEligibilityEndDate,
        medicaidId: formMedicaidId,
        medicareId: formMedicareId,
        coverageType,
        planName,
        programTemplate: memberProgramTemplate,
        gender,
        race,
        ethnicity,
      }) =>
        updateMember({
          variables: {
            id,
            memberPatch: {
              eligibilityEndDate: memberEligibilityEndDate
                ? memberEligibilityEndDate.format(DATE_FORMAT)
                : null,
              siteName: memberSiteName,
              externalId: memberExternalId,
              person: {
                primaryLanguage,
                birthDate: memberBirthdate.format(DATE_FORMAT),
                primaryTimezone: memberTimezone,
                gender,
                race,
                ethnicity,
              },
            },
          },
        })
      }
    />
  );
};

export default EditMemberProfileFormModal;
