import React from "react";
import { Link } from "react-router-dom";
import { Column, SVG, GridRow, Icon, Dropdown } from "@wellth/web-ui";
import Menu from "antd/lib/menu";
import QuickSearch, { SearchProps } from "containers/QuickSearch";
import COLORS from "utils/colors";
import { AdminPrivilege } from "constants/adminPrivilege";
import {
  isAdminOrSuperAdmin,
  isCallCenterAdmin,
  isSuperAdmin,
} from "utils/roles";
import { Tooltip } from "antd";

const { SubMenu } = Menu;
const MENU_MODE = "horizontal";
const SUPER_ADMIN = "super_admin";

const FONT_STYLE: React.CSSProperties = {
  fontFamily: "HelveticaNeue",
  color: "black",
};

const WELLTH_LOGO_STYLE: React.CSSProperties = {
  cursor: "pointer",
};

const ROW_STYLES: React.CSSProperties = {
  width: "100%",
};

const HEADER_STYLES: React.CSSProperties = {
  height: "3.75rem",
  ...ROW_STYLES,
};

const CONTAINER_STYLES: React.CSSProperties = {
  padding: "0 1rem",
  marginBottom: "1rem",
  zIndex: 21,
};

const MENU_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

const MENU_STYLE: React.CSSProperties = {
  border: 0,
};

const LINK_CONTAINER_STYLE: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-evenly",
};

const LINK_STYLE = {
  fontSize: "0.875rem",
  fontWeight: 600,
  lineHeight: 1.43,
  cursor: "pointer",
  ":hover": {
    color: COLORS.midBlue,
  },
};

const LOGO_STYLE: React.CSSProperties = {
  width: "2.5rem",
  height: "2.5rem",
  background: COLORS.gray,
  borderRadius: "50%",
};

const MENU_ITEM_GROUP_STYLE: React.CSSProperties = {
  display: "flex",
};

const MENU_ITEM_ADMIN_GROUP_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "0.5rem",
};

const MENU_ITEM_ADMIN_NAME_STYLE: React.CSSProperties = {
  ...FONT_STYLE,
  fontWeight: 500,
  fontSize: "0.875rem",
};

const MENU_ITEM_ADMIN_EMAIL_STYLE: React.CSSProperties = {
  ...FONT_STYLE,
  fontSize: "0.75rem",
};

const SUB_MENU_STYLE: React.CSSProperties = {
  border: 0,
};

const COLUMN_FULL_WIDTH = 24;
const COLUMN_ONE_TWELFTH = 3;
const COLUMN_ONE_EIGHTH_WIDTH = 3;
const COLUMN_ONE_FOURTH_WIDTH = 6;
const COLUMN_HALF_WIDTH = 12;
const COLUMN_NO_WIDTH = 0;

const GRID_TYPE = "flex";
const GRID_ALIGN = "middle";
const GRID_JUSTIFY = "center";

const acquisitionOptions = (adminRole: AdminPrivilege) => (
  <Menu>
    {!isCallCenterAdmin(adminRole) ? (
      <Menu.Item>
        <Link to="/acquisition/sms-outreach">SMS Outreach</Link>
      </Menu.Item>
    ) : null}
    <Menu.Item>
      <Link to="/acquisition/sender">SMS One-Off Sender</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/acquisition/prospect-upload">Prospect Uploader</Link>
    </Menu.Item>
    {isAdminOrSuperAdmin(adminRole) ? (
      <Menu.Item>
        <Link to="/acquisition/create-prospect">Create Prospect</Link>
      </Menu.Item>
    ) : null}
  </Menu>
);

const manageOptions = (adminRole: AdminPrivilege) => (
  <Menu>
    <Menu.Item>
      <Link to="/manage/members">Manage Members</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/submissions/behaviors/review">Manage Submissions</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/manage/bonusBehaviors">Manage Bonus Behaviors</Link>
    </Menu.Item>
    {isAdminOrSuperAdmin(adminRole) ? (
      <Menu.Item>
        <Link to="/manage/programs">Manage Programs</Link>
      </Menu.Item>
    ) : null}
  </Menu>
);

export interface HeaderProps
  extends Pick<SearchProps, "routeMemberPage" | "routeProspectPage"> {
  signOut: () => void;
  email?: string;
  adminRole: AdminPrivilege;
  adminName: string;
  reindexPersons: () => void;
  routeRoot: () => void;
  routeEnroll: () => void;
  routeAdvancedSearch: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  email,
  adminRole,
  signOut,
  adminName,
  routeRoot,
  routeEnroll,
  routeMemberPage,
  routeProspectPage,
  reindexPersons,
  routeAdvancedSearch,
}) => (
  <div style={CONTAINER_STYLES}>
    <GridRow
      style={HEADER_STYLES}
      type={GRID_TYPE}
      align={GRID_ALIGN}
      justify={GRID_JUSTIFY}
    >
      <Column
        xs={COLUMN_NO_WIDTH}
        sm={COLUMN_NO_WIDTH}
        md={COLUMN_ONE_TWELFTH}
        lg={COLUMN_ONE_TWELFTH}
        xl={COLUMN_ONE_TWELFTH}
        key={1}
      >
        <span style={WELLTH_LOGO_STYLE} onClick={routeRoot}>
          <SVG name="wellthLogo" />
        </span>
      </Column>
      <Column
        xs={COLUMN_FULL_WIDTH}
        sm={COLUMN_FULL_WIDTH}
        md={COLUMN_HALF_WIDTH}
        lg={COLUMN_HALF_WIDTH}
        xl={COLUMN_HALF_WIDTH}
        key={2}
      >
        <QuickSearch
          routeMemberPage={routeMemberPage}
          routeProspectPage={routeProspectPage}
        />
      </Column>
      <Column
        xs={COLUMN_FULL_WIDTH}
        sm={COLUMN_FULL_WIDTH}
        md={COLUMN_ONE_FOURTH_WIDTH}
        lg={COLUMN_ONE_FOURTH_WIDTH}
        xl={COLUMN_ONE_FOURTH_WIDTH}
        key={3}
      >
        <div style={LINK_CONTAINER_STYLE}>
          <Tooltip title="Advanced Search Engine">
            <span style={LINK_STYLE} onClick={routeAdvancedSearch}>
              Avd. <Icon type="search" />
            </span>
          </Tooltip>
          <Dropdown overlay={acquisitionOptions(adminRole)}>
            <span key="smsLink" style={LINK_STYLE}>
              Acquire <Icon type="down" />
            </span>
          </Dropdown>
          {isSuperAdmin(adminRole) ? (
            <Tooltip title="Enroll Member">
              <span key="enroll" style={LINK_STYLE} onClick={routeEnroll}>
                Enroll
              </span>
            </Tooltip>
          ) : null}
          <Dropdown overlay={manageOptions(adminRole)}>
            <span key="manageCustomers" style={LINK_STYLE}>
              Manage <Icon type="down" />
            </span>
          </Dropdown>
        </div>
      </Column>
      <Column
        xs={COLUMN_NO_WIDTH}
        sm={COLUMN_NO_WIDTH}
        md={COLUMN_ONE_EIGHTH_WIDTH}
        lg={COLUMN_ONE_EIGHTH_WIDTH}
        xl={COLUMN_ONE_EIGHTH_WIDTH}
        key={4}
      >
        <div style={MENU_CONTAINER_STYLES}>
          <Menu style={MENU_STYLE} mode={MENU_MODE} selectable={false}>
            <SubMenu style={SUB_MENU_STYLE} title={<div style={LOGO_STYLE} />}>
              <div style={MENU_ITEM_GROUP_STYLE}>
                <div>
                  <div style={LOGO_STYLE} />
                </div>
                <div style={MENU_ITEM_ADMIN_GROUP_STYLE}>
                  <span style={MENU_ITEM_ADMIN_NAME_STYLE}>{adminName}</span>
                  <span style={MENU_ITEM_ADMIN_EMAIL_STYLE}>{email}</span>
                </div>
              </div>
              <Menu.Divider />
              {adminRole === SUPER_ADMIN ? (
                <Menu.Item key="setting:reindex">
                  <span style={LINK_STYLE} onClick={reindexPersons}>
                    Reindex Persons
                  </span>
                </Menu.Item>
              ) : null}
              {adminRole === SUPER_ADMIN ? <Menu.Divider /> : null}
              <Menu.Item
                key="signout"
                onClick={() => {
                  signOut();
                  routeRoot();
                }}
              >
                <span
                  style={LINK_STYLE}
                  key="signOut"
                  data-test-id="signOutButton"
                >
                  Sign Out
                </span>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </Column>
    </GridRow>
  </div>
);

export default Header;
