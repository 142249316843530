import React from "react";
import moment from "moment";
import { isEmpty, toNumber } from "lodash";
import { message as alert } from "@wellth/web-ui";
import EditProgramModal from "components/EditProgramFormModal";
import {
  AllProgramsDocument,
  useAllProgramTemplatesQuery,
  useUpdateProgrambyIdMutation,
} from "hooks/graphql";
import { DATE_FORMAT } from "utils/date";

type Program = {
  programCode: string;
  name: string;
  customerId: string;
  customerName: string;
  programTemplate: string;
  sector: string;
  inactivateAfterPausedDays: number;
  pauseAfterNoActivityDays: number;
  expiry: string;
  rewardCardInfoUrl: string;
  isProspectCreationSuppressed: boolean;
  isProspectReopeningSuppressed: boolean;
};

export interface EditProgramFormModalProps {
  id: string;
  program: Program;
  goBack: () => void;
}

export const EditProgramFormModal: React.FC<EditProgramFormModalProps> = ({
  id,
  program: {
    programCode,
    name,
    customerName,
    programTemplate,
    sector,
    inactivateAfterPausedDays,
    expiry,
    pauseAfterNoActivityDays,
    rewardCardInfoUrl,
    isProspectCreationSuppressed,
    isProspectReopeningSuppressed,
  },
  goBack,
}: EditProgramFormModalProps) => {
  const [updateProgram, { loading }] = useUpdateProgrambyIdMutation({
    onCompleted: goBack,
    onError: ({ message }) => alert.error(message),
    awaitRefetchQueries: true,
    refetchQueries: [{ query: AllProgramsDocument }],
  });
  const {
    data: { allProgramTemplates: { nodes: programTemplates = [] } = {} } = {},
    loading: programTemplateLoading,
  } = useAllProgramTemplatesQuery();

  return (
    <EditProgramModal
      submissionLoading={loading}
      programName={name}
      customerName={customerName}
      programTemplateLoading={programTemplateLoading}
      allProgramTemplates={programTemplates.map((template) => ({
        title: template.name,
        value: template.name,
      }))}
      programTemplate={programTemplate}
      sector={sector}
      inactivateAfterPausedDays={inactivateAfterPausedDays}
      pauseAfterNoActivityDays={pauseAfterNoActivityDays}
      rewardCardInfoUrl={rewardCardInfoUrl}
      isProspectCreationSuppressed={isProspectCreationSuppressed}
      isProspectReopeningSuppressed={isProspectReopeningSuppressed}
      expiry={expiry}
      title={`Edit Program ${programCode}`}
      submit={async (programValues) => {
        const programPatch = {
          name: programValues.programName,
          ...(programValues.programTemplate && {
            programTemplate: programValues.programTemplate,
          }),
          ...(programValues.inactivateAfterPausedDays && {
            inactivateAfterPausedDays: toNumber(
              programValues.inactivateAfterPausedDays,
            ),
          }),
          sector: !isEmpty(programValues.sector) ? programValues.sector : null,
          pauseAfterNoActivityDays:
            programValues.pauseAfterNoActivityDays ||
            programValues.pauseAfterNoActivityDays === 0
              ? toNumber(programValues.pauseAfterNoActivityDays)
              : null,
          expiry: moment(programValues.expiry)
            .startOf("d")
            .format(DATE_FORMAT),
          rewardCardInfoUrl: programValues.rewardCardInfoUrl
            ? programValues.rewardCardInfoUrl
            : null,
          isProspectCreationSuppressed:
            programValues.isProspectCreationSuppressed,
          isProspectReopeningSuppressed:
            programValues.isProspectReopeningSuppressed,
        };
        await updateProgram({ variables: { id, programPatch } });
      }}
      cancel={goBack}
    />
  );
};

export default EditProgramFormModal;
