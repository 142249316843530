import React from "react";
import SMSTemplate from "components/Acquisition/SmsTemplates";
import { Link } from "react-router-dom";
import { Typography } from "@wellth/web-ui";
import { useAllSmsOutreachMessageTemplatesQuery } from "hooks/graphql";

const { Text } = Typography;

const ACTION_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
};

const LINK_STYLE: React.CSSProperties = {
  marginRight: "6rem",
};

const actions = (template) => (
  <span style={ACTION_STYLE}>
    <span style={LINK_STYLE}>
      <Link
        to={{
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          pathname: `/acquisition/test/${template.id}`,
          state: {
            isModalOpen: true,
            template,
          },
        }}
      >
        Test
      </Link>
    </span>
    <span>
      <Link
        to={{
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          pathname: `/acquisition/edit/${template.id}`,
          state: {
            isModalOpen: true,
            template,
          },
        }}
      >
        Edit
      </Link>
    </span>
  </span>
);

const createTemplate = ({ history }) => {
  history.push({
    pathname: "/acquisition/create",
    state: {
      isModalOpen: true,
    },
  });
};

const makeTemplate = (title, sentence) => (
  <span>
    <Text strong>{title}</Text>
    <br />
    <Text>{sentence}</Text>
  </span>
);

const createData = (nodes) =>
  nodes.map((node) => ({
    ...node,
    template: makeTemplate(node.title, node.message),
    actions: actions(node),
  }));

export default (props) => {
  const {
    data: { allSmsOutreachMessageTemplates: { nodes = [] } = {} } = {},
  } = useAllSmsOutreachMessageTemplatesQuery();

  return (
    <div>
      <SMSTemplate
        data={createData(nodes)}
        onCreateTemplate={() => createTemplate(props)}
      />
    </div>
  );
};
