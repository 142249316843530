import React from "react";
import { StatusTag } from "components/StatusTag";
import TestTag from "components/TestTag";

const SHARED_STYLES: React.CSSProperties = {
  fontFamily: "HelveticaNeue",
  margin: 0,
};

const MAIN_HEADER_STYLES: React.CSSProperties = {
  fontSize: "2.125rem",
  fontWeight: "bold",
  color: "#1f2226",
  letterSpacing: "0.6px",
  ...SHARED_STYLES,
};

const SECONDARY_HEADER_STYLES: React.CSSProperties = {
  ...SHARED_STYLES,
  fontSize: "0.875rem",
  fontWeight: 500,
  color: "#53626c",
  marginBottom: "0.5rem",
};

const MAIN_HEADER_CONTAINER: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
};

interface Props {
  shortId: string;
  fullName: string;
  isTestMember: boolean;
  programCode: string;
  status: string;
  handleTestMember?: (event?: any) => void;
}

export default ({
  shortId,
  fullName,
  isTestMember,
  handleTestMember,
  programCode,
  status,
}: Props) => (
  <>
    <div style={MAIN_HEADER_CONTAINER}>
      <h3 style={MAIN_HEADER_STYLES}>{shortId}</h3>
      <TestTag isTestUser={isTestMember} handleTestUser={handleTestMember} />
    </div>
    <h5 style={SECONDARY_HEADER_STYLES}>{fullName}</h5>
    <StatusTag programCode={programCode} status={status} />
  </>
);
