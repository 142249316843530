import React from "react";
import {
  SectionedForm,
  DividerLayout,
  FormInputType,
  FormInputProps,
} from "@wellth/web-ui";
import programExperienceSection from "components/ProgramExperienceInputs";
import { AdminPrivilege } from "constants/adminPrivilege";
import { isSuperAdmin } from "utils/roles";
import namesSection, { NamesSectionProps } from "./sections/names";
import profileSection, { ProfileSectionProps } from "./sections/profile";
import emailAddressesSection, {
  EmailAddressesSectionProps,
} from "./sections/emailAddresses";
import phoneNumbersSection, {
  PhoneNumberSectionProps,
} from "./sections/phoneNumbers";
import mailingAddressesSection, {
  MailingAddressesSectionProps,
} from "./sections/mailingAddresses";
import { ProgramExperienceSectionProps } from "./types";

export interface MemberInformationFormConfiguration {
  names: NamesSectionProps;
  profile: ProfileSectionProps;
  emailAddresses: EmailAddressesSectionProps;
  phoneNumbers: PhoneNumberSectionProps;
  mailingAddresses: MailingAddressesSectionProps;
  programExperience: ProgramExperienceSectionProps;
}

const getProgramExperienceInputs = (
  isTestUser: boolean,
  adminRole: AdminPrivilege,
) =>
  [
    {
      type: FormInputType.Switch,
      title: "Is Test User?",
      key: "isTestUser",
      decoratorOptions: { valuePropName: "checked" },
      disabled: isTestUser,
    },
    {
      type: FormInputType.Switch,
      title: "Reminder Messaging Enabled?",
      key: "isReminderMessagingEnabled",
      decoratorOptions: { valuePropName: "checked" },
    },
    {
      type: FormInputType.Switch,
      title: "Auto Pause Enabled?",
      key: "isAutoPauseEnabled",
      decoratorOptions: { valuePropName: "checked" },
    },
  ].filter(({ key }) => {
    if (isSuperAdmin(adminRole)) {
      return true;
    }
    return key !== "isTestUser";
  }) as FormInputProps[];

export type MemberInformationFormProps = MemberInformationFormConfiguration;

export const MemberInformationForm = ({
  names,
  profile,
  emailAddresses,
  phoneNumbers,
  mailingAddresses,
  programExperience,
}: MemberInformationFormProps) => (
  <SectionedForm
    container={DividerLayout}
    sections={[
      namesSection({
        ...names,
      }),
      profileSection({
        ...profile,
      }),
      phoneNumbersSection({
        ...phoneNumbers,
      }),
      emailAddressesSection({
        ...emailAddresses,
      }),
      mailingAddressesSection({
        ...mailingAddresses,
      }),
      programExperienceSection({
        ...programExperience,
        data: {
          mapPropsToFields: (props, createFormField) => ({
            isTestUser: createFormField({
              value: programExperience.isTestUser,
            }),
            isReminderMessagingEnabled: createFormField({
              value: programExperience.isReminderMessagingEnabled,
            }),
            isAutoPauseEnabled: createFormField({
              value: programExperience.isAutoPauseEnabled,
            }),
          }),
          inputs: getProgramExperienceInputs(
            programExperience.isTestUser,
            programExperience.adminRole,
          ),
          onValuesChange: (
            changedValues,
            // eslint-disable-next-line @typescript-eslint/no-shadow
            { isTestUser, isReminderMessagingEnabled, isAutoPauseEnabled }: any,
          ) => {
            if ("isTestUser" in changedValues) {
              programExperience.isTestUserToggled(isTestUser);
            } else if ("isReminderMessagingEnabled" in changedValues) {
              programExperience.isReminderMessagingEnabledToggled(
                isReminderMessagingEnabled,
              );
            } else if ("isAutoPauseEnabled" in changedValues) {
              programExperience.isAutoPauseEnabledToggled(isAutoPauseEnabled);
            }
          },
          labelCol: {
            sm: 9,
          },
        },
      }),
    ]}
  />
);

export default MemberInformationForm;
