import base64 from "base-64";
import moment from "moment-timezone";
import _ from "lodash";
import createOfflineClient, { WrappedError } from "@wellth/networking";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import store from "constants/store";
import errorAction from "actions/apolloError";
import setSessionToken from "actions/sessionTokenSet";
import clearSession from "actions/sessionClear";
import sessionToken from "selectors/sessionToken";
import refreshToken from "selectors/refreshToken";
import uri from "constants/wellthAPIURI";
import { ReauthenticatePatientDocument as reauthenticatePatientMutation } from "hooks/graphql";

const tokenInvalid = () => store.dispatch(clearSession());

const TokenInvalidationLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  const { requireAuth = true, headers } = context;

  if (requireAuth && (headers === null || headers === undefined)) {
    tokenInvalid();
    throw new Error("Token Expired");
  }

  return forward(operation);
});

const client = createOfflineClient({
  debugLogEnabled: true,
  enableLogging: true,
  disableOffline: true,
  name: "DASHBOARD",
  networkLink: ApolloLink.from([
    TokenInvalidationLink,
    createUploadLink({ uri }),
  ]),
  headerFormat: (token) => `Bearer ${token}`,
  sessionToken: () => sessionToken(store.getState()),
  refreshToken: () => refreshToken(store.getState()),
  expired: (token: string) =>
    moment() >
    _.chain(token.split("."))
      .dropRight()
      .map((component) => base64.decode(component))
      .map((decodedToken) => JSON.parse(decodedToken))
      .filter("exp")
      .map((value) => moment.unix(value.exp))
      .first()
      .value(),
  setSessionToken: (token) => store.dispatch(setSessionToken(token)),
  tokenInvalid,
  error: {
    handler: (networkError: WrappedError) =>
      store.dispatch(errorAction(networkError)),
  },
  reauthenticate: async (token: string) => {
    try {
      const {
        data: {
          reauthenticatePatient: { jwtToken },
        },
      } = await client.mutate<{ reauthenticatePatient: { jwtToken: string } }>({
        mutation: reauthenticatePatientMutation,
        variables: {
          token,
        },
        context: {
          requireAuth: false,
          requireOnline: true,
        },
      });

      return jwtToken;
    } catch (error) {
      store.dispatch(errorAction(error));
      return null;
    }
  },
});

export default client;
