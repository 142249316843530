import React, { useState } from "react";
import Component from "components/Acquisition/SmsOutreach";
import { LoadingScreen } from "@wellth/ui";
import { useAllSmsOutreachMessagesQuery } from "hooks/graphql";
import moment from "moment-timezone";

const LIMIT = 20;

const SmsOutreachBulkSender: React.FC = () => {
  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const {
    loading,
    fetchMore,
    data: { allSmsOutreachMessages: { totalCount = 0 } = {} } = {},
  } = useAllSmsOutreachMessagesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      offset: 0,
      first: LIMIT,
      condition: { approvedBy: null, isAutoApprove: true },
    },
    onCompleted: ({ allSmsOutreachMessages }) => {
      setMessages(allSmsOutreachMessages.edges);
    },
  });

  if (loading) return <LoadingScreen showLoadingIndicator />;

  return (
    <div>
      <Component
        pagination={{
          pageSize: LIMIT,
          total: totalCount,
          current: page,
        }}
        isAutoApprove
        data={messages.map(
          ({
            node: {
              id,
              message,
              smsOutreachRecipientByRecipientId: recipient,
              approveAt,
            },
          }) => {
            const {
              lastName = null,
              firstName = null,
              phoneNumber = null,
              programByProgramId,
            } = recipient;

            const { programCode = null } = programByProgramId || {};

            return {
              id,
              message,
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              fullName: lastName ? `${firstName} ${lastName[0]}` : firstName,
              phoneNumber,
              programCode,
              approveAt: moment
                .tz(approveAt, "America/Los_Angeles")
                .format("LLLL"),
            };
          },
        )}
        onPaginationChange={(p: number) => {
          setPage(p);
          void fetchMore({
            variables: { offset: (p - 1) * LIMIT, first: LIMIT },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              setMessages(fetchMoreResult.allSmsOutreachMessages.edges);
              return { ...fetchMoreResult };
            },
          });
        }}
      />
    </div>
  );
};

export default SmsOutreachBulkSender;
