import React, { useState } from "react";
import moment from "moment-timezone";
import { TIME_FORMAT } from "utils/date";
import { Modal } from "components/Modal";
import ReviewAppointmentModal from "components/ReviewAppointmentModalContent";
import { message as alert, Tabs } from "@wellth/web-ui";
import { AppointmentType } from "containers/MemberProfile/components/TaskCalendar";
import { AppointmentStatusType } from "types/globalTypes";
import { useCreditAppointmentMutation } from "hooks/graphql";

import { MEMBER_PROFILE_REFETCH } from "utils";
import CreditTaskCard from "components/CreditTaskCard";

const { TabPane } = Tabs;

interface ReviewAppointmentContainerProps {
  goBack: () => void;
  id: string;
  calendarData: AppointmentType;
  timezone: string;
}

const ReviewAppointmentContainer: React.FC<ReviewAppointmentContainerProps> = ({
  id,
  timezone,
  calendarData: {
    appointmentId,
    dueTime,
    status,
    type,
    location: { name },
    allowCredit,
  },
  goBack,
}) => {
  const [loading, setLoading] = useState(false);
  const requestContext = {
    onCompleted: goBack,
    context: { requireOnline: true },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message }) => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        void alert.error(`Error updating appointment: ${message}`);
      });
    },
    awaitRefetchQueries: true,
    refetchQueries: MEMBER_PROFILE_REFETCH(id),
  };
  const [creditAppointment] = useCreditAppointmentMutation({
    ...requestContext,
  });
  return (
    <Modal
      title={`Appointment at ${moment
        .tz(dueTime, timezone)
        .format(TIME_FORMAT)} ${moment
        .tz(timezone || moment.tz.guess())
        .format("z")}`}
      loading={false}
      visible
      onCancel={goBack}
      onOk={goBack}
      footer={null}
      width={700}
    >
      <Tabs defaultActiveKey="taskInfo" size="small" tabBarGutter={0}>
        <TabPane tab="Task Info" key="taskInfo">
          <ReviewAppointmentModal
            status={(status || AppointmentStatusType.Scheduled).toUpperCase()}
            type={type}
            locationName={name}
          />
        </TabPane>
        <TabPane
          tab="Credit Appointment"
          key="creditAppointment"
          disabled={!allowCredit}
        >
          <CreditTaskCard
            loading={loading}
            creditTask={async (reason) => {
              setLoading(true);
              await creditAppointment({
                variables: {
                  input: { id: appointmentId, reason },
                },
              });
              setLoading(false);
            }}
            type="appointment"
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ReviewAppointmentContainer;
