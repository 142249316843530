import React from "react";
import EditPhoneNumberModal from "components/EditPhoneNumberFormModal";
import { ContactType } from "types/globalTypes";
import {
  useEditPhoneNumberQuery,
  useUpdatePhoneNumberMutation,
  MemberPhoneNumbersDocument as memberPhoneNumbersQuery,
  ProspectPhoneNumbersDocument as prospectPhoneNumbersQuery,
} from "hooks/graphql";
import { formatPhoneNumber } from "@wellth/utils";
import { handlePhoneNumberError } from "containers/utils/phoneNumberUtils";

export interface EditPhoneNumberFormModalProps {
  id: string;
  contactId: string;
  contactType: ContactType;
  goBack: () => void;
}

export const EditPhoneNumberFormModal: React.FC<
  EditPhoneNumberFormModalProps
> = ({ id, contactId, contactType, goBack }: EditPhoneNumberFormModalProps) => {
  const {
    loading,
    data: { phoneNumber: { isPrimary = false, phoneNumber = "" } = {} } = {},
  } = useEditPhoneNumberQuery({
    variables: {
      phoneNumberId: id,
    },
  });

  const [
    updatePhoneNumber,
    { loading: submissionLoading },
  ] = useUpdatePhoneNumberMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [
      contactType === ContactType.Member
        ? { query: memberPhoneNumbersQuery, variables: { id: contactId } }
        : { query: prospectPhoneNumbersQuery, variables: { id: contactId } },
    ],
  });

  return (
    <EditPhoneNumberModal
      type={contactType}
      title="Update Phone Number"
      isPrimary={isPrimary}
      phoneNumber={phoneNumber}
      loading={loading}
      submissionLoading={submissionLoading}
      cancel={goBack}
      disablePrimary={contactType === ContactType.Prospect}
      submit={async ({ phoneNumber: contactPhoneNumber, ...rest }) => {
        try {
          await updatePhoneNumber({
            variables: {
              input: {
                phoneNumberPatch: {
                  id,
                  phoneNumber: formatPhoneNumber(contactPhoneNumber),
                  ...rest,
                },
              },
            },
          });
        } catch (err) {
          const { message } = err as { message: string };
          handlePhoneNumberError(message);
        }
      }}
    />
  );
};

export default EditPhoneNumberFormModal;
