import React from "react";
import { isEmpty } from "lodash";
import { Card, CardTabListType } from "@wellth/web-ui";
import CARD_STYLES from "../styles";

const SHARED_SYTLES: React.CSSProperties = {
  padding: 0,
};

const HEAD_STYLE: React.CSSProperties = {
  ...SHARED_SYTLES,
};

const BODY_STYLE: React.CSSProperties = {
  maxHeight: "61.25rem",
  display: "block",
  overflow: "auto",
  ...SHARED_SYTLES,
};

const CONTAINER_STYLE: React.CSSProperties = {
  ...CARD_STYLES.card,
  padding: 10,
  fontFamily: "HelveticaNeue",
  color: "#1f2226",
  fontWeight: "bold",
};

interface TabCardProps {
  tabs: CardTabListType[];
  children: (tab: CardTabListType) => JSX.Element;
  activeIndex: number;
}

const TabCard: React.FC<TabCardProps> = ({
  tabs,
  children,
  activeIndex: activeIndexProp,
}: TabCardProps) => {
  let activeIndex = activeIndexProp;

  if (activeIndex < 0 || activeIndex > tabs.length - 1) {
    throw new Error("Invalid active index");
  }
  if (isEmpty(tabs) || (!isEmpty(tabs) && !activeIndex)) {
    activeIndex = 0;
  }
  const [activeTab, setActiveTab] = React.useState<CardTabListType>(
    tabs.length > 0 ? tabs[activeIndex] : null,
  );
  return (
    <Card
      headStyle={HEAD_STYLE}
      bodyStyle={BODY_STYLE}
      style={CONTAINER_STYLE}
      tabList={tabs}
      onTabChange={(key: string) => {
        setActiveTab(tabs.find(({ key: tabKey }) => tabKey === key));
      }}
    >
      {activeTab ? children(activeTab) : null}
    </Card>
  );
};

export default TabCard;
