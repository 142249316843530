import React, { useState, useEffect, Key } from "react";
import Radium from "radium";
import {
  QuickSearch,
  KeydownObserver,
  Option,
  Icon,
  CustomIconComponentProps,
} from "@wellth/web-ui";
import Highlighter from "react-highlight-words";
import { debounce, isEmpty } from "lodash";
import { StatusTag } from "components/StatusTag";
import { Maybe, IndividualType } from "types/globalTypes";
import formatName, { Name } from "utils/formatName";
import { ContactIcon } from "assets/contactIcon";
import { PhoneIcon } from "assets/phoneIcon";
import { LocationPinIcon } from "assets/locationPinIcon";
import { ExternalIdIcon } from "assets/externalId";
import { BirthdateIcon } from "assets/birthdateIcon";
import { PhoneNumber } from "components/ProspectInformationForm/types";
import { formatPhoneNumberToDisplayString } from "utils/phoneNumber";
import moment from "moment-timezone";
import styles from "./styles";

const highlightStyle = { ...styles.highlight, ...styles.boldText };

const FORWARD_SLASH_KEY_CODE = 191;

interface IconType {
  icon: "search" | "loading";
  spin: boolean;
  iconClass?: Maybe<"certain-category-icon">;
}

const SearchIcon: IconType = {
  icon: "search",
  spin: false,
  iconClass: "certain-category-icon",
};

const LoadingIcon: IconType = {
  icon: "loading",
  spin: true,
};

interface ContactInformationProps {
  key: Key;
  icon: React.ComponentType<CustomIconComponentProps>;
  searchTerm: string;
  text: string;
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  key,
  icon,
  searchTerm,
  text,
}: ContactInformationProps) => (
  <div key={key} style={styles.contactInformation}>
    <Icon style={styles.icon} component={icon} />
    <Highlighter
      highlightStyle={highlightStyle}
      searchWords={[searchTerm]}
      textToHighlight={text}
    />
  </div>
);

export interface SearchResult {
  id: string;
  individualType: IndividualType;
  names: Name[];
  phoneNumbers: PhoneNumber[];
  shortId: string;
  programCode: string;
  status: string;
  location: string;
  externalId: string;
  birthDate: string;
}

export type SearchResults = SearchResult[];

export interface SearchProps {
  searchKey?: string;
  loading?: boolean;
  debounce?: number;
  searchResults: SearchResult[];
  onSearch: (searchKey: string) => void;
  routeSearchResult: (individualType: IndividualType, id: string) => void;
}

function setStatusTagWidth(programCodeLength: number) {
  if (programCodeLength >= 10) {
    return "100%";
  }
  if (programCodeLength >= 7) {
    return "10rem";
  }
  return "7.75rem";
}

export const Search: React.FC<SearchProps> = ({
  loading = false,
  searchKey = "",
  searchResults = [],
  debounce: debounceMs = 300,
  onSearch,
  routeSearchResult,
}: SearchProps) => {
  const [shouldFocusInput, setShouldFocusInput] = useState(false);
  const [{ icon, spin, iconClass }, setIcon] = useState<IconType>(SearchIcon);
  const [highlightValue, setHighlightValue] = useState(searchKey);

  useEffect(() => (loading ? setIcon(LoadingIcon) : setIcon(SearchIcon)), [
    loading,
  ]);

  const handleOnSearch = debounce((key: string) => {
    setHighlightValue(key);

    if (!isEmpty(key)) {
      onSearch(key);
    }
  }, debounceMs);

  const dataSource = isEmpty(searchResults)
    ? [<Option key="noResults">No results found</Option>]
    : searchResults.map(
        ({
          id,
          names,
          phoneNumbers,
          individualType,
          shortId,
          programCode,
          status,
          location,
          externalId,
          birthDate,
        }) => (
          <Option
            key={id}
            style={styles.searchResult}
            value={`${id}_${individualType}`}
          >
            <div style={styles.statusContainer}>
              <div style={styles.statusContentContainer}>
                <Highlighter
                  style={styles.shortId}
                  highlightStyle={highlightStyle}
                  searchWords={[highlightValue]}
                  textToHighlight={shortId}
                />
              </div>
            </div>

            <div style={styles.statusTagContainer}>
              <StatusTag
                style={{
                  overflow: "hidden",
                  width: setStatusTagWidth(programCode.length + status.length),
                }}
                programCode={programCode}
                status={status}
              />
            </div>

            <div style={styles.contactInformationContainer}>
              {names.map((name, index) => (
                <ContactInformation
                  key={index}
                  icon={ContactIcon}
                  searchTerm={highlightValue}
                  text={formatName(name)}
                />
              ))}

              {phoneNumbers.map(({ phoneNumber }, index) => (
                <ContactInformation
                  key={index}
                  icon={PhoneIcon}
                  searchTerm={highlightValue}
                  text={formatPhoneNumberToDisplayString(phoneNumber)}
                />
              ))}
              {location ? (
                <ContactInformation
                  key={location}
                  icon={LocationPinIcon}
                  searchTerm={highlightValue}
                  text={location}
                />
              ) : null}
              {externalId ? (
                <ContactInformation
                  key={externalId}
                  icon={ExternalIdIcon}
                  searchTerm={highlightValue}
                  text={externalId}
                />
              ) : null}

              {birthDate ? (
                <ContactInformation
                  key={birthDate}
                  icon={BirthdateIcon}
                  searchTerm={highlightValue}
                  text={moment(birthDate).format("MM/DD/YYYY")}
                />
              ) : null}
            </div>
            <div style={styles.caretIconContainer}>
              <Icon style={styles.caretIcon} type="right" />
            </div>
          </Option>
        ),
      );

  return (
    <div>
      <KeydownObserver
        keys={[FORWARD_SLASH_KEY_CODE]}
        onKeyPressed={() => setShouldFocusInput(true)}
        secondaryKey="metaKey"
      />
      <QuickSearch
        onSearch={(searchTerm: string) => {
          handleOnSearch(searchTerm);
        }}
        onSelect={(value: string) => {
          const [id, type] = value.split("_");
          routeSearchResult(type as IndividualType, id);
        }}
        style={styles.searchBar}
        optionLabelProp="combobox"
        shouldFocusInput={shouldFocusInput}
        onBlur={() => setShouldFocusInput(false)}
        onFocus={() => setShouldFocusInput(true)}
        icon={icon}
        iconClass={iconClass}
        spin={spin}
        dataSource={isEmpty(highlightValue) ? [] : dataSource}
      />
    </div>
  );
};

export default Radium(Search);
