import React from "react";
import { Popconfirm, Button } from "@wellth/web-ui";
import { BUTTON_CONTAINER_STYLES } from "containers/MemberProfile/styles";
import { isAdminOrSuperAdmin } from "utils/roles";
import { AdminPrivilege } from "constants/adminPrivilege";

interface DeleteButtonProps {
  deleteReminder: () => void;
  disabled: boolean;
  loading?: boolean;
}
interface Props extends DeleteButtonProps {
  adminRole: AdminPrivilege;
}

const DeleteButton = ({
  disabled,
  loading,
  deleteReminder,
}: DeleteButtonProps) => (
  <>
    {disabled ? (
      <Button type="danger" disabled={disabled} loading={loading}>
        Delete reminder
      </Button>
    ) : (
      <Popconfirm
        title="Are you sure?"
        okText="Yes"
        cancelText="No"
        onConfirm={deleteReminder}
      >
        <Button type="danger" disabled={disabled} loading={loading}>
          Delete reminder
        </Button>
      </Popconfirm>
    )}
  </>
);

const Footer = ({ deleteReminder, disabled, loading, adminRole }: Props) => (
  <div style={BUTTON_CONTAINER_STYLES}>
    {isAdminOrSuperAdmin(adminRole) ? (
      <DeleteButton
        deleteReminder={deleteReminder}
        disabled={disabled}
        loading={loading}
      />
    ) : null}
  </div>
);

export default Footer;
