import React from "react";
import { LoadingScreen } from "@wellth/ui";
import PatientChatUI from "components/PatientChat";
import {
  useCreateMessageMutation,
  useMemberMessagesQuery,
  MemberMessagesDocument,
} from "hooks/graphql";
import { MessageRecipient, MessageProtocol } from "types/globalTypes";

export interface PatientChatProps {
  patientId: string;
  adminId: string;
}

export const PatientChat: React.FC<PatientChatProps> = ({
  patientId,
  adminId,
}: PatientChatProps) => {
  const {
    data: { member: { memberMessages = [] } = {} } = {},
    loading,
  } = useMemberMessagesQuery({
    variables: {
      id: patientId,
    },
  });

  const [createMessage] = useCreateMessageMutation({
    context: { requireOnline: true },
    refetchQueries: [
      { query: MemberMessagesDocument, variables: { id: patientId } },
    ],
  });

  if (loading) return <LoadingScreen showLoadingIndicator />;

  return (
    <PatientChatUI
      messages={memberMessages}
      sendMessage={async (message) => {
        await createMessage({
          variables: {
            input: {
              message: {
                patientId,
                adminId,
                message,
                recipient: MessageRecipient.Patient,
                protocol: MessageProtocol.Sms,
              },
            },
          },
        });
      }}
    />
  );
};

export default PatientChat;
