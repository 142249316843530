"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _default = _reactNative.StyleSheet.create({
  container: {
    flex: 1
  },
  label: {
    fontFamily: "System",
    fontSize: 20,
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: 25,
    letterSpacing: 0.38,
    textAlign: "left",
    color: _colors["default"].WHITE
  }
});
exports["default"] = _default;