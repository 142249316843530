"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateTestAccountPhoneNumber = exports.validateName = exports.validateEmail = void 0;
const constants_1 = require("./constants");
const validateEmail = emailAddress => constants_1.EMAIL_REGEX.test(emailAddress);
exports.validateEmail = validateEmail;
const validateName = name => constants_1.NAME_REGEX.test(name);
exports.validateName = validateName;
const TEST_ACCOUNT_PHONE_NUMBER_REGEX = /^\+1[0-9]{3}555[0-9]{4}$/g;
const validateTestAccountPhoneNumber = phoneNumber => phoneNumber && phoneNumber.match(TEST_ACCOUNT_PHONE_NUMBER_REGEX);
exports.validateTestAccountPhoneNumber = validateTestAccountPhoneNumber;