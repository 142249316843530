import React from "react";
import { Modal } from "components/Modal";
import {
  ProgramInfoForm,
  ProgramInfoFormProps,
} from "components/ProgramInfoForm";

export interface ProgramInfoFormModalProps extends ProgramInfoFormProps {
  title: React.ReactNode;
  loading?: boolean;
  goBack: () => void;
}

export const ProgramInfoFormModal: React.FC<ProgramInfoFormModalProps> = ({
  loading = false,
  goBack,
  title,
  ...formProps
}: ProgramInfoFormModalProps) => (
  <Modal
    title={title}
    loading={loading}
    onOk={goBack}
    onCancel={goBack}
    width={700}
    cancelButtonProps={{ hidden: true }}
  >
    <ProgramInfoForm {...formProps} />
  </Modal>
);

export default ProgramInfoFormModal;
