"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _default = _reactNative.StyleSheet.create({
  rightPortion: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 18
  },
  leftPortion: {
    flexDirection: "column",
    flex: 1
  },
  titleLabel: {
    marginLeft: 0,
    fontSize: 17,
    lineHeight: 20,
    color: _colors["default"].GREY,
    marginHorizontal: 16
  },
  valueLabel: {
    fontSize: 17,
    lineHeight: 20,
    color: _colors["default"].GREY,
    marginLeft: 16
  }
});
exports["default"] = _default;