import React, { useRef } from "react";
import { Modal } from "components/Modal";
import EditPauseForm, {
  EditPauseFormProps,
  PauseFormProps,
} from "components/EditPauseForm";

export interface EditPauseFormModalProps extends EditPauseFormProps {
  title: React.ReactNode;
  loading?: boolean;
  submissionLoading?: boolean;
  submit: (values: PauseFormProps) => void;
  cancel: () => void;
}

export const EditPauseFormModal: React.FC<EditPauseFormModalProps> = ({
  loading = false,
  submissionLoading = false,
  submit,
  cancel,
  title,
  ...formProps
}: EditPauseFormModalProps) => {
  const formRef = useRef(null);

  return (
    <Modal
      title={title}
      okText="Submit"
      loading={loading}
      submissionLoading={submissionLoading}
      onCancel={cancel}
      onOk={async () => submit(await formRef.current.getReason())}
      width={700}
    >
      <EditPauseForm {...formProps} ref={formRef} />
    </Modal>
  );
};

export default EditPauseFormModal;
