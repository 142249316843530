import React, { useState } from "react";
import { ProspectInformationModal } from "components/ProspectInformationModal";
import { Gender } from "types/globalTypes";
import {
  useGetProspectByIdQuery,
  useDeleteNameMutation,
  useDeleteEmailAddressMutation,
  useDeleteMailingAddressMutation,
  useDeletePhoneNumberMutation,
  ProspectNamesDocument as prospectNamesQuery,
  ProspectEmailAddressesDocument as prospectEmailAddressesQuery,
  ProspectMailingAddressesDocument as prospectMailingAddressesQuery,
  ProspectPhoneNumbersDocument as prospectPhoneNumbersQuery,
  ProspectProgramExperienceDocument as prospectProgramExperienceQuery,
  useUpdateProspectByIdMutation,
} from "hooks/graphql";
import moment from "moment-timezone";
import { DATE_FORMAT } from "utils/date";
import { AdminPrivilege } from "constants/adminPrivilege";

export interface ProspectInformationModalContainerProps {
  id: string;
  adminRole: AdminPrivilege;
  routeEditProfile: () => void;
  routeEditPhoneNumber: (phoneNumberId: string) => void;
  routeCreatePhoneNumber: () => void;
  routeEditName: (nameId: string) => void;
  routeCreateName: () => void;
  routeEditEmailAddress: (emailAddressId: string) => void;
  routeCreateEmailAddress: () => void;
  routeEditMailingAddress: (mailingAddressId: string) => void;
  routeCreateMailingAddress: () => void;
  goBack: () => void;
}

export const ProspectInformationModalContainer: React.FC<
  ProspectInformationModalContainerProps
> = ({
  goBack,
  id: prospectId,
  adminRole,
  routeEditName,
  routeEditProfile,
  routeEditEmailAddress,
  routeEditPhoneNumber,
  routeEditMailingAddress,
  routeCreateName,
  routeCreateEmailAddress,
  routeCreatePhoneNumber,
  routeCreateMailingAddress,
}: ProspectInformationModalContainerProps) => {
  const {
    data: {
      getProspectById: {
        siteName = "",
        externalId = "",
        eligibilityEndDate = null,
        isTestProspect = false,
        medicaidId = "",
        pcpName = "",
        pcpNumber = "",
        person: {
          addresses = [],
          names = [],
          phoneNumbers = [],
          emailAddresses = [],
          primaryTimezone: timezone = null,
          birthDate: birthdate = null,
          primaryLanguage: language = null,
          gender = Gender.Unknown,
          race = "",
          ethnicity = "",
          medicareId = "",
          coverageType = "",
          planName = "",
        } = {},
        program: { programTemplate = "" } = {},
      } = {},
    } = {},
    loading,
  } = useGetProspectByIdQuery({
    variables: {
      id: prospectId,
    },
  });
  const [memberLoading, setLoading] = useState(false);

  const [deleteName] = useDeleteNameMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: prospectNamesQuery, variables: { id: prospectId } },
    ],
  });

  const [deletePhoneNumber] = useDeletePhoneNumberMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: prospectPhoneNumbersQuery, variables: { id: prospectId } },
    ],
  });

  const [deleteEmailAddress] = useDeleteEmailAddressMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: prospectEmailAddressesQuery, variables: { id: prospectId } },
    ],
  });

  const [deleteMailingAddress] = useDeleteMailingAddressMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: prospectMailingAddressesQuery, variables: { id: prospectId } },
    ],
  });

  const [updateProspect] = useUpdateProspectByIdMutation({
    awaitRefetchQueries: true,
    onCompleted: () => setLoading(false),
    refetchQueries: [
      { query: prospectProgramExperienceQuery, variables: { id: prospectId } },
    ],
  });

  return (
    <ProspectInformationModal
      loading={!loading ? memberLoading : loading}
      names={{
        names: names || [],
        onEditName: ({ id }) => routeEditName(id),
        onCreateName: routeCreateName,
        onDeleteName: ({ id }) => deleteName({ variables: { id } }),
      }}
      profile={{
        siteName,
        externalId,
        timezone,
        gender,
        language,
        eligibilityEndDate: eligibilityEndDate
          ? moment(eligibilityEndDate).toDate()
          : null,
        birthdate: moment(birthdate, DATE_FORMAT).toDate(),
        onEditButtonPressed: routeEditProfile,
        race,
        ethnicity,
        medicareId,
        medicaidId,
        programTemplate,
        coverageType,
        planName,
        pcpName,
        pcpNumber,
      }}
      phoneNumbers={{
        phoneNumbers: phoneNumbers || [],
        onEditPhoneNumber: ({ id }) => routeEditPhoneNumber(id),
        onCreatePhoneNumber: routeCreatePhoneNumber,
        onDeletePhoneNumber: async ({ id }) => {
          setLoading(true);
          await deletePhoneNumber({ variables: { id } });
        },
      }}
      emailAddresses={{
        emailAddresses: emailAddresses || [],
        onEditEmailAddress: ({ id }) => routeEditEmailAddress(id),
        onCreateEmailAddress: routeCreateEmailAddress,
        onDeleteEmailAddress: async ({ id }) => {
          setLoading(true);
          await deleteEmailAddress({ variables: { id } });
        },
      }}
      mailingAddresses={{
        mailingAddresses: addresses || [],
        onEditMailingAddress: ({ id }) => routeEditMailingAddress(id),
        onCreateMailingAddress: routeCreateMailingAddress,
        onDeleteMailingAddress: async ({ id }) => {
          setLoading(true);
          await deleteMailingAddress({ variables: { id } });
        },
      }}
      programExperience={{
        adminRole,
        isTestUser: isTestProspect,
        isTestUserToggled: async (isTestProspectValue) => {
          await updateProspect({
            variables: {
              id: prospectId,
              prospectPatch: {
                isTestProspect: isTestProspectValue,
              },
            },
          });
        },
      }}
      cancel={goBack}
    />
  );
};

export default ProspectInformationModalContainer;
