import React from "react";
import moment from "moment-timezone";
import {
  Column,
  GridRow,
  RoundedRectangleButton,
  Card,
  Icon,
} from "@wellth/web-ui";
import KeyMemberInfo from "components/KeyMemberInfo";
import Info, { PersonProfile } from "components/PersonInformationCard";
import * as gridConstants from "constants/grid";
import * as baseStyles from "constants/styles";
import { AdminPrivilege } from "constants/adminPrivilege";
import { ProspectNote, ProspectNotes } from "containers/ProspectNotes";
import Description from "components/Description";
import {
  ProspectPatch,
  Score,
  ProspectStatus as ProspectStatusType,
} from "types/globalTypes";
import { DownloadLinkSender } from "containers/DownloadLinkSender";
import { IdentityTag } from "containers/IdentityTag";
import TabCard from "components/TabCard";
import { ActivityContainer } from "containers/Activity";
import { ProspectStatusHistory } from "components/ProspectStatusHistory";
import COLORS from "utils/colors";
import { ConditionsCard } from "components/Conditions";
import CARD_STYLES from "../styles";

export enum ProspectStatus {
  open = "open",
  closed = "closed",
  converting = "converting",
  converted = "converted",
}

const NOT_ENROLLING_ALERT_CONTAINER: React.CSSProperties = {
  display: "flex",
  marginTop: "0.5rem",
};

const NOT_ENROLLING_ALERT_ICON_CONTAINER: React.CSSProperties = {
  marginRight: "0.5rem",
  fontSize: "1.5rem",
};

export interface ProspectPageProps extends PersonProfile {
  id: string;
  externalId: string;
  shortId: string;
  gender: string;
  birthdate: string;
  name: string;
  programCode: string;
  enrollmentEnabled: boolean;
  isEnrollmentEnabledForProgram: boolean;
  timezone: string;
  prospectSince: moment.Moment;
  status: ProspectStatus;
  prospectStatuses: Partial<ProspectStatusType[]>;
  adminPrivilege: AdminPrivilege;
  prospectNotes: ProspectNote[];
  routeEnrollment: () => void;
  routeProspectStatus: () => void;
  routeProspectInformation: () => void;
  disablePage: boolean;
  updateProspect: (prospectPatch: ProspectPatch) => void;
  description: string;
  isTestUser: boolean;
  overallScore?: Partial<Score>;
  routeReOpenProspect: (programCode: string, shortId: string) => void;
  pcpName: string;
  pcpNumber: string;
}

export const ProspectPage: React.FC<ProspectPageProps> = ({
  id,
  externalId,
  shortId,
  programCode,
  enrollmentEnabled,
  isEnrollmentEnabledForProgram,
  language,
  name,
  gender,
  birthdate,
  phoneNumbers,
  emailAddresses,
  timezone,
  addresses,
  prospectSince,
  status,
  prospectStatuses,
  adminPrivilege,
  prospectNotes,
  routeEnrollment,
  routeProspectStatus,
  routeProspectInformation,
  disablePage,
  updateProspect,
  description,
  isTestUser,
  overallScore,
  routeReOpenProspect,
  pcpName,
  pcpNumber,
}) => (
  <>
    {disablePage ? <div style={baseStyles.DISABLE_SCREEN_STYLES} /> : null}
    <div style={baseStyles.CONTAINER_STYLES}>
      <GridRow
        style={baseStyles.ROW_STYLES}
        type={gridConstants.GRID_TYPE}
        justify={gridConstants.GRID_JUSTIFY}
        gutter={gridConstants.GUTTER}
      >
        <Column
          xs={gridConstants.COLUMN_FULL_WIDTH}
          sm={gridConstants.COLUMN_FULL_WIDTH}
          md={gridConstants.COLUMN_THRID_WIDTH}
          lg={gridConstants.COLUMN_THRID_WIDTH}
          xl={gridConstants.COLUMN_THRID_WIDTH}
        >
          <div style={baseStyles.CONTENT_STYLES}>
            <KeyMemberInfo
              adminRole={adminPrivilege}
              fullName={name}
              shortId={shortId}
              isTestMember={isTestUser}
              title="Prospect"
              programCode={programCode}
              status={status}
              handleChangeStatus={
                status === ProspectStatus.open ? routeProspectStatus : null
              }
              handleReOpeningProspect={
                status === ProspectStatus.closed
                  ? () => {
                      routeReOpenProspect(programCode, shortId);
                    }
                  : null
              }
            />
          </div>

          <div style={baseStyles.CONTENT_STYLES}>
            <IdentityTag wellthId={id} />
          </div>

          {!isEnrollmentEnabledForProgram && (
            <div style={baseStyles.CONTENT_STYLES}>
              <Card
                headStyle={CARD_STYLES.head}
                bodyStyle={CARD_STYLES.body}
                style={{
                  ...CARD_STYLES.card,
                  backgroundColor: COLORS.lightRed,
                }}
              >
                <div style={NOT_ENROLLING_ALERT_CONTAINER}>
                  <div style={NOT_ENROLLING_ALERT_ICON_CONTAINER}>
                    <Icon
                      type="exclamation-circle-o"
                      style={{ color: COLORS.red }}
                    />
                  </div>
                  <div>
                    <b>Enrollment is currently closed for this Program</b>
                    <p>This prospect cannot be enrolled</p>
                  </div>
                </div>
              </Card>
            </div>
          )}

          <div style={baseStyles.CONTENT_STYLES}>
            <RoundedRectangleButton
              title="Enroll Prospect as Member"
              onClick={routeEnrollment}
              disabled={status === ProspectStatus.closed || !enrollmentEnabled}
            />
          </div>

          <div style={baseStyles.CONTENT_STYLES}>
            <ConditionsCard externalId={externalId} programCode={programCode} />
          </div>

          <div style={baseStyles.CONTENT_STYLES}>
            <Info
              personProfile={{
                language,
                phoneNumbers: phoneNumbers.map(
                  ({ phoneNumber, isPrimary }) => ({
                    phoneNumber,
                    isPrimary,
                  }),
                ),
                emailAddresses: emailAddresses.map(
                  ({ emailAddress, isPrimary }) => ({
                    emailAddress,
                    isPrimary,
                  }),
                ),
                addresses: addresses.map(
                  ({ street, street2, city, state, zip, isPrimary }) => ({
                    street,
                    street2,
                    city,
                    state,
                    zip,
                    isPrimary,
                  }),
                ),
                personSince: prospectSince.format("LL"),
                gender,
                birthdate,
              }}
              localTime={moment.tz(timezone).format("h:mm A (z)")}
              onInfoModalClick={routeProspectInformation}
              type="Prospect"
              overallScore={overallScore}
            />
          </div>
        </Column>
        <Column
          xs={gridConstants.COLUMN_FULL_WIDTH}
          sm={gridConstants.COLUMN_FULL_WIDTH}
          md={gridConstants.COLUMN_HALF_WIDTH}
          lg={gridConstants.COLUMN_HALF_WIDTH}
          xl={gridConstants.COLUMN_HALF_WIDTH}
        >
          <div>
            <ProspectStatusHistory prospectStatuses={prospectStatuses} />
          </div>
          <div style={baseStyles.ACTIVITY_CARD}>
            <TabCard
              tabs={[{ key: "activity", tab: "Activity" }]}
              activeIndex={0}
            >
              {({ key }) => {
                switch (key) {
                  case "activity":
                    return (
                      <ActivityContainer wellthId={id} timezone={timezone} />
                    );
                  default:
                    return null;
                }
              }}
            </TabCard>
          </div>
        </Column>
        <Column
          xs={gridConstants.COLUMN_FULL_WIDTH}
          sm={gridConstants.COLUMN_FULL_WIDTH}
          md={gridConstants.COLUMN_THRID_WIDTH}
          lg={gridConstants.COLUMN_THRID_WIDTH}
          xl={gridConstants.COLUMN_THRID_WIDTH}
        >
          <div style={baseStyles.CONTENT_STYLES}>
            <DownloadLinkSender
              phoneNumbers={phoneNumbers}
              language={language}
              programCode={programCode}
              firstName={name.split(" ")[0] || ""}
              wellthId={id}
            />
          </div>
          <div style={baseStyles.CONTENT_STYLES}>
            <ProspectNotes prospectNotes={prospectNotes} prospectId={id} />
          </div>
          <div style={baseStyles.CONTENT_STYLES}>
            <Description
              descriptionText={description}
              onClick={(prospectDescription: string) => {
                updateProspect({
                  description: prospectDescription,
                });
              }}
            />
          </div>
        </Column>
      </GridRow>
    </div>
  </>
);

export default ProspectPage;
