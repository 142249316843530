import React, { useImperativeHandle } from "react";
import { Form, FormInputType, FormInputProps } from "@wellth/web-ui";

export interface NameFormProps {
  firstName: string;
  middleName: string;
  lastName: string;
  isPrimary: boolean;
}

export interface EditNameFormProps extends Partial<NameFormProps> {
  type: "member" | "prospect";
  disablePrimary?: boolean;
  inputStyle?: React.CSSProperties;
}

const EditNameForm: React.RefForwardingComponent<any, EditNameFormProps> = (
  {
    type,
    inputStyle = {
      height: "2.1875rem",
      width: "18.75rem",
    },
    disablePrimary = false,
    ...formProps
  }: EditNameFormProps,
  ref,
) => {
  const formRef = React.useRef(null);
  const isMember = type === "member";
  useImperativeHandle(ref, () => ({
    getName: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      {...formProps}
      wrappedComponentRef={formRef}
      mapPropsToFields={(
        { firstName, middleName, lastName, isPrimary = false },
        createFormField,
      ) => ({
        firstName: createFormField({ value: firstName }),
        middleName: createFormField({ value: middleName }),
        lastName: createFormField({ value: lastName }),
        isPrimary: createFormField({ value: isPrimary }),
      })}
      inputs={[
        {
          type: FormInputType.Text,
          title: "First name:",
          key: "firstName",
          style: inputStyle,
          decoratorOptions: {
            rules: [
              {
                required: true,
                message: "Please provide a first name.",
              },
            ],
          },
        },
        {
          type: FormInputType.Text,
          title: "Middle name:",
          key: "middleName",
          style: inputStyle,
        },
        {
          type: FormInputType.Text,
          title: "Last name:",
          key: "lastName",
          style: inputStyle,
          decoratorOptions: {
            rules: [
              {
                required: true,
                message: "Please provide a last name.",
              },
            ],
          },
        },
        ...(isMember
          ? [
              {
                type: FormInputType.Switch,
                title: "Set as Verified?",
                key: "isPrimary",
                disabled: disablePrimary,
                decoratorOptions: { valuePropName: "checked" },
              } as FormInputProps,
            ]
          : []),
      ]}
      labelCol={{
        sm: { span: 7 },
        xs: { span: 7 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 12 },
      }}
      colValue={12}
    />
  );
};

export default React.forwardRef(EditNameForm);
