import React, { RefAttributes } from "react";
import { SectionType, SectionProps, FormProps } from "@wellth/web-ui";
import { SectionHeader } from "components/SectionHeader";

export interface ProgramExperienceSectionProps extends RefAttributes<any> {
  data: FormProps;
}

export const Inputs = ({
  data,
  ref,
}: ProgramExperienceSectionProps): SectionProps<any> => ({
  ref,
  title: <SectionHeader title="Program Experience" />,
  key: "programExperience",
  type: SectionType.Form,
  data,
});

export default Inputs;
