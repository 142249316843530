import React, { useEffect, useRef } from "react";
import { useSearchByValuesLazyQuery } from "hooks/graphql";
import { AdvancedSearch as AdvancedSearchComponent } from "components/AdvancedSearch";
import { IndividualType } from "types/globalTypes";

interface AdvancedSearchProps {
  routeMemberPage: (id: string) => void;
  routeProspectPage: (id: string) => void;
}

export const AdvancedSearch: React.FC<AdvancedSearchProps> = ({
  routeMemberPage,
  routeProspectPage,
}) => {
  const [
    searchByValues,
    {
      data: { searchByValues: { results = [] } = {} } = {},
      loading = false,
    } = {},
  ] = useSearchByValuesLazyQuery({
    fetchPolicy: "network-only",
  });

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
  });

  return (
    <AdvancedSearchComponent
      search={(searchValues) => {
        searchByValues({ variables: { input: searchValues } });
      }}
      loading={loading}
      results={
        results.length > 0
          ? results.map(
              ({
                id,
                shortId,
                names,
                birthDate,
                programCode,
                status,
                externalId,
                individualType,
                phoneNumbers,
              }) => ({
                id,
                firstName: names.length > 0 ? names[0].firstName : "-",
                lastName: names.length > 0 ? names[0].lastName : "-",
                birthDate,
                programCode,
                status,
                shortId,
                externalId,
                individualType,
                phoneNumber:
                  phoneNumbers.length > 0 ? phoneNumbers[0].phoneNumber : "-",
              }),
            )
          : []
      }
      routeSearchResult={({ contactType, id }) => {
        switch (contactType) {
          case IndividualType.Member:
            routeMemberPage(id);
            break;
          case IndividualType.Prospect:
            routeProspectPage(id);
            break;
          default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error(`Invalid IndividualType provided: ${contactType}`);
        }
      }}
    />
  );
};

export default AdvancedSearch;
