import React from "react";
import moment from "moment-timezone";
import { Rewards as RewardsUI } from "components/Rewards";
import { useRewardCardDetailsByMemberIdQuery } from "hooks/graphql";

export interface RewardsProps {
  memberId: string;
  onRewardModalClick: () => void;
  nextPayoutDate: Date | null;
  nextPayOutAmount: number | null;
  maximumCurrentPayout: number | null;
}

export const Rewards: React.FC<RewardsProps> = ({
  memberId,
  onRewardModalClick,
  nextPayoutDate,
  nextPayOutAmount,
  maximumCurrentPayout,
}) => {
  const { data } =
    useRewardCardDetailsByMemberIdQuery({
      variables: {
        id: memberId,
      },
    }) || {};
  const { rewardCardDetailsByMemberId } = data || {};
  const { latestBalance, updatedAt } = rewardCardDetailsByMemberId || {};
  return (
    <RewardsUI
      latestBalance={latestBalance || "--"}
      balanceAsOfDate={updatedAt ? moment(updatedAt).format("ll") : "--"}
      nextPayOutAmount={nextPayOutAmount}
      nextPayoutDate={
        nextPayoutDate ? `On ${moment(nextPayoutDate).format("ll")}` : "--"
      }
      maximumCurrentPayout={maximumCurrentPayout}
      onRewardModalClick={onRewardModalClick}
    />
  );
};

export default Rewards;
