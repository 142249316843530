"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.SingleRowButton = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _assets = _interopRequireDefault(require("../../assets"));
var _TitleRow = require("../TitleRow");
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/SingleRowButton/index.tsx";
var SingleRowButton = function SingleRowButton(_ref) {
  var detail = _ref.detail,
    detailLabelStyle = _ref.detailLabelStyle,
    titleLabelStyle = _ref.titleLabelStyle,
    _ref$showDisclosureIn = _ref.showDisclosureIndicator,
    showDisclosureIndicator = _ref$showDisclosureIn === void 0 ? true : _ref$showDisclosureIn,
    rowProps = (0, _objectWithoutProperties2["default"])(_ref, ["detail", "detailLabelStyle", "titleLabelStyle", "showDisclosureIndicator"]);
  return _react["default"].createElement(_TitleRow.TitleRow, (0, _extends2["default"])({}, rowProps, {
    titleLabelStyle: [_styles["default"].titleLabel, titleLabelStyle],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 20,
      columnNumber: 3
    }
  }), _react["default"].createElement(_reactNative.View, {
    style: _styles["default"].rightPortion,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 24,
      columnNumber: 5
    }
  }, detail ? _react["default"].createElement(_reactNative.Text, {
    style: [_styles["default"].valueLabel, detailLabelStyle],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 26,
      columnNumber: 9
    }
  }, detail) : null, showDisclosureIndicator ? _react["default"].createElement(_reactNative.Image, {
    style: _styles["default"].disclosureIndicator,
    source: _assets["default"].disclosureIndicator,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 29,
      columnNumber: 9
    }
  }) : null));
};
exports.SingleRowButton = SingleRowButton;
var _default = SingleRowButton;
exports["default"] = _default;