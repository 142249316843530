import React from "react";
import { Card, AntInput, Button } from "@wellth/web-ui";
import CARD_STYLES from "../styles";

const BODY_STYLE: React.CSSProperties = {
  ...CARD_STYLES.body,
  fontFamily: "HelveticaNeue",
  fontSize: "1.125rem",
  color: "#1f2226",
  fontWeight: "bold",
};

const BUTTON_STYLE: React.CSSProperties = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  paddingTop: "0.625rem",
};

const { TextArea } = AntInput;

export interface Props {
  onClick: (text: string) => void;
  descriptionText?: string;
}

export interface State {
  body: string;
}

export default class Description extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      body: null,
    };
  }

  handleChange(value) {
    this.setState({ body: value });
  }

  render() {
    const { onClick, descriptionText } = this.props;
    const { body } = this.state;
    return (
      <Card
        headStyle={CARD_STYLES.head}
        bodyStyle={BODY_STYLE}
        style={CARD_STYLES.card}
        title="Description"
      >
        <TextArea
          rows={4}
          placeholder="Type something here..."
          onChange={(e) => {
            this.handleChange(e.target.value);
          }}
          value={body || descriptionText}
        />
        <div style={BUTTON_STYLE}>
          <Button
            type="primary"
            onClick={() => {
              onClick(body);
              this.setState({ body: null });
            }}
          >
            Save
          </Button>
        </div>
      </Card>
    );
  }
}
