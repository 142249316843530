import React from "react";

export const MemberSinceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="#657786"
      d="M6.382 12.526c.62 0 1.122-.526 1.122-1.175 0-.65-.502-1.176-1.122-1.176-.62 0-1.122.527-1.122 1.176 0 .65.502 1.175 1.122 1.175zM11.618 12.526c.62 0 1.122-.526 1.122-1.175 0-.65-.502-1.176-1.122-1.176-.62 0-1.122.527-1.122 1.176 0 .65.502 1.175 1.122 1.175z"
    />
    <path
      fillRule="evenodd"
      stroke="#667580"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17.228 10.176c0-1.166-.811-2.127-1.874-2.312C14.44 5.105 11.944 3.123 9 3.123s-5.44 1.982-6.355 4.74C1.583 8.05.771 9.01.771 10.177c0 1.165.812 2.126 1.874 2.311C3.56 15.246 6.055 17.23 9 17.23c2.944 0 5.44-1.983 6.354-4.742 1.063-.185 1.874-1.146 1.874-2.311z"
      clipRule="evenodd"
    />
    <path
      stroke="#667580"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9 5.473C8.813 2.666 10.247.934 12.74.771"
    />
  </svg>
);

export default MemberSinceIcon;
