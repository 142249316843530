import React, { useRef } from "react";
import { Modal } from "components/Modal";
import EditMailingAddressForm, {
  EditMailingAddressFormProps,
  MailingAddressFormProps,
} from "components/EditMailingAddressForm";

export interface EditMailingAddressFormModalProps
  extends EditMailingAddressFormProps {
  title: React.ReactNode;
  loading?: boolean;
  submissionLoading?: boolean;
  submit: (values: MailingAddressFormProps) => void;
  cancel: () => void;
}

export const EditMailingAddressFormModal: React.FC<
  EditMailingAddressFormModalProps
> = ({
  loading = false,
  submissionLoading = false,
  submit,
  cancel,
  title,
  ...formProps
}: EditMailingAddressFormModalProps) => {
  const formRef = useRef(null);

  return (
    <Modal
      title={title}
      okText="Submit"
      loading={loading}
      onCancel={cancel}
      submissionLoading={submissionLoading}
      onOk={async () => submit(await formRef.current.getMailingAddress())}
      width={700}
    >
      <EditMailingAddressForm {...formProps} ref={formRef} />
    </Modal>
  );
};

export default EditMailingAddressFormModal;
