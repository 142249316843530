import { SectionType, FormInputType } from "@wellth/web-ui";
import { BindFormSectionOptions, AnySectionBinding } from "./types";

export interface SiteInformation {
  site: string;
  customerId: string;
}

export type SiteInformationInputOptions = BindFormSectionOptions<
  SiteInformation
>;

export const Inputs: AnySectionBinding<SiteInformationInputOptions> = ({
  extraProps: { site = "", customerId = "" } = {},
  ...props
}) => ({
  ...props,
  title: "Site Information",
  key: "siteInformation",
  type: SectionType.Form,
  data: {
    mapPropsToFields: (_props, createFormField) => ({
      site: createFormField({ value: site }),
      customerId: createFormField({ value: customerId }),
    }),
    inputs: [
      {
        type: FormInputType.Info,
        key: "site",
        title: "Site",
      },
      {
        type: FormInputType.Info,
        key: "customerId",
        title: "Customer ID",
      },
    ],
  },
});
