import React from "react";
import { message as alert } from "@wellth/web-ui";
import {
  ChangeStatusFormModal,
  ChangeStatusFormModalProps,
} from "components/ChangeStatusFormModal";
import { MEMBER_PROFILE_REFETCH } from "utils";
import { useChangeMemberStatusMutation } from "hooks/graphql";

export interface ChangeMemberStatusModalProps
  extends Omit<ChangeStatusFormModalProps, "userType" | "submit"> {
  id: string;
}

export const ChangeMemberStatusModal: React.FC<
  ChangeMemberStatusModalProps
> = ({ id, goBack, ...props }: ChangeMemberStatusModalProps) => {
  const [changeMemberStatus] = useChangeMemberStatusMutation({
    refetchQueries: MEMBER_PROFILE_REFETCH(id),
    context: { requireOnline: true },
    onError: ({ message }) => alert.error(message),
    onCompleted: goBack,
  });

  return (
    <ChangeStatusFormModal
      {...props}
      goBack={goBack}
      userType="member"
      submit={async ({ type, reason: changeReason, reasonDetail, note }) => {
        await changeMemberStatus({
          variables: {
            id,
            status: type,
            reason: changeReason,
            reasonDetail,
            note,
          },
        });
      }}
    />
  );
};

export default ChangeMemberStatusModal;
