import React from "react";
import { Table as TableUI } from "antd";
import moment from "moment-timezone";
import { upperFirst } from "lodash";

const EASTERN_TIME = "America/New_York";

export interface TableRow {
  id: string;
  shortId: string;
  name: string;
  status: string;
  programCode: string;
  site?: string;
  externalId?: string;
  enrollmentDate: moment.Moment;
  activationDate: moment.Moment | undefined;
  statusChangedAt?: moment.Moment;
  statusChangeReason?: string;
}

enum ColumnKey {
  ShortId = "shortId",
  Name = "name",
  Status = "status",
  ProgramCode = "programCode",
  Site = "site",
  ExternalId = "externalId",
  EnrollmentDate = "enrollmentDate",
  ActivationDate = "activationDate",
  StatusChangedAt = "statusChangedAt",
  StatusChangeReason = "statusChangeReason",
}

export type TableSortOrder = "ascending" | "descending";

export interface TableSort {
  key: keyof TableRow;
  order: TableSortOrder;
}

const order = (sortOrder: "ascend" | "descend"): TableSortOrder => {
  switch (sortOrder) {
    case "ascend":
      return "ascending";
    case "descend":
      return "descending";
    default:
      throw new Error("Invalid sort order provided");
  }
};

export interface TableFilter {
  currentPage?: number;
  filters: Partial<Record<keyof TableRow, string[]>>;
  sort: TableSort;
}

export interface TablePagination {
  total?: number;
  current?: number;
  pageSize: number;
}

export interface TableProps {
  loading?: boolean;
  rows: TableRow[];
  pagination?: TablePagination;
  onFilterSetChanged: (filterSet: TableFilter) => void;
  onClick: (record: TableRow, index: number) => void;
}

export const Table: React.FC<TableProps> = ({
  onFilterSetChanged,
  onClick,
  rows,
  pagination: { total = rows.length, pageSize },
  loading = false,
}) => (
  <TableUI
    bordered
    loading={loading}
    dataSource={rows}
    rowKey={({ shortId }) => shortId}
    scroll={{ x: 1000 }}
    pagination={{
      total,
      pageSize,
      hideOnSinglePage: true,
    }}
    columns={[
      {
        title: "Short ID",
        key: ColumnKey.ShortId,
        dataIndex: ColumnKey.ShortId,
        fixed: "left",
        width: 100,
        sorter: true,
        render: (_, { shortId }) => <a>{shortId}</a>,
      },
      {
        title: "Member Name",
        key: ColumnKey.Name,
        dataIndex: ColumnKey.Name,
        sorter: true,
      },
      {
        title: "Status",
        key: ColumnKey.Status,
        dataIndex: ColumnKey.Status,
        sorter: true,
        defaultSortOrder: "ascend",
        render: (_, { status }) => upperFirst(status),
      },
      {
        title: "Program Code",
        key: ColumnKey.ProgramCode,
        dataIndex: ColumnKey.ProgramCode,
        sorter: true,
      },
      {
        title: "Site",
        key: ColumnKey.Site,
        dataIndex: ColumnKey.Site,
        sorter: true,
        render: (_, { site }) => site || "-",
      },
      {
        title: "External ID",
        key: ColumnKey.ExternalId,
        dataIndex: ColumnKey.ExternalId,
        sorter: true,
        render: (_, { externalId }) => externalId || "-",
      },
      {
        title: "Enrollment Date",
        key: ColumnKey.EnrollmentDate,
        dataIndex: ColumnKey.EnrollmentDate,
        sorter: true,
        render: (_, { enrollmentDate }) =>
          moment.tz(enrollmentDate, EASTERN_TIME).format("L LT"),
      },
      {
        title: "Activation Date",
        key: ColumnKey.ActivationDate,
        dataIndex: ColumnKey.ActivationDate,
        sorter: true,
        render: (_, { activationDate }) =>
          activationDate
            ? moment.tz(activationDate, EASTERN_TIME).format("L LT")
            : "-",
      },
      {
        title: "Last Status Change",
        key: ColumnKey.StatusChangedAt,
        dataIndex: ColumnKey.StatusChangedAt,
        render: (_, { statusChangedAt }) =>
          moment.tz(statusChangedAt, EASTERN_TIME).format("L LT"),
      },
      {
        title: "Last Status Change Reason",
        key: ColumnKey.StatusChangeReason,
        dataIndex: ColumnKey.StatusChangeReason,
      },
    ]}
    onChange={({ current }, filters, sort) => {
      onFilterSetChanged({
        currentPage: current,
        filters,
        sort: {
          key: sort.columnKey as keyof TableRow,
          order: order(sort.order),
        },
      });
    }}
    onRow={(record, rowIndex) => ({
      onClick: () => onClick(record, rowIndex),
    })}
  />
);

export default Table;
