import React from "react";

const styles: { [key: string]: React.CSSProperties } = {
  card: {
    borderRadius: 10,
    boxShadow:
      "0 .25rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0 .125rem 0 rgba(0, 0, 0, 0.12)",
  },
  header: {
    fontFamily: "HelveticaNeue",
    color: "#1f2226",
    fontWeight: "bold",
    padding: "0 0.5rem",
    maxHeight: "2.25rem",
  },
  body: {
    listStyle: "none",
    padding: "0.5rem",
  },
  addTag: {
    borderStyle: "dashed",
  },
  addTagContainer: {
    marginTop: "0.25rem",
  },
  select: {
    width: "90%",
    marginRight: "0.25rem",
    marginTop: "0.5rem",
  },
};

export default styles;
