"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _default = _reactNative.StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  label: {
    textAlign: "center",
    backgroundColor: _colors["default"].TRANSPARENT,
    color: _colors["default"].WHITE
  },
  activityIndicator: {
    padding: 20
  }
});
exports["default"] = _default;