import { CSSProperties } from "react";

const styles: { [key: string]: CSSProperties } = {
  container: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  addressValidityLabel: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: -0.2,
    textAlign: "center",
    color: "#7b8794",
    paddingTop: 16,
    margin: 0,
  },
};

export default styles;
