import React from "react";
import { Card, Dropdown, Icon, Menu } from "@wellth/web-ui";
import { AdminPrivilege } from "constants/adminPrivilege";
import * as constants from "containers/MemberProfile/constants";
import { isCallCenterAdmin, isSuperAdmin } from "utils/roles";
import CARD_STYLES from "../styles";
import Body from "./components/Body";

export interface KeyMemberInfoProps extends CreateMemberActionProps {
  shortId: string;
  fullName: string;
  programCode?: string;
  status?: string;
  isTestMember: boolean;
  handleTestMember?: (event: any) => void;
  title?: string;
}

interface CreateMemberActionProps {
  adminRole?: AdminPrivilege;
  handleChangeStatus?: () => void;
  handleDeleteMember?: () => void;
  handleReOpeningProspect?: () => void;
}

// no actions for call center agents
const CreateMemberActions = ({
  adminRole,
  handleChangeStatus,
  handleDeleteMember,
  handleReOpeningProspect,
}: CreateMemberActionProps) => (
  <Menu style={CARD_STYLES.card}>
    {handleChangeStatus ? (
      <Menu.Item key={constants.CHANGE_STATUS} onClick={handleChangeStatus}>
        {constants.CHANGE_STATUS}
      </Menu.Item>
    ) : null}
    {!!handleDeleteMember && isSuperAdmin(adminRole) ? (
      <Menu.Item key={constants.DELETE_MEMBER} onClick={handleDeleteMember}>
        {constants.DELETE_MEMBER}
      </Menu.Item>
    ) : null}
    {!!handleReOpeningProspect ? (
      <Menu.Item
        key={constants.REOPEN_PROSPECT}
        onClick={handleReOpeningProspect}
      >
        {constants.REOPEN_PROSPECT}
      </Menu.Item>
    ) : null}
  </Menu>
);

export default ({
  shortId,
  fullName,
  programCode,
  status,
  handleChangeStatus,
  isTestMember,
  handleTestMember,
  handleDeleteMember,
  title,
  adminRole,
  handleReOpeningProspect,
}: KeyMemberInfoProps) => (
  <Card
    headStyle={CARD_STYLES.head}
    bodyStyle={CARD_STYLES.body}
    style={CARD_STYLES.card}
    title={
      <div style={CARD_STYLES.titleStyle}>
        {title}
        {!isCallCenterAdmin(adminRole) ? (
          <Dropdown
            overlay={() => (
              <CreateMemberActions
                adminRole={adminRole}
                handleChangeStatus={handleChangeStatus}
                handleDeleteMember={handleDeleteMember}
                handleReOpeningProspect={handleReOpeningProspect}
              />
            )}
            trigger={["click"]}
          >
            <Icon type="ellipsis" />
          </Dropdown>
        ) : null}
      </div>
    }
  >
    <Body
      shortId={shortId}
      fullName={fullName}
      isTestMember={isTestMember}
      handleTestMember={handleTestMember}
      programCode={programCode}
      status={status}
    />
  </Card>
);
