import React from "react";
import SearchUI from "components/Search";
import { useSearchLazyQuery } from "hooks/graphql";
import { IndividualType } from "types/globalTypes";

export interface SearchProps {
  routeMemberPage: (memberId: string) => void;
  routeProspectPage: (prospectId: string) => void;
}

export const Search: React.FC<SearchProps> = ({
  routeMemberPage,
  routeProspectPage,
}) => {
  const [
    search,
    { data: { search: { results = [] } = {} } = {}, loading = false } = {},
  ] = useSearchLazyQuery({
    fetchPolicy: "network-only",
  });

  return (
    <SearchUI
      searchResults={results}
      loading={loading}
      onSearch={(searchTerm) =>
        search({
          variables: {
            searchTerm,
          },
        })
      }
      routeSearchResult={(contactType, id) => {
        switch (contactType) {
          case IndividualType.Member:
            routeMemberPage(id);
            break;
          case IndividualType.Prospect:
            routeProspectPage(id);
            break;
          default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error(`Invalid IndividualType provided: ${contactType}`);
        }
      }}
    />
  );
};

export default Search;
