import React from "react";

export const AlertTriangle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="42"
    viewBox="0 0 44 42"
  >
    <path
      fill="#7B8794"
      fillRule="nonzero"
      d="M43.271 36.273L25.39 2.195A3.827 3.827 0 0 0 22 .15a3.827 3.827 0 0 0-3.389 2.045L.729 36.273a3.811 3.811 0 0 0 .116 3.748 3.828 3.828 0 0 0 3.274 1.842H39.88c1.34 0 2.58-.699 3.274-1.842a3.811 3.811 0 0 0 .116-3.748zM20.087 14.17c0-1.054.856-1.909 1.913-1.909s1.913.855 1.913 1.91v11.454c0 1.054-.856 1.909-1.913 1.909a1.911 1.911 0 0 1-1.913-1.91V14.172zm2.009 21.974h-.054a2.92 2.92 0 0 1-2.912-2.807 2.815 2.815 0 0 1 .771-2.04 2.826 2.826 0 0 1 2-.88h.053a2.918 2.918 0 0 1 2.916 2.8 2.813 2.813 0 0 1-.77 2.046 2.824 2.824 0 0 1-2.004.88z"
    />
  </svg>
);

export default AlertTriangle;
