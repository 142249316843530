import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { SVG, message as alert } from "@wellth/web-ui";
import setSessionToken from "actions/sessionTokenSet";
import googleClientId from "constants/googleOAuthClientId";
import store from "constants/store";
import { useGetTokenViaSsoMutation } from "hooks/graphql";

const LOGO_STYLES: React.CSSProperties = {
  marginBottom: "1.5rem",
};

export default () => {
  const [getToken] = useGetTokenViaSsoMutation({
    onCompleted: ({ getTokenViaSSO: { jwtToken } }) => {
      store.dispatch(setSessionToken(jwtToken));
    },
    onError: ({ message }) => alert.error(message),
    context: {
      requireAuth: false,
    },
  });

  return (
    <>
      <div style={LOGO_STYLES}>
        <SVG name="wellthLogo" />
      </div>
      <GoogleOAuthProvider clientId={googleClientId}>
        <GoogleLogin
          onSuccess={async ({ credential }: any) => {
            await getToken({
              variables: {
                identityToken: credential,
                provider: "GOOGLE",
              },
            });
          }}
          onError={() => {
            void alert.error("Unable to log in.");
          }}
        />
      </GoogleOAuthProvider>
    </>
  );
};
