import React from "react";
import { Table, Button } from "@wellth/web-ui";
import "./index.css";
import { TablePagination } from "components/ManageMembers/components/Table";

const OUTSIDE_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  marginTop: "3rem",
  width: "75rem",
  marginLeft: "auto",
  marginRight: "auto",
};

const CONTAINER_STYLES: React.CSSProperties = {
  width: "100%",
};

const HEADING_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "1rem",
};

const HEADING_STYLES: React.CSSProperties = {
  fontSize: "1.5rem",
  lineHeight: 1.33,
};

interface SmsOutReach {
  id: string;
  fullName: string;
  message: string;
  phoneNumber: string;
  programCode: string | null;
}

interface Props {
  data: SmsOutReach[];
  onSendMessageClick?: (data: string) => void;
  pagination?: TablePagination;
  onPaginationChange?: (p: number) => void;
  isAutoApprove?: boolean;
}

const BASE_COLUMNS = [
  { title: "Name", dataIndex: "fullName", width: "20%" },
  { title: "Phone #", dataIndex: "phoneNumber", width: "20%" },
  { title: "Program Code", dataIndex: "programCode", width: "20%" },
  { title: "Message", dataIndex: "message", width: "20%" },
];

export default ({
  data,
  onSendMessageClick,
  pagination,
  onPaginationChange,
  isAutoApprove,
}: Props) => (
  <div style={OUTSIDE_CONTAINER_STYLES}>
    <div style={CONTAINER_STYLES}>
      {!isAutoApprove ? (
        <div style={HEADING_CONTAINER_STYLES}>
          <span style={HEADING_STYLES}>Send messages</span>
          <Button
            disabled={data.length === 0}
            onClick={(): void => {
              onSendMessageClick(data[0].id);
            }}
            type="primary"
          >
            Send
          </Button>
        </div>
      ) : (
        <div style={HEADING_CONTAINER_STYLES}>
          <span style={HEADING_STYLES}>Queued messages</span>
          <span style={{ ...HEADING_STYLES, fontSize: "0.875rem" }}>
            Problem? Contact the Engineering team
          </span>
        </div>
      )}

      <Table
        dataSource={data}
        pagination={pagination}
        columns={
          isAutoApprove
            ? [
                ...BASE_COLUMNS,
                {
                  title: "Approve At (PT)",
                  dataIndex: "approveAt",
                  width: "20%",
                },
              ]
            : BASE_COLUMNS
        }
        rowClassName={(): string => "row"}
        bordered={false}
        rowKey="id"
        onChange={({ current }): void => onPaginationChange(current)}
      />
    </div>
  </div>
);
