import React from "react";
import { Button } from "@wellth/web-ui";
import styles from "./styles";

const addressComponentStyle = { ...styles.addressComponentLabel };

export interface Address {
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface AddressSectionProps extends Address {
  title: string;
  note?: string;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  submit: () => void;
}

export const AddressSection: React.FC<AddressSectionProps> = ({
  title,
  street,
  street2,
  city,
  state,
  zip,
  note,
  submit,
  style,
  buttonStyle,
}) => (
  <div style={style}>
    <p style={styles.titleLabel}>{title}</p>

    <div style={styles.addressComponentsSection}>
      {street ? <p style={addressComponentStyle}>{street}</p> : null}
      {street2 ? <p style={addressComponentStyle}>{street2}</p> : null}
      <p style={addressComponentStyle}>
        {city}, {state} {zip}
      </p>
      {note ? <p style={styles.noteLabel}>Note: {note}</p> : null}
    </div>

    <Button
      style={{ ...styles.useAddressButton, ...buttonStyle }}
      onClick={submit}
    >
      Use this address
    </Button>
  </div>
);

export default AddressSection;
