"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const styles = {
  container: {
    margin: "1.25rem"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "1.25rem",
    paddingBottom: "1.25rem"
  }
};
exports.default = styles;