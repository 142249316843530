"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Button = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _styles = _interopRequireDefault(require("./styles"));
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/SquareButtonList/components/Button/index.tsx";
var Button = function Button(_ref) {
  var style = _ref.style,
    title = _ref.title,
    onPress = _ref.onPress,
    imageAsset = _ref.imageAsset;
  return _react["default"].createElement(_reactNative.TouchableOpacity, {
    onPress: onPress,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 26,
      columnNumber: 3
    }
  }, _react["default"].createElement(_reactNative.View, {
    style: [_styles["default"].container, style],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 27,
      columnNumber: 5
    }
  }, _react["default"].createElement(_reactNative.View, {
    style: _styles["default"].titleContainer,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 28,
      columnNumber: 7
    }
  }, _react["default"].createElement(_reactNative.Text, {
    style: _styles["default"].titleLabel,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 29,
      columnNumber: 9
    }
  }, title)), _react["default"].createElement(_reactNative.View, {
    style: _styles["default"].buttonContainer,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 32,
      columnNumber: 7
    }
  }, _react["default"].createElement(_reactNative.Image, {
    source: imageAsset,
    style: _styles["default"].imageAsset,
    resizeMode: "contain",
    __source: {
      fileName: _jsxFileName,
      lineNumber: 33,
      columnNumber: 9
    }
  }))));
};
exports.Button = Button;
var _default = Button;
exports["default"] = _default;