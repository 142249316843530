import { ReasonValues } from "components/ReviewBonusSubmissionInput";

export const RejectionReasonsOptions: ReasonValues[] = [
  {
    value: "verification incomplete",
    title: "Verification Incomplete",
  },
  { value: "verification invalid", title: "Verification Invalid" },
  { value: "verification unclear", title: "Verification Unclear" },
  {
    value: "verification not present",
    title: "Verification Not Present",
  },
  { value: "other", title: "Other" },
];

export default RejectionReasonsOptions;
