"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var Colors = {
  GREEN_NEW: "rgba(48, 102, 67, 1.0)",
  BLACK_25: "rgba(0, 0, 0, 0.25)",
  BLACK_40: "rgba(0, 0, 0, 0.4)",
  HEATHER_BLUE: "rgba(58, 117, 148, 1.0)",
  BLUE_5: "rgba(232, 248, 251, 1.0)",
  BLUE_70: "rgba(58, 117, 148, 1.0)",
  BLUE_80: "rgba(39, 93, 128, 1.0)",
  BLUE_90: "rgba(22, 68, 104, 1.0)",
  WHITE: "rgba(255, 255, 255, 1.0)",
  MAROON_50: "rgba(175, 56, 58, 1.0)",
  NEUTRAL_5: "rgba(245, 246, 247, 1.0)",
  NEUTRAL_10: "rgba(232, 233, 236, 1.0)",
  NEUTRAL_20: "rgba(227, 228, 229, 1.0)",
  NEUTRAL_40: "rgba(204, 206, 211, 1.0)",
  NEUTRAL_50: "rgba(167, 171, 176, 1.0)",
  NEUTRAL_60: "rgba(130, 133, 137, 1.0)",
  NEUTRAL_70: "rgba(104, 106, 111, 1.0)",
  NEUTRAL_80: "rgba(76, 76, 76, 1.0)",
  NEUTRAL_100: "rgba(26, 27, 29, 1.0)",
  GREEN_70: "rgba(66, 123, 87, 1.0)",
  GREY_10: "rgba(228, 231, 235, 1.0)",
  ACCESSORY_BLUE: "#3A7594",
  BLACK: "#000000FF",
  BLUE: "#254ABAFF",
  BLUE_LIGHT: "#0076FFFF",
  GREEN: "#00B183FF",
  GREEN_LIGHT: "#53D1B7FF",
  GREY: "#8A8A8FFF",
  GREY_DARK: "#666666FF",
  GREY_DARK2: "#A8A8A8FF",
  GREY_DARKER: "#393939FF",
  GREY_LIGHT: "#D3D3D3FF",
  GREY_LIGHTER: "#EDEDEDFF",
  RED: "#D23440FF",
  RED70: "#CF1124",
  GREEN1: "#EFFCF6",
  GREEN10: "#C6F7E2",
  GREEN20: "#8EEDC7",
  GREEN30: "#65D6AD",
  GREEN40: "#3EBD83",
  GREEN50: "#27AB83",
  GREEN60: "#199473",
  GREEN70: "#147D64",
  GREEN80: "#0C6B58",
  GREEN90: "#014D40",
  BLUE_GREY: "#595F72",
  BLACK80: "rgba(0,0,0,0.8)",
  WHITE50: "rgba(255,255,255,0.5)",
  BLACK50: "rgba(0,0,0,0.5)",
  BLACK30: "rgba(0,0,0,0.3)",
  WHITE30: "rgba(255,255,255,0.3)",
  BLACK15: "rgba(0,0,0,0.15)",
  BLACK5: "rgba(0,0,0,0.05)",
  TRANSPARENT: "rgba(0,0,0,0)"
};
var _default = Colors;
exports["default"] = _default;