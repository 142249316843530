import React from "react";
import { LoadingScreen } from "@wellth/ui";
import { Modal as UIModal, ModalProps as UIModalProps } from "@wellth/web-ui";

export interface ModalProps extends Omit<UIModalProps, "confirmLoading"> {
  loading?: boolean;
  submissionLoading?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  onOk,
  children,
  loading = false,
  submissionLoading = false,
  ...modalProps
}) => (
  <UIModal
    visible
    confirmLoading={submissionLoading}
    cancelButtonProps={{ disabled: submissionLoading }}
    {...modalProps}
    onOk={(e) => (!loading && !!onOk ? onOk(e) : null)}
  >
    {loading ? <LoadingScreen showLoadingIndicator /> : children}
  </UIModal>
);

export default Modal;
