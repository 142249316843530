import { createAction } from "redux-actions";
import { omit } from "lodash";

export const ERROR = "ERROR";

export type Payload = Error;
export interface Meta {
  title?: string;
  description?: string;
  suppress?: boolean;
}

export type Config = {
  error: Error;
} & Meta;

export default createAction(
  ERROR,
  (config: Config): Payload => config.error,
  (config: Config): Meta => ({
    ...omit(config, "error"),
  }),
);
