const styles: { [key: string]: React.CSSProperties } = {
  trailerContainer: {
    lineHeight: 0,
  },
  trailer: {
    margin: "5px",
  },
  verifiedIcon: {
    color: "#199473",
    marginLeft: "10px",
  },
  verifiedInput: {
    margin: "5x",
    color: "#199473",
  },
  header: {
    marginBottom: "20px",
  },
  inputStyles: {
    height: "2.1875rem",
    width: "18.75rem",
  },
};

export default styles;
