import { CheckInCategory } from "types/globalTypes";

const MA_PAYER = "MA - Payer";
const MA_DSNP = "MA - DSNP";

// Program templates that do not incentivize medication reminder
const NON_INCENTIVIZED_MEDICATION = [MA_PAYER, MA_DSNP];

export const isReminderIncentivized = (
  programTemplate: string,
  checkInCategory: CheckInCategory,
) => {
  // Medication is the only category that may not be incentivized
  if (isMedicationReminder(checkInCategory)) {
    return !NON_INCENTIVIZED_MEDICATION.includes(programTemplate);
  }
  return true;
};

export const isMedicationReminder = (checkInCategory: CheckInCategory) => {
  return checkInCategory === CheckInCategory.Medication;
};
