"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _constants = require("../../constants");
var screenWidth = _constants.FullScreenStyle.width;
var PADDING = 24;
var TWO = 2;
var MARGIN = 4;
var buttonWidth = ((typeof screenWidth === "string" ? parseInt(screenWidth, 10) : screenWidth) - PADDING) / TWO;
var _default = _reactNative.StyleSheet.create({
  listColumnContainer: {
    margin: MARGIN
  },
  button: {
    width: buttonWidth,
    margin: MARGIN
  }
});
exports["default"] = _default;