"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BlurViewProps", {
  enumerable: true,
  get: function get() {
    return _types.BlurViewProps;
  }
});
exports["default"] = exports.BlurView = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _constants = require("../../constants");
var _styles = _interopRequireDefault(require("./styles"));
var _types = require("./types");
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/BlurView/index.tsx";
var BlurView = function BlurView() {
  return _react["default"].createElement(_reactNative.View, {
    style: [_styles["default"].container, _constants.FullScreenStyle],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 10,
      columnNumber: 3
    }
  }, _react["default"].createElement(_reactNative.View, {
    style: [_constants.FullScreenStyle, _styles["default"].compatView],
    __source: {
      fileName: _jsxFileName,
      lineNumber: 11,
      columnNumber: 5
    }
  }));
};
exports.BlurView = BlurView;
var _default = BlurView;
exports["default"] = _default;