import React from "react";
import EditMailingAddressModal from "components/EditMailingAddressFormModal";
import { ContactType } from "types/globalTypes";
import {
  useEditMailingAddressQuery,
  useUpdateMailingAddressMutation,
  MemberMailingAddressesDocument as memberMailingAddressesQuery,
  ProspectMailingAddressesDocument as prospectMailingAddressesQuery,
} from "hooks/graphql";

export interface EditMailingAddressFormModalProps {
  id: string;
  contactId: string;
  contactType: ContactType;
  goBack: () => void;
}

export const EditMailingAddressFormModal: React.FC<
  EditMailingAddressFormModalProps
> = ({
  id,
  contactId,
  contactType,
  goBack,
}: EditMailingAddressFormModalProps) => {
  const {
    loading,
    data: {
      address: {
        isPrimary = false,
        street = null,
        street2 = null,
        city = null,
        state = null,
        zip = null,
      } = {},
    } = {},
  } = useEditMailingAddressQuery({
    variables: {
      mailingAddressId: id,
    },
  });

  const [
    updateMailingAddress,
    { loading: submissionLoading },
  ] = useUpdateMailingAddressMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [
      contactType === ContactType.Member
        ? { query: memberMailingAddressesQuery, variables: { id: contactId } }
        : {
            query: prospectMailingAddressesQuery,
            variables: { id: contactId },
          },
    ],
  });

  return (
    <EditMailingAddressModal
      type={contactType}
      title="Update Mailing Address"
      street={street}
      street2={street2}
      city={city}
      state={state}
      zip={zip}
      isPrimary={isPrimary}
      loading={loading}
      submissionLoading={submissionLoading}
      cancel={goBack}
      disablePrimary={contactType === ContactType.Prospect}
      submit={async (address) => {
        await updateMailingAddress({
          variables: {
            input: {
              addressPatch: {
                id,
                ...address,
                country: "USA",
              },
            },
          },
        });
      }}
    />
  );
};

export default EditMailingAddressFormModal;
