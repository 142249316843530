import React from "react";
import { connect } from "react-redux";
import { State } from "reducers";
import Component from "routers";
import clearSession from "actions/sessionClear";
import store from "constants/store";

const mapStateToProps = (state: State) => ({
  isAuthenticated: !!state.session.token,
});

export interface AppProps {
  isAuthenticated: boolean;
}

const App: React.FC<AppProps> = ({ isAuthenticated }) => (
  <Component
    isAuthenticated={isAuthenticated}
    signOut={() => store.dispatch(clearSession())}
  />
);

export default connect(mapStateToProps)(App);
