"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactNative = require("react-native");
var _colors = _interopRequireDefault(require("../../constants/colors"));
var _default = _reactNative.StyleSheet.create({
  contentContainer: {
    flexDirection: "row",
    paddingVertical: 12,
    borderColor: _colors["default"].BLACK15,
    borderBottomWidth: _reactNative.StyleSheet.hairlineWidth,
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 16
  }
});
exports["default"] = _default;