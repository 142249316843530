import React, { Key } from "react";
import moment from "moment";
import { Card as CardUI } from "@wellth/web-ui";
import Button, { ButtonProps } from "antd/lib/button";
import CARD_STYLES from "../styles";

const IMAGE_STYLES: React.CSSProperties = {
  width: "15rem",
  height: "26.25rem",
};

const CARD_STYLE: React.CSSProperties = {
  alignSelf: "center",
  margin: "0.625rem 0 0.625rem 0",
  ...CARD_STYLES.card,
};

const BODY_STYLE: React.CSSProperties = {
  backgroundColor: "white",
  borderRadius: 10,
};

const HEADER_STYLE: React.CSSProperties = {
  fontSize: "1.5rem",
  ...CARD_STYLES.head,
};

const ROW_STYLE: React.CSSProperties = {
  padding: "1.25rem",
};

const SUBMISSION_INFO_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const DETAILS_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingTop: "1.25rem",
};

const FOOTER_STYLE: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "row",
};

type FooterButtonProps = ButtonProps & { key: Key; title: string };

interface DetailsProps {
  submittedOn: Date;
  submittedBy: string;
  programCode: string;
  reward: string;
  submissionId: string;
  routeToMemberPage: () => void;
}

const Details: React.FC<DetailsProps> = ({
  submittedOn,
  submittedBy,
  programCode,
  reward,
  submissionId,
  routeToMemberPage,
}) => (
  <div style={DETAILS_STYLE}>
    <div>
      Submitted On: {moment(submittedOn).format("h:mm A z on MMMM D, YYYY")}
    </div>
    <div>
      Submitted By: <a onClick={routeToMemberPage}>{submittedBy}</a>
    </div>
    <div>Program Code: {programCode}</div>
    <div>Incentive Amount: {reward}</div>
    <div>Submission Id: {submissionId.split("-")[0]}</div>
  </div>
);

export interface SubmissionReviewCardProps {
  title?: string;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  innerBodyStyle?: React.CSSProperties;
  buttonTitle?: string;
  popconfirm?: boolean;
  placeholder?: string;
  popconfirmTitle?: string;
  photoUrl: string;
  footerButtons: FooterButtonProps[];
  details: DetailsProps;
}

export const SubmissionReviewCard: React.FC<SubmissionReviewCardProps> = ({
  title,
  children,
  footerButtons,
  photoUrl,
  details: {
    submittedOn,
    submittedBy,
    programCode,
    reward,
    submissionId,
    routeToMemberPage,
  },
}) => (
  <CardUI
    headStyle={CARD_STYLES.head}
    bodyStyle={BODY_STYLE}
    style={CARD_STYLE}
  >
    <div style={SUBMISSION_INFO_STYLE}>
      <div style={ROW_STYLE}>
        {photoUrl ? (
          <img style={IMAGE_STYLES} src={photoUrl} alt="submission" />
        ) : null}

        <Details
          submittedOn={submittedOn}
          submittedBy={submittedBy}
          programCode={programCode}
          reward={reward}
          submissionId={submissionId}
          routeToMemberPage={routeToMemberPage}
        />
      </div>
      <div style={{ width: "26.5625rem", ...ROW_STYLE }}>
        <div style={HEADER_STYLE}>{title}</div>
        {children}
      </div>
    </div>
    <div style={FOOTER_STYLE}>
      {footerButtons.map(({ title: buttonTitle, key, ...buttonProps }) => (
        <Button key={key} type="primary" {...buttonProps}>
          {buttonTitle}
        </Button>
      ))}
    </div>
  </CardUI>
);

export default SubmissionReviewCard;
