import React, { useRef } from "react";
import { Modal } from "components/Modal";
import EditPhoneNumberForm, {
  EditPhoneNumberFormProps,
  PhoneNumberFormProps,
} from "components/EditPhoneNumberForm";

export interface EditPhoneNumberFormModalProps
  extends EditPhoneNumberFormProps {
  title: React.ReactNode;
  loading?: boolean;
  submissionLoading?: boolean;
  submit: (values: PhoneNumberFormProps) => void;
  cancel: () => void;
}

export const EditPhoneNumberFormModal: React.FC<
  EditPhoneNumberFormModalProps
> = ({
  loading = false,
  submissionLoading = false,
  submit,
  cancel,
  title,
  ...formProps
}: EditPhoneNumberFormModalProps) => {
  const formRef = useRef(null);

  return (
    <Modal
      title={title}
      okText="Submit"
      loading={loading}
      submissionLoading={submissionLoading}
      onCancel={cancel}
      onOk={async () => submit(await formRef.current.getPhoneNumber())}
      width={700}
    >
      <EditPhoneNumberForm {...formProps} ref={formRef} />
    </Modal>
  );
};

export default EditPhoneNumberFormModal;
