import React, { useRef } from "react";
import { Modal } from "components/Modal";
import CreateBonusBehaviorForm, {
  CreateBonusBehaviorFormInputs,
} from "components/CreateBonusBehaviorForm";

const INSTRUCTION_STYLE: React.CSSProperties = {
  padding: "1rem 0 1rem 0",
  color: "black",
};

export interface BonusBehaviorConfig {
  id: string;
  name: string;
  programCode: string;
  endDate: string;
  startDate: string;
  rewardAmount: number;
  note: string;
  bonusBehaviorPagesetId: string;
}

export interface AvailablePrograms {
  id: string;
  programCode: string;
}

export interface CreateBonusBehaviorFormModalProps {
  title: React.ReactNode;
  loading: boolean;
  programLoading: boolean;
  cancel: () => void;
  submit: (values: CreateBonusBehaviorFormInputs) => void;
  bonusBehaviorConfigs: BonusBehaviorConfig[];
  availablePrograms: AvailablePrograms[];
  submissionLoading: boolean;
}

export const CreateBonusBehaviorModal: React.FC<
  CreateBonusBehaviorFormModalProps
> = ({
  cancel,
  title,
  bonusBehaviorConfigs,
  programLoading,
  availablePrograms,
  submit,
  submissionLoading,
}) => {
  const formRef = useRef(null);
  return (
    <Modal
      title={title}
      okText="Submit"
      submissionLoading={submissionLoading}
      loading={programLoading}
      onCancel={cancel}
      width={700}
      onOk={async () => submit(await formRef.current.getBonusBehavior())}
    >
      <div style={INSTRUCTION_STYLE}>
        Create a new Bonus Behavior using an existing Bonus Behavior Config from
        another program. Configs include all the pages seen in the drop down for
        each Bonus Behavior on the previous page. If you do not see the config
        you are looking for, contact support for more help.
      </div>
      <CreateBonusBehaviorForm
        bonusBehaviorConfigs={bonusBehaviorConfigs.map(
          ({ bonusBehaviorPagesetId, name, programCode }) => ({
            title: `${programCode} - ${name}`,
            value: `${bonusBehaviorPagesetId}, ${programCode}`,
          }),
        )}
        availablePrograms={availablePrograms.map(({ id, programCode }) => ({
          title: programCode,
          value: id,
        }))}
        ref={formRef}
      />
    </Modal>
  );
};

export default CreateBonusBehaviorModal;
