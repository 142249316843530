import React from "react";
import EditMailingAddressModal from "components/EditMailingAddressFormModal";
import { ContactType } from "types/globalTypes";
import {
  useCreateMailingAddressMutation,
  MemberMailingAddressesDocument as memberMailingAddressesQuery,
  ProspectMailingAddressesDocument as prospectMailingAddressesQuery,
} from "hooks/graphql";

export interface EditMailingAddressFormModalProps {
  contactId: string;
  contactType: ContactType;
  goBack: () => void;
}

export const EditMailingAddressFormModal: React.FC<
  EditMailingAddressFormModalProps
> = ({ goBack, contactId, contactType }: EditMailingAddressFormModalProps) => {
  const [
    createMailingAddress,
    { loading: submissionLoading },
  ] = useCreateMailingAddressMutation({
    onCompleted: goBack,
    awaitRefetchQueries: true,
    refetchQueries: [
      contactType === ContactType.Member
        ? { query: memberMailingAddressesQuery, variables: { id: contactId } }
        : {
            query: prospectMailingAddressesQuery,
            variables: { id: contactId },
          },
    ],
  });

  return (
    <EditMailingAddressModal
      type={contactType}
      title="Create Mailing Address"
      cancel={goBack}
      submissionLoading={submissionLoading}
      submit={async ({
        street,
        street2,
        city,
        state,
        zip,
        isPrimary = false,
      }) => {
        await createMailingAddress({
          variables: {
            contactId,
            contactType,
            input: {
              address: {
                street,
                street2,
                city,
                state,
                zip,
                isPrimary,
                country: "USA",
              },
            },
          },
        });
      }}
    />
  );
};

export default EditMailingAddressFormModal;
