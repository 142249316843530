import React from "react";
import { BrowserRouter } from "react-router-dom";
import AuthenticationForm from "containers/Authentication";
import App from "routers/App";

export interface Props {
  isAuthenticated: boolean;
  signOut: () => void;
}

const authenticationFormStyles: React.CSSProperties = {
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

export const Router: React.FC<Props> = ({ isAuthenticated, signOut }) =>
  isAuthenticated ? (
    <BrowserRouter>
      <App signOut={signOut} />
    </BrowserRouter>
  ) : (
    <div style={authenticationFormStyles}>
      <AuthenticationForm />
    </div>
  );

export default Router;
