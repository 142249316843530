import { SectionType, FormInputType } from "@wellth/web-ui";
import {
  TypeOfEnrollmentOptions,
  IntroductionToWellthOptions,
} from "types/globalTypes";
import { BindFormSectionOptions, AnySectionBinding } from "./types";

export interface PaymentOptions {
  id: string;
  name: string;
}

export interface OtherInformation {
  typeOfEnrollment: TypeOfEnrollmentOptions;
  introductionToWellth: IntroductionToWellthOptions;
  paymentOptions?: PaymentOptions[];
  paymentOptionsId?: string;
}

export type OtherInformationInputOptions = BindFormSectionOptions<
  OtherInformation
>;

const setPaymentOptionsId = (
  paymentOptionsId: string,
  paymentOptions: PaymentOptions[],
): string => {
  if (paymentOptionsId) {
    return paymentOptionsId;
  }
  return paymentOptions.length === 1 ? paymentOptions[0].id : null;
};

export const Inputs: AnySectionBinding<OtherInformationInputOptions> = ({
  extraProps: {
    typeOfEnrollment = null,
    introductionToWellth = null,
    paymentOptions = [],
    paymentOptionsId = null,
  } = {},
  ...props
}) => ({
  ...props,
  title: "Other Information",
  key: "otherInformation",
  type: SectionType.Form,
  data: {
    mapPropsToFields: (_props, createFormField) => ({
      typeOfEnrollment: createFormField({ value: typeOfEnrollment }),
      introductionToWellth: createFormField({
        value: introductionToWellth,
      }),
      paymentOptionsId: createFormField({
        value: setPaymentOptionsId(paymentOptionsId, paymentOptions),
      }),
    }),
    inputs: [
      {
        type: FormInputType.Dropdown,
        key: "typeOfEnrollment",
        title: "Type of Enrollment",
        decoratorOptions: { rules: [{ required: true }] },
        options: [
          {
            title: "Remote - Inbound",
            value: TypeOfEnrollmentOptions.RemoteInbound,
          },
          {
            title: "Remote - Outbound",
            value: TypeOfEnrollmentOptions.RemoteOutbound,
          },
          {
            title: "Remote - Interest Form",
            value: TypeOfEnrollmentOptions.RemoteInterestForm,
          },
          {
            title: "Remote - Self Serve Assistance",
            value: TypeOfEnrollmentOptions.RemoteSelfServeAssistance,
          },
          {
            title: "Wellth In Person",
            value: TypeOfEnrollmentOptions.WellthInPerson,
          },
          {
            title: "Customer Enrollment",
            value: TypeOfEnrollmentOptions.CustomerEnrollment,
          },
          { title: "Other", value: TypeOfEnrollmentOptions.Other },
        ],
      },
      {
        type: FormInputType.Dropdown,
        key: "introductionToWellth",
        title: "How did the Member hear about us?",
        decoratorOptions: { rules: [{ required: true }] },
        options: [
          {
            title: "Wellth Letter",
            value: IntroductionToWellthOptions.WellthLetter,
          },
          {
            title: "Wellth Postcard",
            value: IntroductionToWellthOptions.WellthPostcard,
          },
          {
            title: "Wellth Bifold",
            value: IntroductionToWellthOptions.WellthBifold,
          },
          {
            title: "Wellth Email",
            value: IntroductionToWellthOptions.WellthEmail,
          },
          {
            title: "Wellth Text Message",
            value: IntroductionToWellthOptions.SmsTextFromWellth,
          },
          {
            title: "Wellth Facebook Ad",
            value: IntroductionToWellthOptions.WellthFacebookAd,
          },
          {
            title: "Wellth Fyler",
            value: IntroductionToWellthOptions.WellthFlyer,
          },
          {
            title: "Wellth Phone Call",
            value: IntroductionToWellthOptions.WellthPhoneCall,
          },
          {
            title: "Wellth Website",
            value: IntroductionToWellthOptions.WellthWebsite,
          },
          {
            title: "Customer Mailer",
            value: IntroductionToWellthOptions.CustomerMailer,
          },
          {
            title: "Customer Email",
            value: IntroductionToWellthOptions.CustomerEmail,
          },
          {
            title: "Customer Website",
            value: IntroductionToWellthOptions.CustomerWebsite,
          },
          {
            title: "Customer Flyer/Handout",
            value: IntroductionToWellthOptions.CustomerFlyerHandout,
          },
          {
            title: "Customer Care Team",
            value: IntroductionToWellthOptions.CustomerCareTeam,
          },
          { title: "Provider", value: IntroductionToWellthOptions.Provider },
          {
            title: "Family Or Friends",
            value: IntroductionToWellthOptions.FamilyOrFriends,
          },
          {
            title: "Previously participated in a Wellth program",
            value: IntroductionToWellthOptions.ParticipatedInPreviousProgram,
          },
          { title: "Other", value: IntroductionToWellthOptions.Other },
        ],
      },
      {
        type: FormInputType.Dropdown,
        key: "paymentOptionsId",
        title: "Member's Reward Card Choice",
        disabled: paymentOptions.length === 1,
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: "Member's Reward Card Choice is required",
            },
          ],
        },
        options: paymentOptions.map(({ id, name }) => ({
          title: name,
          value: id,
        })),
      },
    ],
  },
});
