import React from "react";
import { LoadingScreen } from "@wellth/ui";
import PatientNotesUI from "components/PatientNotes";
import {
  useCreatePatientNoteMutation,
  useMemberNotesQuery,
  MemberNotesDocument,
} from "hooks/graphql";

export interface PatientNotesProps {
  patientId: string;
  adminId: string;
}

export const PatientNotes: React.FC<PatientNotesProps> = ({
  patientId,
  adminId,
}: PatientNotesProps) => {
  const {
    data: { member: { memberNotes = [] } = {} } = {},
    loading,
  } = useMemberNotesQuery({
    variables: {
      id: patientId,
    },
  });

  const [createNote] = useCreatePatientNoteMutation({
    context: { requireOnline: true },
    refetchQueries: [
      { query: MemberNotesDocument, variables: { id: patientId } },
    ],
  });

  if (loading) return <LoadingScreen showLoadingIndicator />;

  return (
    <PatientNotesUI
      notes={memberNotes.map(({ note, id, createdAt, admin }) => ({
        id,
        createdAt,
        body: note,
        adminName: admin ? `${admin.firstName} ${admin.lastName}` : "System",
      }))}
      sendMessage={async (text) =>
        createNote({
          variables: {
            input: {
              patientNote: {
                patientId,
                adminId,
                note: text,
              },
            },
          },
        })
      }
    />
  );
};

export default PatientNotes;
