import React, { useRef, useImperativeHandle } from "react";
import { Form, FormInputType, OptionProps } from "@wellth/web-ui";
import styles from "./styles";

export interface PaymentOptionFormProps {
  memberPaymentOption: string;
  programPaymentOptions: OptionProps[];
  desiredPaymentOption: string;
}

export interface EditPaymentOptionFormRef {
  getPaymentOptionInput: () => Promise<PaymentOptionFormProps>;
}

export type EditPaymentOptionFormProps = Partial<PaymentOptionFormProps>;

const EditPaymentOptionFormRFC: React.RefForwardingComponent<
  EditPaymentOptionFormRef,
  EditPaymentOptionFormProps
> = ({ memberPaymentOption, programPaymentOptions, ...formProps }, ref) => {
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getPaymentOptionInput: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      {...formProps}
      wrappedComponentRef={formRef}
      inputContainerStyle={styles.inputContainerStyle}
      mapPropsToFields={(_props, createFormField) => ({
        currentPaymentOption: createFormField({
          value: memberPaymentOption,
        }),
      })}
      inputs={[
        {
          type: FormInputType.Info,
          title: "Current Payment Option",
          key: "currentPaymentOption",
          itemProps: { style: styles.itemStyle },
          rowProps: { gutter: undefined },
          columnProps: { span: undefined },
        },
        {
          type: FormInputType.Dropdown,
          title: "New Desired Payment Option",
          key: "desiredPaymentOption",
          placeholder: "Pick a payment option",
          itemProps: { style: styles.itemStyle },
          rowProps: { gutter: undefined },
          columnProps: { span: undefined },
          options: programPaymentOptions,
          decoratorOptions: {
            rules: [
              { message: "New payment option is required", required: true },
            ],
          },
        },
      ]}
    />
  );
};

export const EditPaymentOptionForm = React.forwardRef(EditPaymentOptionFormRFC);

export default EditPaymentOptionForm;
