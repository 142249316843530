import React from "react";
import { Popconfirm } from "@wellth/web-ui";
import COLORS from "utils/colors";

const SHARED_LOCK_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  height: "2.5rem",
  alignItems: "center",
  padding: "0 0.25rem",
};

const SHARED_LOCK_TEXT_STYLES: React.CSSProperties = {
  fontSize: "0.875rem",
  fontWeight: "bold",
  lineHeight: 1.57,
};

const UNLOCKED_TEXT_STYLE: React.CSSProperties = {
  ...SHARED_LOCK_TEXT_STYLES,
  color: COLORS.orange,
};

const UNLOCKED_CONTAINER_STYLE: React.CSSProperties = {
  ...SHARED_LOCK_CONTAINER_STYLES,
  background: COLORS.lightOrange,
};

const LOCK_CONTAINER_STYLE: React.CSSProperties = {
  ...SHARED_LOCK_CONTAINER_STYLES,
  background: COLORS.lightBlue,
};

const LOCK_TEXT_STYLE: React.CSSProperties = {
  ...SHARED_LOCK_TEXT_STYLES,
  color: COLORS.darkGray,
};

const BUTTON_STYLE: React.CSSProperties = {
  fontSize: " 0.75rem",
  cursor: "pointer",
};

const LOCK_BUTTON_STYLE: React.CSSProperties = {
  color: COLORS.midBlue,
  ...BUTTON_STYLE,
};

const UNLOCKED_BUTTON_STYLE: React.CSSProperties = {
  color: COLORS.orange,
  ...BUTTON_STYLE,
};

export interface LockProps {
  isReminderEditingOpen: boolean;
  handleReminderLockChange: () => void;
}

export const Lock: React.FC<LockProps> = ({
  isReminderEditingOpen,
  handleReminderLockChange,
}: LockProps) => (
  <>
    {isReminderEditingOpen ? (
      <div style={UNLOCKED_CONTAINER_STYLE}>
        <span style={UNLOCKED_TEXT_STYLE}>UNLOCKED</span>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleReminderLockChange}
        >
          <span style={LOCK_BUTTON_STYLE}>Lock reminders</span>
        </Popconfirm>
      </div>
    ) : (
      <div style={LOCK_CONTAINER_STYLE}>
        <span style={LOCK_TEXT_STYLE}>LOCKED</span>
        <Popconfirm
          title="Sure to Unlock?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleReminderLockChange}
        >
          <span style={UNLOCKED_BUTTON_STYLE}>Unlock reminders</span>
        </Popconfirm>
      </div>
    )}
  </>
);

export default Lock;
