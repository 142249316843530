import React from "react";
import { SectionedForm, DividerLayout, FormInputType } from "@wellth/web-ui";
import programExperienceSection from "components/ProgramExperienceInputs";
import { isSuperAdmin } from "utils/roles";
import namesSection, { NamesSectionProps } from "./sections/names";
import profileSection, { ProfileSectionProps } from "./sections/profile";
import emailAddressesSection, {
  EmailAddressesSectionProps,
} from "./sections/emailAddresses";
import phoneNumbersSection, {
  PhoneNumberSectionProps,
} from "./sections/phoneNumbers";
import mailingAddressesSection, {
  MailingAddressesSectionProps,
} from "./sections/mailingAddresses";
import { ProgramExperienceSectionProps } from "./types";

export interface ProspectInformationFormConfiguration {
  names: NamesSectionProps;
  profile: ProfileSectionProps;
  emailAddresses: EmailAddressesSectionProps;
  phoneNumbers: PhoneNumberSectionProps;
  mailingAddresses: MailingAddressesSectionProps;
  programExperience: ProgramExperienceSectionProps;
}

export type ProspectInformationFormProps = ProspectInformationFormConfiguration;

export const ProspectInformationForm: React.FC<
  ProspectInformationFormProps
> = ({
  names,
  profile,
  emailAddresses,
  phoneNumbers,
  mailingAddresses,
  programExperience,
}) => (
  <SectionedForm
    container={DividerLayout}
    sections={[
      namesSection({
        ...names,
      }),
      profileSection({
        ...profile,
      }),
      phoneNumbersSection({
        ...phoneNumbers,
      }),
      emailAddressesSection({
        ...emailAddresses,
      }),
      mailingAddressesSection({
        ...mailingAddresses,
      }),
      isSuperAdmin(programExperience.adminRole)
        ? programExperienceSection({
            ...programExperience,
            data: {
              mapPropsToFields: (props, createFormField) => ({
                isTestUser: createFormField({
                  value: programExperience.isTestUser,
                }),
              }),
              inputs: [
                {
                  type: FormInputType.Switch,
                  title: "Is test user?",
                  key: "isTestUser",
                  decoratorOptions: { valuePropName: "checked" },
                  disabled: programExperience.isTestUser,
                },
              ],
              onValuesChange: (_, { isTestUser }) => {
                programExperience.isTestUserToggled(isTestUser);
              },
              labelCol: { sm: 9 },
            },
          })
        : null,
    ]}
  />
);

export default ProspectInformationForm;
