const COLORS = {
  red: "#b93831",
  lightRed: "#FFCCC7",
  green: "#3d948a",
  lightGreen: "#eefcf6",
  blue: "#3f51b5",
  white: "#ffffff",
  gray: "#d6d0d0",
  lightGray: "#aaaaaa",
  black: "#272727",
  blueGray: "#e6ecf0",
  lightBlue: "#e6f7ff",
  darkGray: "#667580",
  midBlue: "#007aff",
  lightOrange: "#fff2e8",
  orange: "#fa541c",
  lightGrayBlue: "#e4e7eb",
  midGray: "#52606d",
  paleGray: "#E7E7E7",
  yellow: "#F0B429",
};

export default COLORS;
