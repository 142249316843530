import * as React from "react";
import moment from "moment-timezone";
import { Timeline } from "antd";
import { isEmpty } from "lodash";
import { DATE_TIME_FORMAT } from "utils/date";

const BOLD_STYLE: React.CSSProperties = { fontWeight: "bold" };
const LIGHTER_STYLE: React.CSSProperties = {
  fontWeight: "lighter",
  fontStyle: "italic",
};

const STYLE_CHECKIN_SUBMITTED: React.CSSProperties = { color: "#199473" };
const STYLE_CHECKIN_SUCCESSFUL: React.CSSProperties = { color: "#F35627" };
const STYLE_WIFI: React.CSSProperties = { color: "#0967D2" };
const STYLE_CELL: React.CSSProperties = { color: "#BA3AE7" };
const STYLE_UNKNOWN: React.CSSProperties = { color: "#FF0000" };

const DATE_FORMAT = "MMM Do YYYY";

export type TimelineEvent = {
  id: string;
  name: string;
  timestamp: string;
  properties: string;
};

interface ActivityTimelineProps {
  events: TimelineEvent[];
  timezone: string;
}

const READABLE_EVENT = {
  "Member Paused": "Paused due to <last_pause_reason>",
  "Member UnPaused": "Unpaused",
  PaymentSuccess: "Successful Payment of $<amount>",
  MemberActivation: "Activated",
  "Member Status Change": "Status Changed to <To> by <Admin>",
  "Primary Email Updated": "Primary Email Updated by <Actor>",
  "Primary Address Updated": "Primary Address Updated by <Actor>",
  "Identity Tag Added": "Identity Tag <Tag Name> Added",
  "Identity Tag Removed": "Identity Tag <Tag Name> Removed",
  "Bonus Behavior Approved": "Bonus Behavior <Bonus Name> Approved",
  "Bonus Behavior Rejected": "Bonus Behavior <Bonus Name> Rejected",
  "Check In Credited":
    "Check In Credited for <Reminder Type> Reminder due to <Credited Reason>",
  "Highlight Sent": "<Highlight Name> Highlight Sent",
  "Highlight survey response":
    "<Highlight Name> Highlight survey response was <Survey Response>",
  "Highlight Interaction": "Highlight Interaction was <target>",
  "Highlight Displayed": "Highlight <Highlight Name> was displayed",
  Interaction: "Interaction type <type> on <target>",
  "Alert Card": "Alert Card displayed showing <title>",
  Navigation: "Navigate TO <to> FROM <from>",
  "Check In Successfully Received - App":
    "Check In Successfully Received on App for reminder type <Reminder Type> via <Network Connection Type>",
  "Check In Successfully Received - Server":
    "Check In Successfully Received - Server for reminder type <Reminder Type>",
  "Check In Submitted": "Check In Submitted for reminder type <Reminder Type>",
  Error: "Error: <message>",
  "Enrollment Permission Changed - Notification":
    "Enrollment Permission Changed - Notification to <State>",
  "Enrollment Permission Changed - Camera":
    "Enrollment Permission Changed - Camera to <State>",
  $identify: "Identify",
};

const replaceStringValuesJSX = (str: string, replaceMap: any) => {
  if (isEmpty(replaceMap)) {
    return str;
  }

  const {
    "Reminder Type": reminder = "unknown",
    "Network Connection Type": connection = "unknown",
  } = replaceMap;

  if (str === "Check In Submitted for reminder type <Reminder Type>") {
    return (
      <>
        <span style={STYLE_CHECKIN_SUBMITTED}>Check In Submitted</span> for
        reminder type {reminder}
      </>
    );
  }

  if (
    str ===
    "Check In Successfully Received on App for reminder type <Reminder Type> via <Network Connection Type>"
  ) {
    let style = STYLE_UNKNOWN;
    if (connection.toLowerCase() === "wifi") {
      style = STYLE_WIFI;
    } else if (connection.toLowerCase() === "cellular") {
      style = STYLE_CELL;
    }
    return (
      <>
        <span style={STYLE_CHECKIN_SUCCESSFUL}>
          Check In Successfully Received
        </span>{" "}
        on App for reminder type {reminder} via
        <span style={style}> {connection}</span>
      </>
    );
  }

  if (
    str ===
    "Check In Successfully Received - Server for reminder type <Reminder Type>"
  ) {
    return (
      <>
        <span style={STYLE_CHECKIN_SUCCESSFUL}>
          Check In Successfully Received
        </span>{" "}
        - Server for reminder type {reminder.toUpperCase()}
      </>
    );
  }

  const regex = new RegExp(
    Object.keys(replaceMap)
      .map((value) => `<${value}>`)
      .join("|"),
    "gi",
  );

  const content = str.replace(regex, (matched) => {
    const replacement = replaceMap[matched.slice(1, -1)];
    return replacement || replacement === 0 ? replacement : "";
  });

  return <span>{content}</span>;
};

const ActivityTimeline: React.FC<ActivityTimelineProps> = ({
  events,
  timezone,
}) => {
  const hasEvents = events.length > 0;

  return hasEvents ? (
    <Timeline>
      {events.map(({ id, name, timestamp, properties }) => {
        if (name === "DATE") {
          return (
            <Timeline.Item
              key={id}
              color="grey"
              dot={moment(timestamp)
                .tz("UTC")
                .format(DATE_FORMAT)}
            >
              <div style={BOLD_STYLE}>&nbsp;</div>
            </Timeline.Item>
          );
        }
        return (
          <Timeline.Item key={id} color="#BFBFBF">
            <div style={BOLD_STYLE}>
              {replaceStringValuesJSX(
                READABLE_EVENT[name] || name,
                JSON.parse(properties),
              )}
            </div>
            <div style={LIGHTER_STYLE}>
              {moment(timestamp)
                .tz(timezone)
                .format(DATE_TIME_FORMAT)}
            </div>
          </Timeline.Item>
        );
      })}
    </Timeline>
  ) : (
    <div>NO EVENTS YET</div>
  );
};

export default ActivityTimeline;
