"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CircularImageButton = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _CircularLoadingButton = require("../CircularLoadingButton");
var _jsxFileName = "/home/circleci/wellth/packages/ui/src/components/CircularImageButton/index.tsx";
var CircularImageButton = function CircularImageButton(_ref) {
  var imageStyle = _ref.imageStyle,
    imageSource = _ref.imageSource,
    props = (0, _objectWithoutProperties2["default"])(_ref, ["imageStyle", "imageSource"]);
  return _react["default"].createElement(_CircularLoadingButton.CircularLoadingButton, (0, _extends2["default"])({
    testID: "circular-image-button",
    accessibilityLabel: "circular-image-button"
  }, props, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 23,
      columnNumber: 3
    }
  }), _react["default"].createElement(_reactNative.Image, {
    testID: "circular-image-button-image",
    accessibilityLabel: "circular-image-button-image",
    style: imageStyle,
    source: imageSource,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 28,
      columnNumber: 5
    }
  }));
};
exports.CircularImageButton = CircularImageButton;
var _default = CircularImageButton;
exports["default"] = _default;